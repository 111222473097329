import { AppBar, Container, Fab, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import OptionBar from "../../../components/OptionsBar";
import { TabPanel } from "../Money";

import AgendaHistory from "./AgendaHistory";
import EventAgenda from "./EventAgenda";

export default function Agenda() {
  const panels = [
    {
      value: 0,
      title: "Toekomst",
      component: <EventAgenda past={false} />,
    },
    {
      value: 1,
      title: "Verleden",
      component: <EventAgenda past={true} />,
    },
    // { value: 2, title: "Verrekeningen", component: <div>verrekeningen</div> },
  ];
  const [activePanel, setActivePanel] = useState(panels[0]);

  const handleChange = (value: any, index: number) => {
    setActivePanel(panels[index]);
  };

  const handleChangeIndex = (event: React.SyntheticEvent, value: number) => {
    setActivePanel(panels[value]);
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 56,
        paddingBottom: 120,

        width: "100%",
      }}
    >
      <AppBar position="static">
        <Tabs
          value={activePanel.value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {panels.map((panel, index) => {
            return (
              <Tab key={index} label={panel.title} id={`panel-${index}`} />
            );
          })}
        </Tabs>
      </AppBar>
      <Container>
        {/* <Tabs onChange={handleChangeIndex} value={activePanel.value}> */}
        {panels.map((panel, index) => {
          return (
            <TabPanel key={index} value={activePanel.value} index={index}>
              {panel.component}
            </TabPanel>
          );
        })}
        {/* </Tabs> */}
      </Container>
      {/* <OptionBar>
        <Grid container spacing={1} style={{ direction: "rtl" }}>
          <Grid item>
            <Fab
              size="small"
              onClick={() => nagivate("../expenses/expense/new")}
              disabled={!hasInternet}
            >
              <AddRoundedIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Center>
              <Typography>Uitgave</Typography>
            </Center>
          </Grid>
        </Grid>
      </OptionBar> */}
    </div>
  );
}
