import { PersistedState } from "redux-persist";
import { RootState } from "./store";

export const migrations = {
  0: (state: PersistedState) => {
    return {
      ...state,
      groups: {
        // @ts-expect-error
        // eslint-disable-next-line
        groups: state?.groups?.groups?.map((g: Group) => {
          return {
            ...g,
            statistics: [],
          };
        }),
      },
    };
  },
  1: (state: PersistedState) => undefined,
  2: (state: PersistedState) => undefined,
  3: (state: PersistedState) => {
    // @ts-expect-error
    if (!state?.groups?.groups) {
      return undefined;
    } else {
      return state;
    }
  },
  4: (state: PersistedState) => {
    // @ts-expect-error
    if (!state?.groups?.groups) {
      return undefined;
    } else {
      return state;
    }
  },
  5: (state: PersistedState) => {
    // @ts-expect-error
    if (state?.groups?.groups) {
      return {
        ...state,
        groups: undefined,
      };
    } else {
      return state;
    }
  },
  6: (state: PersistedState) => {
    return {
      ...state,
      groups: undefined,
    };
  },
  7: (state: PersistedState) => {
    return {
      ...state,

      groups: {
        // @ts-expect-error
        groups: state.groups.groups.map((group) => {
          return { ...group, shared_list: [] };
        }),
      },
    };
  },
  8: () => {
    return undefined;
  },
  9: () => {
    return undefined;
  },
};
