import {
  createTheme,
  ThemeProvider as ThemeProviderMUI,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useEffect, useState, useContext, ReactNode } from "react";
import { Storage } from "@capacitor/storage";
import { ThemeContext } from "./themeContext";
import GlobalStyles from "@mui/material/GlobalStyles";

export enum ThemeType {
  LIGHT = "light",
  DARK = "dark",
}

interface ThemeInterface {
  backgroundColor: string;
  currentTheme: ThemeType;
  switchTheme: () => void;
  setAutoTheme: () => void;
  StyledEngineProvider: any;
}

function useThemeL(): ThemeInterface {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(getSystemTheme());
  const [backgroundColor, setBackgroundColor] = useState(
    "rgba(251, 247, 236, 1)"
  );

  useEffect(() => {
    async function setTheme() {
      const theme = await getThemeType();

      setCurrentTheme(theme);

      if (theme === ThemeType.DARK) {
        setBackgroundColor("rgba(15, 13, 10, 1)");
      } else {
        setBackgroundColor("rgba(251, 247, 236, 1)"); // rgba(240, 242, 245, 0.8)
      }
    }

    setTheme();
  }, [currentTheme]);

  const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
  mediaQueryList.addEventListener("change", (event) => {
    const themeT = event.matches ? ThemeType.DARK : ThemeType.LIGHT;
    setThemeType(themeT);
  });

  function getSystemTheme(): ThemeType {
    // return window.matchMedia("(prefers-color-scheme: dark)").matches
    //   ? ThemeType.DARK
    //   : ThemeType.LIGHT;

    return ThemeType.LIGHT;
  }

  async function setThemeType(theme: ThemeType | undefined) {
    if (!theme) {
      // localStorage.removeItem("theme_type");
      await Storage.remove({ key: "theme_type" });
    } else {
      await Storage.set({ key: "theme_type", value: theme });
      // localStorage.setItem("theme_type", theme);

      setCurrentTheme(theme);
    }
  }

  async function setAutoTheme() {
    setThemeType(undefined);
  }

  async function getThemeType(): Promise<ThemeType> {
    const getResult = await Storage.get({ key: "theme_type" });
    const themeT = getResult.value;

    if (!themeT) {
      return getSystemTheme();
    }

    return themeT === ThemeType.LIGHT ? ThemeType.LIGHT : ThemeType.DARK;
  }

  async function switchTheme() {
    let newT = ThemeType.LIGHT;

    if (currentTheme === ThemeType.LIGHT) {
      newT = ThemeType.DARK;
    }

    setThemeType(newT);
  }

  return {
    currentTheme,
    switchTheme,
    backgroundColor,
    setAutoTheme,
    StyledEngineProvider,
  };
}

export function ThemeProvider({ children, ...rest }: { children: ReactNode }) {
  const { currentTheme, backgroundColor, switchTheme, setAutoTheme } =
    useThemeL();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#006C4C", //"#023047",
      },
      secondary: {
        main: "#F59000", // "#FFB703", // From Max -> "#F59000",, //"#fb8500",
      },
      error: {
        main: "#f44336",
      },
      success: {
        main: "#4caf50",
      },
      mode: currentTheme,
    },
    typography: {
      button: {
        fontWeight: "bold",
        textDecoration: "none !important",
        textTransform: "none",
      },
      allVariants: {
        color: "rgb(100, 116, 139)",

        outlineColor: "red",
      },

      fontFamily: [
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "Noto Sans",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
        "Noto Color Emoji",
      ].join(","),
      // fontSize: 12,
      body1: {
        color: currentTheme === ThemeType.LIGHT ? "black" : "white",
      },
    },
    shape: {
      borderRadius: 10,
    },

    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            // borderRadius: 5,
            // boxShadow: "",
            // border: "1px solid gray",
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          maxWidthXs: "60%",

          root: {
            paddingLeft: 5,
            paddingRight: 5,
            minHeight: "100%",

            // marginBottom: 100,
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
          },
          contained: {
            color: currentTheme === ThemeType.LIGHT ? "white" : "black",
          },
        },
      },
      MuiFab: {
        defaultProps: {
          variant: "circular",
          color: "primary",
          size: "small",
        },
        styleOverrides: {
          root: {
            color: currentTheme === ThemeType.LIGHT ? "white" : "black",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRadius: "0 15px 15px 0",
          },
        },
      },
    },

    // color: theme.palette.background.default,
  });

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        body: {
          height: "100%",
          backgroundColor: `${backgroundColor}`,
          // animation: `$changeBackground ${switchTime}ms`,
        },
        a: {
          textDecoration: "none",
        },
      }}
    />
  );

  return (
    <ThemeContext.Provider
      value={{ switchTheme: switchTheme, currentTheme, setAutoTheme }}
    >
      <ThemeProviderMUI theme={theme} {...rest}>
        {/* @ts-ignore */}
        {inputGlobalStyles}

        {children}
      </ThemeProviderMUI>
    </ThemeContext.Provider>
  );
}

export default function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw Error("useTheme should be used inside the ThemeProvider. ");
  }

  return context;
}
