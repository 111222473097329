import { Typography, Container } from "@mui/material";
import { Title } from "../../components";
import TextBold from "../../components/Bold";

export default function InfoOverstap() {
  return (
    <Container>
      <Title>Gebruik straks de nieuwe app</Title>
      <Typography>
        De oude en nieuwe website communiceren <TextBold>niet</TextBold> met
        elkaar! Het is daarom belangrijk dat iedereen de nieuwe website
        gebruikt!
      </Typography>
    </Container>
  );
}
