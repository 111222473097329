import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loading from "../../components/Loading";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Title from "../../components/styled/Title";
import logger from "../../logger";
// @ts-ignore
import Login, { UnstyledLogin } from "../../not-logged-in/Login";
import { RootState } from "../../redux/store";
import { UnstyledForgotPassword } from "../../not-logged-in/ForgotPassword";
import { UnstyledRegister } from "../../not-logged-in/Register";
import { toast } from "react-toastify";

const InvitedForGroup: React.FC = () => {
  // @ts-ignore
  const { group_id, invite_id }: { group_id?: string; invite_id?: string } =
    useParams();

  const user = useSelector((state: RootState) => state.user.current_user);

  const [groupName, setGroupName] = useState<undefined | string>(undefined);
  const [userNames, setUserNames] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingInvite, setLoadingInvite] = useState(false);
  const navigate = useNavigate();
  const [showLogin, setShowlogin] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDialog = () => {
    setOpen(!open);
  };
  const [activeDialogComponent, setActiveDialogComponent] = useState<
    "login" | "register" | "forgot"
  >("login");

  const user_id = user?.user_id;

  async function joinGroup() {
    if (!user_id) {
      setOpen(true);
      // setShowlogin(true);
      return;
    }

    navigate(`../../../invite_accepted/${group_id}/${invite_id}`);

    if (isLoadingInvite) {
      return;
    }
    setLoadingInvite(true);

    setLoadingInvite(false);
  }

  async function loadingGroupInfo() {
    const fetchResponse = await fetch(
      `${process.env.REACT_APP_GRAPHQL_REST}/group_info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }, // process.env.TOKEN}, // [process.env.HEADER]
        body: JSON.stringify({
          group_id,
        }),
      }
    )
      .then(async (res) => {
        const data = await res.json();

        if (data?.error) {
          return Promise.reject(data?.error);
        }
        return data;
      })
      .catch((e) => {
        logger.error(
          `[loadingGroupInfo] could not load group information group_id${group_id} error:${JSON.stringify(
            e
          )}`
        );
        toast.error("Kon de groep gegevens niet laden. " + e);
      });

    setGroupName(fetchResponse?.getUsersGroup?.group_name ?? "");
    setUserNames(fetchResponse?.getUsersGroup?.names ?? []);
    setLoading(false);
  }

  useEffect(() => {
    loadingGroupInfo();
  }, []);

  // if (showLogin) {
  //   return ;
  // }

  return (
    <>
      <Dialog open={open} onClose={toggleDialog}>
        <DialogContent>
          {activeDialogComponent === "login" ? (
            <>
              <UnstyledLogin
                redirectUrl={`../../../invite_accepted/${group_id}/${invite_id}`}
              />
              <Button onClick={() => setActiveDialogComponent("register")}>
                Registreer
              </Button>
              <Button onClick={() => setActiveDialogComponent("forgot")}>
                Wachtwoord vergeten
              </Button>
            </>
          ) : activeDialogComponent === "forgot" ? (
            <>
              <UnstyledForgotPassword />
              <Button onClick={() => setActiveDialogComponent("login")}>
                Login
              </Button>
            </>
          ) : (
            <>
              <UnstyledRegister
                redirectUrl={`../../../invite_accepted/${group_id}/${invite_id}`}
              />
              <Button onClick={() => setActiveDialogComponent("login")}>
                Login
              </Button>
              <Button onClick={() => setActiveDialogComponent("forgot")}>
                Wachtwoord vergeten
              </Button>
            </>
          )}
          {/* <Title>Accepteer uitnodiging</Title>
          <List>
            <ListItem>
              <ListItemText>Al een account?</ListItemText>
              <ListItemButton>
                <Button onClick={() => {}}>Login</Button>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemText>Geen account?</ListItemText>
              <ListItemButton>
                <Button onClick={() => {}}>Registreer</Button>
              </ListItemButton>
            </ListItem> */}
          {/* </List> */}
        </DialogContent>
      </Dialog>
      <Grid
        container
        justifyContent="center"
        style={{ height: "calc(100vh - 75px)" }}
      >
        <Grid item xs={10} sm={8} md={6} textAlign="center">
          {/* <Center> */}
          <Card>
            <CardContent>
              <Box textAlign="center">
                <Title>{groupName ? groupName : "Group"}</Title>
              </Box>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={isLoadingInvite}
                    fullWidth
                    variant="contained"
                    onClick={joinGroup}
                    disabled={isLoadingInvite}
                  >
                    Word lid van de groep
                  </LoadingButton>
                </Grid>
              </Grid>

              <List>
                <Divider />

                {userNames.length > 0
                  ? userNames.map((name, index) => {
                      return (
                        <Fragment key={name + "-" + index}>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar />
                            </ListItemAvatar>
                            <Typography>{name}</Typography>
                          </ListItem>
                          <Divider />
                        </Fragment>
                      );
                    })
                  : [0, 1, 2].map((index) => {
                      return (
                        <Fragment key={`skeleton-${index}`}>
                          <ListItem key={index}>
                            <Box>
                              <Skeleton variant="text" width={250} />
                            </Box>
                          </ListItem>
                          <Divider />
                        </Fragment>
                      );
                    })}
              </List>
            </CardContent>
          </Card>
          {/* </Center> */}
        </Grid>
      </Grid>
    </>
  );
};

const LoginDialog = ({ open }: { open: boolean }) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Title></Title>
      </DialogContent>
    </Dialog>
  );
};

export default InvitedForGroup;
