import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Center from "../../../components/styled/Center";
import { selectCurrentUser } from "../../../redux/auth";
import { filterTodaysEvents } from "../../../redux/event";
import {
  useEventAllQuery,
  useTodaysEventMutation,
} from "../../../redux/graphqlApi";

const CreateAutomaticEvent: React.FC = () => {
  // @ts-expect-error
  const { timestamp, group_id }: { timestamp: string; group_id } = useParams();
  const currentUser = useSelector(selectCurrentUser);

  const [isLoading, setIsLoading] = useState(false);
  const [createTodaysEvent, result] = useTodaysEventMutation({});

  const {
    data: allEvents = [],
    error: errorEvent,
    isLoading: isLoadingEvent,
  } = useEventAllQuery({
    group_id,
    user_id: currentUser.user_id,
  });

  if (errorEvent) {
    console.error("error event: ", errorEvent);
  }

  const events = filterTodaysEvents(allEvents, moment(timestamp));

  const effectCreateEvent = async () => {
    setIsLoading(true);

    let start_date: Moment | undefined = undefined;

    if (timestamp) {
      start_date = moment(timestamp);
    }

    createTodaysEvent({
      group_id,
      date: start_date,
    })
      .unwrap()

      .catch((e) => {
        console.error("The error: ", e);
        toast.error("Here is an error!");
      });
  };

  useEffect(() => {
    if (!events || events?.length <= 0) {
      effectCreateEvent();
    }
  }, []);

  if (events && events?.length > 0) {
    if (timestamp) {
      return <Navigate to={`../../events/event/${events[0].id}/show`} />;
    } else {
      return <Navigate to={`../events/event/${events[0].id}/show`} />;
    }
  }

  if (timestamp) {
    return (
      <Center>
        <LoadingEventSkeleton />
      </Center>
      // <Typography>
      //   Create using timestamp {param.timestamp}
      //   {moment(param.timestamp).format("YYYY-MMMMM-DDD")}
      // </Typography>
    );
  }

  if (isLoading) {
    return <LoadingEventSkeleton />;
  }
  return (
    <Grid>
      <Typography>
        Je kan je momenteel nog niet opgeven voor avond eten.
        <Button
          onClick={() => {
            effectCreateEvent();
          }}
        >
          Probeer opnieuw.
        </Button>
      </Typography>
    </Grid>
  );
};

export const LoadingEventSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Grid container justifyContent={"center"}>
          <Grid item xs={6}>
            <Skeleton />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <List>
              {[0, 1, 2, 3].map((item) => {
                return (
                  <ListItem key={item}>
                    <ListItemText>
                      <Skeleton variant="text" />
                    </ListItemText>
                    <ListItemAvatar>
                      <Skeleton
                        style={{ width: 25, height: 25 }}
                        variant="circular"
                      />
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateAutomaticEvent;
