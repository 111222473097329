import {
  Badge,
  IconButton,
  Card,
  CardContent,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  Button,
  useTheme,
  CardActions,
  CircularProgress,
  MenuItem,
  Stack,
  Menu,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogContent,
  Collapse,
  ClickAwayListener,
  Container,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { useLongPress } from "use-long-press";
import {
  useEffect,
  useState,
  useContext,
  ReactNode,
  useRef,
  useCallback,
} from "react";
import Title from "../../../components/styled/Title";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import moment from "moment";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "../../../components/Notification";
import { Capacitor } from "@capacitor/core";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ThumbDownAltRoundedIcon from "@mui/icons-material/ThumbDownAltRounded";
import { useSelector } from "react-redux";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { Event as EventType } from "../../../redux/interfaces/interfaces";
import { EventUser, UserEventEnum } from "../../../redux/interfaces/interfaces";

import useNetwork from "../../../redux/network";
import vibrate from "../../../components/vibrate";
import { HeaderContext } from "../../../layout/InsideGroupLayout";
import Center from "../../../components/styled/Center";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import OptionBar from "../../../components/OptionsBar";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { SubTitle } from "../../../components/styled";

import {
  useCloseEventMutation,
  useCreateAttendanceMutation,
  useGetGroupQuery,
  useQueryEventQuery,
  useUpdateAttendanceMutation,
  useUpdateGroupDescriptionMutation,
} from "../../../redux/graphqlApi";
import { selectCurrentUser } from "../../../redux/auth";
import {
  EatOnlyFab,
  UnknownFab,
  GroceriesFab,
  NotAttendFab,
  CookFab,
} from "../../../components/status/Fabs";
import { isAttending, isAttendingStatus } from "../../../redux/helperFunctions";
import { LoadingEventSkeleton } from "./CreateAutomaticEvent";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export default function Event() {
  //@ts-expect-error
  const { event_id, group_id }: { event_id: string } = useParams();

  const {
    data,
    isLoading,
    refetch: refetchEventStatus,
  } = useQueryEventQuery({
    event_id,
  });

  useEffect(() => {
    const interval = setInterval(function () {
      refetchEventStatus();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const { registerNotifications } = useNotification();
  const { data: group } = useGetGroupQuery({ group_id });
  const navigate = useNavigate();
  const [closedAds, setCloseAdsL] = useState<boolean>(
    Boolean(localStorage.getItem("closedAds"))
  );
  const currentUser = useSelector(selectCurrentUser);

  const [otherDate, setOtherDate] = useState(false);

  const { hasInternet } = useNetwork();

  const { setTitle } = useContext(HeaderContext);
  moment.locale("nl");

  dayjs.locale("nl");

  moment.updateLocale("nl", {
    calendar: {
      lastDay: "[Gisteren]",
      sameDay: "[Vandaag]",
      nextDay: "[Morgen]",
      lastWeek: "D MMMM",
      nextWeek: "D MMMM",
      sameElse: "D MMMM",
    },
  });
  moment.updateLocale("en", {
    calendar: {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "D MMMM",
      nextWeek: "D MMMM",
      sameElse: "D MMMM",
    },
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      registerNotifications();
    }

    if (group) {
      setTitle(group.name);
    }

    // @ts-ignore
    const locale = window.navigator?.userLanguage || window.navigator.language;
    moment.locale(locale);
  }, []);

  let users = group?.users
    ?.filter((u) => u.active)
    .map((u) => {
      let guests = BigInt(0);
      let status = UserEventEnum.UNKNOWN;
      let comment = "";

      const user = data?.users.find((eu) => {
        return eu.user_id === u.user_id;
      });

      if (user) {
        guests = BigInt(user.number_guests);
        status = user.status;
        comment = user.comment;
      }

      return new EventUser(u.user_id, status, Number(guests), comment);
    })
    .sort((a, b) => {
      return a.user_id !== currentUser.user_id ? 1 : -1;
    });

  // Uncomment everything below
  const event = data?.event;

  if (!event) {
    return <ErrorTimeout event={event} />;
  }

  const start_date = event?.start_date ?? Date.now();

  if (isLoading) {
    return <div>loading...</div>;
  }

  if (!users) {
    users = [];
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      justifyItems={"center"}
      style={{ paddingBottom: 52 }}
    >
      {/* eslint-disable-next-line */}
      {false ? (
        <Grid item xs={12} md={6}>
          <Card
            // 1920x1200
            style={{
              backgroundSize: "cover",
              paddingTop: "62.5%",
              width: "100%",
              position: "relative",
            }}
          >
            <CardContent
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                padding: 0,

                // height: "100%",
                // backgroundColor : "red",
              }}
            >
              <CardActions>
                <Fab>
                  <ThumbUpAltRoundedIcon />
                </Fab>
                <Fab size="small">
                  <ThumbDownAltRoundedIcon />
                </Fab>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      ) : null}
      {/* {!closedAds && ( */}
      <Collapse in={!closedAds}>
        <Grid item xs={12} md={6}>
          {/* <Card
              style={{
                textAlign: "center",
              }}
            >
              <CardContent>
                <Grid container>
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <Title style={{ fontSize: "1.2rem" }}>
                      Samenn en Eetlijst.nl bundelen hun krachten
                    </Title>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={closeAds}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Typography>
                  Vanaf 2023 gaan wij samen(n) verder als Eetlijst.nl.
                </Typography>
                <Typography>
                  De app blijft hetzelfde, maar gaat vanaf halverwege januari
                  Eetlijst heten.
                </Typography>
                
                <Typography>Meer weten of heb je vragen?</Typography>
                <Button
                  LinkComponent={Link}
                  target="_blank"
                  href="https://lp.samenn.nl/?page_id=384"
                >
                  Ga naar de FAQ
                </Button>
              </CardContent>
            </Card> */}
        </Grid>
      </Collapse>
      {/* )} */}
      <Grid item xs={12} md={users && users?.length > 9 ? 12 : 6} spacing={1}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid xs={1} item>
                {!otherDate && (
                  <IconButton
                    onClick={() => {
                      navigate(
                        `../../new/${moment(start_date).subtract(1, "day")}`
                      );
                    }}
                  >
                    <ArrowBackIosRoundedIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid xs={10} item textAlign="center">
                <DatePicker
                  label=""
                  value={moment(start_date)}
                  onChange={(newValue: any) => {
                    navigate(`../../new/${newValue}`);
                  }}
                  renderInput={({
                    inputRef,
                    inputProps,
                    InputProps,
                  }: {
                    inputRef?: any;
                    inputProps?: any;
                    InputProps?: any;
                  }) => (
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      textAlign="center"
                    >
                      <Grid item xs={3} />
                      <Grid item xs={6}>
                        <Title {...inputProps} style={{ paddingBottom: 0 }}>
                          {moment(start_date).calendar()}
                        </Title>
                        <SubTitle>
                          {dayjs(start_date.toString()).format("dddd")}
                        </SubTitle>
                      </Grid>

                      <Grid xs={2} item>
                        {InputProps?.endAdornment}
                      </Grid>
                      <Grid xs={1} item>
                        <input
                          ref={inputRef}
                          style={{
                            visibility: "collapse",
                            height: 0,
                            width: 0,
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                />
              </Grid>

              <Grid xs={1} item textAlign="end">
                <IconButton
                  onClick={() => {
                    navigate(`../../new/${moment(start_date).add(1, "day")}`);
                  }}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <ChangeEventTitle />
            </Grid>

            {users && (
              <List>
                <Grid container>
                  {users?.map((userEvent, index) => {
                    const user = group?.users?.find((u) => {
                      return u.user_id === userEvent.user_id;
                    });

                    if (!user) {
                      return (
                        <>Er was een error. Kon de gebruiker niet vinden.</>
                      );
                    }

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={users && users?.length > 9 ? 6 : 12}
                        key={"attendance-user-" + index}
                      >
                        <ListItem>
                          <Grid container>
                            <Grid item xs={8}>
                              <Box sx={{ display: "inline" }}>{user.name}</Box>
                              <Box sx={{ display: "inline" }}>
                                <Comment
                                  user_id={userEvent.user_id}
                                  event_id={event_id}
                                  comment={userEvent.comment}
                                  status={userEvent.status}
                                  number_guests={userEvent.number_guests}
                                />
                              </Box>
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              container
                              direction="row"
                              // justify="flex-end"
                              alignItems="center"
                            >
                              <AddGuest
                                user_id={user.user_id}
                                event_id={event_id}
                                number_guests={BigInt(userEvent.number_guests)}
                                disabled={!hasInternet || !event.open}
                                status={userEvent.status}
                              >
                                <StatusFabV2
                                  disabled={!hasInternet || !event.open}
                                  status={userEvent.status}
                                />
                              </AddGuest>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {/* <Divider /> */}
                      </Grid>
                    );
                  })}
                </Grid>
              </List>
            )}

            <Grid xs={6} item></Grid>
          </CardContent>
        </Card>
      </Grid>
      <EventOptions event={event} />
    </Grid>
  );

  function EventOptions({ event }: { event: EventType }) {
    return (
      <OptionBar>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={8}>
              <Stack direction="row" spacing={1}>
                <Grid item>
                  <Fab
                    disabled={!hasInternet}
                    size="small"
                    onClick={() =>
                      navigate("../../../expenses/expense/new/" + event_id)
                    }
                    style={{
                      padding: 10,
                    }}
                  >
                    <EuroRoundedIcon
                      style={{
                        width: "100%",
                        height: "100%",
                        // fontSize: "1.5rem",
                      }}
                    />

                    {/* <AddRoundedIcon />€&nbsp;
                  {(event.payed_amount_sum / 100).toString()} */}
                  </Fab>
                  <Box textAlign="center">
                    <Typography variant="subtitle2">
                      {event.payed_amount_sum === 0
                        ? "Kosten"
                        : `€${(event.payed_amount_sum / 100).toString()}`}
                    </Typography>
                  </Box>
                </Grid>
                <CloseEventSwitch event={event} />
              </Stack>
            </Grid>
            <Grid item textAlign="end" xs={4}>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Grid item>
                  {/* <Badge
                  overlap="circular"
                  color="primary"
                  badgeContent={event?.users
                    .reduce((a, b) => {
                      if (isAttendingStatus(b.status)) {
                        return a + BigInt(1) + BigInt(b.number_guests);
                      }
                      return a;
                    }, BigInt(0))
                    .toString()}
                > */}
                  <Fab
                    color="primary"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    centerRipple
                    style={{
                      padding: 10,
                      fontSize: "1.5rem",
                      zIndex: 1,
                      // backgroundColor: theme.palette.success.main,
                    }}
                    size="small"
                  >
                    {event?.users
                      .reduce((a, b) => {
                        if (isAttendingStatus(b.status)) {
                          return a + BigInt(1) + BigInt(b.number_guests);
                        }
                        return a;
                      }, BigInt(0))
                      .toString()}

                    {/* <PeopleAltIcon style={{ width: "100%", height: "100%" }} /> */}
                  </Fab>
                  {/* </Badge> */}
                  <Box textAlign="center">
                    <Typography variant="subtitle2">Totaal</Typography>
                  </Box>
                </Grid>
                {/* <Badge
                overlap="circular"
                color="primary"
                badgeContent={event?.users
                  .reduce((a, b) => {
                    if (b.status === UserEventEnum.EAT_ONLY) {
                      return a + BigInt(1) + BigInt(b.number_guests);
                    }
                    return a;
                  }, BigInt(0))
                  .toString()}
              >
                <StatusFabV2 status={UserEventEnum.EAT_ONLY} />
              </Badge>
              <Badge
                overlap="circular"
                color="primary"
                badgeContent={event?.users
                  .reduce((a, b) => {
                    if (b.status === UserEventEnum.WANTS_TO_COOK) {
                      return a + BigInt(1) + BigInt(b.number_guests);
                    }
                    return a;
                  }, BigInt(0))
                  .toString()}
              >
                <StatusFabV2 status={UserEventEnum.WANTS_TO_COOK} />
              </Badge>
              <Badge
                overlap="circular"
                color="primary"
                badgeContent={event?.users
                  .reduce((a, b) => {
                    if (b.status === UserEventEnum.GROCERIES) {
                      return a + BigInt(1) + BigInt(b.number_guests);
                    }
                    return a;
                  }, BigInt(0))
                  .toString()}
              >
                <StatusFabV2 status={UserEventEnum.GROCERIES} />
              </Badge> */}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </OptionBar>
    );
  }

  function CloseEventSwitch({ event }: { event: EventType }) {
    const [closeEventMutation] = useCloseEventMutation({});

    const currentUser = useSelector(selectCurrentUser);

    async function handleOpen() {
      if (!event) {
        return;
      }

      closeEventMutation({
        open: !event.open,
        event_id,
        closed_by: currentUser.user_id,
      });
    }

    if (!event) {
      return <>laden..</>;
    }

    if (event.open) {
      return (
        <Grid item>
          <Fab style={{ fill: "white", padding: 10 }} onClick={handleOpen}>
            <LockOpenRoundedIcon
              style={{
                width: "100%",
                height: "100%",
                // fontSize: "1.5rem",
              }}
            />
          </Fab>
          <Box textAlign="center">
            <Typography variant="subtitle2">Open</Typography>
          </Box>
        </Grid>
      );
    }

    const closed_by_user = group?.users?.find((u) => {
      return u.user_id === event.closed_by;
    });

    return (
      <Grid item>
        <Stack direction="row" spacing={1}>
          <Grid item>
            <Fab color="error" onClick={handleOpen}>
              <LockRoundedIcon />
            </Fab>
            <Box textAlign="center">
              <Typography variant="subtitle2">
                {moment(event?.updated_at).format("HH:mm")}
              </Typography>
            </Box>
          </Grid>
          <Grid item textAlign="start" style={{ display: "flex" }}>
            <Typography variant="subtitle2" style={{ alignSelf: "flex-end" }}>
              Gesloten door: {closed_by_user?.name},{" "}
              {moment(event?.updated_at).format("HH:mm")}
            </Typography>

            {/* <Typography
              style={{ alignSelf: "flex-end" }}
              variant="subtitle2"
            ></Typography> */}

            {/* </Center> */}
          </Grid>

          {/* <Grid item>
            <Typography variant="subtitle2">
              Gesloten door {closed_by_user?.name},&nbsp;
              {moment(event?.updated_at).format("HH:mm")}
            </Typography>
          </Grid> */}
        </Stack>
      </Grid>
    );
  }
}

function Comment({
  comment,
  user_id,
  event_id,
  number_guests,
  status,
}: {
  comment: string;
  user_id: string;
  event_id: string;
  number_guests: bigint | number;
  status: UserEventEnum;
}) {
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reload, setReloadVar] = useState(false);
  const forceReload = () => {
    setReloadVar(!reload);
  };
  const [changedText, setChangedText] = useState<string | null>(comment);
  const [commentLocal, setCommentLocal] = useState(comment);
  const [statusLocal, setStatusLocal] = useState(comment);

  useEffect(() => {
    if (comment !== commentLocal) {
      setCommentLocal(comment);
    }
    if (status !== statusLocal) {
      setStatusLocal(status);
    }
  }, [comment, status]);

  const onClose = () => {
    setOpen(false);
  };

  const [updateAttendanceMutation] = useUpdateAttendanceMutation({});
  const [createAttendanceMutation] = useCreateAttendanceMutation({});

  const continueRef = useRef(null);

  const editComment = async (comment: string) => {
    const payload = {
      event_id,
      user_id,
      comment,
      number_guests: Number(number_guests),
      status,
    };

    if (statusLocal === UserEventEnum.UNKNOWN) {
      // Create status on the server.

      const payload2 = {
        objects: [
          {
            ...payload,

            status: UserEventEnum.DONT_KNOW_YET,
          },
        ],
      };

      await createAttendanceMutation(payload2)
        .unwrap()
        .catch((e) => {
          console.error("Error while creating new attendance mutation", e);

          toast.error("Kom opmerking niet wijzigen: Nieuw bericht");
        });
    } else {
      await updateAttendanceMutation(payload)
        .unwrap()
        .catch((e) => {
          toast.error("Kom opmerking niet wijzigen: Wijziging bericht.");
        });
    }
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={onClose}>
        <Grid xs={12} item>
          <DialogContent>
            <Grid xs={12} item>
              <Title>Opmerking</Title>
              <Box>
                <TextField
                  onKeyPress={async (e) => {
                    if (e.key === "Enter") {
                      if (continueRef.current) {
                        //@ts-expect-error
                        continueRef.current.click();
                      }
                    }
                  }}
                  fullWidth
                  autoFocus
                  size="small"
                  // inputProps={{
                  //   style: {
                  //     fontSize: "0.75rem",
                  //   },
                  // }}
                  variant="standard"
                  value={changedText}
                  onChange={(e) => {
                    setChangedText(e.target.value);
                  }}
                  onBlur={(e) => {
                    //TODO EDIT THE COMMENT
                  }}
                />
              </Box>
              <Grid container>
                <Grid xs={6} item textAlign="start">
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Annuleer
                  </Button>
                </Grid>
                <Grid xs={6} item textAlign="end">
                  <Button
                    ref={continueRef}
                    type="submit"
                    onClick={async () => {
                      await editComment(changedText ? changedText : "");
                      setOpen(false);
                      forceReload();
                    }}
                  >
                    Opslaan
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
      {commentLocal ? (
        <Grid
          container
          direction={"row"}
          style={{
            paddingTop: 5,
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <ChatBubbleIcon
            style={{
              fontSize: "0.75rem",
              color: "gray",
            }}
          />
          &nbsp;
          <Typography
            style={{
              fontSize: "0.75rem",
              color: "gray",
            }}
          >
            {commentLocal}
          </Typography>
        </Grid>
      ) : (
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <ChatBubbleIcon
            style={{
              // paddingLeft: 5,
              color: "gray",
              fontSize: "0.75rem",
            }}
          />
        </IconButton>
      )}
    </>
  );
}

function ChangeEventTitle() {
  // @ts-expect-error
  const { event_id }: { event: string } = useParams<{ event_id: string }>();
  const [updatedDescription, setUpdatedDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [updateEventDescriptionMutation] = useUpdateGroupDescriptionMutation();
  const [showSaved, setShowSaved] = useState<boolean>(false);
  const theme = useTheme();

  const { data } = useQueryEventQuery({ event_id: event_id });

  const handleClickAway = () => {
    console.log("Clicked away fromt his elemetn!");
    if (!updatedDescription) {
      return;
    }

    updateEventDescriptionMutation({
      event_id,
      description,
    })
      .unwrap()
      .then(() => {
        setShowSaved(true);
        setTimeout(() => {
          setUpdatedDescription(false);
        }, 200);

        setTimeout(() => {
          setShowSaved(false);
        }, 3000);
      })
      .catch((e) => {
        console.error("Error: ", e);
        toast.error(`Error: ${JSON.stringify(e)}`);
      });
  };
  // groupid 804344aa-bde7-4e26-882e-c94022887119
  // userid c7990198-ddb3-4c23-af85-b1e42e7656ca

  // return <></>
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <TextField
        InputProps={{
          endAdornment: showSaved && (
            <CheckIcon style={{ color: theme.palette.success.main }} />
          ),
        }}
        // variant="standard"
        label={description || data?.event.title ? "" : "Wat wordt er gekookt?"}
        fullWidth
        onChange={(event) => {
          setDescription(event.target.value);
          setUpdatedDescription(true);
        }}
        value={updatedDescription ? description : data?.event.title}
        size="small"
      />
    </ClickAwayListener>
  );
}

function ErrorTimeout({ event }: { event?: EventType }) {
  return (
    <Center>
      <LoadingEventSkeleton />
    </Center>
  );
}

export function StatusFabV2({
  status,
  disabled,
}: {
  status: UserEventEnum;
  disabled?: boolean;
}) {
  // const { data, error, isLoading } = useUserStatusQuery({ user_id, event_id });

  if (!status) {
    return <UnknownFab />;
  }

  switch (status) {
    case UserEventEnum.UNKNOWN: {
      return <UnknownFab disabled={disabled} />;
    }
    case UserEventEnum.EAT_ONLY: {
      return <EatOnlyFab disabled={disabled} />;
    }
    case UserEventEnum.GROCERIES: {
      return <GroceriesFab disabled={disabled} />;
    }
    case UserEventEnum.NOT_ATTEND: {
      return <NotAttendFab disabled={disabled} />;
    }
    case UserEventEnum.DONT_KNOW_YET: {
      return <UnknownFab disabled={disabled} />;
    }
    case UserEventEnum.WANTS_TO_COOK: {
      return <CookFab disabled={disabled} />;
    }
    default:
      return <Fab disabled={disabled}>{status}</Fab>;
  }
}

export function FabMenu({
  children,
  event_id,
  user_id,
  number_guests,
  status,
  disabled,
}: {
  children: ReactNode;
  event_id: string;
  user_id: string;
  number_guests: bigint;
  status: UserEventEnum;
  disabled?: boolean;
}) {
  const createItem = (eventEnum: UserEventEnum, icon: ReactNode) => {
    return { eventEnum, icon };
  };
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const menuItems = [
    createItem(UserEventEnum.NOT_ATTEND, <NotAttendFab />),
    createItem(UserEventEnum.WANTS_TO_COOK, <CookFab />),
    createItem(UserEventEnum.GROCERIES, <GroceriesFab />),
    createItem(UserEventEnum.EAT_ONLY, <EatOnlyFab />),
    createItem(UserEventEnum.DONT_KNOW_YET, <UnknownFab />),
  ].filter((item) => {
    if (status === UserEventEnum.UNKNOWN) {
      return UserEventEnum.DONT_KNOW_YET !== item.eventEnum;
    }

    return item.eventEnum !== status;
  });

  const handleOpen = (e: any) => {
    setOpen(!open);
    setAnchorEl(e.currentTarget);
  };
  const [update, setUpdate] = useState(false);
  const reloadState = () => {
    setUpdate(!update);
  };
  const [
    createAttendanceMutation,
    {
      data: resultMutation,
      error: errorCreateMutation,
      isLoading: isLoadingMutation,
    },
  ] = useCreateAttendanceMutation({});
  const [
    updateAttendanceMutation,
    {
      data: resultUpdateMutation,
      error: errorUpdateMutation,
      isLoading: isLoadingUpdateMutation,
    },
  ] = useUpdateAttendanceMutation({});

  async function handleUpdate(
    status: UserEventEnum,
    add_guests: bigint,
    currentStatus: UserEventEnum
  ) {
    setIsLoading(true);
    const payload = {
      event_id,
      user_id: user_id,
      status: status,
      number_guests: Number(BigInt(number_guests) + add_guests),
    };

    if (currentStatus === UserEventEnum.UNKNOWN) {
      // Create status on the server.

      const payload2 = {
        objects: [payload],
      };

      await createAttendanceMutation(payload2)
        .unwrap()

        .catch((e) => {
          console.error("Error while creating new attendance mutation", e);

          if (
            e.message?.includes(
              "duplicate key value violates unique constraint "
            )
          ) {
            updateAttendanceMutation(payload)
              .unwrap()
              .then(() => {
                toast.success("Toch gelukt! Negeer de vorige error.");
              });
          }
        });
    } else {
      await updateAttendanceMutation(payload)
        .unwrap()
        .catch((e) => {
          console.error(e);
          toast.error("There was an error");
        });
    }

    setIsLoading(false);
    reloadState();

    vibrate();
  }

  if (errorCreateMutation || errorUpdateMutation) {
    console.error(
      "create update error",
      errorCreateMutation,
      errorUpdateMutation
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <div
          onClick={(e) => {
            if (!disabled) {
              handleOpen(e);
            } else {
              toast.error("Avondeten is gesloten.");
            }
          }}
        >
          <FabLoading isLoading={isLoading}>{children}</FabLoading>
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <Stack direction="row">
            {menuItems.map((item) => {
              return (
                <MenuItem
                  key={"status-" + item.eventEnum}
                  onClick={(e) => {
                    handleUpdate(item.eventEnum, BigInt(0), status);
                    handleOpen(e);
                  }}
                >
                  {item.icon}
                </MenuItem>
              );
            })}
          </Stack>
        </Menu>
      </Grid>
    </Grid>
  );
}

export function AddGuest({
  children,
  number_guests,
  event_id,
  user_id,
  status,
  disabled,
  disabledAddButtons,
}: {
  children: ReactNode;
  number_guests: bigint;
  event_id: string;
  user_id: string;
  status: UserEventEnum;
  disabled?: boolean;
  disabledAddButtons?: boolean;
}) {
  const [update, setUpdate] = useState(false);
  const [guests, setGuests] = useState(0);

  const theme = useTheme();
  const reloadState = () => {
    setUpdate(!update);
  };
  const [createAttendanceMutation, { isLoading: isLoadingMutation }] =
    useCreateAttendanceMutation({});
  const [updateAttendanceMutation, { isLoading: isLoadingUpdateMutation }] =
    useUpdateAttendanceMutation({});

  const [enabled, setEnabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const longPressCallback = () => {
    if (!disabled) {
      setOpenDialog(true);
    }
  };

  // @ts-expect-error
  const longPress = useLongPress(enabled ? longPressCallback : null, {
    onStart: (event) => console.log("Press started"),
    onFinish: (event) => console.log("Long press finished"),
    onCancel: (event) => console.log("Press cancelled"),
    onMove: (event) => console.log("Detected mouse or touch movement"),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: "both",
  });

  async function handleUpdate(
    status: UserEventEnum,
    add_guests: bigint,
    currentStatus: UserEventEnum
  ) {
    const payload = {
      event_id,
      user_id: user_id,
      status: status,
      number_guests: Number(BigInt(number_guests) + add_guests),
    };

    if (status === UserEventEnum.UNKNOWN) {
      // Create status on the server.

      const payload2 = {
        objects: [payload],
      };

      await createAttendanceMutation(payload2)
        .unwrap()
        .catch((e) => {
          console.error(e);

          if (
            e.message.contains(
              "duplicate key value violates unique constraint "
            )
          ) {
            toast.success("Toch gelukt!");
          }
        });
    } else {
      await updateAttendanceMutation(payload)
        .unwrap()
        .catch((e) => {
          console.error(e);
        });
    }

    vibrate();
  }

  return (
    <Grid container {...longPress()}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Title>Hoeveel gasten neem je mee?</Title>
          <Stack spacing={1}>
            <TextField
              value={guests}
              onChange={(e) => {
                if (Number(e.target.value) < 0) return;
                setGuests(Number(e.target.value));
              }}
              type="number"
            />
            <LoadingButton
              loading={isLoadingMutation || isLoadingUpdateMutation}
              variant="contained"
              onClick={async () => {
                await handleUpdate(
                  status,
                  BigInt(guests) - number_guests,
                  status
                );
                setOpenDialog(false);
              }}
            >
              Opslaan
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      {!disabledAddButtons && (
        <Grid item xs={3} md={4} textAlign="end">
          <IconButton
            disabled={number_guests <= BigInt(0) || disabled}
            sx={{
              color: theme.typography.h1.color,
              verticalAlign: "center",
            }}
            onClick={() => {
              handleUpdate(status, BigInt(-1), status);
              vibrate();
            }}
          >
            {isAttendingStatus(status) && number_guests > 0 ? (
              <RemoveRoundedIcon
                style={{
                  fontSize: "1rem",
                  // fontWeight: "bold",
                }}
              />
            ) : null}
          </IconButton>
        </Grid>
      )}

      <Grid
        item
        xs={disabledAddButtons ? 12 : 6}
        md={disabledAddButtons ? 12 : 4}
        textAlign="center"
      >
        <Badge
          overlap="circular"
          color="primary"
          badgeContent={
            BigInt(number_guests) > BigInt(0) && isAttendingStatus(status)
              ? (BigInt(number_guests) + BigInt(1)).toString()
              : null
          }
        >
          <FabMenu
            user_id={user_id}
            event_id={event_id}
            number_guests={number_guests}
            status={status}
            disabled={disabled}
          >
            <FabLoading
              isLoading={isLoadingMutation || isLoadingUpdateMutation}
            >
              {children}
            </FabLoading>
          </FabMenu>
        </Badge>
      </Grid>
      {!disabledAddButtons && (
        <Grid item xs={3} md={4} textAlign="start">
          <IconButton
            disabled={disabled}
            sx={{
              color: theme.typography.h1.color,
              verticalAlign: "center",
            }}
            onClick={() => {
              handleUpdate(status, BigInt(1), status);
              reloadState();

              vibrate();
            }}
          >
            {isAttendingStatus(status) && (
              <AddRoundedIcon
                style={{
                  fontSize: "1rem",
                }}
              />
            )}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export function FabLoading({
  children,
  isLoading,
}: {
  children: ReactNode;
  isLoading: boolean;
}) {
  return (
    <Box>
      {children}
      {isLoading && (
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
          }}
        >
          <CircularProgress
            size={40}
            sx={{
              color: "green",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              zIndex: 1,
            }}
          />
        </div>
      )}
    </Box>
  );
}

export function StatusFab({
  children,
  user,
  onClick,
  isLoading,
  reloadEvent,
  disabled,
  fabProps,
  ...rest
}: {
  children?: any;
  user: EventUser;
  onClick?: (e: any) => void;
  isLoading?: boolean;
  disabled?: boolean;
  fabProps?: any;

  reloadEvent?: () => void; // Reload the above state, such that the total can be reloaded as well.
}) {
  // @ts-expect-error
  const { event_id }: { event_id: string; group_id: string } = useParams();
  const theme = useTheme();

  const [ActiveState, setActiveState] = useState(<UnknownFab />);
  const [eventState, setEventStatus] = useState(UserEventEnum.UNKNOWN);

  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [
    createAttendanceMutation,
    { error: errorCreateMutation, isLoading: isLoadingMutation },
  ] = useCreateAttendanceMutation({});
  const [updateAttendanceMutation, { isLoading: isLoadingUpdateMutation }] =
    useUpdateAttendanceMutation({});

  // Changed it
  function handleOpen(e: any) {
    setOpen(!open);
    setAnchorEl(e.currentTarget);
  }

  const reloadState = () => {
    setUpdate(!update);

    if (reloadEvent) {
      reloadEvent();
    }
  };

  useEffect(() => {
    setStatusProperties(user.status);
  }, [
    user,
    isLoading,
    isLoadingMutation,
    isLoadingUpdateMutation,
    fabProps,
    disabled,
  ]);

  const setStatus = (status: UserEventEnum) => handleUpdate(status, BigInt(0));

  async function handleUpdate(status: UserEventEnum, add_guests: bigint) {
    setOpen(false);
    if (!user) {
      console.warn(
        "Cannot change the status of the user. Fab is in DEMO modus."
      );
      return;
    }

    const payload = {
      event_id,
      user_id: user.user_id,
      status: status,
      number_guests: Number(BigInt(user.number_guests) + add_guests),
    };

    if (user.status === UserEventEnum.UNKNOWN) {
      // Create status on the server.

      const payload2 = {
        objects: [payload],
      };

      createAttendanceMutation(payload2);
    } else {
      await updateAttendanceMutation(payload);
    }

    vibrate();
  }

  if (errorCreateMutation) {
    toast.error(`Error: ${errorCreateMutation}`);
  }
  if (!children) {
    children = <div />;
  }

  const setStatusProperties = (status?: UserEventEnum) => {
    if (!status) {
      status = UserEventEnum.UNKNOWN;
    }
    setEventStatus(status);
    switch (status) {
      case UserEventEnum.UNKNOWN: {
        setActiveState(<UnknownFab />);

        break;
      }
      case UserEventEnum.EAT_ONLY: {
        setActiveState(<EatOnlyFab />);

        break;
      }
      case UserEventEnum.GROCERIES: {
        setActiveState(<GroceriesFab />);

        break;
      }
      case UserEventEnum.NOT_ATTEND: {
        setActiveState(<NotAttendFab />);

        break;
      }
      case UserEventEnum.DONT_KNOW_YET: {
        setActiveState(<UnknownFab />);
        break;
      }
      case UserEventEnum.WANTS_TO_COOK: {
        setActiveState(<CookFab />);

        break;
      }
    }
  };

  const createItem = (eventEnum: UserEventEnum, icon: ReactNode) => {
    return { eventEnum, icon };
  };

  const menuItems = [
    createItem(UserEventEnum.NOT_ATTEND, <NotAttendFab />),
    createItem(UserEventEnum.WANTS_TO_COOK, <CookFab />),
    createItem(UserEventEnum.GROCERIES, <GroceriesFab />),
    createItem(UserEventEnum.EAT_ONLY, <EatOnlyFab />),
    createItem(UserEventEnum.DONT_KNOW_YET, <UnknownFab />),
  ].filter((item) => {
    if (eventState === UserEventEnum.UNKNOWN) {
      return UserEventEnum.DONT_KNOW_YET !== item.eventEnum;
    }

    return item.eventEnum !== eventState;
  });

  return (
    <Grid container>
      <Grid item xs={3} md={4} textAlign="end">
        <IconButton
          disabled={
            user.number_guests <= BigInt(0) || !isAttending(user) || disabled
          }
          sx={{
            color: theme.typography.h1.color,
            verticalAlign: "center",
          }}
          onClick={() => {
            handleUpdate(user.status, BigInt(-1));
            vibrate();
          }}
        >
          {isAttending(user) && user.number_guests > 0 ? (
            <RemoveRoundedIcon
              style={{
                fontSize: "1rem",
                // fontWeight: "bold",
              }}
            />
          ) : null}
        </IconButton>
      </Grid>

      <Grid item xs={6} md={4} textAlign="center">
        <Badge
          overlap="circular"
          color="primary"
          badgeContent={
            isAttending(user) && BigInt(user.number_guests) > BigInt(0)
              ? (BigInt(user.number_guests) + BigInt(1)).toString()
              : null
          }
        >
          <Grid item xs={12}>
            <Grid container>
              <div
                onClick={(e) => {
                  if (!disabled) {
                    handleOpen(e);
                  }
                }}
              >
                {ActiveState}
              </div>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Stack direction="row">
                  {menuItems.map((item) => {
                    return (
                      <MenuItem
                        key={"status-" + item.eventEnum}
                        onClick={() => {
                          setStatus(item.eventEnum);
                        }}
                      >
                        {item.icon}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem
                    onClick={() => {
                      setStatus(UserEventEnum.UNKNOWN);
                    }}
                  >
                    <UnknownFab />
                  </MenuItem> */}
                </Stack>
              </Menu>
            </Grid>
          </Grid>
        </Badge>
      </Grid>
      <Grid item xs={3} md={4}>
        <IconButton
          disabled={!isAttending(user) || disabled}
          sx={{
            color: theme.typography.h1.color,
            verticalAlign: "center",
          }}
          onClick={() => {
            handleUpdate(user.status, BigInt(1));
            reloadState();

            vibrate();
          }}
        >
          {isAttending(user) ? (
            <AddRoundedIcon
              style={{
                fontSize: "1rem",
              }}
            />
          ) : null}
        </IconButton>
      </Grid>
    </Grid>
  );
}
