const MultiplyIcon = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke="black"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 12 6 6m6 6 6 6m-6-6 6-6m-6 6-6 6"
    />
  </svg>
);

export default MultiplyIcon;
