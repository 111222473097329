import {
  Card,
  CardContent,
  styled,
  Box,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  ListItem,
} from "@mui/material";
import SwipeRoundedIcon from "@mui/icons-material/SwipeRounded";

import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { HeaderContext } from "../../layout/InsideGroupLayout";
import OptionBar from "../../components/OptionsBar";
import {
  useGetGroupQuery,
  useGetStatisticsQuery,
} from "../../redux/graphqlApi";
import { Loading } from "../../components";
import Center from "../../components/styled/Center";

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const GroupStatistics: React.FC = () => {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { data: stats, isLoading } = useGetStatisticsQuery({ group_id });
  const { data: currentGroup } = useGetGroupQuery({ group_id });
  const [filterShowOldRoommates, setFilterShowOldRoommates] =
    React.useState<boolean>(false);

  const { setTitle } = useContext(HeaderContext);

  useEffect(() => {
    setTitle("Statistieken");
  }, []);

  const createCol = (field: string, headerName: string, width: number = 75) => {
    return { field, headerName, width };
  };

  function onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  const names = stats?.eetschema_group_statistics
    .filter((d) => {
      return d.user?.users_in_groups[0].active || filterShowOldRoommates;
    })
    .map((d) => {
      return d.user?.name;
    })

    .filter(onlyUnique);

  let rows: {
    id: number;
    name: string;
    eat_only: number;
    cook: number;
    got_groceries: number;
    total: number;
    active: boolean;
    user_id: string;
  }[] = [];

  if (currentGroup) {
    currentGroup?.users?.map((user, index) => {
      rows.push({
        id: index,
        name: user.name,
        eat_only: 0,
        cook: 0,
        got_groceries: 0,
        total: 0,
        active: false,
        user_id: user.user_id,
      });
    });
  }

  stats?.eetschema_group_statistics
    .filter((d) => {
      return d.user?.users_in_groups[0].active || filterShowOldRoommates;
    })
    .forEach((d, index) => {
      if (!rows) {
        return;
      }
      const i = rows.findIndex((r) => {
        return d.user?.id === r.user_id;
      });

      if (i >= -1) {
        const u = rows[i];
        rows[i] = {
          ...u,
          [d.status ?? "field"]: d.count,
          active:
            d.user?.users_in_groups[0].active || filterShowOldRoommates
              ? true
              : false,
        };
      }
    });

  const columns = [
    createCol("name", "Naam", 100),
    createCol("eat_only", "Eten"),
    createCol("cook", "Koken"),
    createCol("got_groceries", "Boodschappen", 125),
    createCol("total", "Totaal"),
    createCol("eat_ratio", "Kook/Eet ratio", 125),
    createCol("groc_ratio", "Boodschappen/Eet ratio", 200),
  ];

  if (!rows) {
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  return (
    <>
      <Card>
        <CardContent>
          <ListItem>
            <Checkbox
              checked={filterShowOldRoommates}
              onClick={() => {
                setFilterShowOldRoommates(!filterShowOldRoommates);
              }}
            />
            <Typography>Laat oudhuisgenoten zien</Typography>
          </ListItem>

          {/* <Title>Statistieken</Title> */}

          <div
            style={{
              height: 111 + 40 * (rows?.length > 9 ? 9 : rows.length),
              width: "100%",
            }}
          >
            <DataGrid
              loading={isLoading}
              checkboxSelection={false}
              getRowHeight={() => {
                return 40;
              }}
              // localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              rows={
                rows
                  ?.filter((u) => u.active)
                  .map((info) => {
                    const total =
                      info.cook + info.eat_only + info.got_groceries;

                    return {
                      ...info,
                      total: total,
                      eat_ratio: total ? (info.cook / total).toFixed(2) : 0,
                      groc_ratio: total
                        ? (info.got_groceries / total).toFixed(2)
                        : 0,
                    };
                  }) ?? []
              }
              columns={columns}
              pageSize={9}
              rowsPerPageOptions={[9]}
            />
          </div>
          <Grid item xs={12} textAlign="center">
            <Typography>Swipe voor meer statistieken.</Typography>
            <Box>
              <IconButton>
                <SwipeRoundedIcon />
              </IconButton>
            </Box>
          </Grid>
        </CardContent>
      </Card>
      <OptionBar style={{ paddingTop: 0 }} />
    </>
  );
};

export default GroupStatistics;
