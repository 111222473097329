import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import FinishedIcon from "../../assets/svg/Finished";
import { Title } from "../../components";
import Center from "../../components/styled/Center";
import { saveToken } from "../../redux/auth";
import { useFinishSetupMutation } from "../../redux/auth_eetlijst";
import { RootState } from "../../redux/store";

// import { Container } from './styles';

const FinishedSetup: React.FC = () => {
  const [mutation, { isLoading, data }] = useFinishSetupMutation();

  const dataEetlijst = useSelector((state: RootState) => state.eetlijst_custom);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function saveTokenL(token: string) {
    await dispatch(saveToken({ token }));
    navigate("/groups");
  }

  useEffect(() => {
    if (data?.token) {
      saveTokenL(data.token);
    }
  }, [data]);

  return (
    <Container>
      <Box textAlign="center">
        <FinishedIcon
          style={{
            width: "100%",
            maxWidth: 150,
          }}
        />
      </Box>
      <Title>Setup is klaar</Title>
      <Stack spacing={1}>
        <Typography>
          Klik op de onderstaande knop om de vernieuwde eetlijst te gebruiken.
        </Typography>
        {isLoading && (
          <Typography variant="subtitle1">Dit kan eventjes duren...</Typography>
        )}

        <Center>
          <LoadingButton
            variant="contained"
            fullWidth={false}
            style={{
              maxWidth: 300,
              minWidth: 100,
              textAlign: "center",
            }}
            loading={isLoading}
            onClick={() => {
              mutation({
                chooseNewBalance: dataEetlijst.chooseNewBalance,
                users: dataEetlijst.users,
                options: dataEetlijst.options,
                groupInfo: dataEetlijst.groupInfo,
              });
              // make call to server.
            }}
          >
            Zin in!
          </LoadingButton>
        </Center>
      </Stack>
    </Container>
  );
};

export default FinishedSetup;
