import { Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Outlet, useRoutes } from "react-router-dom";
import GlobalContextProvider from "./context/global-context";
import LandingPage from "./not-logged-in/landingpage/landingpage";
import Account from "./logged-in/outside-group/account/Account";
import AppUrlListener from "./components/AppUrlListener";
import { AuthProvider } from "./hooks/auth/useAuth";
import { StyledEngineProvider } from "@mui/styled-engine";
import { ThemeProvider } from "./hooks/theme/useTheme";
import InvitedForGroup from "./logged-in/in-group/InvitedForGroup";
import { Grid, Snackbar } from "@mui/material";
import OutsideGroup from "./layout/OutsideGroupLayout";
import InsideGroupLayout from "./layout/InsideGroupLayout";
import CreateEvent from "./logged-in/in-group/event/CreateEvent";
import MainPage from "./logged-in/in-group/main-page/MainPage";
import EventRouter from "./layout/EventLayout";

import ExpenseEdit from "./logged-in/in-group/expense/ExpenseEdit";
import Settings from "./logged-in/in-group/settings/Settings";
import CreateGroup from "./logged-in/group-overiew/CreateGroup";
import Expense from "./logged-in/in-group/expense/Expense";
import GroupOverview from "./logged-in/group-overiew/GroupOverview";
import CreateAutomaticEvent from "./logged-in/in-group/event/CreateAutomaticEvent";
import Event from "./logged-in/in-group/event/Event";
import { Loading } from "./components";
import NotAPage from "./components/NotAPage";
import HeaderGroup from "./logged-in/in-group/Header";
import { NotificationProvider } from "./components/Notification";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "./redux/auth";
import { NetworkProvider } from "./redux/network";
import NotLoggedInRouter from "./not-logged-in/NotLoggedInRouter";
import GroupStatistics from "./logged-in/in-group/Statistics";
import GroceryList from "./logged-in/in-group/GroceryList";
import EventAgenda from "./logged-in/in-group/event/EventAgenda";
import "@ionic/react/css/core.css";
import UpdatePopup from "./components/UpdatePopup";
import AcceptedInvite from "./logged-in/in-group/AcceptedInvite";
import Money from "./logged-in/in-group/Money";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Agenda from "./logged-in/in-group/event/Agenda";
import StorePreview from "./not-logged-in/StorePreview";
import RemoveAccount from "./logged-in/outside-group/RemoveAccount";

// /* Core CSS required for Ionic components to work properly */
// import "@ionic/react/css/core.css";

// /* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

// /* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

// import { setupIonicReact } from "@ionic/react";

// setupIonicReact({
//   mode: "md",
// });

export default function App() {
  const [alert, setAlert] = useState<{ message?: string }>({});

  // @ts-ignore
  BigInt.prototype.toJSON = function () {
    const int = Number.parseInt(this.toString());
    return int ?? this.toString();
  };

  function handleClose() {
    setAlert({});
  }

  window.alert = (m: string, hide_after_seconds?: number) => {
    setAlert({ message: m });

    if (hide_after_seconds && hide_after_seconds > 0) {
      setTimeout(() => {
        handleClose();
      }, hide_after_seconds);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <NetworkProvider>
          <BrowserRouter>
            <AuthProvider>
              <AppUrlListener></AppUrlListener>
              <Grid xs={12} item data-testid="top-grid">
                <ToastContainer
                  style={{ width: "80%" }}
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                color="secondary"
                open={alert.message !== undefined}
                autoHideDuration={6000}
                onClick={handleClose}
                message={alert.message}
              />
              <Suspense fallback={LandingPage}>
                <GlobalContextProvider>
                  <Routing />
                </GlobalContextProvider>
              </Suspense>
            </AuthProvider>
          </BrowserRouter>
        </NetworkProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function Routing() {
  const currentUser = useSelector(selectCurrentUser);

  if (currentUser) {
    return <AuthRoutes />;
  }

  return <NotLoggedInRouter />;
}

function AuthRoutes() {
  return useRoutes([
    {
      path: "/",
      element: (
        <Suspense
          fallback={
            <>
              <HeaderGroup />
              <Loading />
            </>
          }
        >
          <NotificationProvider>
            <UpdatePopup />
            <Outlet />
          </NotificationProvider>
        </Suspense>
      ),
      children: [
        {
          path: "",
          element: <OutsideGroup />,
          children: [
            {
              path: "",
              element: <Navigate to="/groups" />,
            },

            {
              path: "invite/:group_id/:invite_id",
              element: <InvitedForGroup />,
            },
            {
              path: "invite_accepted/:group_id/:invite_id",
              element: <AcceptedInvite />,
            },
            { path: "account", element: <Account /> },
            { path: "account/remove", element: <RemoveAccount /> },
            {
              path: "groups",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <GroupOverview />,
                },
                {
                  path: "new",
                  element: <CreateGroup />,
                },
              ],
            },
            {
              path: "login",
              element: <Navigate to="/" />,
            },
          ],
        },
        {
          path: "groups/group/:group_id",
          element: <InsideGroupLayout />,
          children: [
            {
              path: "",
              element: <Navigate to="/balance" />,
            },
            {
              path: "sharedList",
              element: <GroceryList />,
            },
            {
              path: "show",
              element: <CreateAutomaticEvent />,
            },
            {
              path: "settings",
              element: <Settings />,
            },
            // {
            //   path: "balance",
            //   element: <Navigate to={"../money"} />,
            // },
            {
              path: "statistics",
              element: <GroupStatistics />,
            },
            {
              path: "agenda",
              element: <Agenda />,
            },
            {
              path: "money",
              element: <Money />,
            },
            {
              path: "expenses",
              element: <Outlet />,
              children: [
                // {
                //   path: "",
                //   element: <Navigate to={"../../money"} />,
                // },
                {
                  path: "expense",
                  element: <Outlet />,
                  children: [
                    {
                      path: "new",
                      element: <Expense />,
                    },
                    {
                      path: "new/:event_id",
                      element: <EventRouter />,
                      children: [
                        {
                          path: "",
                          element: <Expense />,
                        },
                      ],
                    },
                    {
                      path: ":expense_id",
                      element: <Outlet />,
                      children: [
                        {
                          path: "show",
                          element: <ExpenseEdit />,
                        },
                        {
                          path: "edit",
                          element: <ExpenseEdit />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "events",
              element: <Outlet />,
              children: [
                {
                  path: "new/:timestamp",
                  element: <CreateAutomaticEvent />,
                },
                {
                  path: "new",
                  element: <CreateEvent />,
                },
                { path: "", element: <MainPage /> },
                {
                  path: "event/:event_id/",
                  element: <EventRouter />,
                  children: [
                    {
                      path: "show",
                      element: <Event />,
                    },
                    {
                      path: "check_events",
                      element: <CreateAutomaticEvent />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "preview",
          element: <StorePreview />,
        },
        {
          path: "*",
          element: <NotAPage />,
        },
      ],
    },
    // { path: "*", element: <Navigate to="./groupsz" replace /> },
  ]);
}
