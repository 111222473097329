import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ReactNode } from "react";
import { UserEventEnum } from "./interfaces/interfaces";
import { RootState } from "./store";

export class EetlijstUser {
  constructor(
    public id: string,
    public order: string,
    public name: string, // User name
    public active: boolean,
    public balance: number = 0,
    public address?: string,
    public PERSBVIA?: string,
    public PERSCHNG?: string,
    public email?: string,
    public PERSMNST?: string,
    public PERSMOST?: string,
    public rekeningnummer?: string,
    public PERSRMND?: string,
    public PERSTELN?: string,
    public PERSTYDN?: string,
    public ate?: number,
    public cooked?: number
  ) {}
}

export interface EetlijstGroup {
  name?: string;
  email?: string;
  login?: string;
  description?: string;
  created_at?: string;
  ip?: string;
  address?: string;
  city?: string;
  id?: number;
  totalMinTallies: number;
  totalPlusTallies: number;
  totalCosts: number;
}

export class Option {
  constructor(
    public status: string,
    public icon: ReactNode,
    public unmutable: boolean,
    public checked: boolean,
    public default_status: boolean,
    public type: UserEventEnum
  ) {}
}

export const eetlijstApi = createApi({
  reducerPath: "eetlijst",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_NODE_SERVER}`,
  }),
  endpoints: (builder) => ({
    login: builder.query<
      { users: EetlijstUser[]; groupInfo: EetlijstGroup } | { token: string },
      { login: string; pass: string }
    >({
      query: ({ login, pass }) => ({
        url: `api/v1/auth/eetlijst`,
        method: "POST",
        body: { login, pass },
      }),
      transformResponse: (response: any, meta) => {
        if (response.token) {
          return { token: response.token };
        }
        return {
          groupInfo: {
            name: response.group.name,
            email: response.group.email,
            login: response.group.login,
            description: response.group.description,
            created_at: response.group.created_at,
            ip: response.group.ip,
            address: response.group.address,
            city: response.group.city,
            id: response.group.id,
            totalMinTallies: response.group.totalMinTallies,
            totalPlusTallies: response.group.totalPlusTallies,
            totalCosts: response.group.totalCosts,
          },

          users: response.users
            .map(
              (u: {
                // This is from the REST call.
                id: string;
                order: string;
                PERSNAAM: string; // User name
                active: boolean;
                balance: number;
                PERSADRS?: string;
                PERSBVIA?: string;
                PERSCHNG?: string;
                PERSMAIL?: string;
                PERSMNST?: string;
                PERSMOST?: string;
                PERSREKN?: string;
                PERSRMND?: string;
                PERSTELN?: string;
                PERSTYDN?: string;
                ate?: number;
                cooked?: number;
              }) => {
                return new EetlijstUser(
                  u.id,
                  u.order,
                  u.PERSNAAM,
                  u.active,
                  u.balance,
                  u.PERSADRS,
                  u.PERSBVIA,
                  u.PERSCHNG,
                  u.PERSMAIL,
                  u.PERSMNST,
                  u.PERSMOST,
                  u.PERSREKN,
                  u.PERSRMND,
                  u.PERSTELN,
                  u.PERSTYDN,
                  u.ate,
                  u.cooked
                );
              }
            )
            .sort((a: EetlijstUser, b: EetlijstUser) => {
              // @ts-expect-error
              return a.order - b.order;
            }),
        };
      },
    }),
    finishSetup: builder.mutation<
      any,
      {
        groupInfo: EetlijstGroup;
        users: EetlijstUser[];
        options: Option[];
        chooseNewBalance: boolean;
      }
    >({
      query: ({ groupInfo, users, options, chooseNewBalance }) => {
        return {
          url: `api/v1/group/migrate`,
          method: "POST",
          body: {
            chooseNewBalance: chooseNewBalance,
            login: groupInfo.login,
            group: groupInfo,
            users,
            options: options
              .filter((o) => o.checked)
              .map((o) => {
                return o.type;
              }),
          },
        };
      },
    }),
  }),
});

interface InitialState {
  users: EetlijstUser[];
  options: Option[];
  groupInfo: EetlijstGroup;
  chooseNewBalance: boolean;
  account: { login?: string; pass?: string };
}

const initialState: InitialState = {
  users: [],
  account: {},
  options: [
    new Option("Onbekend", null, true, true, true, UserEventEnum.UNKNOWN), // <UnknownFab />, true),
    new Option("Aanwezig", null, true, true, false, UserEventEnum.EAT_ONLY), // <EatOnlyFab />, true),
    new Option(
      "Niet aanwezig",
      null,
      true,
      true,
      false,
      UserEventEnum.NOT_ATTEND
    ), //<NotAttendFab />, true),
    new Option("Koken", null, false, true, false, UserEventEnum.WANTS_TO_COOK), //<CookFab />, false),
    new Option(
      "Boodschappen",
      null,
      false,
      true,
      false,
      UserEventEnum.GROCERIES
    ), //<GroceriesFab />, false),
  ],
  groupInfo: {
    totalMinTallies: 0,
    totalPlusTallies: 0,
    totalCosts: 0,
  },
  chooseNewBalance: true,
};
export const eetlijstSlice = createSlice({
  name: "eetlijst_custom",
  initialState,
  reducers: {
    changeChooseNewBalance: (state: InitialState) => {
      state.chooseNewBalance = !state.chooseNewBalance;
    },
    addUser: (state: InitialState, action: PayloadAction<string>) => {
      state.users = [
        ...state.users,
        new EetlijstUser(
          String(`P${state.users.length}`),
          String(state.users.length),
          action.payload,
          true
        ),
      ];
    },
    changeUser: (state: InitialState, action: PayloadAction<EetlijstUser>) => {
      const userIndex = state.users.findIndex(
        (u) => u.id === action.payload.id
      );

      if (userIndex !== -1) {
        state.users[userIndex] = action.payload;
      } else {
        console.error("Could not find user:", userIndex);
      }
    },
    saveLogin: (
      state: InitialState,
      action: PayloadAction<{
        users: EetlijstUser[];
        groupInfo: EetlijstGroup;
        account: { login: string; pass: string };
      }>
    ) => {
      state.users = action.payload.users;
      state.groupInfo = action.payload.groupInfo;
      state.account = action.payload.account;
    },

    toggleStatusChecked: (
      state: InitialState,
      action: PayloadAction<Option>
    ) => {
      const optionIndex = state.options.findIndex((o) => {
        return o.status === action.payload.status;
      });

      state.options[optionIndex].checked = !state.options[optionIndex].checked;
    },
    toggleDefaultStatus: (
      state: InitialState,
      action: PayloadAction<Option>
    ) => {
      const optionIndex = state.options.findIndex((o) => {
        return o.status === action.payload.status;
      });

      state.options.forEach((o, i) => {
        state.options[optionIndex].default_status = false;
      });

      state.options[optionIndex].default_status = true;
    },
    changeEmail: (state: InitialState, action: PayloadAction<string>) => {
      state.groupInfo.email = action.payload;
    },
  },
});

export const selectChooseNewBalance = (state: RootState): boolean => {
  return state.eetlijst_custom.chooseNewBalance;
};

export const selectUsersEetlijst = (state: RootState): EetlijstUser[] => {
  return [...state.eetlijst_custom.users].sort((a, b) => {
    return Number(b.active) - Number(a.active);
  });
};

export const selectLogin = (
  state: RootState
): { pass?: string; login?: string } => {
  return state.eetlijst_custom.account;
};

export const { useLoginQuery, useLazyLoginQuery, useFinishSetupMutation } =
  eetlijstApi;
export const {
  saveLogin,
  changeUser,
  addUser,
  toggleStatusChecked,
  toggleDefaultStatus,
  changeEmail,
  changeChooseNewBalance,
} = eetlijstSlice.actions;
