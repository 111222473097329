import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import {
  createLabel,
  getInitValues,
  LabelInterface,
} from "../../components/FormikMuiForm";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { HeaderContext } from "../../layout/InsideGroupLayout";

import { EmptyCartIcon } from "../../assets/svg/Undraw";
import KeyboardBar from "../../components/KeyboardBar";
import { styled } from "@mui/material/styles";
import {
  useCreateSharedListItemMutation,
  useGetGroupQuery,
  useGetSharedListQuery,
  useUpdateSharedListItemMutation,
} from "../../redux/graphqlApi";
import { useParams } from "react-router-dom";
import { SharedListItem } from "../../redux/interfaces/interfaces";

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[10],
  margin: 0,
  padding: 0,
  paddingBottom: 0,
  paddingLeft: 5,
  paddingRight: 5,
  bottom: 0,
}));

const GroceryList: React.FC = () => {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const [update, setUpdate] = useState(false);

  const [updateListItem] = useUpdateSharedListItemMutation({});

  const { data: listItems } = useGetSharedListQuery({
    group_id: group_id,
  });

  const handleUpdate = () => {
    setUpdate(!update);
  };

  const { setTitle } = useContext(HeaderContext);

  const editItem = async (
    id: string,
    checked: boolean,
    text: string,
    active: boolean
  ) => {
    updateListItem({ id, text, active, checked, group_id });
  };

  useEffect(() => {
    setTitle("Lijst");
  }, []);

  /**
   * Component to edit the text of the list item.
   * @param param0
   * @returns
   */
  const EditTextSharedListItem = ({ item }: { item: SharedListItem }) => {
    const textRef = useRef(null);
    const [editTextItem, setEditTextItem] = useState<SharedListItem | null>(
      null
    );
    const [changedText, setChangedText] = useState(item.text);

    if (editTextItem) {
      return (
        <>
          <TextField
            fullWidth
            ref={textRef}
            variant="standard"
            value={changedText}
            onChange={(e) => {
              setChangedText(e.target.value);
            }}
            onBlur={(e) => {
              const { id, checked, active } = item;
              editItem(id, checked, e.target.value, active);
              setEditTextItem(null);
              handleUpdate();
            }}
          />
        </>
      );
    }
    return (
      <ListItemText
        onClick={() => {
          setEditTextItem(item);
        }}
      >
        {item.text}
      </ListItemText>
    );
  };

  /**
   * Component to create a new item.
   * @returns
   */
  const AddSharedListItem = () => {
    const [createListItem, resultCreateItem] = useCreateSharedListItemMutation(
      {}
    );

    async function createNewItem(
      values: FormikValues,
      helper: FormikHelpers<FormikValues>
    ) {
      const { title } = values;

      if (!title) {
        console.error("There was no title?");
        return;
      }

      // Can't do this, because i dont know the item id not yet.
      // dispatch(CREATE_SHARED_LIST_ITEM_LOCAL({ text: title }));
      createListItem({ text: title, group_id });
      handleUpdate();
      helper.resetForm();
    }

    // console.log("The shared list is: ", listItems);

    const labeledValues: LabelInterface[] = [
      createLabel("Item", "title", Yup.string().required(), ""),
    ];

    const formik = useFormik({
      initialValues: getInitValues(labeledValues),
      // validationSchema: getValidationValues(labeledValues),
      onSubmit: createNewItem,
    });

    return (
      <CardContentStyled
        style={{
          paddingBottom: 0,
        }}
      >
        <Container maxWidth="md">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  sx={{ padding: 0, margin: 0 }}
                  inputProps={{
                    style: {
                      paddingTop: 5,
                      paddingBottom: 5,
                    },
                  }}
                  id={`${labeledValues[0].field}`}
                  name={`${labeledValues[0].field}`}
                  onBlur={formik.handleBlur}
                  value={`${formik.values[labeledValues[0].field]}`}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched[labeledValues[0].field] &&
                    Boolean(formik?.errors[labeledValues[0].field])
                  }
                  label={""}
                  helperText={
                    formik?.touched[labeledValues[0].field] &&
                    formik?.errors[labeledValues[0].field]
                  }
                />
              </Grid>
              <Grid item xs={2} textAlign="end">
                {/* <Center> */}
                <Fab type="submit" size="small" sx={{ zIndex: 5 }}>
                  <AddRoundedIcon />
                </Fab>
                {/* </Center> */}
              </Grid>
            </Grid>
          </form>
        </Container>
      </CardContentStyled>
    );
  };

  const list = listItems?.filter((item) => {
    return item.active;
  });

  return (
    <KeyboardBar bar={<AddSharedListItem />}>
      <Card>
        <CardContent>
          <List>
            {!list || list.length === 0 ? (
              <Box textAlign="center" justifyContent="center">
                <Box>
                  <EmptyCartIcon style={{ width: "45%", maxWidth: 200 }} />
                </Box>
                <Typography>De gedeelde lijst is leeg</Typography>
              </Box>
            ) : null}
            {list?.map((item, index) => {
              const { checked, text, id, active } = item;

              return (
                <ListItem dense disablePadding key={"item-" + index}>
                  <Checkbox
                    checked={checked}
                    onClick={() => {
                      editItem(id, !checked, text, active);
                    }}
                  />

                  <EditTextSharedListItem item={item} />
                  <Box>
                    <IconButton
                      onClick={() => {
                        editItem(id, checked, text, false);
                      }}
                    >
                      <DeleteOutlineRoundedIcon />
                    </IconButton>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </KeyboardBar>
  );
};

export default GroceryList;
