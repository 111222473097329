import { ReactNode, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  List,
  ListItem,
  Stack,
  Link,
} from "@mui/material";

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import {
  EmptyCartIcon,
  SignUpIcon,
  WelcomeIcon,
} from "../../assets/svg/Undraw";
import DemoStatusFab from "../../components/DemoStatusFab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Title } from "../../components";
import Center from "../../components/styled/Center";
import { SubTitle } from "../../components/styled";
import Spacing from "../../components/Spacing";
import UndrawVault from "../../assets/svg/undraw_vault";
enum UserEventEnum {
  UNKNOWN = "unspecified",
  EAT_ONLY = "eat_only",
  GROCERIES = "got_groceries",
  WANTS_TO_COOK = "cook",
  NOT_ATTEND = "not_attending",
}

function MobileLandingPage() {
  const [isNativePlatform, setIsNativePlatform] = useState<boolean>(true);
  const [swiper, setSwiper] = useState<Swiper>({});
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const steps = [
    { component: <Welcome /> },
    {
      title: "Aanmeldlijst",
      component: <SignUpFeature />,
    },

    {
      title: "Overzicht",
      component: <Event />,
    },
    {
      title: "Uitgaven",
      component: <SplitCosts />,
    },
    { title: "Status", component: <ExplanationFab /> },
    { title: "Four status", component: <FourStatus /> },
    { title: "Vrienden toevoegen", component: <BringFriends /> },
    { title: "Meld je nu aan!", component: <End /> },
  ];

  useEffect(() => {
    setIsNativePlatform(Capacitor.isNativePlatform());
    const platform = Capacitor.getPlatform();
    setIsNativePlatform(platform === "android" || platform === "ios");
    setIsNativePlatform(true);
  }, [swiper]);

  if (!isNativePlatform) {
    return <Navigate replace to="" />;
  }

  const PrevNextBtns = () => {
    if (!swiper) {
      return <>No Swiper yet</>;
    }

    return (
      <Stack
        direction="row"
        style={{
          bottom: 0,
          width: "100%",
          position: "fixed",
        }}
      >
        <ListItem>
          <Button
            disabled={activeSlideIndex === 0}
            onClick={() => {
              // @ts-expect-error
              swiper.slidePrev();
            }}
            variant="contained"
          >
            <KeyboardArrowLeft />
          </Button>
        </ListItem>
        <ListItem>
          {activeSlideIndex + 1 !== steps.length && (
            <Button fullWidth href="/login" LinkComponent={Link} variant="text">
              Skip
            </Button>
          )}
        </ListItem>
        <ListItem>
          <Box
            textAlign={"end"}
            style={{
              width: "100%",
            }}
          >
            {activeSlideIndex + 1 !== steps.length && (
              <Button
                variant="contained"
                onClick={() => {
                  // @ts-expect-error
                  swiper.slideNext();
                }}
              >
                <KeyboardArrowRight />
              </Button>
            )}
          </Box>
        </ListItem>
      </Stack>
    );
  };

  return (
    <Container style={{ marginTop: 55 }}>
      <Swiper
        slideToClickedSlide
        spaceBetween={0}
        slidesPerView={1}
        preloadImages={false}
        onInit={(swiper) => {
          // @ts-expect-error
          setSwiper(swiper);
        }}
        // width={400}
        // initialSlide={4}
        style={{
          padding: 0,
          height: "calc(100vh - 150px)",
          paddingBottom: 0,
        }}
        centeredSlidesBounds
        centeredSlides
        onSlideChange={(swiper) => {
          //@ts-expect-error
          setSwiper(swiper);
          setActiveSlideIndex(swiper.activeIndex);
        }}
        onSwiper={(swiper) => {
          //@ts-expect-error
          setSwiper(swiper);
        }}
      >
        {steps.map((step, index) => {
          return (
            <SwiperSlide
              style={{
                height: "auto",
              }}
              key={"slide-" + index}
            >
              {/* <Grid container direction="column"> */}

              {/* <Box textAlign="center">
                <Title>{step.title}</Title>
              </Box> */}
              <Box
                style={{
                  height: "calc(100% - 95px)",
                }}
              >
                <Center>{step.component}</Center>
              </Box>
              <PrevNextBtns />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
}

function WithSvg({ children, icon }: { children: any; icon: ReactNode }) {
  return (
    <Grid
      container
      textAlign="center"
      alignItems="center"
      justifyContent={"center"}
      justifyItems={"center"}
    >
      <Grid item xs={10} textAlign="center">
        <Box textAlign="center">{icon}</Box>
      </Grid>
      {children}
    </Grid>
  );
}

function Welcome() {
  return (
    <WithSvg
      icon={
        <WelcomeIcon
          style={{
            textAlign: "center",
            width: "75%",
            maxWidth: 250,
          }}
        />
      }
    >
      <Grid item xs={12}>
        <Title>Welkom bij Eetlijst</Title>
      </Grid>
      <Grid item xs={12}>
        <SubTitle>Dé enige echte Eetlijst sinds 2000</SubTitle>
      </Grid>
    </WithSvg>
  );
}

function ExplanationFab() {
  const [clicked, setClicked] = useState(false);

  return (
    <Grid item xs={12}>
      <List>
        <ListItem>
          <DemoStatusFab
            onClickProp={() => setClicked(true)}
            initStatus={UserEventEnum.UNKNOWN}
            numberGuests={BigInt(0)}
          />
        </ListItem>
        <ListItem>
          <SubTitle
            textAlign="center"
            style={{
              width: "100%",
            }}
          >
            Klik op de ronde knop
          </SubTitle>
        </ListItem>
      </List>
    </Grid>
  );
}

function FourStatus() {
  return (
    <WithSvg
      icon={
        <Box sx={{ width: "100%" }}>
          <img
            alt="Four possible statusses: cook, eat-only, groceries, dont eat or unknown"
            style={{ width: "100%", maxWidth: 250 }}
            src={require("../../assets/img/landingpage/4-statuses.png").default}
          />
        </Box>
      }
    >
      <Grid xs={12} item>
        <Title>4 opties voor het avondeten.</Title>
        <SubTitle>Niet mee eten, koken, boodschappen, mee eten</SubTitle>
      </Grid>
    </WithSvg>
  );
}

function BringFriends() {
  const [friends, setFriends] = useState(BigInt(0));
  const [status, setStatus] = useState(UserEventEnum.EAT_ONLY);

  return (
    <>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <DemoStatusFab
              initStatus={status}
              numberGuests={friends}
              showAddButtons
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <SubTitle>Klik op het plusje om vrienden mee te nemen.</SubTitle>
      </Grid>
    </>
  );
}

function End() {
  const navigate = useNavigate();

  return (
    <WithSvg
      icon={
        <WelcomeIcon
          style={{
            maxWidth: 250,
            width: "75%",
            textAlign: "center",
          }}
        />
      }
    >
      <Grid
        container
        textAlign="center"
        alignItems="center"
        justifyContent={"center"}
        justifyItems={"center"}
      >
        <Grid item xs={10}></Grid>
        <Grid item xs={12}>
          <Title>Dat was het</Title>
          <SubTitle>Zo simpel kan het leven soms zijn!</SubTitle>
        </Grid>
        <Spacing variant="medium" />
        <Stack
          direction="row"
          style={{
            width: "100%",
          }}
        >
          <ListItem>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </ListItem>
          <ListItem>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                navigate("/register");
              }}
            >
              Registreer
            </Button>
          </ListItem>
        </Stack>
      </Grid>
    </WithSvg>
  );
}

function SignUpFeature() {
  return (
    <WithSvg
      icon={
        <SignUpIcon
          style={{
            textAlign: "center",
            width: "75%",
            maxWidth: 250,
          }}
        />
      }
    >
      <Grid xs={12} item>
        <Title>Eetlijst</Title>
        <SubTitle>Meld je aan voor het eten</SubTitle>
      </Grid>
    </WithSvg>
  );
}

function Event() {
  return (
    <WithSvg
      icon={
        <EmptyCartIcon
          style={{
            textAlign: "center",
            width: "75%",
            maxWidth: 250,
          }}
        />
      }
    >
      <Grid xs={12} item>
        <Title>Boodschappenlijst</Title>
        <SubTitle>Vergeet nooit meer spullen te kopen</SubTitle>
      </Grid>
    </WithSvg>
  );
}

function SplitCosts() {
  return (
    <WithSvg
      icon={
        <UndrawVault
          style={{
            textAlign: "center",
            width: "100%",
            maxWidth: 400,
          }}
        />
      }
    >
      <Grid xs={12} item>
        <Title>Betaallijst</Title>
        <SubTitle>Verdeel allerlei kosten</SubTitle>
      </Grid>
    </WithSvg>
  );
}

export default MobileLandingPage;
