import {
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";
import Welcome from "../../assets/svg/undraw_welcome";
import { Title } from "../../components";
import { RootState } from "../../redux/store";

// import { Container } from './styles';

const WelcomeEetlijst: React.FC = () => {
  return (
    <>
      <Box textAlign="center">
        <Welcome
          style={{
            maxWidth: 150,
          }}
        />
      </Box>
      <Title>Welkom bij Eetlijst Versie 5!</Title>
      <Typography>
        We stellen je groep in via een korte setup. Hierbij kan je keuzes maken
        voor je Eetlijst.
      </Typography>

      <Typography>
        Wij raden aan om deze setup op een laptop uit te voeren.
      </Typography>
    </>
  );
};

export default WelcomeEetlijst;
