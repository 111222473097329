import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { useParams } from "react-router-dom";
import {
  useGetGroupQuery,
  useTotalGroupExpenseQuery,
} from "../../../redux/graphqlApi";
import { GroupUser } from "../../../redux/interfaces/interfaces";
import { Box } from "@mui/material";
import Spacing from "../../../components/Spacing";

function Balance() {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { data: totalExpense, error } = useTotalGroupExpenseQuery({ group_id });

  const { data: group } = useGetGroupQuery({ group_id });

  let users: GroupUser[] = [];
  if (group?.users) {
    users = [...group.users]
      .filter((u) => u.active)
      .sort((a, b) => {
        return Number(b.balance) - Number(a.balance);
      });
  }

  return (
    <Grid item xs={12}>
      <ListItem>
        {/* <Button variant="contained" fullWidth>
          Verreken
        </Button> */}
      </ListItem>
      <Card>
        <CardContent>
          {/* <Title>Balans</Title> */}
          <List>
            {users?.map((u, index) => {
              return (
                <div key={index}>
                  <Divider />
                  <ListItem key={"balance-user-" + index}>
                    <Grid container>
                      <Grid item xs={6}>
                        {u.name}
                      </Grid>
                      <Grid item xs={6} textAlign="end">
                        <Typography color={u.balance < 0 ? "error" : "success"}>
                          € {(Number(u.balance) / 100).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </div>
              );
            })}
            <Divider />
            <Spacing />
            <ListItem>
              <Box textAlign="center" style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Totaal uitgegeven: € {Number(totalExpense) / 100}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {/* <OptionBar style={{ paddingTop: 0 }} /> */}
    </Grid>
  );
}

export default Balance;
