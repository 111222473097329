import {
  Avatar,
  Drawer,
  Grid,
  IconButton,
  List,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Logo from "../../components/Logo";
import OfflineBar from "../../components/OfflineBar";
import { useState } from "react";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { useSelector } from "react-redux";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useFetchGroupsQuery } from "../../redux/graphqlApi";
import { selectCurrentUser } from "../../redux/auth";
import { RouterLink } from "../../components/RouterLink";

export default function HeaderGroup() {
  const [open, setOpen] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const { data: groups } = useFetchGroupsQuery({
    user_id: currentUser.user_id,
  });
  const theme = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <AppBar
      position="sticky"
      style={{
        marginBottom: 10,
      }}
    >
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            borderRadius: 0,
          },
        }}
      >
        <List>
          <div onClick={() => toggleDrawer()}>
            <RouterLink
              icon={<Avatar variant="rounded" />}
              to={`/account`}
              text={"Account"}

              // match={group.id}
            />
          </div>

          {/* <ListItem>Groepen</ListItem>
          <Divider /> */}
          {groups?.map((group, index) => {
            return (
              <div onClick={() => toggleDrawer()} key={`group-${index}`}>
                <RouterLink
                  icon={<PeopleAltRoundedIcon />}
                  to={`/groups/group/${group.id}/show`}
                  text={group.name}
                  match={group.id}
                />
              </div>
              // <ListItem disablePadding>
              //   <ListItemButton
              //     component={NavLink}
              //     to={`/groups/group/${group.id}/show`}
              //     onClick={toggleDrawer}
              //     // activeClassName="Mui-selected"

              //     className="Mui-selected"
              //     // style={(isActive) => ({ color: isActive ? "green" : "blue" })}

              //     // className={(isActive) => (isActive ? "Mui-active" : "")}
              //   >
              //     <ListItemIcon>
              //       <PeopleAltRoundedIcon />
              //     </ListItemIcon>

              //     <ListItemText>{group.name}</ListItemText>
              //   </ListItemButton>
              // </ListItem>
            );
          })}

          <div onClick={() => toggleDrawer()}>
            <RouterLink
              icon={<AddRoundedIcon />}
              to={"/groups/new/"}
              text={"Nieuwe groep"}

              // match={group.id}
            />
          </div>
        </List>
      </Drawer>
      <div
        style={{
          backgroundColor: theme.palette.background.paper, // theme.palette.background.paper,
          padding: "0.5rem 1rem 0.5rem 1rem",
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <IconButton onClick={() => toggleDrawer()}>
              <MenuRoundedIcon />
            </IconButton>
          </Grid>
          <Grid xs={6} item textAlign="center">
            <div
              style={{
                textAlign: "center",
                color: theme.palette.text.primary,
                top: "calc(50% - 0.5rem)",
                fontSize: "1.5rem",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              <Logo />
            </div>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </div>
      <OfflineBar />
    </AppBar>
  );
}
