/**
 * This is a component to manually create a new event.
 */
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Card, CardContent, TextField, useTheme } from "@mui/material";
import Title from "../../../components/styled/Title";
import moment from "moment";
import TimePicker from "@mui/lab/TimePicker";
import logger from "../../../logger";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiTextField-root": {
//       margin: theme.spacing(1),
//       width: "25ch",
//     },
//   },
// }));

const CreateEvent: React.FC = () => {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();

  // const [commitEvent, isInFlight] =
  //   useMutation<CreateEventQuery>(createEventMutation);

  const commitEvent = (object: any) => {
    toast.error("Not yet implemented. HOI");
  };
  const theme = useTheme();

  const [eventName, setEventName] = useState("");

  const [description, setDescription] = useState<string>("");
  const [time, setTime] = useState<string | null>("");
  const [errors, setErrors] = useState<{
    eventName?: string;
    hour?: string;
    minutes?: string;
    description?: string;
  }>();

  function createEvent() {
    if (!eventName) {
      toast.error("Voer een naam in. ");
      return;
    }

    const variables = {
      group_id,
      name: eventName,
      start_date: moment(Date()).format("YYYY-MM-DD:hh:mm:ss"),
    };

    commitEvent({
      variables: variables,
      onError: (e: any) => {
        toast.error("Failed");
        logger.error(`[createEvent] ${JSON.stringify(e)}`);
      },
      onCompleted: () => {
        toast.success("Event has been created.");
      },
    });
  }

  function handleDescription(event: any) {
    setDescription(event.target.value);
  }

  return (
    <Card>
      <CardContent>
        <Title>Create a new event.</Title>
        <form
          noValidate
          style={{
            // "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: "25ch",
            // },
          }}
        >
          <TextField
            margin="dense"
            required
            error={Boolean(errors?.eventName)}
            id={"standard-required"}
            label={"avond eten"}
            variant="outlined"
            helperText={errors?.eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
          <TimePicker
            label="Tijdstip"
            value={time}
            onChange={(newValue: any) => {
              setTime(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
          <TextField
            variant="outlined"
            onChange={handleDescription}
            helperText={errors?.minutes}
            error={Boolean(errors?.minutes)}
            label={"Beschrijving "}
            margin="dense"
          ></TextField>
        </form>

        <Button color="primary" variant="contained" onClick={createEvent}>
          <AddIcon />
        </Button>
      </CardContent>
    </Card>
  );
};

export default CreateEvent;
