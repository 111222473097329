import React from "react";
import {
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Title } from "../../components";
import TextBold from "../../components/Bold";

// What was a space in React again? Something with &nbsp;?

const InfoCheck: React.FC = () => {
  return (
    <Container>
      <Title>Belangrijke informatie:</Title>
      {/* <Typography>
        Voordat we jullie groep verzetten willen wij kort wat informatie geven
        wat er precies gaat veranderen.
      </Typography> */}
      <List>
        <ListItem>
          <Typography>
            Stap met álle huisgenoten over naar de nieuwe omgeving. De oude en
            nieuwe omgeving praten&nbsp;<TextBold>niet</TextBold>
            &nbsp;met elkaar. Het is dus niet mogelijk beide omgevingen te
            gebruiken.
          </Typography>
        </ListItem>
        <ListItem>
          <ListItemText>
            De balans wordt overgezet, maar wordt samengevat. Het wordt niet
            mogelijk om in de geschiedenis te kijken. TIP: exporteer de oude
            uitgaven in Excel.
          </ListItemText>
        </ListItem>
        {/* <ListItem>
          <ListItemText>
            De statistieken worden overgezet, maar er bestaan geen kookpunten
            meer.
          </ListItemText>
        </ListItem> */}
        <ListItem>
          <ListItemText>
            Het is mogelijk om terug te gaan naar de oude omgeving, maar
            uitgaven en statistieken die daarna in de oude omgeving ingevoerd
            zijn komen niet in de nieuwe omgeving. Whatsapp ons als dit gebeurt,
            dan resetten wij jullie nieuwe omgeving.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Willen jullie om één of andere reden toch niet overstappen? Laat ons
            dit weten! Wij proberen de app aan jullie wensen aan te passen.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik heb een vraag over het overstappen: `,
                  "_blank"
                );
              }}
            >
              Een vraag? Whatsapp Ivo!
            </Button>
            {/* <Button></Button> */}
          </ListItemText>
        </ListItem>
      </List>
    </Container>
  );
};

export default InfoCheck;
