import { ReactNode } from "react";

const Center = ({ children, ...rest }: { children: ReactNode }) => {
  return (
    <div
      {...rest}
      style={{
        display: "table",
        height: "100%",
        overflow: "hidden",
        textAlign: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "table-cell",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Center;
