import CircularProgress from "@mui/material/CircularProgress";
import React, { ReactNode } from "react";

function Loading({ children, ...rest }: { children?: ReactNode; rest?: any }) {
  return (
    <div {...rest}>
      <CircularProgress />
      {children}
    </div>
  );
}

export default Loading;
