import { Button, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { useGetExpensesQuery } from "../../../redux/graphqlApi";
import Expense from "./Expense";

export default function ExpenseEdit() {
  // @ts-ignore
  const { expense_id, group_id }: { expense_id: string; group_id: string } =
    useParams();

  const { expense } = useGetExpensesQuery(
    { group_id },
    {
      selectFromResult: ({ data }) => ({
        expense: data?.find((expense) => expense.id === expense_id),
      }),
    }
  );

  const navigate = useNavigate();

  if (!expense) {
    return (
      <div>
        <Typography>We konden deze uitgave niet vinden.</Typography>
        <Button
          onClick={() => {
            navigate("../../../../expenses");
          }}
        >
          Ga terug
        </Button>
      </div>
    );
  }

  return <Expense oldExpense={expense} />;
}
