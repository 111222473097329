import {
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  LinearProgress,
  IconButton,
  TextField,
  InputAdornment,
  Grow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {
  createLabel,
  getInitValues,
  getValidationValues,
  LabelInterface,
} from "../components/FormikMuiForm";
import * as Yup from "yup";
import { FormikValues, useFormik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { loginUser, saveToken } from "../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Center from "../components/styled/Center";
import Spacing from "../components/Spacing";
import { Logo } from "../components";
import { SubTitle } from "../components/styled";
import ErrorMessage from "../components/ErrorMessage";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import { saveLogin, useLazyLoginQuery } from "../redux/auth_eetlijst";
import { RootState } from "../redux/store";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={4} lg={3} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <UnstyledLogin redirectUrl="./" />
              <Button onClick={() => navigate("/register")}>Registreer</Button>
              <Button onClick={() => navigate("/forgot")}>
                Wachtwoord vergeten
              </Button>
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
}

export const UnstyledLogin = ({ redirectUrl }: { redirectUrl: string }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [enterPassword, setEnterPassword] = useState(false);
  const [loginType, setLoginType] = useState<"samenn" | "eetlijst">("samenn");
  const [accountName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const users = useSelector((state: RootState) => state.eetlijst_custom.users);

  const [loginEetlijst, { data: eetlijstData }, lastPromiseInfo] =
    useLazyLoginQuery({});
  const emailSchema = Yup.string().required().email();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const labeledValues: LabelInterface[] = [
    createLabel(
      "Email/Accountnaam",
      "email_account",
      Yup.string().required(),
      "",
      "",
      { disabled: enterPassword }
    ),
    createLabel(
      "Wachtwoord",
      "password",
      Yup.string().required(),
      "",
      "password"
    ),
  ];

  const formik = useFormik({
    initialValues: getInitValues(labeledValues),
    validationSchema: getValidationValues(labeledValues),
    onSubmit: handleLogin,
  });

  const continueToPasswordRef = useRef(null);
  const continueToLoginRef = useRef(null);

  useEffect(() => {
    if (eetlijstData) {
      //@ts-expect-error
      if (eetlijstData.token) {
        //@ts-expect-error
        dispatch(saveToken({ token: eetlijstData.token }));
      } else {
        dispatch(
          // @ts-expect-error
          saveLogin({
            ...eetlijstData,
            ...{ account: { login: accountName, pass: password } },
          })
        );
      }
    }
  }, [eetlijstData]);

  const handleEmail = (values: FormikValues) => {
    const email_account = accountName;
    setAccountName(email_account);
    setError("");

    if (emailSchema.isValidSync(email_account)) {
      setLoginType("samenn");
    } else {
      setLoginType("eetlijst");
    }
    setEnterPassword(true);
  };

  async function handleLogin(values: FormikValues) {
    const { password } = values;
    setPassword(password);
    setError("");

    const onError = (e: any) => {
      console.error(e);
      toast.error("Email of wachtwoord is onjuist. ");
      // setError("Email of wachtwoord is onjuist. ");
      let error = e;
      if (e.message) {
        error = e.messgae;
      }

      toast.error(error);

      setError(JSON.stringify(error));
      setIsLoading(false);
    };

    const onSuccess = () => {
      setIsLoading(false);
      navigate(redirectUrl);
    };

    setIsLoading(true);
    if (loginType === "samenn") {
      await dispatch(
        loginUser({ email: accountName, password, onError, onSuccess })
      );
    } else {
      const result = await loginEetlijst({
        login: accountName,
        pass: password,
      }).catch((e) => {
        toast("SHow this error");
        console.error("Should show this errorr?", e);
        return { status: "rejected" };
      });

      if (result.status === "rejected") {
        console.error("Returned result status error", result);

        //@ts-expect-error
        toast.error(JSON.stringify(result.error?.error));
        //@ts-expect-error
        setError(result.error?.error);
      }
    }

    setIsLoading(false);
  }

  if (users.length > 0) {
    return <Navigate to="/eetlijst/welcome" />;
  }

  return (
    <div style={{ minWidth: 250 }} data-testid="login-title">
      {isLoading && <LinearProgress />}
      <Logo />
      <SubTitle>{!enterPassword ? "Login" : "Welkom"}</SubTitle>
      {/* <Title>Login</Title> */}
      <Spacing />
      <Grid item xs={12} textAlign="center" justifyContent={"center"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            formik.handleSubmit();
          }}
        >
          <List>
            <ListItem>
              <TextField
                onKeyPress={async (e) => {
                  if (e.key === "Enter") {
                    if (continueToPasswordRef.current) {
                      //@ts-expect-error
                      continueToPasswordRef.current.click();
                    }
                  }
                }}
                fullWidth
                type={labeledValues[0].type}
                id={labeledValues[0].field}
                label={labeledValues[0].label}
                name={labeledValues[0].field}
                onBlur={formik.handleBlur}
                value={accountName}
                onChange={(e) => {
                  setAccountName(e.target.value);
                  formik.setFieldValue(labeledValues[0].field, e.target.value);
                }}
                error={
                  formik?.touched[labeledValues[0].field] &&
                  Boolean(formik?.errors[labeledValues[0].field])
                }
                helperText={
                  formik?.touched[labeledValues[0].field] &&
                  formik?.errors[labeledValues[0].field]
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        display: enterPassword ? "none" : "",
                      }}
                    >
                      <IconButton
                        ref={continueToPasswordRef}
                        size="small"
                        style={{
                          borderWidth: 1,
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                        onClick={() => {
                          if (!formik.values["email_account"]) {
                            alert("Its undefined the formik.");
                            return;
                          }
                          if (labeledValues[0].field === "email_account") {
                            handleEmail(formik.values);
                          }

                          if (labeledValues[0].field === "password") {
                            handleLogin(formik.values);
                          }
                        }}
                      >
                        <NavigateNextRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
            <Grow
              // style={{
              //   display: enterPassword ? "" : "none",
              // }}
              unmountOnExit={true}
              in={enterPassword}
            >
              <ListItem>
                <TextField
                  onKeyPress={async (e) => {
                    if (e.key === "Enter") {
                      if (continueToLoginRef.current) {
                        //@ts-expect-error
                        continueToLoginRef.current.click();
                      }
                    }
                  }}
                  autoFocus
                  fullWidth
                  type={labeledValues[1].type}
                  id={labeledValues[1].field}
                  label={labeledValues[1].label}
                  name={labeledValues[1].field}
                  onBlur={formik.handleBlur}
                  value={formik.values[labeledValues[1].field]}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched[labeledValues[1].field] &&
                    Boolean(formik?.errors[labeledValues[1].field])
                  }
                  helperText={
                    formik?.touched[labeledValues[1].field] &&
                    formik?.errors[labeledValues[1].field]
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isLoading ? (
                          <>inloggen...</>
                        ) : (
                          // <CircularProgress
                          //   size="small"
                          //   style={{ backgroundColor: "red", color: "black" }}
                          // />
                          <IconButton
                            ref={continueToLoginRef}
                            size="small"
                            style={{
                              borderWidth: 1,
                              borderColor: "white",
                              borderStyle: "solid",
                            }}
                            onClick={() => {
                              if (labeledValues[1].field === "email_account") {
                                handleEmail(formik.values);
                              }

                              if (labeledValues[1].field === "password") {
                                handleLogin(formik.values);
                              }
                            }}
                          >
                            <NavigateNextRoundedIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            </Grow>

            <ErrorMessage>{error}</ErrorMessage>
            {/* <ListItem>
              <LoadingButton
                size="large"
                fullWidth
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {enterPassword ? "Inloggen" : "Volgende"}
              </LoadingButton>
            </ListItem> */}
          </List>
        </form>
      </Grid>
    </div>
  );
};

export default Login;
