import { Button, ButtonGroup, Grid, Typography, useTheme } from "@mui/material";
import html2canvas from "html2canvas";
import { useRef, useState } from "react";

export default function StorePreview() {
  const exportRef = useRef();
  const exportAsImage = async (el: any, imageFileName: any) => {
    // @ts-ignore
    const canvas = await html2canvas(document.getElementById("exportRef"), {
      useCORS: true,
      allowTaint: true,
      logging: true,
      profile: true,
    });
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  };
  const downloadImage = (blob: any, fileName: any) => {
    const fakeLink = window.document.createElement("a");
    // @ts-expect-error
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };
  const [activeStep, setActiveStep] = useState(0);

  const width = 7.15 * 1.4;

  const texts = [
    { text1: "Eet jij mee", text2: "vanavond?" },
    {
      text1: "Krijg een",
      text2: "herinnering!",
    },
    {
      text1: "Gezamelijke",
      text2: "boodschappenlijst",
    },
    {
      text1: "Verreken kosten",
      text2: "en bekijk je balans",
    },
    {
      text1: "Bekijk de",
      text2: "statistieken",
    },
  ];

  const altText = "Vergeet nooit meer boodschappen!";

  return (
    <Grid container textAlign="center">
      <ButtonGroup>
        {texts.map((text, index) => (
          <Button
            onClick={() => {
              setActiveStep(index);
            }}
          >
            {index}
          </Button>
        ))}
      </ButtonGroup>
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          width: "100%",
        }}
      >
        <div
          // @ts-expect-error
          ref={exportRef}
          id="exportRef"
          style={{
            backgroundColor: "#0fa405",
            width: `${width * 1.2}cm`,
            maxHeight: 750,
            padding: 10,
            border: "1px solid black",
          }}
        >
          <>
            {altText ? (
              <Typography
                variant="h3"
                style={{
                  color: "white",

                  fontFamily: "Compact Rounded Ultra-Heavy",
                }}
              >
                {altText}
              </Typography>
            ) : (
              <>
                <Typography
                  variant="h3"
                  style={{
                    color: "white",

                    fontFamily: "Compact Rounded Ultra-Heavy",
                  }}
                >
                  {texts[activeStep].text1}
                </Typography>
                <Typography
                  paddingBottom={2}
                  style={{
                    color: "white",
                    fontFamily: "Compact Rounded Ultra-Heavy",
                  }}
                  variant="h3"
                >
                  {texts[activeStep].text2}
                </Typography>
              </>
            )}
          </>
          <iframe
            title="App preview"
            src="http://localhost:3000"
            style={{
              width: `${width}cm`,
              height: `${width * 2}cm`,
              border: "15px solid black",
              borderRadius: 50,
            }}
          ></iframe>
        </div>
      </div>
      <Button
        id="foo"
        onClick={() => {
          exportAsImage(exportRef.current, "image.png");
        }}
      >
        Download Image
      </Button>
    </Grid>
  );
}
