import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const TitleStyled = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.secondary.main,
  paddingBottom: theme.spacing(1),
}));

export default function Title({ children, className, variant, ...rest }: any) {
  if (!variant) {
    variant = "h5";
  }

  return (
    <TitleStyled variant={variant} className={className} {...rest}>
      {children}
    </TitleStyled>
  );
}
