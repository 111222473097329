const MinusIcon = (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 60.364 60.364"
  >
    <g>
      <path
        d="M52.161,26.081c0,3.246-2.63,5.875-5.875,5.875H5.875C2.63,31.956,0,29.327,0,26.081l0,0c0-3.245,2.63-5.875,5.875-5.875
		h40.411C49.531,20.206,52.161,22.835,52.161,26.081L52.161,26.081z"
      />
    </g>
  </svg>
);

export default MinusIcon;
