import {
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { Title } from "../../components";
import { selectCurrentUser } from "../../redux/auth";
import {
  useCurrentUserInfoQuery,
  useFetchGroupsQuery,
} from "../../redux/graphqlApi";

export default function RemoveAccount() {
  const user = useSelector(selectCurrentUser);
  const { data: groups = [] } = useFetchGroupsQuery({
    user_id: user.user_id,
  });

  const removeAccount = () => {
    if (
      window.confirm(
        "Weet je zeker dat je je account wilt verwijderen? Dit kan niet ongedaan worden gemaakt."
      )
    ) {
      if (groups.length !== 0) {
        toast.error(
          "Je mag in geen enkele groep actief zijn om je account te kunnen verwijderen."
        );
      } else {
        window.open(
          `mailto:info@eetlijst.nl?subject=Verwijderen account&body=Beste Eetlijst, ik wil mijn account verwijderen: ${user.user_id}`
        );
      }
    }
  };

  return (
    <Container>
      <Card>
        <CardContent>
          <Title>Verwijder account</Title>

          <Typography>
            Het kan tot 30 dagen duren voordat je account is verwijderd (Het kan
            dus ook sneller, wij willen alles even dubbel checken). Je account
            kan alleen volledig zijn verwijderd als ook alle groepen waarin jij
            zat zijn verwijderd. Dit komt omdat er financiële gegevens zijn
            verwerkt in de groepen en die informatie kan niet zomaar worden
            verwijderd. Als alle groepen volledig zijn verwijderd waarin jij
            deelnam, zal ook alle informatie van jouw account worden verwijderd.
            Tot die tijd wordt jouw account anonimiseert.
          </Typography>
          <Typography>
            Het volgende wordt voor die tijd wel verwijderd:
          </Typography>
          <List>
            {["Email", "(Encrypt) wachtwoord"].map((item) => {
              return (
                <ListItem>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Button onClick={removeAccount} variant="contained" color="error">
            Verwijder account
          </Button>
          <Button
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
                "_blank"
              );
            }}
          >
            Vragen?
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
}
