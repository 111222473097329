import { NavLink, NavLinkProps } from "react-router-dom";
import React, { ReactNode, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

type RouterLinkProps = React.PropsWithChildren<{
  to: string;
  text: string;
  icon: ReactNode;
  match?: string; // What should it match to be active? Else it just uses the react-router-dom match
  exact?: boolean;
  onClick?: () => void;
}>;

export const RouterLink = (props: RouterLinkProps) => {
  type MyNavLinkProps = Omit<NavLinkProps, "to">;

  useEffect(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, []);
  const MyNavLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<HTMLAnchorElement, MyNavLinkProps>(
        (navLinkProps, ref) => {
          const { className: previousClasses, ...rest } = navLinkProps;
          const elementClasses = previousClasses?.toString() ?? "";

          let isMatch = false;

          if (
            window.location.pathname.includes(
              props.match ?? Math.random().toString()
            )
          ) {
            isMatch = true;
          }

          return (
            <NavLink
              {...rest}
              ref={ref}
              to={props.to}
              end
              //   style={({ isActive }) =>
              //     isActive || isMatch
              //       ? { backgroundColor: "red" }
              //       : { backgroundColor: "blue" }
              //   }
              className={({ isActive }) =>
                isActive || isMatch
                  ? elementClasses + " Mui-selected "
                  : elementClasses
              }
            />
          );
        }
      ),
    [props.to]
  );
  return (
    <ListItemButton
      component={MyNavLink}
      sx={{
        ".Mui-selected > &": { color: (theme) => theme.palette.primary.main },
      }}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItemButton>
  );
};
