import {
  Card,
  CardContent,
  TextField,
  Grid,
  Box,
  IconButton,
  styled,
  DialogContent,
  Dialog,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Loading, Title } from "../components";
import FormikMuiForm, {
  createLabel,
  LabelInterface,
} from "../components/FormikMuiForm";
import Center from "../components/styled/Center";
import * as Yup from "yup";
import { FormikValues } from "formik";
import { SubTitle } from "../components/styled";
import { useLocation } from "react-router-dom";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useLoginQuery } from "../redux/auth_eetlijst";
import axios from "axios";
// import { Container } from './styles';

const RightTop = styled("div")((theme) => ({
  position: "absolute",
  right: 0,
  top: 0,
}));

const LoginEetlijst: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleDialog = () => {
    setOpen(!open);
  };

  const labeledValues: LabelInterface[] = [
    createLabel("Loginnaam", "account_name", Yup.string().required(), ""),
    createLabel(
      "Wachtwoord",
      "password",
      Yup.string().required(),
      "",
      "password"
    ),
  ];

  const {
    data,
    error,
    isLoading: isLoadingLogin,
  } = useLoginQuery({ login: "", pass: "" });

  // console.log("Error login? ", error, data);

  const handleLoginEetlijst = async (values: FormikValues) => {
    // console.error(`[handleLoginEetlijst] not yet implemeneted.`);
    setIsLoading(true);

    try {
      const res = await axios
        .post("http://localhost:3001/api/v1/auth/eetlijst", {
          username: "",
          password: "",
        })
        .catch((e) => {
          console.error("there was an error: ", e);

          return Promise.reject(e?.data?.message);
        }); //https://www.eetlijst.nl/login.php");
    } catch (e: any) {
      console.error(e);
    }

    setIsLoading(false);

    // const { account_name, password } = values;
  };

  if (isLoadingLogin) {
    return <Loading />;
  }

  return (
    <Grid container justifyContent="center" style={{ height: "calc(100vh - 75px)" }}>
      <Dialog open={open} onClose={() => toggleDialog()}>
        <DialogContent>
          <Title>Eetlijst.nl v5</Title>
          <List>
            <ListItem>
              <ListItemText>
                Na jaren lang het zelfde design te hebben, krijgt eetlijst.nl
                een nieuwe design update!
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                Meld je vanaf nu aan voor de beta van eetlijst.nl om hier
                gebruik van te maken.
              </ListItemText>
            </ListItem>
          </List>

          <Button variant="contained" onClick={toggleDialog}>
            Yes, zin in!
          </Button>
        </DialogContent>
      </Dialog>
      <Grid item xs={10} sm={8} md={6} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <Box textAlign="center">
                <img
                  style={{ width: "75%", maxWidth: 200 }}
                  src={require("../assets/img/eetlijst.png").default}
                />
                <SubTitle>
                  Beta 1.0
                  <IconButton onClick={toggleDialog}>
                    <HelpOutlineRoundedIcon />
                  </IconButton>
                </SubTitle>
              </Box>
              <FormikMuiForm
                errorMessage={errorMessage}
                labeledValues={labeledValues}
                isLoading={isLoading}
                formik={{
                  onSubmit: handleLoginEetlijst,
                }}
                submitButtonText="Inloggen"
                inputProps={{ fullWidth: true }}
              />
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
};
export default LoginEetlijst;
