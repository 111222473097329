import { Button, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Center from "./styled/Center";
import Title from "./styled/Title";

// import { Container } from './styles';

function NotAPage() {
  const navigate = useNavigate();

  console.warn("This page does not exist: ", window.location.pathname);

  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={6} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <Title>Deze pagina bestaat niet</Title>
              <Button onClick={() => navigate(-1)}>Ga terug</Button>
              <Button onClick={() => navigate("")}>Home</Button>
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
}

export default NotAPage;
