import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Typography, Container, Grid } from "@mui/material";

import { Title } from "./components";
import Center from "./components/styled/Center";
import BugIcon from "./assets/svg/BugIcon";
import logger from "./logger";
import "./index.css";

Bugsnag.start({
  apiKey: "76c014e114ec90141839522af1c1e3ab",
  plugins: [new BugsnagPluginReact()],
});

const persistor = persistStore(store);

function ErrorView({ ...rest }) {
  return (
    <Container>
      <Grid item>
        {JSON.stringify(rest)}
        <Title>Oops... de app is gecrashed</Title>
      </Grid>
      <Typography>
        Er heeft een onbekende error plaats gevonden. Wij hebben een email
        ontvangen met deze bug.
      </Typography>
      <Grid item xs={8}>
        <Center>
          <BugIcon style={{ maxWidth: 350 }} />
        </Center>
      </Grid>
    </Container>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error);
    console.error(info);

    this.setState({
      error: error,
      errorInfo: info,
    });
  } // Display fallback UI    this.setState({ hasError: true });    // You can also log the error to an error reporting service    logErrorToMyService(error, info);  }
  render() {
    //@ts-expect-error
    if (this.state.errorInfo) {
      logger.error(`[error_boundary] errorState:${this.state}`);
      // @ts-expect-error
      // eslint-disable-next-line
      return this.props.FallbackComponent;
    } else {
      // eslint-disable-next-line
      return this.props.children;
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <ErrorBoundary FallbackComponent={ErrorView}>
      <Provider store={store}>
        {/* @ts-ignore */}
        <PersistGate
          loading={<>Loading data from memory</>}
          persistor={persistor}
        >
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
