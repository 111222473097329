import {
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Stack,
  Container,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Title } from "../../components";
import Spacing from "../../components/Spacing";
import {
  addUser,
  changeUser,
  EetlijstUser,
  selectUsersEetlijst,
} from "../../redux/auth_eetlijst";

const TransferUsers: React.FC = () => {
  const users = useSelector(selectUsersEetlijst);
  const [addPerson, setAddPerson] = useState(false);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const forceUpdate = () => {
    setUpdate(!update);
  };

  return (
    <Container>
      <Title>Selecteer huisgenoten</Title>
      <Typography>
        Welke huisgenoten zijn actief? ({users.filter((u) => u.active).length})
      </Typography>

      <Spacing />

      <Button
        variant="outlined"
        onClick={() => {
          if (users.find((u) => !u.active)) {
            users.forEach((user) => {
              const updateUser = {
                ...user,
                active: true,
              };

              dispatch(changeUser(updateUser));
            });
          } else {
            users.forEach((user) => {
              const updateUser = {
                ...user,
                active: false,
              };

              dispatch(changeUser(updateUser));
            });
          }
        }}
      >
        {users.find((u) => !u.active) ? "Iedereen" : "Niemand"}
      </Button>
      <Button
        onClick={() => {
          setAddPerson(true);
        }}
      >
        Voeg persoon toe
      </Button>

      <List dense>
        <Divider />

        {users.map((user, index) => {
          return (
            <Fragment key={`user-${index}`}>
              <ListItem key={user.name + "-" + index}>
                <Checkbox
                  checked={user.active}
                  onClick={() => {
                    const updateUser = {
                      ...user,
                      active: !user.active,
                    };

                    dispatch(changeUser(updateUser));

                    forceUpdate();
                  }}
                />
                <ListItemText>{user.name}</ListItemText>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
      </List>
      <Dialog open={addPerson} onClose={() => setAddPerson(false)}>
        <DialogContent>
          <Title>Voeg iemand toe</Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              if (name) {
                dispatch(addUser(name));
                setName("");
              }
            }}
          >
            <Stack>
              <TextField
                label="Naam"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Button type="submit">Opslaan</Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TransferUsers;
