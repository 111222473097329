import {
  Typography,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
  DialogContent,
  Stack,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import FunctionsIcon from "@mui/icons-material/Functions";
import { useDispatch, useSelector } from "react-redux";
import TocIcon from "@mui/icons-material/Toc";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import {
  selectUpdateInfoDialogVersion,
  SET_UPDATE_INFO_DIALOG_VERSION,
} from "../redux/notification";
import Title from "./styled/Title";

import ManageSearchIcon from "@mui/icons-material/ManageSearch";
const UpdatePopup: React.FC = () => {
  const dialogVersion = useSelector(selectUpdateInfoDialogVersion) ?? 0;
  const dispatch = useDispatch();

  const currentVersion = 6;

  const [open, setOpen] = useState(dialogVersion < currentVersion);
  const onClose = () => {
    dispatch(SET_UPDATE_INFO_DIALOG_VERSION(currentVersion));
    setOpen(false);
  };

  return (
    <>
      <Typography
        onClick={() => {
          dispatch(SET_UPDATE_INFO_DIALOG_VERSION(1));
        }}
      ></Typography>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Title>Een update!</Title>
          <List>
            <ListItem>
              <ListItemIcon>
                <ManageSearchIcon />
              </ListItemIcon>
              <ListItemText>Bekijk de geschiedenis</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText>Agenda is nu compacter</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FunctionsIcon />
              </ListItemIcon>
              <ListItemText>
                Zie direct hoeveel mensen in totaal mee eten
              </ListItemText>
            </ListItem>
          </List>

          <Stack>
            <Button variant="contained" onClick={onClose}>
              Nice
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdatePopup;
