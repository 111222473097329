function Welcome(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      data-name="Layer 1"
      viewBox="0 0 613 700"
    >
      <path
        fill="#f2f2f2"
        d="M294.343 657.212l-.781-17.576-.244-.129c-8.376-4.395-17.976 3.561-15.3 12.634 2.517 8.537 4.208 17.74 8.815 25.212a34.144 34.144 0 0025.039 15.778l10.642 6.516a57.215 57.215 0 00-12.06-46.364 55.267 55.267 0 00-10.157-9.437c-2.549 6.72-5.954 13.366-5.954 13.366z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M199.067 478.844c-20.488-21.129-50.177-33.247-81.456-33.247l-1.207.004a116.315 116.315 0 01-58.635-15.835l-1.852-1.08 1.491-1.536a113.373 113.373 0 0019.993-28.307 34.282 34.282 0 01-27.667 8.938 35.104 35.104 0 01-25.77-16.506C13.03 373.537 0 348.37 0 329.195a116.669 116.669 0 0138.063-86.091 72.651 72.651 0 0024-54.172l-.006-1.452a164.774 164.774 0 014.89-39.873l.33-1.317 1.356.047a111.391 111.391 0 0026.046-2.182 87.918 87.918 0 01-23.359-6.213l-1.458-.59.495-1.493c34.101-21.114 111.077-10.18 155.757-112.404C262.342-59.434 390.17 97.036 390.17 187.48c0 9.228-9.133 21.424-2.296 27.524 73.91 65.938 22.913 108.28 6.834 150.885-3.814 10.109 4.457 21.206 4.457 32.142 0 1.856-.047 3.772-.14 5.694l-.143 2.955-2.464-1.64a81.626 81.626 0 01-13.48-11.057 71.135 71.135 0 01-2.256 61.412c-7.746 14.62-15.24 25.085-22.272 31.105a116.29 116.29 0 01-159.343-7.656z"
      ></path>
      <path
        fill="#3f3d56"
        d="M322.808 698.78a2.093 2.093 0 002.07-1.799c.089-.625 8.8-63.454.976-146.956-7.225-77.116-30.429-188.19-99.939-279.415a2.094 2.094 0 10-3.33 2.538c68.91 90.436 91.926 200.696 99.1 277.268 7.78 83.016-.864 145.355-.952 145.975a2.095 2.095 0 002.075 2.389z"
      ></path>
      <path
        fill="#3f3d56"
        d="M266.084 399.286a2.093 2.093 0 001.232-3.787c-.252-.183-25.68-18.506-63.696-32.425-35.143-12.866-88.582-24.53-143.597-7.02a2.093 2.093 0 101.27 3.989c53.887-17.15 106.358-5.679 140.887 6.963 37.473 13.72 62.427 31.7 62.675 31.88a2.085 2.085 0 001.229.4zM361.896 160.653a2.093 2.093 0 00-3.688-1.502c-.201.239-20.307 24.282-36.928 61.197-15.364 34.124-30.845 86.585-17.343 142.718a2.093 2.093 0 104.07-.979c-13.224-54.983 1.995-106.491 17.09-140.02 16.384-36.388 36.114-59.982 36.312-60.217a2.085 2.085 0 00.487-1.197z"
      ></path>
      <path
        fill="#2f2e41"
        d="M359.063 268.597c1.766 14.25 11.64 26 26 26a26 26 0 0026-26c0-14.359-11.702-24.672-26-26-15.171-1.408-28.268 7.705-26 26z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M397.105 687.328L407.006 687.327 411.716 649.137 397.103 649.138 397.105 687.328z"
      ></path>
      <path
        fill="#2f2e41"
        d="M396.757 697.656h30.447v-.386a11.851 11.851 0 00-11.85-11.85h-.002l-5.561-4.22-10.377 4.22h-2.658z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M358.105 687.328L368.006 687.327 372.716 649.137 358.103 649.138 358.105 687.328z"
      ></path>
      <path
        fill="#2f2e41"
        d="M357.757 697.656h30.447v-.386a11.851 11.851 0 00-11.85-11.85h-.002l-5.561-4.22-10.377 4.22h-2.658zM431.177 448.137l7 24-8.246 101.645s4.246 6.355 1.246 6.355-6.847 1.41-4.423 5.204-5.86 17.421-5.86 17.421l-11.717 72.375-14.93-.22s-7.59-4.23-3.33-8.505 7.26-4.275 3.26-8.275-4-2.796-4-5.898-4.008-41.016 5.496-55.559l-2.863-59.295-6.745 58.161-13.888 77.59-14.382-.118s-6.642-6.402-6.63-11.142 4.104-5.133 1.058-7.936-3.046-45.866 5.954-69.803c0 0 5.091-13.374-.011-17.957s-.007-9.826-.007-9.826 8.082-2.922-.007-11.34-.044-67.948-.044-67.948-8.842-24.031.113-33.48 72.956 4.55 72.956 4.55z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M306.503 489.486l12.489-30.182 9.84 5.467-5.943 30.703a10.935 10.935 0 11-16.386-5.988zM516.077 250.816l-19.004 26.567-8.345-7.554 12.745-28.558a10.935 10.935 0 1114.604 9.545z"
      ></path>
      <path
        fill="#006c4c"
        d="M433.063 450.597c12.326 19.119-49.631 43.38-73.405 33.64-4.162-1.705-18.482-7.339-8.694-15.15s-1.135-8.909-1.393-13.993c-.173-3.4 2.46-10.856-.287-10.536s-5.96-4.381.02-6.404.065-3.868.117-5.788c.572-20.956 16.65-54.287 16.65-54.287l-28.97 74.057s.213 12.812-3.481 8.897-3.426 8.756-3.426 8.756-5.494 3.834-2.335 5.968-3.4 8.694-3.4 8.694l-15.307-1.094s.24-11.37 2.853-13.885 1.33-6.471 1.33-6.471 6.804-6.024 2.242-10.915c-5.867-6.29 15.525-112.53 22.907-111.477a28.12 28.12 0 0120.08-21.452l16.532-4.552 2.936-11.647h23.278l4.055 7.653 17.698 7.986c3.334-4.703 5.825-9.66 19.475-11.266 0 0 36.783-33.467 34.987-36.08s3.906-8.731 3.906-8.731 4.87-1.594 2.122-2.445 2.446-2.818 2.446-2.818 4.81-1.264 2.541-2.928 7.572-2.758 7.572-2.758l12.767 10.417-2.58 4.375s1.573 5.958-.48 3.783-2.44 4.137-2.44 4.137-1.05 6.912-7.267 8.995-10.934 21.866-10.934 21.866c-14.664 19.067-31.97 33.942-52.48 43.733 7.993 9.175.365 101.72.365 101.72z"
      ></path>
      <circle cx="387.937" cy="273.086" r="23.056" fill="#ffb6b6"></circle>
      <path
        fill="#2f2e41"
        d="M364.063 275.597c.73 5.01 5 12 4 12s-8.11-20.653-1-22c5.157-.976 6.31-.12 11.13-2.21l-.68.64c3.19 2.32 7.63.89 11.27-.62 3.65-1.5 8.08-2.93 11.28-.62 2.01 1.45 2.86 3.97 4.11 6.11 1.25 2.15 3.56 4.18 5.94 3.52 1.91-.53 3.01-2.67 3-4.65s-2.588-3.863-1.72-5.64c2.573-5.264.26-8.852-5.35-11.33q-3.06-.465-6.12-.95a17.358 17.358 0 013.84-3.86 8.774 8.774 0 00-3.1-3.97c-1.63-.97-3.65-.9-5.54-.8l-12.65.66c-3.12.16-6.35.35-9.14 1.78-3.48 1.79-5.76 5.28-7.42 8.83-3.72 7.98-3.13 14.4-1.85 23.11z"
      ></path>
      <path
        fill="#cacaca"
        d="M268.152 700.254l226.75.307a1.19 1.19 0 100-2.381l-226.75-.307a1.19 1.19 0 000 2.38z"
      ></path>
      <ellipse
        cx="471.988"
        cy="695.094"
        fill="#e6e6e6"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 267.687 814.78)"
      ></ellipse>
      <ellipse
        cx="521.16"
        cy="690.296"
        fill="#3f3d56"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 316.859 809.981)"
      ></ellipse>
      <ellipse
        cx="458.524"
        cy="655.286"
        fill="#ff6584"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 254.223 774.971)"
      ></ellipse>
      <ellipse
        cx="441.313"
        cy="640.043"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 244.413 737.958)"
      ></ellipse>
      <ellipse
        cx="434.067"
        cy="609.671"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 237.167 707.587)"
      ></ellipse>
      <ellipse
        cx="490.412"
        cy="663.123"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 293.513 761.039)"
      ></ellipse>
      <ellipse
        cx="466.651"
        cy="674.987"
        fill="#ff6584"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 269.75 772.903)"
      ></ellipse>
      <ellipse
        cx="499.728"
        cy="696.915"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 302.828 794.83)"
      ></ellipse>
      <ellipse
        cx="849.988"
        cy="541.094"
        fill="#e6e6e6"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 645.687 660.78)"
      ></ellipse>
      <ellipse
        cx="899.16"
        cy="536.296"
        fill="#3f3d56"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 694.859 655.981)"
      ></ellipse>
      <ellipse
        cx="836.524"
        cy="501.286"
        fill="#ff6584"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 632.222 620.972)"
      ></ellipse>
      <ellipse
        cx="819.313"
        cy="486.043"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 622.413 583.958)"
      ></ellipse>
      <ellipse
        cx="812.067"
        cy="455.671"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 615.167 553.587)"
      ></ellipse>
      <ellipse
        cx="868.412"
        cy="509.123"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 671.513 607.039)"
      ></ellipse>
      <ellipse
        cx="844.651"
        cy="520.987"
        fill="#ff6584"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 647.75 618.903)"
      ></ellipse>
      <ellipse
        cx="877.728"
        cy="542.915"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 680.828 640.83)"
      ></ellipse>
      <ellipse
        cx="666.988"
        cy="256.094"
        fill="#fff"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 462.687 375.78)"
      ></ellipse>
      <ellipse
        cx="716.16"
        cy="251.296"
        fill="#3f3d56"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 511.859 370.981)"
      ></ellipse>
      <ellipse
        cx="653.524"
        cy="216.286"
        fill="#ff6584"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 449.223 335.971)"
      ></ellipse>
      <ellipse
        cx="636.313"
        cy="201.043"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 439.413 298.958)"
      ></ellipse>
      <ellipse
        cx="629.067"
        cy="170.671"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 432.167 268.587)"
      ></ellipse>
      <ellipse
        cx="685.412"
        cy="224.123"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 488.513 322.039)"
      ></ellipse>
      <ellipse
        cx="661.651"
        cy="235.987"
        fill="#ff6584"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 464.75 333.903)"
      ></ellipse>
      <ellipse
        cx="694.728"
        cy="257.915"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 497.828 355.83)"
      ></ellipse>
    </svg>
  );
}

export default Welcome;
