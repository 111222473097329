function PlaystoreIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-35 -817)">
          <g transform="translate(16 613)">
            <g transform="translate(0 194)">
              <g transform="translate(19 10)">
                <path
                  fill="hsla(192,60%,43%,1)"
                  d="M0 18.5v-17C0 .91.34.39.84.15L10.69 10 .84 19.85C.34 19.6 0 19.09 0 18.5z"
                ></path>
                <path
                  fill="hsla(328,60%,43%,1)"
                  d="M13.81 13.12L3.05 19.34 11.54 10.85z"
                ></path>
                <path
                  fill="hsla(44,100%,20%,1)"
                  d="M17.16 8.81c.34.27.59.69.59 1.19s-.22.9-.57 1.18l-2.29 1.32-2.5-2.5 2.5-2.5 2.27 1.31z"
                ></path>
                <path
                  fill="hsla(140,60%,43%,1)"
                  d="M3.05 0.66L13.81 6.88 11.54 9.15z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PlaystoreIcon;
