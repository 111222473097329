import { Network } from "@capacitor/network";
import {
  useContext,
  useState,
  createContext,
  useEffect,
  ReactNode,
} from "react";

interface NetworkInterface {
  hasInternet: boolean;
}
const NetworkContext = createContext<NetworkInterface>({
  hasInternet: true,
});

const useNetwork = () => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw Error("You are using useNetwork outside the NetworkProvider.");
  }

  return context;
};

export const NetworkProvider = ({
  children,
  ...rest
}: {
  children: ReactNode;
}) => {
  const [hasInternet, setHasInternet] = useState(true);

  useEffect(() => {
    networkListener();
  }, []);

  const networkListener = async () => {
    const status = await Network.getStatus();
    setHasInternet(status.connected);

    Network.addListener("networkStatusChange", (status) => {
      setHasInternet(status.connected);
    });
  };

  const state = {
    hasInternet,
  };

  return (
    <NetworkContext.Provider value={state} {...rest}>
      {children}
    </NetworkContext.Provider>
  );
};

export default useNetwork;
//TODO Implement network reducer

// import { Network } from "@capacitor/network";
// import {
//   createAsyncThunk,
//   createSlice,
//   current,
//   Draft,
// } from "@reduxjs/toolkit";
// import { RootState } from "./store";

// export const ADD_NETWORK_LISTENER = createAsyncThunk(
//   "network/listener",
//   async () => {
//     console.log("This is the current state:");
//     console.log(current(state));

//     const networkListener = async () => {
//       const status = await Network.getStatus();
//       state.online = status.connected;

//       Network.addListener("networkStatusChange", (status) => {
//         state.online = status.connected;
//       });
//     };

//     console.log("Added listener..");
//     networkListener();
//   }
// );

// interface NetworkState {
//   online: boolean;
// }

// const initialState: NetworkState = {
//   online: true,
// };

// const networkSlice = createSlice({
//   name: "network",
//   initialState,
//   reducers: {},
//   extraReducers: {
//     [`${ADD_NETWORK_LISTENER.rejected}`]: (state, action) => {
//       console.error("Could not register network listener", action);
//     },
//     [`${ADD_NETWORK_LISTENER.fulfilled}`]: (
//       state,
//       { payload }: { payload: boolean }
//     ) => {
//       console.log("Successfully registered network", action.payload);
//     },
//   },
// });

// export const selectNetworkOnline = (state: RootState) => {
//   return state.network.online;
// };

// export default networkSlice.reducer;
