import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Title from "../../components/styled/Title";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider/Divider";
import Container from "@mui/material/Container";
import { Box, Button, IconButton, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

import { useFetchGroupsQuery } from "../../redux/graphqlApi";
import { selectCurrentUser } from "../../redux/auth";
import { UndrawNoHouseIcon } from "../../assets/svg/undraw_no_house";

function GroupOverview() {
  const user = useSelector(selectCurrentUser);
  const {
    data: groups = [],
    error,
    isLoading,
  } = useFetchGroupsQuery({
    user_id: user.user_id,
  });

  const navigate = useNavigate();

  if (error) {
    return <Typography color="error">{JSON.stringify(error)}</Typography>;
  }

  if (groups?.length === 1) {
    return <Navigate to={"/groups/group/" + groups[0].id + "/show"} />;
  }

  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={11}>
              <Title>Groepen</Title>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  navigate("new");
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>

          <List>
            {groups?.length === 0 && !isLoading ? (
              <>
                <ListItem>
                  <Box textAlign="center" sx={{ width: "100%" }}>
                    <UndrawNoHouseIcon
                      style={{
                        maxWidth: 200,
                      }}
                    />
                  </Box>
                </ListItem>

                <ListItem>
                  <Box textAlign={"center"} sx={{ width: "100%" }}>
                    Je zit niet in een groep. Maak een nieuwe groep aan, of klik
                    op een uitnodigingslink
                  </Box>
                </ListItem>

                <ListItem>
                  <Box textAlign={"center"} sx={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("new");
                      }}
                    >
                      Groep aanmaken
                    </Button>
                  </Box>
                </ListItem>
              </>
            ) : (
              isLoading &&
              [0, 1, 2].map((index) => {
                return (
                  <ListItem key={index}>
                    <Skeleton width={250} variant="text" />
                  </ListItem>
                );
              })
            )}

            {groups.map((group) => {
              return (
                <NavLink
                  key={"group-" + group.id}
                  to={window.location.pathname + "/group/" + group.id + "/show"}
                >
                  <Divider />
                  <ListItem button>
                    <ListItemText>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography>
                            {/* // @ts-expect-error */}
                            {group.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              );
            })}

            {groups.length > 0 && <Divider />}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
}

export default GroupOverview;
