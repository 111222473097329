import { useState, useEffect, ReactNode, useContext } from "react";
import { HeaderContext } from "../../../layout/InsideGroupLayout";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import {
  Grid,
  Table,
  Typography,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  Tooltip,
  ClickAwayListener,
  Box,
  IconButton,
  Skeleton,
} from "@mui/material";
import SwipeRoundedIcon from "@mui/icons-material/SwipeRounded";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

import { useEventAllQuery, useGetGroupQuery } from "../../../redux/graphqlApi";

import { selectCurrentUser } from "../../../redux/auth";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AddGuest, FabMenu, StatusFabV2 } from "./Event";
import { EventUser, UserEventEnum } from "../../../redux/interfaces/interfaces";
import useNetwork from "../../../redux/network";
import { transformToUserEventEnum } from "../../../redux/helperFunctions";
import dayjs from "dayjs";

function EventAgenda({ past }: { past: boolean }) {
  const currentUser = useSelector(selectCurrentUser);
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { hasInternet } = useNetwork();
  const { setTitle } = useContext(HeaderContext);
  const { data: group } = useGetGroupQuery({ group_id });
  const navigate = useNavigate();
  const { data: events2, refetch: refetchAllEvents } = useEventAllQuery({
    group_id,
    user_id: currentUser.user_id,
  });

  useEffect(() => {
    setTitle("Agenda");
    const interval = setInterval(function () {
      refetchAllEvents();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const events = [...(events2 ?? [])]
    .filter((event) => {
      if (past) {
        return dayjs(event.start_date.toString()).isBefore(
          dayjs().startOf("day")
        );
      }

      const diff = dayjs()
        .startOf("day")
        .diff(dayjs(event.start_date.toString()).startOf("day"), "days");
      const isAfter = dayjs(event.start_date.toString()).isAfter(
        dayjs().startOf("day")
      );

      return diff <= 0 && diff >= -6 && isAfter;
    })
    ?.sort((a, b) => {
      if (past) {
        // @ts-expect-error
        return dayjs(b.start_date.toString()) - dayjs(a.start_date.toString());
      } else {
        // @ts-expect-error
        return dayjs(a.start_date.toString()) - dayjs(b.start_date.toString());
      }
    })
    .filter((e, i) => i < 50);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ minWidth: 25, maxHeight: "calc(100vh - 250px)" }}
        // sx={{ minWidth: 120 + (group?.users?.length ?? 3) * 140 }}
      >
        <Table stickyHeader size="small">
          <TableHead style={{ paddingLeft: 0, paddingRight: 0 }}>
            <TableRow style={{ paddingLeft: 0, paddingRight: 0 }}>
              <TableCell />
              {group
                ? group.users
                    .filter((u) => u.active)
                    .sort((a, b) => {
                      return a.user_id !== currentUser.user_id ? 1 : -1;
                    })
                    .map((item, index) => {
                      return (
                        <TableCell
                          key={index}
                          style={{
                            height: 80,
                            width: 25,
                          }}
                        >
                          <TooltipClick title={item.name}>
                            <Typography
                              style={{
                                transform: "rotate(-90deg)",
                                // display: "inline-block",
                                transformOrigin: "left",
                                // transformOrigin: "left",
                                marginLeft: 0,
                                marginTop: 50,
                                marginBottom: -20,
                                width: 15,
                              }}
                            >
                              {item.name}
                            </Typography>
                          </TooltipClick>
                        </TableCell>
                      );
                    })
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {events.length === 0
              ? [0, 1, 2, 3, 4, 5, 6].map((index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          minWidth: 30,
                          paddingRight: 0,
                        }}
                      >
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                      </TableCell>
                      {group?.users.map((user, index2) => {
                        return (
                          <TableCell
                            key={index2}
                            style={{
                              minWidth: 15,
                              paddingLeft: 0,
                              paddingRight: 0,
                            }}
                          >
                            <Grid container>
                              <Grid item xs={3} md={4} textAlign="end" />
                              <Grid item xs={6} md={4} textAlign="end">
                                <Skeleton
                                  width={40}
                                  height={40}
                                  variant="circular"
                                />
                              </Grid>
                              <Grid item xs={3} md={4} textAlign="end" />
                            </Grid>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              : events.map((event, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: 500,
                          paddingRight: 1,
                        }}
                        onClick={() => {
                          navigate(`../events/event/${event.id}/show`);
                        }}
                      >
                        <Typography>
                          {dayjs(event.start_date.toString())
                            .locale("nl-NL")
                            .format("dd MMM-DD")}
                          <IconButton size="small">
                            <LaunchRoundedIcon style={{ fontSize: "1rem" }} />
                          </IconButton>
                        </Typography>

                        {/* <Typography variant="subtitle2">
                          {dayjs(event.start_date.toString()).format("MMM-DD")}
                        </Typography> */}
                        <Typography
                          style={{
                            display: "inline-block",
                            width: 145,
                          }}
                          variant="subtitle2"
                        >
                          € {(event.payed_amount_sum / 100).toFixed(2)} |{" "}
                          {event.title}
                        </Typography>
                      </TableCell>

                      {group
                        ? group.users
                            .filter((u) => u.active)
                            .sort((a, b) => {
                              return a.user_id !== currentUser.user_id ? 1 : -1;
                            })
                            .map((user, index) => {
                              let guests = BigInt(0);
                              let status = UserEventEnum.UNKNOWN;
                              let comment = "";

                              const foundUser = event.users.find((eu) => {
                                return eu.user_id === user.user_id;
                              });

                              if (foundUser) {
                                guests = BigInt(foundUser.number_guests);
                                status = transformToUserEventEnum(
                                  foundUser.status
                                );
                                comment = foundUser.comment;
                              }

                              const userEvent = new EventUser(
                                user.user_id,
                                status,
                                Number(guests),
                                comment
                              );

                              return (
                                <TableCell
                                  key={index}
                                  style={{
                                    width: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                  }}
                                >
                                  <AddGuest
                                    disabledAddButtons
                                    user_id={user.user_id}
                                    event_id={event.id}
                                    number_guests={BigInt(
                                      userEvent.number_guests
                                    )}
                                    disabled={!hasInternet || !event.open}
                                    status={userEvent.status}
                                  >
                                    <StatusFabV2
                                      disabled={!hasInternet || !event.open}
                                      status={userEvent.status}
                                    />
                                  </AddGuest>
                                </TableCell>
                              );
                            })
                        : null}
                    </TableRow>
                  );
                })}
            {past && (
              <TableRow>
                <Box padding={1}>
                  <Typography>
                    Oudere events zien? Email info@eetlijst.nl
                  </Typography>
                </Box>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} textAlign="center">
        <Typography>Swipe om meer huisgenoten te zien.</Typography>
        <Box>
          <IconButton>
            <SwipeRoundedIcon />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
}

function TooltipClick({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <div onClick={handleTooltipOpen}>{children}</div>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default EventAgenda;
