function base64Decode(base64String?: string): string {
  if (!base64String) {
    return "";
  }

  return JSON.parse(Buffer.from(base64String, "base64").toString("utf8"))[3];
}

function parseJwt(token: string) {
  if (!token) {
    console.error("There was no token given! ", token);
    return;
  }

  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export { base64Decode, parseJwt };
