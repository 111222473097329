import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios from "axios";
import { parseJwt } from "../components/Base64";
import logger from "../logger";
import { RootState } from "./store";

interface UserRaw {
  sub: string;
  name: string;
  iat: number;
  iss: string;
  "https://hasura.io/jwt/claims": {
    "x-hasura-allowed-roles": string[];
    "x-hasura-user-id": string;
    "x-hasura-default-role": string;
    "x-hasura-role": string;
  };
  exp: number;
}

export class User {
  public user_id: string;
  public name: string;
  public iat: number;
  public iss: string;
  public claim: { allowed_roles: string[]; default_role: string; role: string };
  public avatar?: string;

  constructor(jwt: string) {
    const raw: UserRaw = parseJwt(jwt);

    this.user_id = raw.sub;
    this.name = raw.name;
    this.iat = raw.iat;
    this.iss = raw.iss;
    this.claim = {
      allowed_roles:
        raw["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
      default_role:
        raw["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
      role: raw["https://hasura.io/jwt/claims"]["x-hasura-role"],
    };
  }
}

interface State {
  token?: string;
  current_user?: User;
  loading: boolean;
}

const initialState: State = {
  loading: false,
};

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({
    email,
    password,
    onError,
    onSuccess,
  }: {
    email: string;
    password: string;
    onError: (e: any) => void;
    onSuccess: () => void;
  }) => {
    const variables = {
      input: { email, password },
    };
    const res = await axios
      .post(process.env.REACT_APP_AUTH_URL + "/api/v1/auth/login", variables)
      .then(async (res: any) => {
        const token = res.data.token;

        if (!token) {
          return Promise.reject({
            success: false,
            message: "Token is corrupt.",
          });
        }

        onSuccess();
        return token;
      })
      .catch((e: any) => {
        logger.error(
          `[loginUser] could not login user email:${email} error:${JSON.stringify(
            e
          )}`
        );
        onError(e);
        return Promise.reject(e);
      });

    return res;
  }
);

// REGISTER

export const switchUser = createAsyncThunk(
  "auth/switchUser",
  async ({
    group_id,
    user_id,
    password,
    onError,
    onSuccess,
  }: {
    group_id: string;
    user_id: string;
    password: string;
    onError: (e: any) => void;
    onSuccess: () => void;
  }) => {
    if (!group_id || !user_id || !password) {
      const e = "Parameters incorrect";

      logger.error(`[auth/register] Kon niet inloggen: ${JSON.stringify(e)}`);
      onError(e);
      return Promise.reject(e);
    }
    return await axios
      .post(`${process.env.REACT_APP_AUTH_URL}/api/v1/auth/eetlijst/user`, {
        user_id,
        group_id,
        password,
      })
      .then((res: any) => {
        if (!res.data.token) {
          return Promise.reject({
            success: false,
            message: "Token is corrupt.",
          });
        }
        onSuccess();
        return res.data.token;
      })
      .catch((e) => {
        const error_message = "Kon niet inloggen " + e.response?.data?.message;

        logger.error(`[auth/register] Kon niet inloggen: ${JSON.stringify(e)}`);
        onError(error_message);
        return Promise.reject(error_message);
      });
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async ({
    email,
    name,
    password,
    password_check,
    onError,
    onSuccess,
  }: {
    email: string;
    name: string;
    password: string;
    password_check: string;
    onError: (e: any) => void;
    onSuccess: () => void;
  }) => {
    if (!email || !name || !password || !password_check) {
      console.error(email, name, password, password_check);
      const error_message = "Er mist een veld.";
      onError(error_message);
      return Promise.reject(error_message);
    }

    if (password !== password_check) {
      const error_message = "Wachtwoorden komen niet overeen.";
      onError(error_message);
      return Promise.reject(error_message);
    }

    const variables = {
      input: {
        email,
        name,
        password,
      },
    };

    return await axios
      .post(process.env.REACT_APP_AUTH_URL + "/api/v1/auth/signUp", variables)
      .then((res: any) => {
        if (!res.data.token) {
          return Promise.reject({
            success: false,
            message: "Token is corrupt.",
          });
        }

        onSuccess();

        return res.data.token;
      })
      .catch((e: any) => {
        const error_message = "Kon niet inloggen " + e.response?.data?.message;

        logger.error(`[auth/register] Kon niet inloggen: ${JSON.stringify(e)}`);
        onError(error_message);
        return Promise.reject(error_message);
      });
  }
);

/**
 * Everything to do with the user and authentication.
 * Add:
 * - forgotPassword
 * - toggleNotifications
 * - registerNotificationToken
 * - register
 * - resetPassword
 */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state: State) => {
      localStorage.clear();
      state.current_user = undefined;
      state.token = undefined;
    },
    saveToken: (
      state: State,
      { payload }: PayloadAction<{ token: string }>
    ) => {
      state.current_user = new User(payload.token);
      state.token = payload.token;
    },
  },
  extraReducers: {
    [loginUser.fulfilled.toString()]: (
      state,
      { payload }: { payload: string }
    ) => {
      state.current_user = new User(payload);
      state.token = payload;
    },
    // [loginUser.rejected.toString()]: (state, action) => {
    //   logger.error(`[loginUser rejected] error ${JSON.stringify(action)}`);
    // },
    [`${register.pending}`]: (state, action) => {},
    [`${register.rejected}`]: (state, action) => {
      logger.error(`[register] rejected error ${JSON.stringify(action)}`);
    },
    [`${register.fulfilled}`]: (state, { payload }: { payload: string }) => {
      state.token = payload;
      state.current_user = new User(payload);
    },
    [`${switchUser.fulfilled}`]: (state, { payload }: { payload: string }) => {
      state.current_user = new User(payload);
      state.token = payload;
    },

    [`${switchUser.rejected}`]: (state, action) => {
      logger.error(`[switchUser] rejected error ${JSON.stringify(action)}`);
    },
  },
});

export const { logout, saveToken } = authSlice.actions;
export default authSlice.reducer;

// @ts-expect-error
export function selectLoading(state): boolean {
  return state.user.loading;
}
// @ts-expect-error
export function selectCurrentUser(state): User {
  return state.user.current_user;
}
// @ts-expect-error
export function selectToken(state): String {
  return state.user.token;
}

// Infer the `RootState` and `AppDispatch` types from the store itself

export type AuthState = ReturnType<typeof authSlice.getInitialState>;
