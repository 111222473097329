import { EventUser, UserEventEnum } from "./interfaces/interfaces";

export function isAttending(user: EventUser): boolean {
  return isAttendingStatus(user.status);
}

export function isAttendingStatus(status: UserEventEnum): boolean {
  return (
    status !== UserEventEnum.UNKNOWN &&
    status !== UserEventEnum.NOT_ATTEND &&
    status !== UserEventEnum.DONT_KNOW_YET
  );
}

export function transformToUserEventEnum(str: string): UserEventEnum {
  const keys = Object.keys(UserEventEnum);
  const i = keys.indexOf(str);
  if (i !== -1) {
    // @ts-expect-error
    return UserEventEnum[keys[i]];
  }

  const values: string[] = Object.values(UserEventEnum);
  const i2 = values.indexOf(str);

  if (i2 !== -1) {
    // @ts-expect-error
    return UserEventEnum[keys[i2]];
  } else {
    return UserEventEnum.UNKNOWN;
  }
}
