import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import CardContent from "@mui/material/CardContent";
import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import Title from "../../../components/styled/Title";
import RemoveIcon from "@mui/icons-material/Remove";
import { Moment } from "moment";
import Loading from "../../../components/Loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Navigate, useNavigate, useParams } from "react-router";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import InputLabel from "@mui/material/InputLabel";
import useKeyboard, {
  KeyboardProvider,
} from "../../../components/KeyboardCalculator";
import {
  Expense as ExpenseType,
  Expense,
} from "../../../redux/interfaces/interfaces";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/auth";

import useNetwork from "../../../redux/network";
import { isAttending } from "../../../redux/helperFunctions";
import {
  useGetGroupQuery,
  useEditExpenseMutation,
  useCreateExpenseMutation,
  useLazyQueryEventQuery,
} from "../../../redux/graphqlApi";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, useTheme } from "@mui/material";
import { GroupUser } from "../../../redux/interfaces/interfaces";
import { toast } from "react-toastify";
import OptionBar from "../../../components/OptionsBar";
import Spacing from "../../../components/Spacing";

//@ts-ignore
const expenseContext = createContext<ExpenseContextType>({});

enum ExpenseMode {
  edit,
  view,
  new,
}

export class UserDivide {
  _manualInputMoney: bigint = BigInt(0);
  _manualShares: bigint = BigInt(0);
  _needs_to_pay: bigint = BigInt(0); // Automatically calculated
  _payed_money: bigint = BigInt(0);
  _unlucky_money: bigint = BigInt(0);
  _calculation: string[] = [];

  constructor(public user: GroupUser) {}

  set unlucky_money(money: bigint) {
    this._unlucky_money = money;
  }

  get unlucky_money(): bigint {
    return this._unlucky_money;
  }

  set manualInputMoney(money: bigint) {
    this._manualShares = BigInt(0);
    this._manualInputMoney = money;
  }

  get manualInputMoney(): bigint {
    return this._manualInputMoney;
  }

  set manualShares(share: bigint) {
    this._manualInputMoney = BigInt(0);
    this._manualShares = share;
  }

  get manualShares(): bigint {
    return this._manualShares;
  }

  get needs_to_pay(): bigint {
    if (this.manualInputMoney !== BigInt(0)) {
      return this.manualInputMoney;
    }

    return this._needs_to_pay;
  }

  set payed_money(payed_money: bigint) {
    this._payed_money = payed_money;
  }

  get payed_money(): bigint {
    return this._payed_money;
  }

  to_divide_price(totalPrice: bigint, number_of_shares: bigint) {
    if (this.manualInputMoney === BigInt(0) && number_of_shares > BigInt(0)) {
      this._needs_to_pay = (totalPrice / number_of_shares) * this.manualShares;
    } else if (this.manualInputMoney === BigInt(0)) {
      this._needs_to_pay = BigInt(0);
    }
  }

  addShare(bigint: bigint) {
    if (this._manualShares + bigint >= 0) {
      this.manualShares = this._manualShares + bigint;
    }
  }
}

interface ExpenseContextType {
  manualInputShares?: bigint[];
  setShares?: (shares: bigint) => void;
  moneyToDivide?: { divide: bigint };
  setMoneyToDivide: (money: { divide: bigint }) => void;
  manualInputMoney?: any[];
  // setManualInputMoney;
  forceUpdate: () => void;
  handleDivide?: () => any;
  handleRemoveExpence?: () => any;
  users?: UserDivide[];
  setDescription: (des: string) => void;
  description: string;
  isLoading?: boolean;
  // goBack: props.goBack,
  // calculatedOutcome,
  payedBy: UserDivide;
  mode: ExpenseMode;
  setMode: (expenseMode: ExpenseMode) => void;
  setPayedBy: (user: UserDivide | undefined) => void;
  oldExpense?: ExpenseType;
}

export default function ExpenseView({ children, oldExpense, ...rest }: any) {
  // @ts-expect-error
  const { group_id, event_id }: { group_id: string; event_id: string } =
    useParams();

  const user_id = useSelector(selectCurrentUser).user_id;
  const [moneyToDivide, setMoneyToDivide] = useState<{ divide: bigint }>({
    divide: BigInt(0),
  });
  const [update, forceUpdateLoc] = useState(false);
  const [usersDivide, setUsers] = useState<UserDivide[]>();
  const [payedBy, setPayedBy] = useState<UserDivide | undefined>();
  const [description, setDescription] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const { data: group } = useGetGroupQuery({
    group_id,
  });

  const users = group?.users.filter((u) => u.active);

  const [mode, setMode] = useState<ExpenseMode>(ExpenseMode.new);
  const currentUser = useSelector(selectCurrentUser);
  let old_expense: Expense = oldExpense;

  const navigate = useNavigate();
  const [
    updateExpense,
    {
      isLoading: isLoadingUpdateExpense,

      data: dataUpdateExpense,
    },
  ] = useEditExpenseMutation({});
  const [
    createExpense,
    {
      isLoading: isLoadingCreateExpense,

      data: dataCreateExpense,
    },
  ] = useCreateExpenseMutation({});
  const [updateNotUseEffect, setUpdateNotUseEffect] = useState(false);

  useEffect(() => {
    if (!usersDivide) {
      const users_to_divide = users?.map((user) => {
        return new UserDivide(user);
      });

      setUsers(users_to_divide);

      if (users_to_divide !== undefined && users_to_divide?.length > 0) {
        setPayedBy(users_to_divide.find((u) => u.user.user_id === user_id));
      }

      if (old_expense) {
        setMode(ExpenseMode.view);

        const { description, payed_amount, expense_distribution } = old_expense;

        const payed_by = users_to_divide?.find(
          (u) => u.user.user_id === old_expense.payed_by
        );

        expense_distribution.forEach((e) => {
          const user = users_to_divide?.find(
            (u) => u.user.user_id === e.user_id
          );

          // TODO There only goes something wrong when the payedBy person does not
          // have 1 share or more..
          if (user) {
            if (e.count && e.count > BigInt(0)) {
              user.manualShares = BigInt(e.count);
            } else {
              user.manualShares = BigInt(0);
              // If they did not had shares, their payed money is just the payed_amount.
              user.manualInputMoney = e.payed_amount * BigInt(-1);
            }
          }
        });

        if (payed_by) {
          setPayedBy(payed_by);

          payed_by.payed_money = BigInt(payed_amount);
        }

        // Set payed by user. This must be the UserDivide.
        // setPayedBy(old_expense_node.payed_by);
        setMoneyToDivide({ divide: BigInt(payed_amount) });
        setDescription(description);
        forceUpdate();
      } else {
        setMode(ExpenseMode.new);
      }
    }

    calculateMoneyDistribution();
  }, [update, moneyToDivide, usersDivide, payedBy]);

  function forceUpdate() {
    forceUpdateLoc(!update);
  }

  function calculateMoneyDistribution() {
    //todo make this function work. Divide the money amongs all users.
    let totalShares = BigInt(0);
    let moneyToDivideLocal = moneyToDivide.divide;

    usersDivide?.forEach((user) => {
      moneyToDivideLocal -= user.manualInputMoney;
      totalShares += user.manualShares;
      user.unlucky_money = BigInt(0);
      user.to_divide_price(BigInt(0), totalShares);

      if (user.user.user_id === payedBy?.user.user_id) {
        user.payed_money = moneyToDivide.divide;
      } else {
        user.payed_money = BigInt(0);
      }
    });

    usersDivide?.forEach((user) => {
      user.to_divide_price(moneyToDivideLocal, totalShares);
    });

    let total_payed_money = usersDivide?.reduce((a: bigint, b: UserDivide) => {
      return a + b.needs_to_pay;
    }, BigInt(0));

    if (!total_payed_money) {
      total_payed_money = BigInt(0);
    }

    const left_over_money = moneyToDivide.divide - total_payed_money!;
    let still_to_divide = left_over_money;

    const usersToDivideApon = usersDivide
      ?.filter((u) => u.manualShares > BigInt(0))
      .sort(() => (Math.random() > 0.5 ? 1 : -1));

    if (
      usersToDivideApon &&
      usersToDivideApon.length > 0 &&
      still_to_divide !== BigInt(0)
    ) {
      let makeNegative = BigInt(1);

      if (still_to_divide < BigInt(0)) {
        makeNegative = BigInt(-1);
      }

      for (let i = Math.abs(Number(still_to_divide)); i > 0; i--) {
        const unluckyIndex = (Number(i) - 1) % usersToDivideApon.length;
        const unlucky_user = usersToDivideApon[unluckyIndex];

        unlucky_user.unlucky_money =
          unlucky_user.unlucky_money + BigInt(1) * makeNegative;
      }
    }

    setUpdateNotUseEffect(!updateNotUseEffect);
  }

  async function handleEditTransaction(deleted: boolean = false) {
    const { id: expense_id } = old_expense;
    setIsLoading(true);

    if (!payedBy) {
      return Promise.reject("Iemand moet dit betaalt hebben.");
    }

    let distributions = usersDivide
      ?.filter(
        (divide) =>
          divide.needs_to_pay + divide.unlucky_money !== BigInt(0) ||
          divide.payed_money !== BigInt(0)
      )
      .map((divide) => {
        return {
          count: Number(divide.manualShares),
          payed_amount: Number(
            divide.payed_money - divide.needs_to_pay - divide.unlucky_money
          ),
          user_id: divide.user.user_id,
          expense_id,
        };
      });

    if (!distributions || distributions.length <= 0) {
      return Promise.reject("Er moet iets verdeelt worden");
    }

    // What all the users payed + what has been payed must be 0.
    const total_payed_money = usersDivide?.reduce(
      (a: bigint, b: UserDivide) => {
        return a - b.needs_to_pay - b.unlucky_money + b.payed_money;
      },
      BigInt(0)
    );
    if (total_payed_money !== BigInt(0)) {
      return Promise.reject("Totaal bedrag klopt niet");
    }

    if (!payedBy?.user.user_id) {
      return Promise.reject("Selecteer wie dit heeft betaald. ");
    }

    if (!description) {
      return Promise.reject("Voer een beschrijving in");
    }

    const variables = {
      description: description,
      objects: distributions,
      // group_id,
      payed_by: payedBy.user.user_id,
      // payed_at: Date(),
      total_cost: Number(moneyToDivide.divide),
      event_id,
      expense_id,
      deleted,
      updated_by: currentUser.user_id,
    };

    await updateExpense(variables);
    // await dispatch(EDIT_EXPENSE(variables));
  }

  async function handleRemoveExpence() {
    setIsLoading(true);

    await handleEditTransaction(true)
      .then((m) => {
        toast.success("Transatie is verwijderd.");
        if (event_id) {
          navigate("../../../show");
        } else {
          navigate("../../../../money");
        }
      })
      .catch((e: any) => {
        toast.error("Error: " + JSON.stringify(e));
      });

    setIsLoading(false);
  }

  async function onDivide() {
    setIsLoading(true);

    if (ExpenseMode.edit === mode) {
      await handleEditTransaction()
        .then((m) => {
          toast.success("Transatie is gewijzigd.");
          if (event_id) {
            navigate("../../../../show");
          } else {
            navigate("../../../../money");
          }
        })
        .catch((e: any) => {
          toast.error("Error: " + JSON.stringify(e));
        });
    } else {
      await handleTransaction()
        .then(() => {
          toast.success("Transatie is ingevoerd.");
          if (event_id) {
            navigate("../../../show");
          } else {
            navigate("../../../money");
          }
        })
        .catch((e) => {
          toast.error("Error: " + JSON.stringify(e));
        });
    }

    setIsLoading(false);
  }

  async function handleTransaction() {
    const user_id = usersDivide ? usersDivide[0].user.user_id : undefined;

    if (!user_id) {
      return Promise.reject("Invaid user. ");
    }

    let distributions = usersDivide
      ?.filter(
        (divide) =>
          divide.needs_to_pay + divide.unlucky_money !== BigInt(0) ||
          divide.payed_money !== BigInt(0)
      )
      .map((divide) => {
        return {
          count: Number(divide.manualShares),
          payed_amount: Number(
            divide.payed_money - divide.needs_to_pay - divide.unlucky_money
          ),
          user_id: divide.user.user_id,
        };
      });

    if (!distributions || distributions.length <= 0) {
      return Promise.reject("Er moet iets verdeelt worden");
    }

    // What all the users payed + what has been payed must be 0.
    const total_payed_money = usersDivide?.reduce(
      (a: bigint, b: UserDivide) => {
        return a - b.needs_to_pay - b.unlucky_money + b.payed_money;
      },
      BigInt(0)
    );
    if (total_payed_money !== BigInt(0)) {
      return Promise.reject("Totaal bedrag klopt niet");
    }

    if (!payedBy?.user.user_id) {
      return Promise.reject("Selecteer wie dit heeft betaald. ");
    }

    if (!description) {
      return Promise.reject("Voer een beschrijving in");
    }

    const onError = (e: any) => {
      throw new Error(JSON.stringify(e));
    };

    const onSuccess = () => {
      return Promise.resolve();
    };

    const variables = {
      description: description,
      data: distributions,
      group_id,
      payed_by: payedBy.user.user_id,
      payed_amount: Number(moneyToDivide.divide),
      event_id,
      onError,
      onSuccess,
    };

    createExpense(variables);
  }

  const state = {
    moneyToDivide,
    setMoneyToDivide,
    forceUpdate,
    handleDivide: onDivide,
    handleRemoveExpence,
    users: usersDivide,
    setDescription,
    description,
    payedBy,
    setPayedBy,
    isLoading: isLoadingUpdateExpense || isLoadingCreateExpense,
    mode,
    setMode,
    oldExpense,
  };

  if (dataCreateExpense || dataUpdateExpense) {
    if (event_id) {
      return <Navigate to={"../../../../show"} />;
    } else {
      return <Navigate to={"../../../money"} />;
    }
  }

  return (
    <expenseContext.Provider value={state} {...rest}>
      <KeyboardProvider>
        <Suspense fallback="loading THE GROUP">
          <ExpenseContent />
        </Suspense>
      </KeyboardProvider>
    </expenseContext.Provider>
  );
}

export function useExpense() {
  const context = useContext(expenseContext);

  if (!context) {
    throw Error("You are using the useGroup outside the GroupProvider. ");
  }

  return context;
}

const ExpenseContent: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [reload, setReload] = useState(false);
  const [hasLoadedEvent, setHasLoadedEvent] = useState(false);
  const [update, setLocalUpdate] = useState(false);
  const { hasInternet } = useNetwork();
  const theme = useTheme();
  // @ts-expect-error
  const { event_id }: { event_id: string; group_id: string } = useParams();
  const [queryEvent, { data: eventData }] = useLazyQueryEventQuery({});
  // const {
  //   data: eventData,
  //   isLoading: isLoadingEvent,
  //   error: errorLoadingEvent,
  // }

  const event = eventData?.event;

  useEffect(() => {
    if (!event && event_id) {
      queryEvent({ event_id });
    }
    if (event && !hasLoadedEvent) {
      setHasLoadedEvent(true);
      divideBetweenAll();
    }
  }, [reload, eventData]);

  const {
    setFocussedElement,
    setFocussedUser,
    setCalculatorMode,
    setInsertFunction,
    setDivideMoney: setDivideMoneyKeyboard,
    divideMoney: divideMoneyKeyboard,
  } = useKeyboard();

  function localUpdate() {
    setLocalUpdate(!update);
    forceUpdate();
  }

  function handleDateChange(date: any) {
    setSelectedDate(date);
  }

  const {
    handleDivide,
    users,
    forceUpdate,
    setDescription,
    description,
    moneyToDivide,
    setMoneyToDivide,
    payedBy,
    setPayedBy,
    isLoading,
    mode,
    setMode,
    handleRemoveExpence,
    oldExpense,
  } = useExpense();

  if (
    moneyToDivide &&
    moneyToDivide?.divide !== divideMoneyKeyboard.money &&
    mode === ExpenseMode.new
  ) {
    setMoneyToDivide({ divide: divideMoneyKeyboard.money });
  }

  if (!users) {
    return (
      <>
        <Loading />
      </>
    );
  }

  function divideBetweenAll() {
    if (!event || !users) {
      return;
    }
    users.forEach((u) => {
      u.manualShares = BigInt(0);
    });
    event.users.forEach((eventUser) => {
      const u = users.find((u) => {
        return u.user.user_id === eventUser.user_id;
      });

      if (isAttending(eventUser)) {
        if (u) {
          u.manualShares = BigInt(1) + BigInt(eventUser.number_guests);
        }
      } else if (u) {
        u.manualShares = BigInt(0);
      }
    });
    forceUpdate();
    setReload(!reload);
  }

  return (
    <Card>
      <CardContent>
        <Title
          sx={{
            color: ExpenseMode.view === mode ? theme.palette.grey[500] : null,
          }}
        >
          Uitgave invoeren
        </Title>
        {/* <Button onClick={onDivide}>Add transaction</Button> */}
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={ExpenseMode.view === mode}
            >
              <InputLabel id="demo-simple-select-outlined-label" required>
                {/* {alert("This is payed by user: " + payedBy.name)} */}
                Door
              </InputLabel>
              <Select
                size="small"
                disabled={ExpenseMode.view === mode}
                required
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={users.indexOf(payedBy)}
                onChange={(event) => {
                  const index = Number(event.target.value);
                  setPayedBy(users[index] ? users[index] : undefined);
                  localUpdate();
                }}
                label="Door"
              >
                {users?.map((division, index) => {
                  return (
                    <MenuItem value={index} key={"user-" + division.user.name}>
                      {division.user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <DatePicker
              InputProps={{
                size: "small",
              }}
              disabled={ExpenseMode.view === mode}
              openTo="day"
              views={["year", "month", "day"]}
              renderInput={(params: any) => <TextField fullWidth {...params} />}
              label="Datum"
              inputFormat="DD/MMM"
              value={selectedDate}
              // InputAdornmentProps={{ position: "start" }}
              onChange={handleDateChange}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            {/* Keyboard money: {divideMoneyKeyboard.money.toString()}
            Divide money: {moneyToDivide?.divide.toString()} */}

            <TextField
              id={"number-input-" + Math.random()}
              disabled={ExpenseMode.view === mode}
              required
              type={
                moneyToDivide && moneyToDivide.divide > BigInt(0)
                  ? "number"
                  : "number"
              }
              inputProps={{ inputMode: "decimal", step: "0.1", lang: "nl-NL" }}
              size="small"
              fullWidth
              value={
                moneyToDivide && moneyToDivide.divide
                  ? (Number(moneyToDivide.divide) / 100).toString()
                  : ""
              }
              variant="outlined"
              // type="number"
              autoFocus
              placeholder={"€ 0"}
              onFocus={(el) => {
                if (
                  setFocussedElement &&
                  setFocussedUser &&
                  setCalculatorMode &&
                  setDivideMoneyKeyboard
                ) {
                  setFocussedElement(el.currentTarget);
                  setFocussedUser(payedBy);
                  setCalculatorMode("spend");
                }
              }}
              onChange={(e: any) => {
                let num = e.target.value;
                num = num.replace(",", ".");

                if (
                  num === "0." ||
                  num === "0.0" ||
                  num === "." ||
                  num === ".0" ||
                  num === "0"
                ) {
                  setMoneyToDivide({ divide: BigInt(0) });
                  if (setDivideMoneyKeyboard) {
                    setDivideMoneyKeyboard({ money: BigInt(0) });
                  }

                  localUpdate();
                  return;
                }

                if (num === "-") {
                  num = 0;
                }

                if (
                  !isNaN(Number(num)) &&
                  setDivideMoneyKeyboard &&
                  setCalculatorMode
                ) {
                  setCalculatorMode("spend");
                  const divide_money = BigInt(
                    Math.floor(Math.round(Number(num) * 100))
                  );
                  setMoneyToDivide({ divide: divide_money });
                  setDivideMoneyKeyboard({ money: divide_money });
                } else {
                  setMoneyToDivide({ divide: num });
                }

                localUpdate();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={ExpenseMode.view === mode}
              fullWidth
              size="small"
              label="Omschrijving"
              variant="outlined"
              required
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Button
                disabled={ExpenseMode.view === mode}
                onClick={() => {
                  users.forEach((u) => {
                    u.manualShares = BigInt(1);
                  });
                  forceUpdate();
                }}
              >
                Iedereen 1x
              </Button>
              {event && (
                <Button onClick={divideBetweenAll}>Alle aanwezigen</Button>
              )}
              <Button
                disabled={ExpenseMode.view === mode}
                color="warning"
                onClick={() => {
                  users.forEach((u) => {
                    u.manualShares = BigInt(0);
                  });
                  forceUpdate();
                }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Spacing />
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            {/* For each user render this. */}
            {users?.map((division, index) => {
              return (
                <InputMoney division={division} key={index} index={index} />
              );
            })}
          </Grid>

          {/* <Grid item xs={6}></Grid>
          <Grid item xs={6} alignItems="end" justifyItems="end"></Grid> */}
        </Grid>
        <OptionBar>
          <Stack direction={"row"} spacing={1}>
            <LoadingButton
              loading={isLoading}
              onClick={handleDivide}
              color="primary"
              variant="contained"
              disabled={ExpenseMode.view === mode}
            >
              Opslaan
            </LoadingButton>

            {ExpenseMode.new !== mode && (
              <LoadingButton
                loading={isLoading}
                onClick={handleRemoveExpence}
                color="error"
                variant="contained"
                disabled={ExpenseMode.view === mode}
              >
                Verwijder
              </LoadingButton>
            )}

            {ExpenseMode.edit === mode ? (
              <Button
                color="error"
                variant="contained"
                onClick={() => setMode(ExpenseMode.view)}
              >
                Annuleer
              </Button>
            ) : null}

            {ExpenseMode.view === mode && (
              <Button
                color="secondary"
                onClick={() =>
                  // alert("Momenteel kan je de transactie nog niet wijzigen. ")
                  setMode(ExpenseMode.edit)
                }
                variant="contained"
                disabled={!hasInternet}
              >
                Edit
              </Button>
            )}
          </Stack>
        </OptionBar>
      </CardContent>
    </Card>
  );
};

function InputMoney({
  division,
  index,
}: {
  division: UserDivide;
  index: number;
}) {
  const { mode, forceUpdate } = useContext(expenseContext);
  const [exceptionCase, setExceptionCase] = useState("");

  const collapsed = division.manualShares > 0;

  let money_to_pay: number | string = "";
  if (division.needs_to_pay + division.unlucky_money !== BigInt(0)) {
    money_to_pay = Number(division.needs_to_pay + division.unlucky_money) / 100;
  }

  const { setFocussedElement, setFocussedUser, setCalculatorMode } =
    useKeyboard();

  useEffect(() => {
    setTimeout(() => {
      const t = document.getElementById("number-input");
    }, 100);
  }, []);

  return (
    <Grid
      style={{ paddingBottom: 5 }}
      container
      spacing={1}
      key={"expense-row-" + division.user.user_id}
      alignItems="center"
    >
      <Grid item xs={4}>
        {division.user.name.substring(0, 10)}
      </Grid>
      <Grid item xs={4} style={{ minWidth: 90 }}>
        <Grid
          container
          direction="row"
          // alignItems="center"
          // justifyContent="center"
          // style={{ textAlign: "center", backgroundColor: "yellow" }}
        >
          <Grid item xs={6}>
            <Box>
              <Grow in={collapsed}>
                <Fab
                  style={{
                    width: "35px",
                    height: "20px",
                  }}
                  disabled={mode === ExpenseMode.view}
                  variant="circular"
                  size="small"
                  onClick={() => {
                    division.addShare(BigInt(-1));
                    forceUpdate();
                  }}
                >
                  <RemoveIcon
                    sx={{
                      fontSize: "0.75rem",
                    }}
                  />
                </Fab>
              </Grow>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Fab
              style={{
                width: "35px",
                height: "20px",
              }}
              disabled={mode === ExpenseMode.view}
              variant="circular"
              color="secondary"
              size="small"
              onClick={() => {
                division.addShare(BigInt(1));
                forceUpdate();
              }}
            >
              {division.manualShares.toString()}
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {/* This is the final divided money */}
        <TextField
          id={"number-input-" + Math.random()}
          disabled={ExpenseMode.view === mode}
          inputProps={{ inputMode: "decimal" }}
          fullWidth
          size="small"
          placeholder={"€ 0"}
          value={exceptionCase ? exceptionCase : money_to_pay}
          type={
            division.manualShares > BigInt(0) || exceptionCase
              ? "number"
              : "number"
          }
          variant="outlined"
          onFocus={(el) => {
            if (setFocussedElement && setFocussedUser && setCalculatorMode) {
              setFocussedElement(el.currentTarget);
              setFocussedUser(division);
              setCalculatorMode("insert");
            }
          }}
          onChange={(e: any) => {
            if (setCalculatorMode) {
              setCalculatorMode("insert");
              handleBigInt(e);
            }
          }}
        />
      </Grid>
    </Grid>
  );

  function handleBigInt(e: any) {
    if (
      e.target.value === "0." ||
      e.target.value === "0.0" ||
      e.target.value === "." ||
      e.target.value === ".0" ||
      e.target.value === "0"
    ) {
      division.manualInputMoney = BigInt(0);
      setExceptionCase(e.target.value);
      forceUpdate();
      return;
    }

    if (!isNaN(Number(e.target.value))) {
      const manual_input_int = Math.floor(
        Math.round(Number(e.target.value) * 100)
      );

      division.manualInputMoney = BigInt(manual_input_int);
      forceUpdate();
    } else if (e.target.value === "-") {
      division.manualInputMoney = BigInt(0);
    }
    setExceptionCase("");
  }
}
