function UndrawVault(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      data-name="Layer 1"
      viewBox="0 0 940.675 433.962"
    >
      <path
        fill="#f0f0f0"
        d="M199.49 409.814c-22.522-14.678-38.899-41.062-35.547-67.736a123.863 123.863 0 0076.435 37.278c11.219 1.123 23.813 1.203 31.78 9.181 4.958 4.964 7.064 12.22 7.173 19.234.108 7.015-1.578 13.919-3.252 20.732l.411 1.702c-26.865-.993-54.476-5.713-77-20.391z"
      ></path>
      <path
        fill="#fff"
        d="M164.507 342.073a105.87 105.87 0 0042.013 49.777 45.591 45.591 0 0013.058 5.792 26.149 26.149 0 0013.752-.528c4.18-1.222 8.313-2.957 12.717-3.142a16.01 16.01 0 0112.09 5.027c3.962 3.993 6.253 9.171 8.317 14.313 2.292 5.708 4.582 11.674 9.25 15.903.565.512-.263 1.36-.828.848-8.12-7.357-9.014-19.16-15.398-27.694-2.978-3.982-7.175-7.09-12.306-7.222-4.486-.115-8.75 1.67-12.972 2.944a27.903 27.903 0 01-13.427 1.017 42.148 42.148 0 01-13.232-5.269 102.713 102.713 0 01-24.765-20.426 107.747 107.747 0 01-19.411-31.025c-.288-.704.856-1.015 1.142-.315z"
      ></path>
      <path
        fill="#fff"
        d="M200.216 388.42a15.884 15.884 0 01-20.256 3.572c-.656-.388-.085-1.426.572-1.038a14.708 14.708 0 0018.835-3.362c.484-.589 1.33.242.849.828zM231.292 398.013a30.615 30.615 0 00-9.853-20.017c-.564-.515.264-1.362.827-.849a31.844 31.844 0 0110.21 20.851c.063.762-1.122.772-1.184.015zM176.075 363.758a8.991 8.991 0 003.346-7.848c-.072-.76 1.114-.77 1.185-.015a10.075 10.075 0 01-3.683 8.69.612.612 0 01-.838.01.595.595 0 01-.01-.837z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M240.77 293.266c.059.474.117.947.184 1.425a118.406 118.406 0 003.986 18.79c.135.477.278.959.426 1.431a124.827 124.827 0 0021.468 40.635 121.223 121.223 0 0012.53 13.5c6.233 5.767 13.45 11.523 17.3 18.798a20.798 20.798 0 011.087 2.333l-19.216 39.393c-.088.068-.168.14-.257.208l-.695 1.608c-.248-.2-.5-.411-.749-.61-.144-.115-.284-.239-.428-.354-.095-.079-.19-.159-.28-.225a1.63 1.63 0 01-.085-.075c-.09-.066-.167-.137-.248-.2q-2.124-1.77-4.23-3.576c-.01-.004-.01-.004-.014-.017-10.676-9.203-20.678-19.377-28.817-30.747-.245-.342-.5-.688-.735-1.048a115.673 115.673 0 01-9.61-16.248 102.262 102.262 0 01-4.015-9.536 85.037 85.037 0 01-4.997-26.114c-.568-17.767 4.553-35.519 16.475-48.396.305-.33.606-.65.92-.975z"
      ></path>
      <path
        fill="#fff"
        d="M241.227 293.599a105.87 105.87 0 003.576 65.039 45.591 45.591 0 006.939 12.486 26.149 26.149 0 0011.297 7.858c4.074 1.54 8.419 2.644 12.047 5.148a16.01 16.01 0 016.626 11.293c.76 5.573-.529 11.087-1.977 16.435-1.607 5.938-3.37 12.08-2.189 18.267.143.75-1.028.927-1.171.179-2.055-10.763 4.337-20.725 4.379-31.383.02-4.973-1.46-9.982-5.477-13.176-3.513-2.793-7.993-3.935-12.13-5.459a27.903 27.903 0 01-11.334-7.271 42.148 42.148 0 01-7.393-12.174 102.712 102.712 0 01-7.475-31.22 107.747 107.747 0 013.18-36.459c.195-.735 1.295-.294 1.102.437z"
      ></path>
      <path
        fill="#fff"
        d="M241.835 352.103a15.884 15.884 0 01-18.324-9.343c-.29-.705.79-1.19 1.082-.484a14.708 14.708 0 0017.062 8.656c.741-.18.916.993.18 1.171zM260.871 378.473a30.615 30.615 0 004.185-21.915c-.14-.75 1.03-.929 1.171-.18a31.844 31.844 0 01-4.4 22.797c-.41.645-1.362-.06-.956-.702zM237.407 317.877a8.991 8.991 0 007.397-4.25c.4-.65 1.353.055.955.7a10.075 10.075 0 01-8.172 4.722.612.612 0 01-.676-.496.595.595 0 01.496-.676z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M886.838 409.814c22.523-14.678 38.9-41.062 35.548-67.736a123.863 123.863 0 01-76.435 37.278c-11.219 1.123-23.814 1.203-31.78 9.181-4.958 4.964-7.064 12.22-7.173 19.234-.108 7.015 1.578 13.919 3.252 20.732l-.412 1.702c26.866-.993 54.477-5.713 77-20.391z"
      ></path>
      <path
        fill="#fff"
        d="M921.822 342.073a105.87 105.87 0 01-42.013 49.777 45.591 45.591 0 01-13.058 5.792 26.149 26.149 0 01-13.752-.528c-4.18-1.222-8.313-2.957-12.717-3.142a16.01 16.01 0 00-12.09 5.027c-3.962 3.993-6.253 9.171-8.317 14.313-2.292 5.708-4.582 11.674-9.25 15.903-.565.512.263 1.36.828.848 8.12-7.357 9.014-19.16 15.398-27.694 2.978-3.982 7.175-7.09 12.306-7.222 4.486-.115 8.75 1.67 12.972 2.944a27.902 27.902 0 0013.427 1.017 42.148 42.148 0 0013.232-5.269 102.713 102.713 0 0024.765-20.426 107.747 107.747 0 0019.411-31.025c.288-.704-.856-1.015-1.142-.315z"
      ></path>
      <path
        fill="#fff"
        d="M886.113 388.42a15.884 15.884 0 0020.256 3.572c.656-.388.085-1.426-.572-1.038a14.708 14.708 0 01-18.835-3.362c-.484-.589-1.33.242-.849.828zM855.037 398.013a30.615 30.615 0 019.853-20.017c.564-.515-.264-1.362-.827-.849a31.844 31.844 0 00-10.21 20.851c-.063.762 1.122.772 1.184.015zM910.254 363.758a8.991 8.991 0 01-3.346-7.848c.071-.76-1.114-.77-1.185-.015a10.075 10.075 0 003.683 8.69.612.612 0 00.838.01.595.595 0 00.01-.837z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M845.558 293.266c-.058.474-.116.947-.184 1.425a118.407 118.407 0 01-3.986 18.79c-.134.477-.277.959-.425 1.431a124.827 124.827 0 01-21.468 40.635 121.225 121.225 0 01-12.53 13.5c-6.233 5.767-13.45 11.523-17.3 18.798a20.796 20.796 0 00-1.087 2.333l19.216 39.393c.088.068.168.14.257.208l.695 1.608c.248-.2.5-.411.749-.61.144-.115.284-.239.428-.354.095-.079.19-.159.28-.225a1.63 1.63 0 00.085-.075c.09-.066.167-.137.248-.2q2.124-1.77 4.23-3.576c.01-.004.01-.004.014-.017 10.676-9.203 20.678-19.377 28.817-30.747.245-.342.5-.688.735-1.048a115.673 115.673 0 009.61-16.248 102.26 102.26 0 004.015-9.536 85.036 85.036 0 004.997-26.114c.568-17.767-4.553-35.519-16.475-48.396-.305-.33-.606-.65-.92-.975z"
      ></path>
      <path
        fill="#fff"
        d="M845.102 293.599a105.87 105.87 0 01-3.576 65.039 45.591 45.591 0 01-6.939 12.486 26.149 26.149 0 01-11.297 7.858c-4.074 1.54-8.419 2.644-12.047 5.148a16.01 16.01 0 00-6.626 11.293c-.76 5.573.529 11.087 1.976 16.435 1.608 5.938 3.371 12.08 2.19 18.267-.143.75 1.028.927 1.171.179 2.055-10.763-4.337-20.725-4.379-31.383-.02-4.973 1.46-9.982 5.477-13.176 3.513-2.793 7.993-3.935 12.13-5.459a27.902 27.902 0 0011.334-7.271 42.148 42.148 0 007.393-12.174 102.712 102.712 0 007.475-31.22 107.747 107.747 0 00-3.18-36.459c-.195-.735-1.295-.294-1.102.437z"
      ></path>
      <path
        fill="#fff"
        d="M844.494 352.103a15.884 15.884 0 0018.324-9.343c.29-.705-.79-1.19-1.082-.484a14.708 14.708 0 01-17.062 8.656c-.741-.18-.916.993-.18 1.171zM825.458 378.473a30.615 30.615 0 01-4.185-21.915c.14-.75-1.03-.929-1.171-.18a31.844 31.844 0 004.4 22.797c.41.645 1.362-.06.956-.702zM848.922 317.877a8.991 8.991 0 01-7.397-4.25c-.4-.65-1.353.055-.955.7a10.075 10.075 0 008.172 4.722.612.612 0 00.675-.496.595.595 0 00-.495-.676z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M243.852 280.375a6.803 6.803 0 01-1.614-10.306l-11.981-21 12.537.81 9.455 19.818a6.84 6.84 0 01-8.397 10.678z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M151.623 423.528L159.918 423.528 163.864 391.535 151.622 391.536 151.623 423.528z"
      ></path>
      <path
        fill="#2f2e41"
        d="M149.508 420.82h16.335a10.41 10.41 0 0110.41 10.41v.338h-26.745z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M208.454 423.528L216.748 423.528 220.694 391.535 208.452 391.536 208.454 423.528z"
      ></path>
      <path
        fill="#2f2e41"
        d="M206.338 420.82h16.335a10.41 10.41 0 0110.41 10.41v.338h-26.745zM229.782 249.972s2.281 32.489-2.212 48.851-5.296 115.312-5.296 115.312h-15.06l-13.055-134.691-30.094 135.166-14.346-.987 7.868-159.275z"
      ></path>
      <path
        fill="#3f3d56"
        d="M163.81 140.454l11.514-8.402 19.294 4.2 5.222 12.41 9 4.462a20.399 20.399 0 0111.126 15.352l12.857 88.78-75.236-2.908-9.109-104.556a8.197 8.197 0 017.69-8.894z"
      ></path>
      <path
        fill="#3f3d56"
        d="M213.6 160.059L220.549 169.426 250.815 261.738 236.628 267.418 213.911 224.403 213.6 160.059z"
      ></path>
      <circle cx="188.411" cy="110.861" r="16.804" fill="#ffb9b9"></circle>
      <path
        d="M151.778 179.997L179.2 218.137 209.852 238.125 151.778 179.997z"
        opacity="0.1"
      ></path>
      <path
        fill="#2f2e41"
        d="M179.506 123.01c-1.17-2.335-2.372-4.875-1.925-7.446s3.398-4.813 5.675-3.538c.945-3.415 5.128-4.91 8.65-4.515s6.763 2.093 10.23 2.822 7.68.15 9.553-2.857l-4.484-.077 4.67-5.416-5.413-.405c1.875-2.023 1.377-5.461-.438-7.538s-4.57-3.06-7.257-3.682a40.428 40.428 0 00-16.343-.376 21.245 21.245 0 00-7.26 2.527 14.445 14.445 0 00-5.076 5.264 17.377 17.377 0 00-2.063 7.92 22.145 22.145 0 009.758 18.836"
      ></path>
      <path
        fill="#e4e4e4"
        d="M805.173 432H283.684a20.28 20.28 0 01-20.256-20.256V20.256A20.28 20.28 0 01283.684 0h521.489a20.279 20.279 0 0120.256 20.256v391.488A20.279 20.279 0 01805.173 432z"
      ></path>
      <path
        fill="#fff"
        d="M673.924 179.75h-80.5a15 15 0 010-30h80.5a15 15 0 010 30zM603.424 220.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM682.424 220.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM642.424 220.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM603.424 241.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM682.424 241.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM642.424 241.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM603.424 262.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM682.424 262.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM642.424 262.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 010 11zM603.424 283.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 110 11zM682.424 283.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 110 11zM642.424 283.75h-19a5.5 5.5 0 010-11h19a5.5 5.5 0 110 11z"
      ></path>
      <circle cx="474.924" cy="210.75" r="51" fill="#cacaca"></circle>
      <path
        fill="#fff"
        d="M474.924 273.25a15.017 15.017 0 01-15-15v-95a15 15 0 1130 0v95a15.017 15.017 0 01-15 15z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M608.16 173.309h-14a2 2 0 010-4h14a2 2 0 110 4zM630.16 173.809h-14a2 2 0 010-4h14a2 2 0 110 4zM652.16 174.309h-14a2 2 0 010-4h14a2 2 0 110 4zM674.16 174.809h-14a2 2 0 010-4h14a2 2 0 110 4z"
      ></path>
      <path
        fill="#cacaca"
        d="M329.924 370.249a18.693 18.693 0 005.56 13.31h-8.26a18.56 18.56 0 01-18.56-18.56V64.119a18.56 18.56 0 0118.56-18.56h12.02a18.744 18.744 0 00-9.32 16.2zM939.485 433.962H1.19a1.19 1.19 0 010-2.381h938.294a1.19 1.19 0 010 2.381z"
      ></path>
      <path
        fill="#fff"
        d="M327.924 133.479q-9.48-2.655-19.03-4.92a543.559 543.559 0 00-66.09-11.52 14.785 14.785 0 00-18.03 10.41l-11.11 41a14.777 14.777 0 0010.39 18.11l.15.03c14.7 1.67 29.54 3.99 44.3 6.95 1.32.26 2.63.53 3.95.81q7.096 1.47 14.15 3.14c2.06.48 4.11.98 6.17 1.49q8.31 2.07 16.63 4.41 9.256 2.61 18.52 5.57c.67.2 1.34.41 2 .63v-75.54c-.66-.2-1.33-.39-2-.57z"
      ></path>
      <path
        fill="#006c4c"
        d="M327.924 133.479q-9.48-2.655-19.03-4.92a543.559 543.559 0 00-66.09-11.52 14.785 14.785 0 00-18.03 10.41l-11.11 41a14.777 14.777 0 0010.39 18.11l.15.03c14.7 1.67 29.54 3.99 44.3 6.95 1.32.26 2.63.53 3.95.81q7.096 1.47 14.15 3.14c2.06.48 4.11.98 6.17 1.49q8.31 2.07 16.63 4.41 9.256 2.61 18.52 5.57c.67.2 1.34.41 2 .63v-2.11c-.67-.21-1.34-.42-2-.63q-8.52-2.715-17.02-5.11-3.18-.915-6.36-1.77-5.73-1.545-11.43-2.96-3.03-.765-6.05-1.47c-.15-.04-.31-.07-.46-.11q-5.145-1.215-10.31-2.32c-1.32-.28-2.64-.56-3.96-.83-15.92-3.3-31.96-5.87-47.83-7.67a12.777 12.777 0 01-8.91-15.63l11.11-41.01a12.767 12.767 0 0115.65-8.97l.15.02a541.782 541.782 0 0165.26 11.32c.22.06.44.11.66.16q9.78 2.34 19.5 5.06c.66.18 1.33.37 2 .57v-2.08c-.66-.2-1.33-.39-2-.57z"
      ></path>
      <path
        fill="#006c4c"
        d="M329.924 135.089v73.44c-.67-.22-1.33-.43-2-.64q-8.864-2.835-17.79-5.34-8.565-2.43-17.19-4.57c-2.04-.5-4.07-.99-6.11-1.47-.08-.36-.16-.72-.23-1.08-.37-1.72-.66-3.46-.88-5.21-.1-.67-.17-1.34-.24-2.02a69.067 69.067 0 0122.28-57.86c.29-.28.59-.55.89-.81q9.69 2.31 19.27 5c.67.17 1.34.36 2 .56zM263.515 165.159l-27.81-3.28a7.502 7.502 0 10-1.76 14.9l27.81 3.28a7.502 7.502 0 001.76-14.9zm4.58 8.1a5.506 5.506 0 01-6.11 4.81l-27.81-3.28a5.498 5.498 0 011.29-10.92l27.81 3.28a5.52 5.52 0 014.82 6.11z"
      ></path>
      <path
        fill="#fff"
        d="M329.924 152.289v34.83a16.298 16.298 0 01-2 1.02 20.168 20.168 0 01-21.84-3.54c-.28-.25-.56-.52-.83-.8a15.7 15.7 0 01-.78-.85 20.186 20.186 0 0123.45-31.69 20.895 20.895 0 012 1.03z"
      ></path>
      <path
        fill="#fff"
        d="M327.924 177.499q-11.804 2.46-23.45 5.45-4.35 1.11-8.67 2.3-5.16 1.425-10.32 2.95-6.584 1.95-13.15 4.08c-1.27.42-2.55.84-3.83 1.26a554.952 554.952 0 00-36.15 13.47 14.787 14.787 0 00-10.79 17.81l10.03 41.28a14.779 14.779 0 0017.83 10.86l.14-.05a533.852 533.852 0 0159.93-20.52q9.12-2.535 18.43-4.78c.67-.16 1.33-.32 2-.47v-74.05c-.67.12-1.33.26-2 .41z"
      ></path>
      <path
        fill="#006c4c"
        d="M327.924 177.499q-11.804 2.46-23.45 5.45-4.35 1.11-8.67 2.3-5.16 1.425-10.32 2.95-6.584 1.95-13.15 4.08c-1.27.42-2.55.84-3.83 1.26a554.952 554.952 0 00-36.15 13.47 14.787 14.787 0 00-10.79 17.81l10.03 41.28a14.779 14.779 0 0017.83 10.86l.14-.05a533.852 533.852 0 0159.93-20.52q9.12-2.535 18.43-4.78c.67-.16 1.33-.32 2-.47v-2.06l-2 .48-1.51.36q-8.82 2.145-17.45 4.54c-.15.04-.31.09-.46.13a533.386 533.386 0 00-59.63 20.45 12.788 12.788 0 01-15.34-9.41l-10.02-41.28a12.772 12.772 0 019.38-15.41l.15-.05a539.825 539.825 0 0139.41-14.54c1.28-.42 2.56-.83 3.84-1.24q4.71-1.485 9.43-2.89 4.98-1.485 9.95-2.86l.66-.18q4.86-1.335 9.75-2.58 10.845-2.76 21.84-5.07c.66-.14 1.33-.28 2-.41v-2.03c-.67.12-1.33.26-2 .41z"
      ></path>
      <path
        fill="#006c4c"
        d="M329.924 178.099v72.01c-.66.15-1.33.3-2 .46q-9.39 2.28-18.7 4.86-.374-.42-.72-.84a69.12 69.12 0 01-15.73-55.61c.05-.33.11-.67.17-1 .05-.32.11-.65.17-.97a68.508 68.508 0 012.56-9.65c.13-.38.26-.76.4-1.14q4.575-1.26 9.18-2.42 11.266-2.91 22.67-5.29c.66-.15 1.33-.28 2-.41zM283.454 243.329a7.51 7.51 0 00-9.77-4.13l-25.95 10.52a7.5 7.5 0 005.64 13.9l25.95-10.52a7.517 7.517 0 004.13-9.77zm-4.88 7.91l-25.95 10.53a5.504 5.504 0 01-4.14-10.2l25.95-10.52a5.5 5.5 0 114.14 10.19z"
      ></path>
      <path
        fill="#fff"
        d="M329.924 196.489v39.21a19.61 19.61 0 01-2 .4 20.205 20.205 0 01-18.52-32.71c.23-.29.47-.57.73-.84.24-.28.5-.55.77-.81a20.197 20.197 0 0117.02-5.64 17.2 17.2 0 012 .39z"
      ></path>
      <path
        fill="#2f2e41"
        d="M327.924 128.269v99.81c.67-.22 1.34-.43 2-.63v-98.27c-.67-.31-1.33-.61-2-.91zm0 0v99.81c.67-.22 1.34-.43 2-.63v-98.27c-.67-.31-1.33-.61-2-.91zm0 0v99.81c.67-.22 1.34-.43 2-.63v-98.27c-.67-.31-1.33-.61-2-.91zm0 7.29v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v116.05c.67-.16 1.33-.32 2-.47v-115.01c-.67-.2-1.34-.39-2-.57zm436.25-94.56h-415.49a20.795 20.795 0 00-20.76 20.76v308.49a20.785 20.785 0 0020.76 20.75h415.49a20.775 20.775 0 0020.75-20.75V61.759a20.777 20.777 0 00-20.75-20.76zm18.75 329.25a18.77 18.77 0 01-18.75 18.75h-415.49a18.778 18.778 0 01-18.76-18.75V61.759a18.788 18.788 0 0118.76-18.76h415.49a18.772 18.772 0 0118.75 18.76zm-455-234.69v114l2-.48v-112.95c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0 0v115.01c.67-.16 1.34-.31 2-.46v-113.98c-.67-.2-1.34-.39-2-.57zm0-7.29v99.81c.67-.22 1.34-.43 2-.63v-98.27c-.67-.31-1.33-.61-2-.91zm0 0v99.81c.67-.22 1.34-.43 2-.63v-98.27c-.67-.31-1.33-.61-2-.91z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M238.85 233.874a6.803 6.803 0 00-9.99-3.003l-19.17-14.73-.907 12.53 18.344 12.067a6.84 6.84 0 0011.723-6.864z"
      ></path>
      <path
        fill="#3f3d56"
        d="M161.6 144.976l-6.309-2.98s-10.162 2.858-5.225 16.296l29.879 55.664 36.479 21.357 11.372-10.077-36.505-28.884z"
      ></path>
    </svg>
  );
}

export default UndrawVault;
