import { Outlet, useRoutes } from "react-router-dom";
import NotAPage from "../components/NotAPage";
import Finished from "./eetlijst/Finished";
import TransferBalance from "./eetlijst/TransferBalance";
import TransferUsers from "./eetlijst/TransferUsers";
import LoggedOutLayout from "../layout/LoggedOutLayout";
import InvitedForGroup from "../logged-in/in-group/InvitedForGroup";
import ForgotPassword from "./ForgotPassword";
import HeaderPublic from "./HeaderPublic";

import Install from "./Install";
import LandingPage from "./landingpage/landingpage";
import MobileLandingPage from "./landingpage/MobileLandingPage";
import Login from "./Login";
import LoginEetlijst from "./LoginEetlijst";
import LoginSplit from "./LoginSplit";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import WelcomeEetlijst from "./eetlijst/WelcomeEetlijst";
import EetlijstLayout from "./eetlijst/LayoutEetlijst";
import StorePreview from "./StorePreview";

export default function NotLoggedInRouter() {
  return useRoutes([
    {
      path: "/",
      element: <LoggedOutLayout />,
      children: [
        {
          path: "",
          element: (
            <>
              <HeaderPublic />
              <Outlet />
            </>
          ),
          children: [
            { path: "", element: <Login /> },

            {
              path: "register",
              element: <Register />,
            },
            {
              path: "forgot",
              element: <ForgotPassword />,
            },
            {
              path: "invite/:group_id/:invite_id",
              element: <InvitedForGroup />,
            },
            {
              path: "/account/reset_password/:user_id/:reset_auth_id",
              element: <ResetPassword />,
            },
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "preview",
              element: <StorePreview />,
            },
          ],
        },
        {
          path: "tutorial",
          element: <MobileLandingPage />,
        },

        {
          path: "install",
          element: <Install />,
        },
        {
          path: "*",
          element: <NotAPage />,
        },
        {
          path: "eetlijst",
          element: <EetlijstLayout />,
          children: [
            {
              path: "login",
              element: <LoginEetlijst />,
            },
            {
              path: "choice",
              element: <LoginSplit />,
            },
            { path: "users", element: <TransferUsers /> },

            {
              path: "finished",
              element: <Finished />,
            },
            {
              path: "welcome",
              element: <WelcomeEetlijst />,
            },
          ],
        },
      ],
    },
  ]);
}
