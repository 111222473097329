import { Button, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../../components";

// import { Container } from './styles';

const Finished: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title>Account is klaar</Title>
      <List>
        <ListItem>
          <Typography>
            Jullie account is nu klaar om van de nieuwe eetlijst gebruik te
            maken!
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>Veel plezier!</Typography>
        </ListItem>
      </List>
      <Button
        onClick={() => {
          navigate("../../groups");
        }}
        variant="contained"
      >
        Bedankt
      </Button>
    </>
  );
};

export default Finished;
