import { Typography, Container } from "@mui/material";
import React from "react";
import { Title } from "../../components";
import TextBold from "../../components/Bold";

const CheckBalanceExplaination: React.FC = () => {
  return (
    <Container>
      <Title>Controleer balans</Title>
      <Typography>
        Wij hebben voor jullie de oude balans geïmporteerd, maar soms klopt dit
        niet! Wij hebben er alles aan gedaan om het kloppend te krijgen, maar
        dat is helaas niet gelukt voor sommige (oude) huizen.
      </Typography>

      <br />
      <Typography>
        In het volgende scherm kan je de balans corrigeren. Links staat de
        geïmporteerde balans, rechts de oude balans. Ga naar
        &quot;kostenbeheer&quot; en controleer voor <TextBold>elke</TextBold>
        &nbsp; huisgenoot of de balans klopt en pas dit eventueel aan.
      </Typography>

      <br />
      <Typography>
        Excuus dat dit moet, maar we hopen dat jullie begrijpen dat de oude
        Eetlijst meer dan 10 jaar oud is, en dit helaas niet altijd automatisch
        goed ging.
      </Typography>
    </Container>
  );
};

export default CheckBalanceExplaination;
