import { Device, OperatingSystem } from "@capacitor/device";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "../components";
import AppleIcon from "@mui/icons-material/Apple";
import PlaystoreIcon from "../assets/img/landingpage/play-store-2";
// import { Container } from './styles';

const Install: React.FC = () => {
  const [os, setOs] = useState<OperatingSystem>("unknown");
  const android_store =
    "https://play.google.com/store/apps/details?id=nl.samenn.app";
  const ios_store = "https://apps.apple.com/nl/app/samenn/id1589607263?l=nl";
  const navigate = useNavigate();

  async function goToStore() {
    const device = await Device.getInfo();

    const os = device.operatingSystem;
    setOs(os);

    if (os === "android") {
      window.location.href = android_store;
    }

    if (os === "ios") {
      window.location.href = ios_store;
    }
  }

  useEffect(() => {
    goToStore();
  }, []);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} textAlign="center">
          <Grid xs={12} item>
            <Title>Installeer de app</Title>
          </Grid>

          {os !== "ios" && (
            <Grid xs={12} sm={6} md={6} item>
              <Button
                style={{ width: 250 }}
                component={Link}
                href={android_store}
                variant="contained"
              >
                <Grid xs={4} item textAlign="start">
                  <PlaystoreIcon />
                </Grid>
                <Grid xs={8} item textAlign="start">
                  <Typography
                    display="inline-block"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Get it on
                  </Typography>
                  <br />
                  <Typography
                    display="inline-block"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Google Playstore
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          )}
          {os !== "android" && (
            <Grid xs={12} sm={6} md={6} item>
              <Button
                style={{ width: 250 }}
                component={Link}
                href={ios_store}
                variant="contained"
              >
                <Grid xs={4} item textAlign="start">
                  <AppleIcon />
                </Grid>
                <Grid xs={8} item textAlign="start">
                  <Typography
                    display="inline-block"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Download on the
                  </Typography>
                  <br />
                  <Typography
                    display="inline-block"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Apple Appstore
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          )}
          <Grid xs={12} item>
            <Button component={Link} href="./" fullWidth>
              Naar de home pagina
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Install;
