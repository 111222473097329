import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../logged-in/in-group/Header";
import Container from "@mui/material/Container";

const OutsideGroup: React.FC = () => {
  return (
    <>
      <Header />

      <Container style={{ paddingBottom: 75 }}>
        <Outlet />
      </Container>

      {/* <OutsideGroupBottomNav /> */}
    </>
  );
};

export default OutsideGroup;
