import { PushNotifications } from "@capacitor/push-notifications";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentUser } from "../redux/auth";
import logger from "../logger";
import {
  useRegistrateNotificationMutation,
  useUpdateNotificationMutation,
} from "../redux/graphqlApi";
import {
  selectAskedPermission,
  ASK_TO_REGISTRATE_NOTIFICATION,
  ASKED_PERMISSION,
} from "../redux/notification";
import { Title } from "./styled";

interface NotificationInterface {
  registerNotifications: () => void;
}

// @ts-ignore
const NotificationStore = createContext<NotificationInterface>(null);

export const NotificationProvider: React.FC = ({ children }) => {
  const [askPermission, setAskPermission] = useState<string | boolean>(false);
  const user = useSelector(selectCurrentUser);
  const asked_permission = useSelector(selectAskedPermission);
  const [createNotification, { data, isLoading }] =
    useRegistrateNotificationMutation();
  const [editNotification, { data: dataEditNotification }] =
    useUpdateNotificationMutation();
  const dispatch = useDispatch();

  const promptNotificationRegistration = async () => {
    setAskPermission(false);
    dispatch(ASK_TO_REGISTRATE_NOTIFICATION());
    dispatch(ASKED_PERMISSION());
  };

  const addListeners = async () => {
    const isNative = Capacitor.isNativePlatform();

    if (!isNative) {
      return;
    }

    await PushNotifications.addListener("registration", async (token) => {
      const t = token.value;

      if (!t) {
        // alert("Kon geen notificaties sturen. Onbekende error.");
        return;
      }

      await createNotification({
        device_token: t,
        user_id: user.user_id,
        platform: Capacitor.getPlatform(),
      });
    });

    await PushNotifications.addListener("registrationError", (err) => {
      // logger.error(
      //   `[PushNotifications registrationError] Registration error ${JSON.stringify(
      //     err.error
      //   )}`
      // );
      // alert("There was a registration error...");
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push notification received: ", notification);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue,
          notification.notification
        );
        PushNotifications.removeAllDeliveredNotifications();
      }
    );
  };

  const registerNotifications = () => {
    if (!asked_permission) {
      setAskPermission(true);
      dispatch(ASKED_PERMISSION());
    }
  };

  useEffect(() => {
    addListeners();
  }, []);

  const state = {
    registerNotifications,
  };

  return (
    <>
      {askPermission && !asked_permission ? (
        <PermissionDialog
          open={askPermission !== undefined}
          handleCancel={() => {
            setAskPermission(false);
          }}
          handleAccept={() => promptNotificationRegistration()}
        />
      ) : null}
      <NotificationStore.Provider value={state}>
        {children}
      </NotificationStore.Provider>
    </>
  );
};

function useNotification() {
  const context = useContext(NotificationStore);

  if (!context) {
    throw new Error("Did not wrap useNotification in NotificationProvider");
  }

  return context;
}

export default useNotification;

interface RemoveEventAlertType {
  open: boolean;
  handleCancel: (e: any) => any;
  handleAccept: (e: any) => any;
}
function PermissionDialog(props: RemoveEventAlertType) {
  const { open, handleCancel, handleAccept } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Title id="alert-dialog-title">Notificaties</Title>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Graag willen we je notificaties sturen voor dit evenement. Hier
            hebben wij je toestemming voor nodig.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuleer
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Geef toestemming
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
