import { isRejected, isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import logger from "../logger";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("A rejected action: ", action);
      if (action.error.name === "ConditionError") {
        return next(action);
      }
      logger.error(
        `[${action.type}] caught in middleware ${JSON.stringify(action)}`
      );
      console.error(action);
      // if (action.payload.error.message) {
      //   console.log("ERROR WAS NOW");
      //   toast.error(action.payload.error);

      if (action.payload.message) {
        toast.error(action.payload.message.slice(0, 100));
      } else if (action.error?.data?.message) {
        toast.error(action.error.data.message);
      } else {
        console.error("[middlware action error] ", action);
        if (action.error.message) {
          toast.error(action.error.message);
        } else {
          toast.error(action.payload.error);
        }
      }
    }

    return next(action);
  };
