import { createContext } from "react";

export interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: any;
  error?: Error;

  // Auth functions.
  login: (email: string, password: string) => void;
  logout: () => void;
  register: (
    email: string,
    name: string,
    password: string,
    password_2: string
  ) => Promise<{ success?: Boolean; message?: string }>;
  jwt?: string;
  user_id: string;
  forgotPassword: (email: string) => Promise<any>;
  resetPassword: (
    user_id: string,
    reset_auth_id: string,
    password: string
  ) => Promise<{ success?: boolean; message?: string }>;
}

export const AuthContext = createContext<AuthState | null>(null);
