import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import {
  Box,
  Container,
  Divider,
  FormLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
  useTheme as useThemeMui,
  useThemeProps,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { default as SwitchMui } from "@mui/material/Switch";
import Avatar from "@mui/material/Avatar";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ListItemText } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import useTheme, { ThemeType } from "../../../hooks/theme/useTheme";
import { Capacitor } from "@capacitor/core";
import { selectCurrentUser } from "../../../redux/auth";
// import { AppVersion } from "@awesome-cordova-plugins/app-version";
import SwitchAccountRoundedIcon from "@mui/icons-material/SwitchAccountRounded";
import ArrowForwardIosRounded from "@mui/icons-material/ArrowForwardIosRounded";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import {
  ASK_TO_REGISTRATE_NOTIFICATION,
  selectNotificationToken,
  selectSendNotifications,
} from "../../../redux/notification";
import { resetStore } from "../../../redux/store";
import {
  useCurrentUserInfoQuery,
  useUpdateNotificationMutation,
} from "../../../redux/graphqlApi";

interface NotificationSettings {
  all: boolean;
  cooking: boolean;
  no_reply: boolean;
}

const Account: React.FC = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [toggleNotifications, { data }] = useUpdateNotificationMutation();

  const { data: user, isLoading } = useCurrentUserInfoQuery({
    user_id: currentUser.user_id,
  });

  const theme = useThemeMui();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const push_token = useSelector(selectNotificationToken);
  const send_permission = useSelector(selectSendNotifications);

  // const modal = useRef<HTMLIonModalElement>(null);
  // const [version, setVersion] = useState<{
  //   build_version: string | number;
  //   version_number: string;
  //   package_name: string;
  //   app_name: string;
  // }>({
  //   build_version: -1,
  //   version_number: "-1",
  //   package_name: "",
  //   app_name: "",
  // });

  const { currentTheme, switchTheme } = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>({
      all: send_permission,
      cooking: true,
      no_reply: true,
    });

  const getVersion = async () => {
    // try {
    // const build_version = await AppVersion.getVersionCode();
    // const version_number = await AppVersion.getVersionNumber();
    // const package_name = await AppVersion.getPackageName();
    // const app_name = await AppVersion.getAppName();
    // setVersion({
    //   ...version,
    //   // build_version,
    // });
    // } catch (e) {
    //   console.error(e);
    // }
    // setVersion({
    //   // app_name,
    //   version_code,
    //   // version_number,
    //   // package_name,
    // });
  };

  useEffect(() => {
    getVersion();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <Container>
      <Card>
        <CardContent>
          <Grid
            container
            textAlign="center"
            justifyContent={"center"}
            justifyItems="center"
            justifySelf="center"
          >
            <Avatar
              variant="circular"
              onClick={() => {
                console.info(user);
              }}
              alt={"profile " + user?.name}
              src={user?.profile_image}
            />

            <Grid xs={12} item>
              <Typography>{user?.name}</Typography>
            </Grid>
          </Grid>

          <List>
            <Divider />
            {/* {JSON.stringify(user)} */}

            {/* </ListItem> */}
            {/* <ListItemCustom title="Gebruikersnaam">
              <TextField size="small" value={user.name} />
            </ListItemCustom> */}
            {/* <ListItemCustom title="Telefoon nummer">
              <TextField size="small" value={"PHONE_NUMBER"} />
            </ListItemCustom>

            <ListItemCustom title="Allergiën">
              <TextField size="small" value={"LIST_WITH_ALLERGIES"} />
            </ListItemCustom>

            <ListItemCustom title="Rekening nummer">
              <TextField size="small" value={"IBAN"} />
            </ListItemCustom> */}
            {/* <ListItemCustom title="Meest favoriete gerechten"></ListItemCustom>
            <ListItemCustom title="Minst favoriete gerechten"></ListItemCustom>
            <ListItemCustom title="Werk-/sport-/studietijden"></ListItemCustom>
            <ListItemCustom title="Email"></ListItemCustom> */}
            <ListItem title="">
              <ListItemIcon>
                <Brightness4Icon
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    padding: 7,
                    fontSize: "1rem",
                    borderRadius: 5, //,theme.shape.borderRadius,
                    color: theme.palette.primary.contrastText,
                  }}
                />
              </ListItemIcon>
              <ListItemText>Darkmode</ListItemText>
              <Switch
                checked={currentTheme === ThemeType.DARK}
                onChange={switchTheme}
              />
            </ListItem>
            <Divider />
            <ListItemButton
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
                  "_blank"
                );
              }}
            >
              <ListItemIcon>
                <ChatBubbleOutlineIcon
                  style={{
                    backgroundColor: theme.palette.success.main,
                    padding: 7,
                    fontSize: "1rem",
                    borderRadius: 5, //,theme.shape.borderRadius,
                    color: theme.palette.primary.contrastText,
                  }}
                />
              </ListItemIcon>

              <ListItemText>Geef feedback</ListItemText>
              <ListItemIcon
                style={{
                  direction: "rtl",
                }}
              >
                <ArrowForwardIosRounded
                  style={{
                    fontSize: "1rem",
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
            <Divider />

            {user?.origin !== "eetlijst.nl" && (
              <>
                <ListItemButton LinkComponent={Link} href="/account/remove">
                  <ListItemIcon>
                    <AutoDeleteIcon
                      style={{
                        backgroundColor: theme.palette.error.main,
                        padding: 7,
                        fontSize: "1rem",
                        borderRadius: 5, //,theme.shape.borderRadius,
                        color: theme.palette.primary.contrastText,
                      }}
                    />
                  </ListItemIcon>

                  <ListItemText>Verwijder account</ListItemText>
                  <ListItemIcon
                    style={{
                      direction: "rtl",
                    }}
                  >
                    <ArrowForwardIosRounded
                      style={{
                        fontSize: "1rem",
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
                <Divider />
              </>
            )}
            {Capacitor.isNativePlatform() && (
              <>
                <ListItem>
                  <Typography>Notificaties</Typography>
                  <Box textAlign="end" sx={{ width: "100%" }}>
                    <Switch
                      checked={notificationSettings.all}
                      onChange={() => {
                        if (!notificationSettings.all && !push_token) {
                          dispatch(ASK_TO_REGISTRATE_NOTIFICATION());
                        }

                        toggleNotifications({
                          user_id: currentUser.user_id,
                          wants_to_recieve: !notificationSettings.all,
                        });

                        setNotificationSettings({
                          all: !notificationSettings.all,
                          cooking: notificationSettings.cooking,
                          no_reply: notificationSettings.no_reply,
                        });
                      }}
                    />
                  </Box>
                </ListItem>
                <Divider />
                <ListItem>
                  <Typography>Versie</Typography>
                  {/* <Box textAlign="end" sx={{ width: "100%" }}>
                    Build: {version.build_version}
                  </Box> */}
                </ListItem>
              </>
            )}
          </List>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} textAlign="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  dispatch(resetStore());
                  navigate("../");
                }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* <Grid item xs={12} md={6}> */}
      {/* {Capacitor.isNativePlatform() ? (
            <Card>
              <CardContent>
                <Title>Notificaties</Title>
                <List>
                  <ListItem>
                    <Grid item xs={9}>
                      <ListItemText>Notificaties toestaan</ListItemText>
                    </Grid>
                    <Grid item xs={3} textAlign="end">
                      <Switch
                        checked={notificationSettings.all}
                        onChange={() => {
                          if (!notificationSettings.all && !push_token) {
                            dispatch(ASK_TO_REGISTRATE_NOTIFICATION());
                          }

                          toggleNotifications({
                            user_id: user.user_id,
                            wants_to_recieve: !notificationSettings.all,
                          });

                          setNotificationSettings({
                            all: !notificationSettings.all,
                            cooking: notificationSettings.cooking,
                            no_reply: notificationSettings.no_reply,
                          });
                        }}
                      />
                    </Grid>
                  </ListItem>
                  {/* <ListItem>
                  <Grid item xs={9}>
                    <ListItemText>
                      Herinnering bij een vergeten bonnetje.
                    </ListItemText>
                  </Grid>
                  <Grid item xs={3} textAlign="end">
                    <Switch
                      checked={notificationSettings.cooking}
                      disabled={!notificationSettings.all}
                      onChange={() => {
                        setNotificationSettings({
                          all: notificationSettings.all,
                          cooking: !notificationSettings.cooking,
                          no_reply: notificationSettings.no_reply,
                        });
                      }}
                    />
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid item xs={9}>
                    <ListItemText>
                      Herinnering als je je niet hebt opgegeven.
                    </ListItemText>
                  </Grid>
                  <Grid item xs={3} textAlign="end">
                    <Switch
                      checked={notificationSettings.no_reply}
                      disabled={!notificationSettings.all}
                      onChange={() => {
                        setNotificationSettings({
                          all: notificationSettings.all,
                          cooking: notificationSettings.cooking,
                          no_reply: !notificationSettings.no_reply,
                        });
                      }}
                    />
                  </Grid>
                </ListItem> */}
      {/* </List>

                
              </CardContent>
            </Card>
          ) : null}
        </Grid> */}
    </Container>
    //   </IonContent>
    // </IonPage>
  );
};

function Switch({ name, ...rest }: any) {
  return (
    <>
      <Grid item xs={12}>
        <FormLabel>{name}</FormLabel>
      </Grid>
      <SwitchMui {...rest} />
    </>
  );
}

function ListItemCustom({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) {
  return (
    <>
      <ListItem>
        <Grid xs={6} sm={8}>
          <ListItemText>{title}</ListItemText>
        </Grid>
        <Grid xs={6} sm={4}>
          <Box textAlign="end" sx={{ width: "100%" }}>
            {children}
          </Box>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
}

export default Account;
