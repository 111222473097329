function Spacing({
  variant = "small",
  margin,
}: {
  variant?: "small" | "medium" | "large";
  margin?: number;
}) {
  if (!margin) {
    switch (variant) {
      case "small": {
        margin = 10;
        break;
      }
      case "medium": {
        margin = 50;
        break;
      }
      case "large": {
        margin = 100;
        break;
      }
      default:
        margin = 10;
    }
  }

  return <div style={{ marginTop: margin }} />;
}

export default Spacing;
