import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import EetlijstLogo from "../assets/img/eetlijst.png";
import EetlijstLogoDarkmode from "../assets/img/eetlijst-darkmode.png";
import { useTheme } from "@mui/material";

function Logo({ height, className, ...rest }: any) {
  const theme = useTheme();

  const [hostEetlijst, setHostEetlijst] = useState(true); // = false

  useEffect(() => {
    const hostname = window.location.hostname;

    // if (hostname == "eetlijst") {
    //   setHostEetlijst(true);
    // }
  }, []);

  if (!height) {
    height = 39;
  }

  return (
    <div
      style={{
        height,
      }}
      {...rest}
    >
      <Grid item>
        {hostEetlijst ? (
          <img
            alt="Eetlijst logo"
            {...rest}
            style={{ height }}
            src={
              theme.palette.mode === "dark"
                ? EetlijstLogoDarkmode
                : EetlijstLogo
            }
          />
        ) : (
          <svg
            {...rest}
            style={{
              color: theme.palette.primary.main,
              fill: theme.palette.primary.main,
            }}
            height={height}
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            // width="614.000000pt"
            // height="134.000000pt"
            viewBox="0 0 614.000000 134.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              // className={classes.svg}
              transform="translate(0.000000,134.000000) scale(0.100000,-0.100000)"
              // fill="green"
              stroke="none"
            >
              <path
                d="M283 1255 c-75 -21 -122 -47 -170 -97 -94 -97 -113 -249 -48 -379 38
-74 108 -128 267 -206 79 -39 159 -84 177 -99 84 -70 81 -191 -5 -262 -83 -68
-220 -66 -367 7 -46 23 -90 41 -98 41 -28 0 -39 -24 -39 -83 0 -55 3 -62 34
-92 107 -101 421 -116 565 -28 212 131 230 432 34 576 -27 19 -110 65 -185
102 -163 79 -213 122 -224 191 -8 55 -1 85 31 123 35 41 83 54 175 49 64 -4
89 -11 149 -41 40 -20 79 -37 86 -37 22 0 35 34 35 93 0 43 -4 58 -22 74 -76
69 -268 102 -395 68z"
              />
              <path
                d="M1144 936 c-72 -17 -164 -58 -188 -85 -21 -23 -24 -104 -6 -126 19
-22 41 -18 126 22 64 31 91 37 159 41 140 7 185 -29 193 -155 l5 -73 -99 0
c-149 0 -272 -33 -345 -92 -120 -96 -120 -312 -1 -406 57 -46 112 -62 207 -62
93 0 144 15 210 60 l45 31 0 -25 c0 -40 25 -56 90 -56 54 0 58 2 70 29 18 45
8 648 -13 718 -21 74 -59 118 -132 154 -55 27 -76 31 -162 35 -62 2 -120 -1
-159 -10z m286 -600 l0 -84 -42 -34 c-64 -52 -101 -68 -160 -68 -88 0 -141 44
-141 117 0 58 10 78 51 106 48 34 97 45 205 46 l87 1 0 -84z"
              />
              <path
                d="M2209 936 c-47 -18 -109 -58 -133 -85 -28 -30 -36 -26 -36 18 0 49
-26 71 -85 71 -22 0 -51 -7 -63 -16 l-22 -15 0 -439 c0 -503 -9 -460 99 -460
94 0 91 -10 91 335 l0 291 37 44 c113 131 244 135 299 8 17 -38 19 -78 22
-348 2 -194 7 -309 14 -317 14 -17 140 -17 156 -1 9 9 12 92 12 311 l0 298 52
55 c69 73 108 96 165 96 53 0 88 -22 118 -73 19 -32 20 -55 23 -349 4 -367 -1
-350 97 -350 103 0 97 -25 93 360 -3 324 -4 337 -27 395 -49 125 -160 193
-297 182 -72 -5 -136 -37 -214 -104 -41 -37 -54 -43 -61 -32 -5 8 -9 17 -9 21
0 14 -64 70 -103 89 -52 27 -176 35 -228 15z"
              />
              <path
                d="M3655 936 c-147 -35 -246 -142 -291 -311 -19 -70 -18 -253 2 -328 39
-153 128 -246 269 -283 121 -33 371 -7 446 46 25 19 29 28 29 70 0 90 -11 95
-123 55 -85 -31 -193 -41 -268 -26 -97 20 -163 96 -176 203 -4 37 -2 52 8 59
8 5 136 9 286 9 295 0 295 0 309 57 10 40 -2 153 -25 223 -25 79 -92 160 -160
194 -88 45 -205 57 -306 32z m205 -161 c57 -30 100 -105 100 -177 l0 -28 -210
0 -210 0 0 23 c0 66 87 179 148 193 15 3 32 7 37 10 19 7 108 -7 135 -21z"
              />
              <path
                d="M4694 932 c-28 -10 -74 -35 -102 -55 -29 -20 -54 -37 -56 -37 -2 0
-6 18 -10 40 -4 26 -13 43 -28 51 -25 14 -114 7 -131 -10 -9 -9 -13 -125 -15
-438 -3 -504 -10 -473 98 -473 58 0 71 3 80 19 6 12 10 130 10 313 l0 294 58
56 c70 69 107 88 169 88 94 0 139 -54 154 -184 4 -39 8 -181 9 -316 0 -178 3
-249 12 -258 18 -18 142 -16 157 2 9 11 12 100 9 348 -3 315 -5 336 -26 388
-47 118 -132 178 -264 186 -55 4 -86 0 -124 -14z"
              />
              <path
                d="M5725 941 c-45 -12 -110 -47 -148 -81 -39 -34 -47 -31 -47 16 0 46
-35 68 -95 62 -80 -9 -75 21 -75 -464 0 -380 2 -434 16 -448 22 -22 136 -22
158 0 14 13 16 56 16 311 l0 295 30 38 c83 105 209 143 280 84 64 -54 65 -57
70 -407 4 -268 7 -319 20 -327 8 -5 43 -10 77 -10 97 0 93 -15 93 313 0 296
-8 380 -42 459 -23 54 -92 122 -145 145 -43 17 -164 26 -208 14z"
              />
            </g>
          </svg>
        )}
      </Grid>

      {/* <img src={logo.default} height={height} alt={alt} /> */}
    </div>
  );
}

export default Logo;
