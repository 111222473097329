export default function FinishedIcon(props: any) {
  const color = "#006C4C";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      data-name="Layer 1"
      viewBox="0 0 574.903 685.123"
      {...props}
    >
      <path
        fill="#e6e6e6"
        d="M222.665 562.323c-11.44-11.797-28.016-18.563-45.48-18.563l-.675.002a64.944 64.944 0 01-32.739-8.842l-1.034-.602.833-.858a63.301 63.301 0 0011.163-15.805 19.141 19.141 0 01-15.448 4.99 19.6 19.6 0 01-14.388-9.216c-6.104-9.904-13.38-23.957-13.38-34.662a65.142 65.142 0 0121.252-48.07 40.565 40.565 0 0013.4-30.246l-.003-.81a92.001 92.001 0 012.73-22.264l.185-.735.756.027a62.195 62.195 0 0014.543-1.219 49.089 49.089 0 01-13.042-3.469l-.814-.329.276-.834c19.04-11.789 62.02-5.684 86.966-62.76 20.229-46.281 91.6 41.083 91.6 91.582 0 5.153-5.099 11.962-1.281 15.368 41.267 36.817 12.793 60.458 3.816 84.247-2.13 5.644 2.488 11.84 2.488 17.946 0 1.036-.026 2.106-.078 3.18l-.08 1.65-1.376-.916a45.575 45.575 0 01-7.526-6.174 39.718 39.718 0 01-1.26 34.29c-4.324 8.162-8.508 14.005-12.435 17.367a64.93 64.93 0 01-88.969-4.275z"
      ></path>
      <path
        fill="#3f3d56"
        d="M291.755 685.123a1.169 1.169 0 001.156-1.004c.05-.349 4.914-35.429.545-82.052-4.034-43.058-16.99-105.076-55.8-156.01a1.169 1.169 0 10-1.86 1.416c38.476 50.495 51.327 112.058 55.333 154.812 4.343 46.352-.483 81.159-.532 81.505a1.17 1.17 0 001.158 1.333z"
      ></path>
      <path
        fill="#3f3d56"
        d="M260.084 517.902a1.169 1.169 0 00.688-2.115 161.099 161.099 0 00-35.565-18.104c-19.622-7.184-49.46-13.696-80.177-3.92a1.169 1.169 0 10.709 2.228c30.088-9.576 59.385-3.171 78.664 3.887a158.452 158.452 0 0134.994 17.8 1.164 1.164 0 00.687.224zM313.58 384.662a1.169 1.169 0 00-2.06-.839 161.099 161.099 0 00-20.618 34.17c-8.578 19.053-17.222 48.344-9.683 79.686a1.169 1.169 0 102.272-.547c-7.383-30.7 1.115-59.46 9.543-78.18a158.452 158.452 0 0120.274-33.622 1.164 1.164 0 00.272-.668z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M539.035 683.886h-93.05l-.399-.726a101.396 101.396 0 01-3.412-6.793c-9.584-20.52-13.635-43.988-17.21-64.693l-2.691-15.607a9.636 9.636 0 0115.169-9.428q21.211 15.435 42.439 30.84c5.358 3.899 11.478 8.411 17.339 13.288.565-2.747 1.157-5.501 1.748-8.217a9.643 9.643 0 0117.61-3.045l10.887 17.49c7.94 12.774 14.948 25.361 13.52 38.937a2.122 2.122 0 01-.036.493 30.693 30.693 0 01-1.58 6.535z"
      ></path>
      <path
        fill="#a0616a"
        d="M495.684 318.075l-17.958-66.467a11.113 11.113 0 00-13.48-7.834 11.113 11.113 0 00-7.843 14.092l20.77 68.471 10.868 42.138a9.698 9.698 0 1012.433.554zM344.574 303.76l54.562-41.991a11.113 11.113 0 002.09-15.451 11.113 11.113 0 00-16.02-1.863l-55.34 45.356-34.788 26.142a9.698 9.698 0 104.238 11.703zM389.044 313.413l-2.405 53.714 75.516-16.412c-6.427-21.584-10.798-25.265-5.968-40.286z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M435.477 214.94h-25.162L399.51 233.43l-5.149-.284a18.55 18.55 0 00-19.572 18.522l6.344 9.833s-11.346 19.773 4.997 29.5c0 0-2.915 18.329 0 18.132s9.63-3.927 2.915-.197-6.213 4.23-5.344 5.1l.868.868 70.601 11.855s2.065-13.687 2.288-16.128-1.27 3.527.222-2.441 3.73-3.73 1.492-5.969 14.502-29.965 7.624-40.72l11.027-10.756c0-10.316-16.57-18.68-26.887-18.68h-1.321z"
      ></path>
      <path
        fill="#a0616a"
        d="M374.852 663.178L364.667 663.178 359.822 623.895 374.853 623.896 374.852 663.178z"
      ></path>
      <path
        fill="#2f2e41"
        d="M344.113 678.205a4.332 4.332 0 004.304 4.313h19.172l.594-1.231 2.711-5.596 1.05 5.596.233 1.23h7.231l-.103-1.239-1.928-23.123-2.523-.155-10.898-.646-2.678-.163v6.904c-2.031 2.16-17.975 10.33-17.165 14.11z"
      ></path>
      <path
        fill="#a0616a"
        d="M526.21 636.502L517.645 642.012 492.317 611.596 504.959 603.465 526.21 636.502z"
      ></path>
      <path
        fill="#2f2e41"
        d="M508.488 665.77a4.332 4.332 0 005.954 1.299l16.123-10.373-.166-1.356-.747-6.174 3.91 4.138.862.91 6.082-3.913-.758-.986-14.132-18.404-2.205 1.235-9.515 5.353-2.34 1.311 3.735 5.807c-.54 2.916-9.528 18.413-6.803 21.153z"
      ></path>
      <path
        fill="#cacaca"
        d="M573.719 684.604l-315.358.307a1.19 1.19 0 010-2.381l315.358-.308a1.19 1.19 0 010 2.382z"
      ></path>
      <path
        fill="#2f2e41"
        d="M460.972 344.647s-48.271 25.533-73.803 8.113c0 0-5.585 27.05-7.823 29.288s-5.804 3.213-3.275 5.71 3.937 5.868-.87 10.15-9.6 2.924-4.966 7.333-14.016 112.584-14.016 112.584 2.984 100.598 0 103.64-6.973 3.724-3.113 7.487 3.86.844 3.86 6.033v5.19l1.765 5.157h24.223s5.845-6.79 0-12.688 1.779-7.512 3.562-13.046 4.767.434 1.782-5.535-4.417-4.832-4.446-8.757 43.986-157.607 43.986-157.607l5.331 85.06 64.125 93.157 18.875-14.156s-13.742-19.345-10.529-22.005-9.47-5.995-5.47-8.995-5.63-6.023-2-10-5-14-10-14-12.018-16.709-12.018-16.709l10.475-131.992zM409.81 160.645c-.342.449-3.064.207-6.337-.27-6.47-.945-7.353.177-12.19 4.578-7.008 6.374-3.024 45.253-6.467 53.677l-1.113.82a40.018 40.018 0 006.479-1.814c1.189-3.751 2.381-7.515 3.581-11.263a32.988 32.988 0 012.116 9.096c1.666-.656 3.322-2.262 10.282-4.416 3.505-2.378 28.862-4.768 32.121-5.815 8.274-28.148-5.059-39.532-28.473-44.593z"
      ></path>
      <circle cx="418.37" cy="179.82" r="24.231" fill="#a0616a"></circle>
      <path
        fill="#2f2e41"
        d="M433.626 158.51a10.824 10.824 0 01-3.873-1.358 3.682 3.682 0 01-1.763-3.484c.299-1.722 2.245-2.75 3.99-2.645a10.85 10.85 0 014.808 1.946 7.453 7.453 0 00-6.349-6.68c2.295-.123 4.68-.232 6.8.65s3.89 3.067 3.507 5.333c3.566-.46 7.083 2.025 8.556 5.306s1.18 7.133.004 10.531a3.72 3.72 0 01-1.864 2.464c-1.2.456-2.492-.308-3.532-1.061a84.448 84.448 0 01-14.73-13.603"
      ></path>
      <path
        fill="#2f2e41"
        d="M444.191 205.582c-1.027.483-.492 2.282-1.29 3.088a3.281 3.281 0 01-1.224.761 14.726 14.726 0 00-1.037-4.372c-.104-.253-.276-.537-.545-.514-.313.037-.395.447-.403.753a12.64 12.64 0 01-.992 4.41l-.022.051-.694-.022c-1.73-.067-5.565-4.513-6.416-3.006 3-5.32 6.655-13.317 7.214-19.397a8.603 8.603 0 00-.276-3.805 3.362 3.362 0 00-2.797-2.313 2.14 2.14 0 00-2.216 2.447l-.433-.06c-4.013-3.267-.552-8.944-5.222-11.19-1.581-.76-11.265 1.783-12.973 1.336a14.705 14.705 0 00-1.082-5.245c-.105-.254-.276-.537-.545-.515-.313.038-.395.448-.403.754a12.641 12.641 0 01-.992 4.409 18.479 18.479 0 00-7.095.373c-2.044.537-3.983 1.462-6.035 1.954a7.803 7.803 0 01-6.132-.671c-2.604-1.746-3.06-5.61-1.761-8.46a16.662 16.662 0 013.745-4.834c1.171-1.134 2.387-2.224 3.603-3.313l3.268-2.932a39.838 39.838 0 014.372-3.566 21.57 21.57 0 0116.838-2.94c3.431.822 8.02-.335 11.16 1.27 1.642.842 5.275 4.073 6.715 5.221 3.685 2.932 1.191.543 5.222 2.239 9.611 4.042 5.361 44.834-1.552 48.089z"
      ></path>
      <circle cx="175.897" cy="232.17" r="150.273" fill="#fff"></circle>
      <path
        fill={color}
        d="M175.897 383.942c-83.688 0-151.773-68.085-151.773-151.772S92.21 80.397 175.897 80.397s151.772 68.085 151.772 151.773-68.085 151.772-151.772 151.772zm0-300.545c-82.034 0-148.773 66.74-148.773 148.773s66.74 148.772 148.773 148.772 148.772-66.739 148.772-148.772S257.93 83.397 175.897 83.397z"
      ></path>
      <path
        fill={color}
        d="M155.396 310.38a16.71 16.71 0 01-13.37-6.688l-40.998-54.663a16.715 16.715 0 1126.746-20.057l26.821 35.759 68.887-103.33a16.716 16.716 0 0127.818 18.545l-81.994 122.99a16.723 16.723 0 01-13.444 7.439c-.156.002-.31.005-.466.005z"
      ></path>
      <ellipse
        cx="635.792"
        cy="197.637"
        fill="#e6e6e6"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 417.417 324.575)"
      ></ellipse>
      <ellipse
        cx="684.963"
        cy="192.839"
        fill="#3f3d56"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 466.588 319.777)"
      ></ellipse>
      <ellipse
        cx="622.327"
        cy="157.829"
        fill="#ff6584"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 403.952 284.767)"
      ></ellipse>
      <ellipse
        cx="605.116"
        cy="142.586"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 394.715 246.327)"
      ></ellipse>
      <ellipse
        cx="597.871"
        cy="112.214"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 387.47 215.955)"
      ></ellipse>
      <ellipse
        cx="654.216"
        cy="165.666"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 443.815 269.407)"
      ></ellipse>
      <ellipse
        cx="630.454"
        cy="177.53"
        fill="#ff6584"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 420.053 281.271)"
      ></ellipse>
      <ellipse
        cx="663.531"
        cy="199.458"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 453.13 303.199)"
      ></ellipse>
      <ellipse
        cx="353.792"
        cy="553.637"
        fill="#e6e6e6"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 135.417 680.575)"
      ></ellipse>
      <ellipse
        cx="402.963"
        cy="548.839"
        fill="#3f3d56"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 184.588 675.777)"
      ></ellipse>
      <ellipse
        cx="340.327"
        cy="513.829"
        fill="#ff6584"
        rx="5.257"
        ry="7.557"
        transform="rotate(-81.722 121.952 640.767)"
      ></ellipse>
      <ellipse
        cx="323.116"
        cy="498.586"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 112.715 602.327)"
      ></ellipse>
      <ellipse
        cx="315.871"
        cy="468.214"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 105.47 571.955)"
      ></ellipse>
      <ellipse
        cx="372.216"
        cy="521.666"
        fill="#e6e6e6"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 161.815 625.407)"
      ></ellipse>
      <ellipse
        cx="348.454"
        cy="533.53"
        fill="#ff6584"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 138.053 637.271)"
      ></ellipse>
      <ellipse
        cx="381.531"
        cy="555.458"
        fill="#3f3d56"
        rx="4.776"
        ry="3.322"
        transform="rotate(-89.567 171.13 659.199)"
      ></ellipse>
    </svg>
  );
}
