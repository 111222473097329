function BugIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      data-name="Layer 1"
      viewBox="0 0 690 448.759"
      {...props}
    >
      <path
        fill="#f2f2f2"
        d="M211.813 420.11c4.668-10.083 9.333-20.316 11.36-31.241s1.175-22.783-4.628-32.26-17.228-15.902-28.131-13.76c-8.955 1.76-16.146 8.813-20.357 16.91s-5.9 17.23-7.537 26.209c-.53-10.2-1.09-20.565-4.478-30.2s-10.06-18.615-19.57-22.343-21.878-.797-26.89 8.103c-7.06 12.538 2.302 30.125-5.817 42.006-1.393-11.917-13.855-21.333-25.7-19.416s-20.7 14.781-18.264 26.53c1.45 6.99 6.22 12.936 11.922 17.231s12.334 9.275 18.895 12.09z"
      ></path>
      <path
        fill="#fff"
        d="M68.02 372.537c9.408 3.289 18.903 6.615 27.492 11.756 7.698 4.608 14.553 10.812 18.882 18.757a33.356 33.356 0 014.126 13.858c.063 1.018 1.655 1.025 1.592 0-.557-9.06-4.972-17.353-11.087-23.91-6.704-7.19-15.392-12.1-24.361-15.91-5.318-2.26-10.769-4.18-16.221-6.085-.969-.339-1.385 1.198-.423 1.534zM122.287 335.004a143.383 143.383 0 0113.792 30.616 145.117 145.117 0 016.36 32.968 143.305 143.305 0 01.152 18.837c-.059 1.024 1.533 1.02 1.592 0a145.194 145.194 0 00-2.04-33.82 146.926 146.926 0 00-9.77-32.44 143.25 143.25 0 00-8.711-16.964.796.796 0 00-1.375.803zM200.188 344.147a232.044 232.044 0 00-17.116 57.578q-1.343 8.365-2.078 16.812c-.09 1.02 1.503 1.015 1.591 0a231.21 231.21 0 0112.738-58.025q2.834-7.892 6.24-15.562c.411-.928-.96-1.738-1.375-.803z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M709.176 639.478H719.058V686.0659999999999H709.176z"
        transform="rotate(89.259 700.897 420.802)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M499.027 443.996l-6.653-.02-4.112-7.337 4.445-7.312 6.18.018a11.999 11.999 0 10.14 14.65zM419.208 443.996l6.653-.02 4.112-7.337-4.445-7.312-6.18.018a11.999 11.999 0 11-.14 14.65z"
      ></path>
      <path
        fill="#3f3d56"
        d="M689 420.97H1a1 1 0 010-2h688a1 1 0 010 2z"
      ></path>
      <circle cx="540.486" cy="197.969" r="33" fill="#2f2e41"></circle>
      <path
        fill="#ffb8b8"
        d="M553.859 383.226L556.401 395.219 603.871 391.124 600.12 373.423 553.859 383.226z"
      ></path>
      <path
        fill="#2f2e41"
        d="M550.166 420.71l-7.988-37.694 14.563-3.086 4.902 23.13a14.887 14.887 0 01-11.477 17.65z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M524.12 382.586L518.777 393.621 473.673 378.264 481.559 361.979 524.12 382.586z"
      ></path>
      <path
        fill="#2f2e41"
        d="M511.808 399.976l10.304-21.28 13.399 6.487-16.791 34.68a14.887 14.887 0 01-6.912-19.887z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M435.26 360.793a10.743 10.743 0 0012.708-10.48l74.374-68.571-18.47-14.306-67.337 71.944a10.8 10.8 0 00-1.276 21.413z"
      ></path>
      <circle cx="536.344" cy="207.898" r="24.561" fill="#ffb8b8"></circle>
      <path
        fill="#6c63ff"
        d="M558.386 352.273c-17.228 0-37.978-3.629-50.778-18.477l-.289-.335.297-.328c.097-.107 9.514-10.868.11-30.06L494.8 307.05l-12.87-16.989 7.13-21.39 29.177-23.503a26.75 26.75 0 0114.61-5.79 80.21 80.21 0 0027.785-7.912 27.906 27.906 0 0112.8-2.799l.574.017a9.95 9.95 0 019.64 10.707c-1.978 25.63-5.472 87.544 4.792 108.861l.265.551-.592.15a136.113 136.113 0 01-29.724 3.32z"
      ></path>
      <path
        fill="#2f2e41"
        d="M510.986 333.47s-65-6-72 13 1 28 13 32 41 9 41 9l13-16 34 2s37.885 21.972 48.36 45.473a30.762 30.762 0 0025.777 18.239c8.398.584 15.863-2.588 15.863-15.713 0-30-42-73-42-73zM461.486 362.97s17-5 44 8M511.843 192.235a73.041 73.041 0 0031.599 10.412l-3.33-3.991a24.477 24.477 0 007.56 1.501 8.28 8.28 0 006.75-3.159 7.702 7.702 0 00.515-7.115 14.589 14.589 0 00-4.589-5.738 27.323 27.323 0 00-25.43-4.545 16.33 16.33 0 00-7.596 4.872 9.236 9.236 0 00-1.863 8.56M540.31 172.604a75.485 75.485 0 0119.136-26.522c5.292-4.702 11.472-8.743 18.446-9.962s14.833.87 19.11 6.51c3.499 4.614 4.153 10.794 3.768 16.57s-1.677 11.496-1.553 17.284a35.468 35.468 0 0050.527 31.351c-6.022 3.329-10.714 8.598-16.305 12.608s-12.963 6.76-19.312 4.11c-6.718-2.804-9.8-10.42-12.206-17.29l-10.732-30.64c-1.824-5.208-3.739-10.57-7.462-14.645s-9.765-6.556-14.89-4.508c-3.883 1.552-6.412 5.257-8.63 8.804s-4.557 7.32-8.303 9.18-9.299.714-10.523-3.286"
      ></path>
      <path
        fill="#e6e6e6"
        d="M802.919 543.314H812.8009999999999V589.9019999999999H802.919z"
        transform="rotate(-45.741 412.919 756.065)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M566.51 354.289a9.882 9.882 0 1013.975-.18 9.882 9.882 0 00-13.975.18zm10.111 9.853a4.235 4.235 0 11-.077-5.99 4.235 4.235 0 01.077 5.99zM529.48 307.928l4.69 4.718-2.28 8.096-8.314 2.028-4.358-4.384a11.999 11.999 0 1010.262-10.458z"
      ></path>
      <path
        fill="#ffb8b8"
        d="M548.736 352.359a11.579 11.579 0 01-1.268-.07 11.023 11.023 0 01-9.68-9.682 11.003 11.003 0 0110.93-12.25 10.625 10.625 0 011.467.11l33.578-35.745-3.505-11.685 17.605-7.892 4.934 12.06a20.49 20.49 0 01-6.177 23.673l-36.961 29.4a10.259 10.259 0 01.06 1.08 11.018 11.018 0 01-3.664 8.196 10.872 10.872 0 01-7.319 2.805z"
      ></path>
      <path
        fill="#6c63ff"
        d="M574.986 233.47a9.453 9.453 0 0112.628 5.155l17.372 43.844-27 18z"
      ></path>
      <path
        fill="#3f3d56"
        d="M448.486 243.707V25.262A25.298 25.298 0 00423.224 0H79.954a25.298 25.298 0 00-25.262 25.262v218.445a25.298 25.298 0 0025.262 25.262h343.27a25.298 25.298 0 0025.262-25.262zm-368.532 22.29a22.317 22.317 0 01-22.29-22.29V25.262a22.317 22.317 0 0122.29-22.29h343.27a22.317 22.317 0 0122.29 22.29v218.445a22.317 22.317 0 01-22.29 22.29z"
      ></path>
      <path
        fill="#3f3d56"
        d="M445.514 39.303H57.664a1.486 1.486 0 110-2.972h387.85a1.486 1.486 0 010 2.972z"
      ></path>
      <circle cx="82.926" cy="20.804" r="7.43" fill="#6c63ff"></circle>
      <circle cx="108.188" cy="20.804" r="7.43" fill="#6c63ff"></circle>
      <circle cx="133.451" cy="20.804" r="7.43" fill="#6c63ff"></circle>
      <path d="M188.979 199.232a1 1 0 01-1-1c0-10.427-4.571-16.243-11.946-22.247a1 1 0 111.263-1.551c7.83 6.375 12.683 12.58 12.683 23.798a1 1 0 01-1 1zM103.317 199.232a1 1 0 01-1-1c0-11.218 4.852-17.423 12.682-23.798a1 1 0 111.263 1.55c-7.375 6.005-11.945 11.822-11.945 22.248a1 1 0 01-1 1zM180.413 130.701a1 1 0 01-.632-1.775c7.581-6.171 8.198-14.938 8.198-24.923a1 1 0 012 0c0 10.505-.673 19.747-8.935 26.474a.994.994 0 01-.63.224zM111.882 130.166a.994.994 0 01-.63-.224c-8.263-6.727-8.935-15.727-8.935-25.94a1 1 0 012 0c0 10.037.603 18.206 8.197 24.389a1 1 0 01-.632 1.775zM201.828 152.152h-21.415a1 1 0 010-2h21.415a1 1 0 110 2zM111.883 152.152H90.467a1 1 0 010-2h21.416a1 1 0 010 2zM146.148 194.948a1 1 0 01-1-1v-68.53a1 1 0 012 0v68.53a1 1 0 01-1 1z"></path>
      <path d="M146.148 194.948a35.305 35.305 0 01-35.265-35.265v-25.717c0-16.468 13.513-26.699 35.265-26.699 22.74 0 35.265 9.482 35.265 26.699v25.717a35.305 35.305 0 01-35.265 35.265zm0-85.68c-16.081 0-33.265 6.488-33.265 24.698v25.717a33.265 33.265 0 1066.53 0v-25.717c0-21.49-20.842-24.699-33.265-24.699z"></path>
      <path d="M125.651 113.441a1 1 0 01-.93-.63 14.088 14.088 0 01-.987-4.531 22.476 22.476 0 0122.355-22.41h.115a22.467 22.467 0 0122.36 22.357 12.227 12.227 0 01-.911 4.211 1 1 0 01-1.85-.76 10.195 10.195 0 00.761-3.488 20.454 20.454 0 00-20.363-20.32h-.11a20.461 20.461 0 00-20.359 20.362 12.076 12.076 0 00.847 3.838 1.001 1.001 0 01-.928 1.371z"></path>
      <path
        fill="#ccc"
        d="M247.71 108.003h-12a7 7 0 010-14h12a7 7 0 010 14zM329.71 137.503h-12a7 7 0 010-14h12a7 7 0 010 14zM367.71 137.503h-12a7 7 0 010-14h12a7 7 0 010 14zM405.71 137.503h-12a7 7 0 010-14h12a7 7 0 010 14zM330.71 108.503h-56a7 7 0 010-14h56a7 7 0 010 14zM291.71 136.503h-56a7 7 0 010-14h56a7 7 0 010 14zM401.71 108.503h-43a7 7 0 010-14h43a7 7 0 010 14zM247.71 167.003h-12a7 7 0 010-14h12a7 7 0 010 14zM330.71 167.503h-56a7 7 0 010-14h56a7 7 0 010 14zM401.71 167.503h-43a7 7 0 010-14h43a7 7 0 010 14zM382.71 191.003a7.008 7.008 0 017-7h12a7 7 0 010 14h-12a7.008 7.008 0 01-7-7zM299.71 191.503a7.008 7.008 0 017-7h56a7 7 0 010 14h-56a7.008 7.008 0 01-7-7zM228.71 191.503a7.008 7.008 0 017-7h43a7 7 0 010 14h-43a7.008 7.008 0 01-7-7z"
      ></path>
    </svg>
  );
}

export default BugIcon;
