import {
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  ListItemText,
  Divider,
  Container,
} from "@mui/material";
import { Fragment, useState } from "react";
import { Title } from "../../components";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  changeChooseNewBalance,
  selectChooseNewBalance,
  selectUsersEetlijst,
} from "../../redux/auth_eetlijst";
import TextBold from "../../components/Bold";

const TransferBalance = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const choosenNewBalance = useSelector(selectChooseNewBalance);
  const users = useSelector(selectUsersEetlijst) ?? [];

  const toggleDialog = () => {
    setOpen(!open);
  };

  const activeUsers = users.filter((u) => u.active);
  const toMuch = activeUsers.reduce((a, b) => {
    return a + BigInt(b.balance);
  }, BigInt(0));

  let pp = BigInt(0);
  let residue = BigInt(0);

  if (activeUsers.length !== 0) {
    pp = toMuch / BigInt(activeUsers.length);
    residue = toMuch % BigInt(activeUsers.length);
  }

  const usersBalances = users
    .filter((u) => u.active)
    .map((u, i) => {
      if (residue > i) {
        return (u.balance - Number(pp) - Number(residue)) / 100;
      }
      return (u.balance - Number(pp)) / 100;
    });

  if (
    usersBalances?.reduce(
      (a, b) => a + BigInt(Math.floor(Math.round(b * 100))),
      BigInt(0)
    ) === BigInt(0)
  ) {
    return (
      <Typography>Deze check klopt. Ga naar de volgende pagina.</Typography>
    );
  }

  return (
    <Container>
      <Title>
        Balans{" "}
        {usersBalances
          ?.reduce(
            (a, b) => a + BigInt(Math.floor(Math.round(b * 100))),
            BigInt(0)
          )
          .toString()}
      </Title>

      <Typography>
        De oude balans is niet sluitend (som van de kosten is niet 0). Willen
        jullie de nieuwe balans hiervoor corrigeren? Wij raden aan om dat wel te
        doen.
      </Typography>
      <Grid item xs={12}>
        <IconButton onClick={toggleDialog}>
          <HelpOutlineRoundedIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12} spacing={1}>
        {!choosenNewBalance ? (
          <Button
            onClick={() => {
              dispatch(changeChooseNewBalance());
            }}
            variant="contained"
          >
            Corrigeer balans
          </Button>
        ) : (
          <Button
            onClick={() => {
              dispatch(changeChooseNewBalance());
            }}
            variant="contained"
          >
            Behoud toch oude balans
          </Button>
        )}
      </Grid>

      <Grid xs={12} item>
        <List>
          <Divider />
          <ListItem>
            <Grid container>
              <Grid xs={4} item>
                <ListItemText>
                  <Typography>Naam</Typography>
                </ListItemText>
              </Grid>
              <Grid xs={4} item>
                <ListItemText disableTypography>
                  <Typography
                    style={{
                      opacity: `${!choosenNewBalance ? 1 : 0.5}`,
                    }}
                  >
                    Oud
                  </Typography>
                </ListItemText>
              </Grid>
              <Grid xs={4} item>
                <ListItemText>
                  <Typography
                    style={{
                      opacity: `${!choosenNewBalance ? 0.5 : 1}`,
                    }}
                  >
                    Nieuw
                  </Typography>
                </ListItemText>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          {users
            .filter((u) => u.active)
            .map((user, index) => {
              const userBalance = usersBalances[index];
              return (
                <Fragment key={index}>
                  <ListItem key={`user-balance-${index}`}>
                    <Grid container>
                      <Grid xs={4} item>
                        <ListItemText>
                          <Typography>{user.name}</Typography>
                        </ListItemText>
                      </Grid>
                      <Grid xs={4} item>
                        <ListItemText disableTypography>
                          <Typography
                            style={{
                              opacity: `${!choosenNewBalance ? 1 : 0.5}`,
                            }}
                            color={user.balance > 0 ? "success" : "error"}
                          >
                            {Number(user.balance / 100).toFixed(2)}
                            &nbsp;euro
                          </Typography>
                        </ListItemText>
                      </Grid>
                      <Grid xs={4} item>
                        <ListItemText>
                          <Typography
                            style={{
                              opacity: `${!choosenNewBalance ? 0.5 : 1}`,
                            }}
                            color={userBalance > 0 ? "success" : "error"}
                          >
                            {Number(userBalance).toFixed(2)}
                            &nbsp;euro
                          </Typography>
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
        </List>
      </Grid>

      <Dialog open={open} onClose={toggleDialog}>
        <DialogContent>
          <Title>Waarom wijkt het af?</Title>
          <Typography>
            De oude balans was inclusief leden die niet meer actief zijn, maar
            nog wel een positief/negatief balans hadden. Hierdoor is de som van
            de balans niet 0 euro. Hier wordt nu rekening mee gehouden. Wij
            adviseren om de balans <TextBold>wel</TextBold> te corrigeren!
          </Typography>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TransferBalance;
