import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "./store";

export const restApi = createApi({
  reducerPath: "restV1",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_NODE_SERVER}"/api/v1/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).user.token;
      if (token) {
        headers.set("authentication", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginSamenn: builder.query<
      { token: string } | { error: string },
      { email: string; password: string }
    >({
      query: ({ email, password }) => ({
        url: "auth/login/",
        method: "POST",
        body: { email, password },
      }),
    }),
  }),
});

export const { useLazyLoginSamennQuery } = restApi;
