//TODO remove this hook. Move it to RKT Query.
import { Device } from "@capacitor/device";
import { DeviceInfo } from "firebase-functions/lib/providers/analytics";
import { ReactNode, useContext, useState } from "react";
import { useEffect } from "react";
import { Storage } from "@capacitor/storage";
import { AuthContext, AuthState } from "./authContext";
import { parseJwt } from "../../components/Base64";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import firebase from "./../../firebase";
import logger from "../../logger";
import { toast } from "react-toastify";

export function useAuthL(): AuthState {
  const [jwt, setJWT] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState<any | undefined>(undefined);
  const navigate = useNavigate();

  // const messaging = firebase.messaging.isSupported()
  //   ? firebase.messaging()
  //   : null;

  useEffect(() => {
    if (!jwt) {
      retrieveJWT();
    } else {
      const user = parseJwt(jwt);
      setUser(user);
    }

    Device.getInfo()
      .then((info: any) => {
        const deviceInfo: DeviceInfo = info;
      })
      .catch((e: any) => {
        return Promise.reject("Could not login user: " + e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jwt]);

  async function retrieveJWT() {
    // First check in localstorage if id token is there.
    // This is faster, and routing is relying for this to be directly available.
    let jwt = localStorage.getItem("id_token");

    if (!jwt) {
      jwt = await (await Storage.get({ key: "id_token" })).value;
    }

    if (jwt) {
      setJWT(jwt);
      localStorage.setItem("id_token", jwt);
    }
  }

  async function logout() {
    return await Storage.remove({ key: "id_token" }).then(() => {
      localStorage.removeItem("id_token");
      setUser(undefined);
      setJWT(undefined);
      navigate("");
    });
  }

  async function login(email: string, password: string) {
    if (isLoading) {
      return Promise.reject("Cannot login. Application is still loading. ");
    }

    await loginPartizon(email, password);
  }

  async function loginPartizon(email: string, password: string) {
    setLoading(true);
    const variables = {
      input: { email, password },
    };

    await axios
      .post(process.env.REACT_APP_AUTH_URL + "/api/v1/auth/login", variables)
      .then(async (res: any) => {
        const token = res.data.token;

        if (!token) {
          return Promise.reject({
            success: false,
            message: "Token is corrupt.",
          });
        }
        return await Storage.set({ key: "id_token", value: token }).then(() => {
          setJWT(token);
          const user = parseJwt(token);
          setUser(user);

          navigate("/groups");
          // window.location.reload();
        });
      })
      .catch((e: any) => {
        toast.error("Kon niet inloggen " + e.response?.data?.message);
        logger.error(
          `[loginPartizon] kon niet inloggen bij Partizon:${JSON.stringify(e)}`
        );
      });

    setLoading(false);
  }

  async function registerPartizon(
    email: string,
    name: string,
    password: string,
    password_check: string
  ): Promise<{ success?: Boolean; message?: string }> {
    if (!email || !name || !password || !password_check) {
      console.error(email, name, password, password_check);
      const error_message = "Er mist een veld.";
      toast.error(error_message);

      return Promise.reject({ success: false, message: error_message });
    }

    if (password !== password_check) {
      const error_message = "Wachtwoorden komen niet overeen.";
      toast(error_message);

      return Promise.reject({ success: false, message: error_message });
    }

    setLoading(true);

    const variables = {
      input: {
        email,
        name,
        password,
      },
    };

    return await axios
      .post(process.env.REACT_APP_AUTH_URL + "/api/v1/auth/signUp", variables)
      .then((res: any) => {
        if (!res.data.token) {
          return Promise.reject({
            success: false,
            message: "Token is corrupt.",
          });
        }

        Storage.set({ key: "id_token", value: res.data.token });
        // localStorage.setItem("id_token", res.data.token);

        toast("U bent geregistreerd");
        window.location.reload();
        setLoading(false);
        return { success: true, message: "U bent geregistreerd!" };
      })
      .catch((e: any) => {
        const error_message = "Kon niet inloggen " + e.response?.data?.message;
        toast.error(error_message);
        logger.error(`[registerPartizon] ${JSON.stringify(e)}`);
        setLoading(false);
        return Promise.reject({ message: error_message, success: false });
      });
  }

  async function resetPassword(
    user_id: string,
    reset_password_uuid: string,
    password: string
  ): Promise<{ success?: boolean; message?: string }> {
    setLoading(true);
    const variables = {
      input: {
        user_id,
        password,
        reset_password_uuid,
      },
    };

    return await axios
      .post(
        process.env.REACT_APP_AUTH_URL + "/api/v1/auth/reset_password",
        variables
      )
      .then((res: any) => {
        setLoading(false);
        if (res.data.success) {
          return Promise.resolve({ success: true });
        } else {
          return Promise.reject({ success: false, message: "Onbekende error" });
        }
      })
      .catch((e: any) => {
        toast.error(
          "Updaten van het wachtwoord is niet gelukt. " +
            e?.response?.data?.message
        );
        logger.error(`[resetPassword] ${JSON.stringify(e)}`);

        setLoading(false);
        return Promise.reject({
          success: false,
          message: e?.response?.data?.message,
        });
      });
  }

  async function forgotPassword(email: string) {
    setLoading(true);
    const variables = {
      input: {
        email: email,
      },
    };

    return await axios
      .post(
        process.env.REACT_APP_AUTH_URL + "/api/v1/auth/forgot_password",
        variables
      )
      .then((res: any) => {
        if (!res.data?.success) {
          setLoading(false);
          return Promise.reject(res.data);
        } else {
          toast.success(
            "Email is verstuurd waarmee u uw wachtwoord kan resetten."
          );
          setLoading(false);
          return;
        }
      })
      .catch((e: any) => {
        const message = e.response?.data?.message || "onbekende reden.";
        logger.error(`[forgotPassword] ${JSON.stringify(e)}`);
        setLoading(false);
        return Promise.reject(message);
      });
  }

  return {
    error: undefined,
    isAuthenticated: jwt !== undefined,
    isLoading,
    user,
    login,
    logout,
    register: registerPartizon,
    jwt,
    user_id: user?.sub,
    resetPassword,
    forgotPassword,
  };
}

export default function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw Error("You forgot to wrap your component using AuthProvider");
  }

  return context;
}

export function AuthProvider({ children, ...rest }: { children: ReactNode }) {
  const hooks = useAuthL();

  return (
    <AuthContext.Provider value={hooks} {...rest}>
      {children}
    </AuthContext.Provider>
  );
}
