import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { Link, useLocation, useParams } from "react-router-dom";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import { Badge, Fab } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ViewListIcon from "@mui/icons-material/ViewList";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { useGetSharedListQuery } from "../../redux/graphqlApi";

function OutsideGroupBottomNav() {
  // const [activeRoute, setActivateRoute] = useState<string | undefined>();
  const location = useLocation();
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();

  const { data: shared_list } = useGetSharedListQuery({ group_id });

  const path = "/groups/group/" + group_id;

  function createRoute(
    path: string,
    label: string,
    icon: any
  ): { path: string; label: string; icon: any } {
    return {
      path,
      label,
      icon,
    };
  }

  const routes: { path: string; icon: any; label: string }[] = [
    createRoute("/show", "eten", <RestaurantMenuIcon />),
    createRoute(
      "/sharedList",
      "lijst",
      <Badge
        color="error"
        badgeContent={
          shared_list?.filter((i) => {
            return !i.checked && i.active;
          }).length
        }
      >
        <ViewListIcon />
      </Badge>
    ),
    createRoute("/agenda", "Agenda", <DateRangeIcon />),
    // createRoute("/expenses/expense/new", "", <AddRoundedIcon />),
    createRoute("/money", "Geld", <EuroSymbolIcon />),
    // createRoute("/balance", "balance", <AccountBalanceWalletRoundedIcon />),
    createRoute("/statistics", "statistieken", <EqualizerRoundedIcon />),
  ];

  const regExResult = location.pathname.match(/[^/]+$/g);
  let activeRoute: string | undefined = undefined;

  if (regExResult && regExResult?.length > 0) {
    activeRoute = regExResult[0];
  }

  return (
    <BottomNavigation
      style={{
        zIndex: 10000,
        width: "100%",
        bottom: 0,
        paddingBottom: 7,
        position: "fixed",
      }}
      // sx={{ boxShadow: 5 }}
      value={activeRoute}
      showLabels
    >
      {routes.map((route) => {
        if (!route.label) {
          return (
            <Fab key={route.label} component={Link} to={"." + route.path}>
              <AddRoundedIcon />
            </Fab>
          );
        }

        return (
          <BottomNavigationAction
            key={route.label}
            label={route.label}
            value={route.path.replace("/", "")}
            icon={route.icon}
            to={path + route.path}
            component={Link}
          />
        );
      })}
    </BottomNavigation>
  );
}

export default OutsideGroupBottomNav;
