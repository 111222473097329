import {
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  TextField,
  IconButton,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Loading, Title } from "../components";
import FormikMuiForm, {
  createLabel,
  LabelInterface,
} from "../components/FormikMuiForm";
import useAuth from "../hooks/auth/useAuth";
import * as Yup from "yup";
import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import Center from "../components/styled/Center";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";

function ForgotPasswordUnStyled() {
  const navigate = useNavigate();
  return (
    <Card>
      <CardContent>
        <UnstyledForgotPassword />
        <Button onClick={() => navigate("/login")}>Inloggen</Button>
        <Button onClick={() => navigate("/register")}>Registreer</Button>
      </CardContent>
    </Card>
  );
}

export const UnstyledForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const labeledValues: LabelInterface[] = [
    createLabel("Email", "email", Yup.string().email().required(), "", "email"),
  ];

  function handleForgotPassword(values: FormikValues) {
    setErrorMessage("");
    setLoading(true);
    forgotPassword(values.email)
      .catch((e) => {
        setErrorMessage(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <div style={{ minWidth: 300 }}>
      <Grid item textAlign="center">
        <Title>Wachtwoord vergeten</Title>
        <IconButton
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          <QuestionMarkRoundedIcon />
        </IconButton>
      </Grid>
      <FormikMuiForm
        errorMessage={errorMessage}
        submitButtonText="Wachtwoord aanvragen"
        isLoading={isLoading}
        labeledValues={labeledValues}
        formik={{
          onSubmit: handleForgotPassword,
        }}
        inputProps={{ fullWidth: true }}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Title>Geen email adres?</Title>
          <Typography>
            Maak je nog gebruik van een gebruikersnaam voor Eetlijst.nl, of heb
            je een andere vraag? Neem dan contact op met password@eetlijst.nl.
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

// function ForgotPassword() {
//   const navigate = useNavigate();

function ForgotPassword() {
  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={4} lg={3} textAlign="center">
        <Center>
          <ForgotPasswordUnStyled />
        </Center>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
