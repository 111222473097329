import { ReactNode, useEffect, useState } from "react";

import "./KeyboardBar.css";
import { Container, Grid } from "@mui/material";
import { Capacitor } from "@capacitor/core";
import { Device, OperatingSystem } from "@capacitor/device";
import { Keyboard } from "@capacitor/keyboard";

const stylesDefault = {
  overflow: {},
  bar_default: {
    position: "absolute",
    bottom: 63,
    left: 0,
    width: "100%",
  },
  bar_open: {
    position: "absolute",
    bottom: 63,
    left: 0,
    width: "100%",
  },
};

const stylesIOSNative = {
  overflow: {},
  bar_default: {
    position: "absolute",
    bottom: 63,
    left: 0,
    width: "100%",
  },
  bar_open: {
    position: "absolute",
    left: 0,
    width: "100%",
  },
};

const stylesIOSWeb = {
  overflow: {
    // position: "absolute",
    left: 0,
    top: 0,
    overflowY: "auto",
    maxHeight: "100%", // calc(100vh - 92px - 330px )",
  },
  bar_default: {
    // position: "absolute",
    // backgroundColor: "red",
    // bottom: 307,
    // width: "100%",
    position: "fixed",
    bottom: 63,
    width: "100%",
  },
  bar_open: {
    position: "fixed",
    bottom: 307,
    width: "100%",
  },
};

const KeyboardBar = ({
  bar,
  children,
}: {
  bar: ReactNode;
  children: ReactNode;
}) => {
  const native = Capacitor.isNativePlatform();

  const [os, setOs] = useState<OperatingSystem>("unknown");
  const classesDefault = stylesDefault;
  const classesIOSNative = stylesIOSNative;
  const classesIOSWeb = stylesIOSWeb;
  const [nativeKeyboardHeight, setKeyboardHeightNative] = useState(0);
  const [classes, setClasses] = useState<any>(classesDefault);
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);
  const [viewport, setViewport] = useState<{
    maxHeight: number;
  }>({
    maxHeight: window?.visualViewport?.height ?? 100,
  });
  const setIOSNative = () => {
    setClasses(classesIOSNative);
  };

  const setIOSWeb = () => {
    setClasses(classesIOSWeb);
  };

  const setOS = async () => {
    const os = await Device.getInfo();
    setOs(os.operatingSystem);
    if (os.operatingSystem === "ios") {
      if (native) {
        setIOSNative();
      } else {
        setIOSWeb();
      }

      handleNativeKeyboard();
    }
  };

  useEffect(() => {
    setOS();

    if (
      typeof window !== "undefined" &&
      typeof window.visualViewport !== "undefined"
    ) {
      window?.visualViewport?.addEventListener("resize", updateViewport);
    }
  }, []);

  async function handleNativeKeyboard() {
    const device = await Device.getInfo();
    const os = device.operatingSystem;

    if (os === "ios" && Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        setKeyboardIsOpen(true);
        setKeyboardHeightNative(info.keyboardHeight);
      });

      Keyboard.addListener("keyboardDidHide", () => {
        setKeyboardIsOpen(false);
        setKeyboardHeightNative(0);
      });
    } else {
      // alert("platform is ..." + JSON.stringify(os));
    }
  }

  const updateViewport = () => {
    // alert("Platform is: " + platform);
    if (
      os === "android" ||
      os === "ios" ||
      (os === "unknown" && Capacitor.isNativePlatform())
    ) {
      return;
    }

    const newHeight = window?.visualViewport?.height ?? 100;

    if (newHeight < viewport.maxHeight) {
      setKeyboardIsOpen(true);
    } else if (newHeight === viewport.maxHeight) {
      setKeyboardIsOpen(false);

      const el = document.getElementById("number-input");
      el?.removeEventListener("focus", () => {});
    }

    setTimeout(function () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  };

  return (
    <Container>
      <Grid xs={12} item style={classes.overflow}>
        {children}
      </Grid>
      <Grid
        xs={12}
        item
        style={{
          bottom:
            Capacitor.isNativePlatform() && os === "ios"
              ? nativeKeyboardHeight
                ? nativeKeyboardHeight
                : 63
              : "",
          ...(keyboardIsOpen ? classes.bar_open : classes.bar_default),
        }}
      >
        {bar}
      </Grid>
    </Container>
  );
};

export default KeyboardBar;
