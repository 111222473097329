import { Grid, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";

import { NavLink, useNavigate } from "react-router-dom";

import Logo from "../components/Logo";

export default function HeaderPublic() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <AppBar
        position="sticky"
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: "0.5rem 1rem 0.5rem 1rem",
          marginBottom: 10,
        }}
      >
        <Grid container>
          <Grid item xs={9}>
            <Logo
              onClick={() => {
                navigate("./");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                textAlign: "end",
                color: theme.palette.error.main,
              }}
            >
              <NavLink to="/login">
                <Button variant="contained" color="primary">
                  Login
                </Button>
              </NavLink>
            </div>
          </Grid>
        </Grid>
      </AppBar>
    </Grid>
  );
}
