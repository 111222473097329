import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Grid,
  Fab,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OptionBar from "../../components/OptionsBar";
import Center from "../../components/styled/Center";
import Balance from "./expense/Balance";
import ExpenseHistory from "./expense/ExpenseHistory";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import useNetwork from "../../redux/network";

const Money: React.FC = () => {
  const panels = [
    { value: 0, title: "Balans", component: <Balance /> },
    { value: 1, title: "Uitgaven", component: <ExpenseHistory /> },
    // { value: 2, title: "Verrekeningen", component: <div>verrekeningen</div> },
  ];
  const [activePanel, setActivePanel] = useState(panels[0]);
  const nagivate = useNavigate();
  const { hasInternet } = useNetwork();
  const handleChange = (value: any, index: number) => {
    setActivePanel(panels[index]);
  };

  const handleChangeIndex = (value: number) => {
    setActivePanel(panels[value]);
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 56,
        paddingBottom: 120,

        width: "100%",
      }}
    >
      <AppBar position="static">
        <Tabs
          value={activePanel.value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {panels.map((panel, index) => {
            return (
              <Tab key={index} label={panel.title} id={`panel-${index}`} />
            );
          })}
        </Tabs>
      </AppBar>
      <Container style={{}}>
        {panels.map((panel, index) => {
          return (
            <TabPanel key={index} value={activePanel.value} index={index}>
              {panel.component}
            </TabPanel>
          );
        })}
      </Container>
      <OptionBar>
        <Grid container spacing={1} style={{ direction: "rtl" }}>
          <Grid item>
            <Fab
              size="small"
              onClick={() => nagivate("../expenses/expense/new")}
              disabled={!hasInternet}
            >
              <AddRoundedIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Center>
              <Typography>Uitgave</Typography>
            </Center>
          </Grid>
        </Grid>
      </OptionBar>
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ paddingTop: 10 }}
      role="tabpanel"
      //   hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default Money;
