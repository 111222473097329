import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";

import logger from "../logger";
import { RootPersistedState, RootState } from "./store";
/**
 * Action + Reducers
 */

export const ASK_TO_REGISTRATE_NOTIFICATION = createAsyncThunk(
  "notification/ask_to_register_token",
  async (_: undefined, thunkAPI) => {
    // You cannot register notifications on web.
    if (!Capacitor.isNativePlatform()) {
      return Promise.reject("web");
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
      return;

      // return Promise.reject(permStatus.receive);
    }

    await PushNotifications.register();
  }
);

const ASK_TO_REGISTRATE_NOTIFICATION_PENDING = (
  proxyState: any,
  { payload }: any
) => {};

const ASK_TO_REGISTRATE_NOTIFICATION_REJECTED = (
  proxyState: any,
  action: any
) => {
  logger.error(
    `[ASK_TO_REGISTRATE_NOTIFICATION_REJECTED] action:${JSON.stringify(
      action
    )} state:${JSON.stringify(current(proxyState))}`
  );
};

const ASK_TO_REGISTRATE_NOTIFICATION_FULFILLED = (
  proxyState: any,
  { payload }: any
) => {};

export const CHECK_NOTIFICATION_PERMISSION = createAsyncThunk(
  "notification/ask_permission",
  async (payload: any, store) => {
    if (Capacitor.isNativePlatform()) {
      let permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === "granted") {
        return true;
      } else {
        return Promise.reject(permStatus.receive);
      }
    }

    return Promise.reject("web");
  }
);

const CHECK_NOTIFICATION_PERMISSION_PENDING = (
  proxyState: any,
  { payload }: any
) => {};

const CHECK_NOTIFICATION_PERMISSION_REJECTED = (
  proxyState: any,
  {
    payload,
  }: { payload: "web" | "denied" | "prompt" | "prompt-with-rationale" }
) => {
  logger.error(
    `[CHECK_NOTIFICATION_PERMISSION_REJECTED] payload:${JSON.stringify(
      payload
    )} state:${JSON.stringify(current(proxyState))}`
  );
};

const CHECK_NOTIFICATION_PERMISSION_FULFILLED = (
  proxyState: any,
  { payload }: any
) => {};

/**
 * States etc.
 */

interface NotificationState {
  send_permission: boolean;
  asked_permission: boolean;
  token?: string;
  update_info_dialog_version: number;
}

const initialState: NotificationState = {
  send_permission: false,
  asked_permission: false,
  update_info_dialog_version: 1,
};

export const notificationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ASKED_PERMISSION: (state: NotificationState) => {
      state.asked_permission = true;
    },
    SET_UPDATE_INFO_DIALOG_VERSION: (
      state: NotificationState,
      action: PayloadAction<number>
    ) => {
      state.update_info_dialog_version = Number(action.payload);
    },
  },
  extraReducers: {
    [ASK_TO_REGISTRATE_NOTIFICATION.pending.toString()]:
      ASK_TO_REGISTRATE_NOTIFICATION_PENDING,
    [ASK_TO_REGISTRATE_NOTIFICATION.rejected.toString()]:
      ASK_TO_REGISTRATE_NOTIFICATION_REJECTED,
    [ASK_TO_REGISTRATE_NOTIFICATION.fulfilled.toString()]:
      ASK_TO_REGISTRATE_NOTIFICATION_FULFILLED,

    [CHECK_NOTIFICATION_PERMISSION.pending.toString()]:
      CHECK_NOTIFICATION_PERMISSION_PENDING,
    [CHECK_NOTIFICATION_PERMISSION.rejected.toString()]:
      CHECK_NOTIFICATION_PERMISSION_REJECTED,
    [CHECK_NOTIFICATION_PERMISSION.fulfilled.toString()]:
      CHECK_NOTIFICATION_PERMISSION_FULFILLED,
  },
});

export const notificationReducer = notificationSlice.reducer;

export const selectNotificationToken = (
  state: RootState
): string | undefined => {
  return state.notification.token;
};

export const selectSendNotifications = (state: RootState) => {
  return state.notification.send_permission;
};

export const selectAskedPermission = (state: RootState) => {
  return state.notification.asked_permission;
};

export const selectUpdateInfoDialogVersion = (state: RootState) => {
  return state.notification.update_info_dialog_version;
};

export const { ASKED_PERMISSION, SET_UPDATE_INFO_DIALOG_VERSION } =
  notificationSlice.actions;
