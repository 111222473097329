import { Card, CardContent } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Title from "../../../components/styled/Title";

import EventAgenda from "../event/EventAgenda";
import store from "../../../redux/store";
import { HeaderContext } from "../../../layout/InsideGroupLayout";

const MainPage: React.FC = () => {
  const { setTitle } = useContext(HeaderContext);

  useEffect(() => {
    setTitle("Agenda");
  }, []);

  return (
    <Card>
      <CardContent>
        {/* <Title
          onClick={() => console.info("showing group id, and auth: ", store)}
        >
          Agenda
        </Title> */}
        <EventAgenda past={false} />
      </CardContent>
    </Card>
  );
};

export default MainPage;
