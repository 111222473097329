import { createContext } from "react";

export interface User {
  id: number,
  name: string
}

export const GlobalContext = createContext({});

const current_user: User = { name: "Ivo", id: 0 };
const all_users_in_group: User[] = [
  { name: "Ivo", id: 0 },
  { name: "Japs", id: 1 },
  { name: "Donals", id: 2 },
  { name: "Woep", id: 3 },
  { name: "Midas", id: 4 },
  { name: "Max", id: 5 },
];

export default function GlobalContextProvider(props: any) {
  const state = { current_user, all_users_in_group };

  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  );
}
