import CircularProgress from "@mui/material/CircularProgress";
import useTheme from "@mui/material/styles/useTheme";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import {
  Badge,
  Fab,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import vibrate from "./vibrate";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

enum UserEventEnum {
  UNKNOWN = "unspecified",
  EAT_ONLY = "eat_only",
  GROCERIES = "got_groceries",
  WANTS_TO_COOK = "cook",
  NOT_ATTEND = "not_attending",
}

function StyledFab(
  icon: any,
  color: string,
  isLoading?: boolean,
  disabled?: boolean
) {
  const theme = useTheme();
  return (
    <div>
      <Fab
        disabled={disabled}
        style={{
          fill: disabled
            ? `${theme.palette.text.disabled}`
            : `${theme.palette.background.default}`,
          padding: 10,
          backgroundColor: `${color}`,
        }}
      >
        {icon}
        {isLoading && (
          <div
            style={{
              zIndex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "green",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                zIndex: 1,
              }}
            />
          </div>
        )}
      </Fab>
    </div>
  );
}

export function EatOnlyFab() {
  const theme = useTheme();

  return StyledFab(
    <CheckRoundedIcon style={{ width: "100%", height: "100%" }} />,
    theme.palette.success.main
  );
}

export function GroceriesFab() {
  const theme = useTheme();
  return StyledFab(
    <LocalGroceryStoreRoundedIcon style={{ width: "100%", height: "100%" }} />,
    "#8ECAE6"
  );
}

export function UnknownFab() {
  const theme = useTheme();
  return StyledFab(
    <QuestionMarkRoundedIcon style={{ width: "100%", height: "100%" }} />,
    theme.palette.grey[500]
  );
}

export function NotAttendFab() {
  const theme = useTheme();
  return StyledFab(
    <ClearRoundedIcon style={{ width: "100%", height: "100%" }} />,
    theme.palette.error.main
  );
}

export function CookFab() {
  const theme = useTheme();
  return StyledFab(
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      x="0px"
      y="0px"
    >
      <g transform="translate(0,100) scale(0.1,-0.1)" stroke="none">
        <path
          d="M390 962 c-19 -9 -53 -35 -75 -58 -34 -35 -48 -42 -94 -47 -92 -11
-167 -64 -206 -144 -22 -45 -20 -154 5 -207 23 -50 71 -96 129 -122 l41 -18 0
-141 0 -141 33 -32 33 -32 240 0 240 0 32 33 32 33 0 140 0 140 41 18 c58 26
106 72 129 122 25 53 27 162 5 207 -39 80 -114 133 -206 144 -46 5 -60 12 -94
47 -22 23 -56 49 -77 59 -48 23 -161 23 -208 -1z m151 -96 c45 -19 73 -52 88
-103 6 -24 15 -43 19 -43 3 0 25 7 49 16 57 22 107 12 149 -30 53 -53 55 -127
5 -185 -21 -24 -85 -51 -122 -51 l-29 0 0 -70 0 -70 -205 0 -205 0 0 70 0 70
-29 0 c-16 0 -50 9 -75 20 -36 15 -50 29 -67 63 -28 58 -20 108 25 153 42 42
92 52 149 30 24 -9 45 -16 49 -16 3 0 11 20 18 44 13 47 44 83 88 102 36 17
54 16 93 0z m157 -693 l-3 -48 -200 0 -200 0 -3 48 -3 47 206 0 206 0 -3 -47z"
        />
      </g>
    </svg>,
    theme.palette.secondary.main
  );
}

/**
 * Status fab only for demo purpouses
 * @param param0
 * @returns
 */
export function DemoStatusFab({
  initStatus,
  numberGuests,
  showAddButtons = false,
  onClickProp,
}: {
  initStatus: UserEventEnum;
  numberGuests: bigint;
  showAddButtons?: boolean;
  onClickProp?: () => void;
}) {
  const [ActiveState, setActiveState] = useState(<UnknownFab />);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState(initStatus);
  const [number_guests, setNumberGuests] = useState(numberGuests);

  // Changed it
  function handleOpen(e: any) {
    setOpen(!open);
    setAnchorEl(e.currentTarget);
  }

  useEffect(() => {
    setStatusProperties(status);
    setNumberGuests(numberGuests);
    setOpen(false);
  }, [status, numberGuests]);

  async function handleUpdate(status: UserEventEnum, add_guests: bigint) {
    setIsLoading(true);
    setOpen(false);

    await setTimeout(async () => {
      setStatus(status);
      setStatusProperties(status);
      setNumberGuests(number_guests + add_guests);
    }, 100);

    setIsLoading(false);
    vibrate();
  }

  const setStatusProperties = (status?: UserEventEnum) => {
    switch (status) {
      case UserEventEnum.UNKNOWN: {
        setActiveState(<UnknownFab />);

        break;
      }
      case UserEventEnum.EAT_ONLY: {
        setActiveState(<EatOnlyFab />);

        break;
      }
      case UserEventEnum.GROCERIES: {
        setActiveState(<GroceriesFab />);

        break;
      }
      case UserEventEnum.NOT_ATTEND: {
        setActiveState(<NotAttendFab />);

        break;
      }
      case UserEventEnum.WANTS_TO_COOK: {
        setActiveState(<CookFab />);

        break;
      }
    }
  };

  function StyledFab(icon: any, color: string) {
    return (
      <div>
        <Fab
          style={{
            fill: `${theme.palette.background.default}`,
            padding: 10,
            backgroundColor: `${color}`,
          }}
        >
          {icon}
          {isLoading && (
            <div
              style={{
                zIndex: 1,
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                size={40}
                sx={{
                  color: "green",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  zIndex: 1,
                }}
              />
            </div>
          )}
        </Fab>
      </div>
    );
  }

  function EatOnlyFab() {
    return StyledFab(
      <CheckRoundedIcon style={{ width: "100%", height: "100%" }} />,
      theme.palette.success.main
    );
  }

  function GroceriesFab() {
    return StyledFab(
      <LocalGroceryStoreRoundedIcon
        style={{ width: "100%", height: "100%" }}
      />,
      theme.palette.primary.main
    );
  }

  function UnknownFab() {
    return StyledFab(
      <QuestionMarkRoundedIcon style={{ width: "100%", height: "100%" }} />,
      theme.palette.grey[500]
    );
  }

  function NotAttendFab() {
    return StyledFab(
      <ClearRoundedIcon style={{ width: "100%", height: "100%" }} />,
      theme.palette.grey[500]
    );
  }

  function CookFab() {
    return StyledFab(
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        x="0px"
        y="0px"
      >
        <g transform="translate(0,100) scale(0.1,-0.1)" stroke="none">
          <path
            d="M390 962 c-19 -9 -53 -35 -75 -58 -34 -35 -48 -42 -94 -47 -92 -11
-167 -64 -206 -144 -22 -45 -20 -154 5 -207 23 -50 71 -96 129 -122 l41 -18 0
-141 0 -141 33 -32 33 -32 240 0 240 0 32 33 32 33 0 140 0 140 41 18 c58 26
106 72 129 122 25 53 27 162 5 207 -39 80 -114 133 -206 144 -46 5 -60 12 -94
47 -22 23 -56 49 -77 59 -48 23 -161 23 -208 -1z m151 -96 c45 -19 73 -52 88
-103 6 -24 15 -43 19 -43 3 0 25 7 49 16 57 22 107 12 149 -30 53 -53 55 -127
5 -185 -21 -24 -85 -51 -122 -51 l-29 0 0 -70 0 -70 -205 0 -205 0 0 70 0 70
-29 0 c-16 0 -50 9 -75 20 -36 15 -50 29 -67 63 -28 58 -20 108 25 153 42 42
92 52 149 30 24 -9 45 -16 49 -16 3 0 11 20 18 44 13 47 44 83 88 102 36 17
54 16 93 0z m157 -693 l-3 -48 -200 0 -200 0 -3 48 -3 47 206 0 206 0 -3 -47z"
          />
        </g>
      </svg>,
      theme.palette.secondary.main
    );
  }

  const isAttending = (): boolean => {
    return (
      status !== UserEventEnum.UNKNOWN && status !== UserEventEnum.NOT_ATTEND
    );
  };

  return (
    <Grid container>
      {showAddButtons && (
        <Grid item xs={4} md={5} textAlign="end">
          <IconButton
            disabled={!isAttending()}
            onClick={() => {
              handleUpdate(status, BigInt(-1));

              vibrate();
            }}
          >
            {isAttending() && number_guests > 0 ? (
              <RemoveRoundedIcon
                style={{
                  fontSize: "1rem",
                  // fontWeight: "bold",
                }}
              />
            ) : null}
          </IconButton>
        </Grid>
      )}

      <Grid
        item
        xs={showAddButtons ? 4 : 12}
        md={showAddButtons ? 2 : 12}
        textAlign="center"
      >
        <Badge
          overlap="circular"
          color="primary"
          badgeContent={
            isAttending() && BigInt(number_guests) > BigInt(0)
              ? (BigInt(number_guests) + BigInt(1)).toString()
              : null
          }
        >
          <Grid item xs={12}>
            <Grid container>
              <div
                onClick={(e) => {
                  if (onClickProp) {
                    onClickProp();
                  }
                  handleOpen(e);
                }}
              >
                {ActiveState}
              </div>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Stack direction="row">
                  <MenuItem
                    onClick={() => {
                      setStatus(UserEventEnum.NOT_ATTEND);
                    }}
                  >
                    <NotAttendFab />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatus(UserEventEnum.WANTS_TO_COOK);
                    }}
                  >
                    <CookFab />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatus(UserEventEnum.GROCERIES);
                    }}
                  >
                    <GroceriesFab />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatus(UserEventEnum.EAT_ONLY);
                    }}
                  >
                    <EatOnlyFab />
                  </MenuItem>
                </Stack>
              </Menu>
            </Grid>
          </Grid>
        </Badge>
      </Grid>
      {showAddButtons ? (
        <Grid item xs={4} md={5} textAlign="start">
          <IconButton
            disabled={!isAttending()}
            //   className={classes.button_text}
            onClick={() => {
              handleUpdate(status, BigInt(1));
              vibrate();
            }}
          >
            {isAttending() ? (
              <AddRoundedIcon
                style={{
                  fontSize: "1rem",
                }}
              />
            ) : null}
          </IconButton>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default DemoStatusFab;
