import useAuth from "../hooks/auth/useAuth";
import * as Yup from "yup";
import { FormikValues } from "formik";
import FormikMuiForm, {
  createLabel,
  LabelInterface,
} from "../components/FormikMuiForm";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Logo, Title } from "../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "../redux/auth";
import { useState } from "react";
import Center from "../components/styled/Center";
import { SubTitle } from "../components/styled";

function Register() {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={4} lg={3} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <UnstyledRegister redirectUrl="../groups" />

              <Button onClick={() => navigate("/login")}>Of log in</Button>
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
}

export const UnstyledRegister = ({ redirectUrl }: { redirectUrl: string }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const labeledValues: LabelInterface[] = [
    createLabel("Email", "email", Yup.string().email().required(), "", "email"),
    createLabel("Naam", "name", Yup.string().required(), ""),
    createLabel(
      "Wachtwoord",
      "password",
      Yup.string().required(),
      "",
      "password"
    ),
    createLabel(
      "Wachtwoord check",
      "password_check",
      Yup.string().required(),
      "",
      "password"
    ),
  ];

  async function handleRegister(values: FormikValues) {
    const { email, name, password, password_check } = values;
    setError("");

    setLoading(true);

    const onError = (e: any) => {
      setError("Registratie error: " + JSON.stringify(e));
    };

    const onSuccess = () => {
      navigate(redirectUrl);
    };

    await dispatch(
      register({ email, name, password, password_check, onError, onSuccess })
    );

    setLoading(false);
  }
  return (
    <Grid item xs={12} style={{ minWidth: 350 }}>
      <Logo />
      <SubTitle>Registreer</SubTitle>
      <FormikMuiForm
        errorMessage={error}
        submitButtonText="Account aanmaken"
        isLoading={isLoading}
        labeledValues={labeledValues}
        formik={{
          onSubmit: handleRegister,
        }}
        inputProps={{ fullWidth: true }}
      />
    </Grid>
  );
};

export default Register;
