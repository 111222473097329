import {
  Box,
  Grid,
  ListItem,
  List,
  Typography,
  ListItemText,
  Container,
} from "@mui/material";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import Investment from "../../assets/svg/Investment";
import { Title } from "../../components";
import TextBold from "../../components/Bold";
import { selectUsersEetlijst } from "../../redux/auth_eetlijst";
import { RootState } from "../../redux/store";

/**
 * This is to show that your
 * house has already made a big investment
 *
 * - x years active
 * - y housemates
 * - z monnies spended.
 */
const HookInvestment: React.FC = () => {
  const group = useSelector(
    (state: RootState) => state.eetlijst_custom.groupInfo
  );

  const users = useSelector(selectUsersEetlijst);

  return (
    <>
      
      {/* <Box textAlign="center">
        <Investment
          style={{
            maxWidth: 250,
            width: "100%",
          }}
        />
      </Box> */}
      <Container>
        <Title
        onClick={() => {
          console.log(group, users);
        }}
      >
        Maar eerst, wist je dat?
      </Title>
        <Grid xs={12} md={6} item>
          <List>
            <ListItem>
              <ListItemText>
                Jouw groep al bestaat sinds{" "}
                <TextBold>
                  {
                    //@t.s-expect-error
                    group?.created_at === undefined
                      ? "GEEN_DATUM"
                      : dayjs(group.created_at).format("MMMM YYYY").toString()
                  }
                </TextBold>
                {/* {JSON.stringify(group)} */}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Er in totaal<TextBold>&nbsp;{users.length}&nbsp;</TextBold>
                mensen in deze lijst hebben gezeten!
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Jullie met zn allen
                <TextBold>&nbsp;{group.totalCosts / 100}&nbsp;</TextBold>euro
                hebben gespendeerd!
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <TextBold>
                  {users.reduce((a, b) => {
                    if (b.cooked && !isNaN(b.cooked)) {
                      return a + Number(b.cooked);
                    } else {
                      return a;
                    }
                  }, 0)}
                  &nbsp;
                </TextBold>{" "}
                keer is gekookt
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <TextBold>
                  {users.reduce((a, b) => {
                    if (b.ate && !isNaN(b.ate)) {
                      return a + Number(b.ate);
                    } else {
                      return a;
                    }
                  }, 0)}
                  &nbsp;
                </TextBold>{" "}
                keer is mee gegeten
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Container>
    </>
  );
};

export default HookInvestment;
