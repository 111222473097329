import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { RootState } from "./store";
import {
  CloseEventMutationMutation,
  CreateGroupMutationMutation,
  CreateSharedListItemMutationMutation,
  EventAllQueryQuery,
  EventCreateAttendanceMutationMutation,
  EventSingleEventQueryQuery,
  EventUpdateAttendenceMutationMutation,
  GroupsQueryQuery,
  GroupsSharedListQueryQuery,
  GroupsSpecificQueryQuery,
  SharedListItemMutationMutation,
  AutomaticEventQueryQuery,
  ExpenseHistoryQueryQuery,
  CreateExpensesMutationMutation,
  UpdateExpenseMutationMutation,
  StatisticsQueryQuery,
  GetUserStatusQuery,
  UpdateUserGroupRelationMutation,
  ChangeNotificationPreferencesMutation,
  AuthNotificationMutation,
  TotalGroupExpenseQueryQuery,
  GetAppStatusQuery,
  UserInformationQueryQuery,
  UpdateEventDescriptionMutation,
} from "../graphqlTypes";
import {
  EventUser,
  UserEventEnum,
  Expense,
  ExpenseDistribution,
} from "./interfaces/interfaces";
import {
  GroupUser,
  SharedListItem,
  Group,
  Event,
} from "./interfaces/interfaces";
import { Moment } from "moment";
import moment from "moment";
import { transformToUserEventEnum } from "./helperFunctions";
import dayjs, { Dayjs } from "dayjs";

export class User {
  constructor(
    public id: string,
    public name: string,
    public active: boolean,
    public email?: string,
    public origin?: string,
    public address?: string,
    public alias?: string,
    public allergies?: string,
    public bank_account?: string,
    public created_at?: Dayjs,
    public last_seen?: Dayjs,
    public phone_nr?: string,
    public profile_image?: string,
    public updated_at?: Dayjs
  ) {}
}

/**
 * These are the GraphQL endpoints for the api v1.
 *
 * REST endpoints are down in other createApi.
 */
export const graphqlApi = createApi({
  reducerPath: "graphqlV1",
  tagTypes: [
    "Event",
    "Group",
    "User",
    "List",
    "Expense",
    "Statistics",
    "Event_Status",
  ],
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL}`,
    prepareHeaders: (headers: any, { getState }: { getState: any }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).user.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    todaysEvent: builder.mutation<[], { group_id: string; date?: Moment }>({
      query: ({ group_id, date }) => {
        // hi
        if (!date) {
          date = moment();
        }
        return {
          document: gql`
            query AutomaticEventQuery($group_id: uuid!, $date: String!) {
              queryTodaysEvents(group_id: $group_id, date: $date) {
                id
                name
                start_date
              }
            }
          `,
          variables: { group_id, date: date?.format("YYYY-MM-DD:hh:mm:ss") },
        };
      },
      transformResponse: (res: AutomaticEventQueryQuery) => {
        // The result is not used, but it should invalidate the "Event", such that those are fetched again.
        return [];
        // return new Event(
        //   res.queryTodaysEvents?.id,
        //   res.queryTodaysEvents?.name,
        //   [],
        //   res.queryTodaysEvents?.start_date
        // );
      },
      invalidatesTags: ["Event"],
    }),
    // Implement this one
    eventAll: builder.query<Event[], { group_id: string; user_id: string }>({
      query: ({ group_id, user_id }) => ({
        document: gql`
          query eventAllQuery($group_id: uuid!, $user_id: String!) {
            eetschema_event(where: { group_id: { _eq: $group_id } }) {
              id
              start_date
              name
              open
              description
              linked_expenses {
                payed_by
                description
                id
                payed_amount
                payed_at
                expense_distributions(where: { user_id: { _eq: $user_id } }) {
                  payed_amount
                }
              }
              event_attendees {
                status
                user_id
                number_guests
                comment
              }
            }
          }
        `,
        variables: {
          group_id,
          user_id,
        },
      }),
      providesTags: ["Event"],
      transformResponse: (res: EventAllQueryQuery) => {
        return res.eetschema_event.map((event) => {
          const e = new Event(
            event.id,
            event.description ?? "",
            event.event_attendees.map((user) => {
              return new EventUser(
                user.user_id,
                user.status,
                user.number_guests,
                user.comment
              );
            }),
            event.start_date,
            undefined,
            event.open
          );
          e.payed_amount_sum = event.linked_expenses.reduce(
            (a, b) => a + b.payed_amount,
            0
          );
          return e;
        });
      },
    }),
    getGroup: builder.query<Group, { group_id: string }>({
      query: ({ group_id }) => ({
        document: gql`
          query GroupsSpecificQuery($group_id: uuid!) {
            eetschema_users_in_group(where: { group_id: { _eq: $group_id } }) {
              active
              user {
                alias
                id
                name
                profile_image
                email
              }
            }
            eetschema_group_by_pk(id: $group_id) {
              name
              created_at
              created_at_eetlijst
              id
              invite_uuid
              invite_open
              description
              summary(order_by: { payed_total: desc }) {
                payed_total
                user_id
              }
            }
          }
        `,
        variables: { group_id },
      }),
      providesTags: ["Group"],
      transformResponse: (res: GroupsSpecificQueryQuery) => {
        const id = res.eetschema_group_by_pk?.id;
        const invite_open = res.eetschema_group_by_pk?.invite_open ?? false;
        const invite_uuid = res.eetschema_group_by_pk?.invite_uuid;
        const name = res.eetschema_group_by_pk?.name ?? "";
        const description = res.eetschema_group_by_pk?.description ?? "";

        const users = res.eetschema_users_in_group.map((u) => {
          if (!u.user?.id || !u.user?.name) {
            // logger.error(
            console.error(
              `[getGroup] Response with user has no id/name if-statement:${(
                (!u.user?.id || u.user?.name) === true
              ).toString()} id:${u.user?.id} name:${
                u.user?.name
              } ${JSON.stringify(res)}`
            );
          }
          const user = new GroupUser(
            u.user?.id ?? "",
            u.user?.name ?? "",
            u.user?.profile_image,
            u.user?.alias,
            undefined,
            undefined,
            undefined,
            u.user.email
          );

          user.active = u.active;

          const summary = res.eetschema_group_by_pk?.summary.find((b) => {
            return b.user_id === u.user?.id;
          });
          user.balance = summary?.payed_total ?? BigInt(0);

          return user;
        });

        return new Group(
          id,
          name,
          invite_open,
          invite_uuid,
          users ?? [],
          [],
          description,
          res.eetschema_group_by_pk?.created_at_eetlijst
            ? dayjs(res.eetschema_group_by_pk?.created_at_eetlijst)
            : dayjs(res.eetschema_group_by_pk?.created_at)
        );
      },
    }),

    fetchGroups: builder.query<Group[], { user_id: string }>({
      query: ({ user_id }) => ({
        document: gql`
          query groupsQuery($user_id: String!) {
            eetschema_users_in_group(
              where: { user_id: { _eq: $user_id }, active: { _eq: true } }
            ) {
              group {
                id
                name
              }
            }
          }
        `,
        variables: {
          user_id,
        },
      }),
      providesTags: ["Group"],
      transformResponse: (res: GroupsQueryQuery) => {
        const result = res.eetschema_users_in_group.map((g) => {
          return new Group(g.group?.id, g.group?.name ?? "");
        });
        return result;
      },
    }),

    createGroup: builder.mutation<
      { id?: string; name?: string },
      { name: string; user_id: string }
    >({
      query: ({ name, user_id }) => ({
        document: gql`
          mutation CreateGroupMutation($name: String!, $user_id: String) {
            insert_eetschema_group_one(
              object: {
                name: $name
                users_in_groups: { data: { user_id: $user_id } }
              }
            ) {
              id
              name
            }
          }
        `,
        variables: {
          name,
          user_id,
        },
      }),
      transformResponse: (res: CreateGroupMutationMutation) => {
        return {
          id: res.insert_eetschema_group_one?.id,
          name: res.insert_eetschema_group_one?.name,
        };
      },
      invalidatesTags: ["Group"],
    }),
    closeEvent: builder.mutation<
      CloseEventMutationMutation,
      {
        event_id: string;
        open: boolean;
        closed_by: string;
      }
    >({
      query: ({ event_id, open, closed_by }) => ({
        document: gql`
          mutation CloseEventMutation(
            $event_id: uuid!
            $open: Boolean!
            $closed_by: String!
          ) {
            update_eetschema_event_by_pk(
              pk_columns: { id: $event_id }
              _set: { open: $open, closed_by: $closed_by }
            ) {
              open
              closed_by
            }
          }
        `,
        variables: { event_id, open, closed_by },
      }),
      invalidatesTags: ["Event"],
    }),
    queryEvent: builder.query<
      { users: EventUser[]; event: Event },
      { event_id: string }
    >({
      // { users: EventUser[]; event: Event },
      // { event_id: string }
      query: ({ event_id }) => ({
        document: gql`
          query EventSingleEventQuery($event_id: uuid!) {
            eetschema_event_by_pk(id: $event_id) {
              start_date
              name
              id
              description
              end_date
              open
              updated_at
              created_at
              closed_by
              linked_expenses_aggregate {
                aggregate {
                  sum {
                    payed_amount
                  }
                }
              }
            }

            eetschema_event_attendees(where: { event_id: { _eq: $event_id } }) {
              status
              user_id
              number_guests
              comment
            }
          }
        `,
        variables: {
          event_id,
        },
      }),

      providesTags: ["Event"],
      transformResponse: (response: EventSingleEventQueryQuery, error) => {
        const event = new Event(
          response.eetschema_event_by_pk?.id,
          response.eetschema_event_by_pk?.description ?? "",
          response.eetschema_event_attendees.map((u) => {
            return new EventUser(
              u.user_id,
              u.status,
              u.number_guests,
              u.comment
            );
          }),
          response.eetschema_event_by_pk?.start_date,
          response.eetschema_event_by_pk?.end_date,
          response.eetschema_event_by_pk?.open,
          response.eetschema_event_by_pk?.closed_by,
          response.eetschema_event_by_pk?.updated_at
        );

        const payed_amount_sum = Number(
          response.eetschema_event_by_pk?.linked_expenses_aggregate?.aggregate
            ?.sum?.payed_amount
        );

        if (!isNaN(payed_amount_sum)) {
          event.payed_amount_sum = payed_amount_sum;
        }

        return {
          users: response.eetschema_event_attendees.map((u) => {
            return new EventUser(
              u.user_id,
              u.status,
              u.number_guests,
              u.comment
            );
          }),

          event,
        };
      },
    }),

    createAttendance: builder.mutation<
      EventCreateAttendanceMutationMutation,
      {
        objects: {
          event_id: string;
          user_id: string;
          status: string;
          number_guests: number;
        }[];
      }
    >({
      query: ({ objects }) => ({
        document: gql`
          mutation EventCreateAttendanceMutation(
            $objects: [eetschema_event_attendees_insert_input!]!
          ) {
            insert_eetschema_event_attendees(objects: $objects) {
              returning {
                status
                user_id
                updated_at
                number_guests
              }
            }
          }
        `,
        variables: { objects },
      }),

      invalidatesTags: ["Event", "Event_Status"],
      transformResponse: (res: EventCreateAttendanceMutationMutation) => {
        return res;
      },
    }),
    updateAttendance: builder.mutation<
      EventUpdateAttendenceMutationMutation,
      {
        event_id: string;
        user_id: string;
        status: string;
        number_guests: number;
        comment?: string;
      }
    >({
      query: ({ event_id, user_id, status, number_guests, comment }) => {
        if (comment === undefined || comment === null) {
          return {
            document: gql`
              mutation EventUpdateAttendenceMutation2(
                $event_id: uuid!
                $user_id: String!
                $status: String!
                $number_guests: Int
              ) {
                update_eetschema_event_attendees_by_pk(
                  pk_columns: { event_id: $event_id, user_id: $user_id }
                  _set: { status: $status, number_guests: $number_guests }
                ) {
                  status
                  number_guests
                }
              }
            `,
            variables: {
              event_id,
              user_id,
              status,
              number_guests,
            },
          };
        }
        return {
          document: gql`
            mutation EventUpdateAttendenceMutation(
              $event_id: uuid!
              $user_id: String!
              $status: String!
              $number_guests: Int
              $comment: String
            ) {
              update_eetschema_event_attendees_by_pk(
                pk_columns: { event_id: $event_id, user_id: $user_id }
                _set: {
                  status: $status
                  number_guests: $number_guests
                  comment: $comment
                }
              ) {
                status
                number_guests
              }
            }
          `,
          variables: {
            event_id,
            user_id,
            status,
            number_guests,
            comment,
          },
        };
      },

      invalidatesTags: ["Event", "Event_Status"],
      transformResponse: (res: EventUpdateAttendenceMutationMutation) => {
        return res;
      },
    }),
    getSharedList: builder.query<SharedListItem[], { group_id: string }>({
      query: ({ group_id }) => ({
        document: gql`
          query GroupsSharedListQuery($group_id: uuid!) {
            eetschema_list(where: { group_id: { _eq: $group_id } }) {
              active
              checked
              group_id
              id
              text
            }
          }
        `,
        variables: { group_id },
      }),
      transformResponse: (res: GroupsSharedListQueryQuery) => {
        return res.eetschema_list.map((item) => {
          return {
            text: item.text,
            id: item.id,
            checked: item.checked,
            active: item.active,
            group_id: item.group_id,
          };
        });
      },
      providesTags: ["List"],
    }),
    updateSharedListItem: builder.mutation<
      SharedListItemMutationMutation,
      {
        id: string;
        active: boolean;
        checked: boolean;
        text: string;
        group_id: string;
      }
    >({
      query: ({ id, active, checked, text }) => {
        return {
          document: gql`
            mutation SharedListItemMutation(
              $id: uuid!
              $active: Boolean
              $checked: Boolean
              $text: String
            ) {
              update_eetschema_list_by_pk(
                pk_columns: { id: $id }
                _set: { active: $active, checked: $checked, text: $text }
              ) {
                active
                checked
                group_id
                id
                text
              }
            }
          `,
          variables: { id, active, checked, text },
        };
      },
      invalidatesTags: ["List"],
      async onQueryStarted(
        { id, active, checked, text, group_id },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          graphqlApi.util.updateQueryData(
            "getSharedList",
            { group_id },
            (draft) => {
              const newItem: SharedListItem = {
                text,
                id,
                checked,
                active,
                group_id,
              };
              const itemIndex = draft.findIndex((item) => {
                return item.id === id;
              });

              if (itemIndex !== -1) {
                draft[itemIndex] = newItem;
              } else {
                console.error("Could not find item index");
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createSharedListItem: builder.mutation<
      CreateSharedListItemMutationMutation,
      { group_id: string; text: string }
    >({
      query: ({ group_id, text, ...patch }) => ({
        document: gql`
          mutation createSharedListItemMutation(
            $group_id: uuid!
            $text: String!
          ) {
            insert_eetschema_list_one(
              object: {
                active: true
                checked: false
                group_id: $group_id
                text: $text
              }
            ) {
              id
              active
              checked
              group_id
              text
            }
          }
        `,
        variables: {
          group_id,
          text,
        },
      }),
      invalidatesTags: ["List"],
      async onQueryStarted({ text, group_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          graphqlApi.util.updateQueryData(
            "getSharedList",
            { group_id },
            (draft) => {
              const newItem: SharedListItem = {
                text,
                id: `temp-id-${Math.random()}`,
                checked: false,
                active: true,
                group_id,
              };
              draft.push({
                ...newItem,
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getExpenses: builder.query<
      Expense[],
      { group_id: string; event_id?: string }
    >({
      query: ({ group_id, event_id }) => ({
        document: gql`
          query ExpenseHistoryQuery($group_id: uuid, $event_id: uuid) {
            eetschema_expense(
              where: {
                group_id: { _eq: $group_id }
                event_id: { _eq: $event_id }
              }
            ) {
              description
              payed_amount
              payed_at
              payed_by
              event_id
              deleted
              updated_at
              expense_distributions {
                count
                id
                payed_amount
                user_id
              }
              id
              payed_by_user {
                name
              }
              updatedByUser {
                name
              }
            }
          }
        `,

        variables: { group_id, event_id },
      }),
      providesTags: ["Expense", "Group"],
      transformResponse: (res: ExpenseHistoryQueryQuery) => {
        // return res.response.
        return res.eetschema_expense
          .map((e) => {
            const distribution: ExpenseDistribution[] =
              e.expense_distributions.map((d) => {
                let count = BigInt(0);
                let payed_amount = BigInt(0);
                const payed_amount_c = Number(d.payed_amount);
                const num = Number(d.count);
                if (!isNaN(num) && !isNaN(payed_amount_c)) {
                  count = BigInt(num);
                  payed_amount = BigInt(payed_amount_c);
                } else {
                  throw Error("Should be convertable to a number!");
                }
                return {
                  count,
                  payed_amount,
                  user_id: d.user_id,
                };
              });

            let payed_amount = BigInt(0);
            const num = Number(e.payed_amount);
            if (!isNaN(num)) {
              payed_amount = BigInt(num);
            } else {
              throw Error("payed_amount Should be convertable to a number!");
            }
            return new Expense(
              e.id,
              payed_amount,
              distribution,
              moment(e.payed_at),
              e.description,
              e.payed_by,
              moment(e.updated_at),
              e.deleted,
              e.event_id,
              e.updatedByUser?.name
            );
          })
          .sort((a, b) => {
            return Number(b.updated_at) - Number(a.updated_at);
          });
      },
    }),
    editExpense: builder.mutation<
      UpdateExpenseMutationMutation,
      {
        description: string;
        objects: {
          count: number;
          payed_amount: number;
          user_id: string;
          expense_id: string;
        }[];
        deleted?: boolean;
        payed_by: string;
        total_cost: number;
        event_id?: string;
        expense_id: string;
        updated_by: string;
      }
    >({
      query: ({
        total_cost,
        deleted,
        description,
        payed_by,
        expense_id,
        objects,
        updated_by,
      }) => ({
        document: gql`
          mutation UpdateExpenseMutation(
            $total_cost: Int
            $deleted: Boolean = false
            $description: String
            $payed_by: String
            $expense_id: uuid
            $objects: [eetschema_expense_distribution_insert_input!]!
            $updated_by: String
          ) {
            update_eetschema_expense(
              where: { id: { _eq: $expense_id } }
              _set: {
                payed_amount: $total_cost
                deleted: $deleted
                description: $description
                payed_by: $payed_by
                updated_by: $updated_by
              }
            ) {
              affected_rows
            }
            delete_eetschema_expense_distribution(
              where: { expense_id: { _eq: $expense_id } }
            ) {
              affected_rows
            }
            insert_eetschema_expense_distribution(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          total_cost,
          deleted,
          description,
          payed_by,
          expense_id,
          objects,
          updated_by,
        },
      }),
      invalidatesTags: ["Expense", "Group"],
    }),
    createExpense: builder.mutation<
      CreateExpensesMutationMutation,
      {
        description: string;
        data: {
          count: number;
          payed_amount: number;
          user_id: string;
        }[];
        group_id: string;
        payed_by: string;
        payed_amount: number;
        event_id?: string;
      }
    >({
      query: ({
        group_id,
        payed_by,
        data,
        description,
        payed_amount,
        event_id,
      }) => ({
        document: gql`
          mutation CreateExpensesMutation(
            $group_id: uuid!
            $payed_by: String!
            $data: [eetschema_expense_distribution_insert_input!]!
            $description: String!
            $payed_amount: Int
            $event_id: uuid
          ) {
            insert_eetschema_expense_one(
              object: {
                description: $description
                expense_distributions: { data: $data }
                group_id: $group_id
                payed_by: $payed_by
                payed_amount: $payed_amount
                event_id: $event_id
              }
            ) {
              description
              id
            }
          }
        `,
        variables: {
          group_id,
          payed_by,
          data,
          description,
          payed_amount,
          event_id,
        },
      }),
      invalidatesTags: ["Expense", "Group"],
    }),
    totalGroupExpense: builder.query<number, { group_id: string }>({
      query: ({ group_id }) => ({
        document: gql`
          query TotalGroupExpenseQuery($group_id: uuid) {
            eetschema_expense_aggregate(
              where: { group_id: { _eq: $group_id } }
            ) {
              aggregate {
                sum {
                  payed_amount
                }
              }
            }
            eetschema_expense_eetlijst_import_aggregate {
              aggregate {
                sum {
                  payed_amount
                }
              }
            }
          }
        `,
        variables: { group_id },
      }),
      transformResponse: (response: TotalGroupExpenseQueryQuery) => {
        const payed_amount =
          response.eetschema_expense_aggregate.aggregate?.sum?.payed_amount ??
          0;
        const import_amount =
          response.eetschema_expense_eetlijst_import_aggregate.aggregate?.sum
            ?.payed_amount ?? 0;

        return payed_amount + import_amount;
      },
      providesTags: ["Expense"],
    }),
    getStatistics: builder.query<
      StatisticsQueryQuery,
      {
        group_id: string;
      }
    >({
      query: ({ group_id }) => ({
        document: gql`
          query StatisticsQuery($group_id: uuid!) {
            eetschema_group_statistics(
              where: { group_id: { _eq: $group_id } }
            ) {
              count
              number_guests
              status
              user {
                name
                id
                users_in_groups(where: { group_id: { _eq: $group_id } }) {
                  active
                }
              }

              # user_name
            }
          }
        `,
        variables: { group_id },
      }),
      providesTags: ["Statistics"],
    }),
    userStatus: builder.query<
      {
        status: UserEventEnum;
        number_guests: bigint;
        user_id: string;
        comment: string;
      }[],
      {
        event_id: string;
      }
    >({
      providesTags: ["Event_Status"],
      query: ({ event_id }) => ({
        document: gql`
          query GetUserStatus($event_id: uuid) {
            eetschema_event_attendees(where: { event_id: { _eq: $event_id } }) {
              status
              number_guests
              comment
              user_id
            }
          }
        `,
        variables: { event_id },
      }),
      transformResponse: (res: GetUserStatusQuery) => {
        return res.eetschema_event_attendees.map((attendee) => {
          return {
            status: transformToUserEventEnum(attendee.status),
            number_guests: attendee.number_guests
              ? BigInt(attendee.number_guests)
              : BigInt(0),
            user_id: attendee.user_id,
            comment: attendee.comment ? attendee.comment : "",
          };
        });
      },
    }),
    updateUserGroupRelation: builder.mutation<
      UpdateUserGroupRelationMutation,
      { user_id: string; group_id: string; active: boolean }
    >({
      query: ({ user_id, group_id, active }) => {
        return {
          document: gql`
            mutation UpdateUserGroupRelation(
              $group_id: uuid!
              $user_id: String!
              $active: Boolean!
            ) {
              update_eetschema_users_in_group(
                where: {
                  user_id: { _eq: $user_id }
                  group_id: { _eq: $group_id }
                }
                _set: { active: $active }
              ) {
                affected_rows
                returning {
                  active
                  user {
                    name
                  }
                }
              }
            }
          `,
          variables: { group_id, user_id, active },
        };
      },
      invalidatesTags: ["Group"],
    }),
    registrateNotification: builder.mutation<
      AuthNotificationMutation,
      {
        device_token: string;
        user_id: string;
        platform: string;
      }
    >({
      query: ({ device_token, user_id, platform }) => ({
        document: gql`
          mutation AuthNotification(
            $device_token: String!
            $user_id: String!
            $platform: String
          ) {
            insert_eetschema_notification(
              objects: {
                device_token: $device_token
                user_id: $user_id
                title: "Welkom bij Samenn"
                body: "Je ontvangt nu pushmeldingen."
                platform: $platform
              }
            ) {
              affected_rows
            }
          }
        `,
        variables: { device_token, user_id, platform },
      }),
    }),
    updateNotification: builder.mutation<
      ChangeNotificationPreferencesMutation,
      {
        user_id: string;
        wants_to_recieve: boolean;
      }
    >({
      query: ({ user_id, wants_to_recieve }) => ({
        document: gql`
          mutation ChangeNotificationPreferences(
            $user_id: String
            $wants_to_recieve: Boolean
          ) {
            update_eetschema_notification(
              where: { user_id: { _eq: $user_id } }
              _set: { wants_to_recieve: $wants_to_recieve }
            ) {
              affected_rows
            }
          }
        `,
        variables: { user_id, wants_to_recieve },
      }),
    }),

    updateGroup: builder.mutation<
      UpdateUserGroupRelationMutation,
      {
        id: string;
        beta: boolean;
        // email: string;
        name: string;
        description: string;
      }
    >({
      query: ({ id, beta, name, description }) => ({
        document: gql`
          mutation UpdateGroup(
            $id: uuid!
            $beta: Boolean
            $name: String
            $description: String
          ) {
            update_eetschema_group_by_pk(
              pk_columns: { id: $id }
              _set: { beta: $beta, name: $name, description: $description }
            ) {
              id
            }
          }
        `,
        variables: { id, beta, name, description },
      }),
      invalidatesTags: ["Group"],
    }),

    appStatus: builder.query<
      {
        updated_at: string;
        beta_online: boolean;
        id: string;
      },
      {}
    >({
      query: () => ({
        document: gql`
          query GetAppStatus {
            eetschema_app_status {
              updated_at
              beta_online
              id
            }
          }
        `,
        variables: {},
      }),
      transformResponse: (res: GetAppStatusQuery) => {
        const { updated_at, beta_online, id } = res.eetschema_app_status[0];
        return {
          updated_at,
          beta_online,
          id,
        };
      },
    }),
    currentUserInfo: builder.query<User | null, { user_id: string }>({
      query: ({ user_id }) => ({
        document: gql`
          query UserInformationQuery($user_id: String!) {
            eetschema_user_private(where: { id: { _eq: $user_id } }) {
              active
              address
              alias
              allergies
              bank_account
              created_at
              email
              id
              last_seen
              name
              origin
              phone_nr
              profile_image
              updated_at
            }
          }
        `,
        variables: { user_id },
      }),
      transformResponse: (res: UserInformationQueryQuery) => {
        if (res.eetschema_user_private.length !== 1) {
          return null;
        }
        const user = res.eetschema_user_private[0];
        return new User(
          //@ts-expect-error
          user.id,
          //@ts-expect-e rror
          user.name,
          //@ts-expect-e rror
          user.active,
          //@ts-expect-er ror
          user.email,
          user?.origin,
          user?.address,
          user?.alias,
          user?.allergies,
          user?.bank_account,
          user?.created_at,
          user?.last_seen,
          user?.phone_nr,
          user?.profile_image,
          user?.updated_at
        );
      },
    }),
    updateGroupDescription: builder.mutation<
      UpdateEventDescriptionMutation,
      { event_id: string; description: string }
    >({
      query: ({ event_id, description }) => ({
        document: gql`
          mutation UpdateEventDescription(
            $event_id: uuid!
            $description: String!
          ) {
            update_eetschema_event_by_pk(
              pk_columns: { id: $event_id }
              _set: { description: $description }
            ) {
              id
            }
          }
        `,
        variables: { event_id, description },
      }),
      invalidatesTags: ["Event"],
    }),
  }),
});

export const {
  useEventAllQuery,
  useCreateSharedListItemMutation,
  useFetchGroupsQuery,
  useCreateGroupMutation,
  useQueryEventQuery,
  useLazyQueryEventQuery,
  useGetGroupQuery,
  useCreateAttendanceMutation,
  useUpdateAttendanceMutation,
  useGetSharedListQuery,
  useUpdateSharedListItemMutation,
  useCloseEventMutation,
  useTodaysEventMutation,
  useGetExpensesQuery,
  useEditExpenseMutation,
  useCreateExpenseMutation,
  useGetStatisticsQuery,
  useUserStatusQuery,
  useUpdateUserGroupRelationMutation,
  useRegistrateNotificationMutation,
  useUpdateNotificationMutation,
  useUpdateGroupMutation,
  useTotalGroupExpenseQuery,
  useAppStatusQuery,
  useCurrentUserInfoQuery,
  useUpdateGroupDescriptionMutation,
} = graphqlApi;
