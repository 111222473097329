function SignUpIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      data-name="Layer 1"
      viewBox="0 0 893.68 749.53"
    >
      <path
        fill="#3f3d56"
        d="M581.759 741.149L414.346 439.311a4.503 4.503 0 00-2.234-3.607 4.451 4.451 0 00-2.419-.584c-.027-.003-.054 0-.081-.002-.028.001-.055 0-.082.002a4.442 4.442 0 00-4.633 4.047L227.465 741.15a4.5 4.5 0 107.794 4.5l174.233-296.536 164.473 296.536a4.5 4.5 0 107.794-4.5z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M381.871 689.362L400.794 685.02 393.053 609.967 365.125 616.374 381.871 689.362z"
      ></path>
      <path
        fill="#2f2e41"
        d="M396.501 653.105l-26.93 6.177-8.61-16.827-.487-.596a26.776 26.776 0 01-6.015-16.579l-22.383-59.207-1.83-.87a11.022 11.022 0 01-4.523-15.935l-2.37-6.268-66.862.123-.066-.02-82.106-23.03 8.621-51.392 23.096 14.528c7.61-.494 137.98-8.498 150.024 20.719 10.725 26.017 26.18 70.467 37.257 103.174a25.533 25.533 0 002.05 4.225 18.443 18.443 0 012.937 8.864 21.594 21.594 0 00.783 4.057c4.604 13.762-2.41 28.494-2.481 28.641zM375.478 683.087l6.153-1.412 21.779-15.278 15.114 6.813h.002a28.146 28.146 0 0133.725 21.139l.205.891-70.479 16.17z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M96.085 684.772c1.924-20.94 14.19-41.907 33.864-49.334a96.89 96.89 0 002.14 66.487c3.323 8.17 7.869 16.911 5.261 25.336-1.622 5.243-5.871 9.35-10.683 11.988-4.813 2.637-10.204 3.994-15.528 5.325l-1.027.906c-9.128-18.944-15.95-39.767-14.027-60.708z"
      ></path>
      <path
        fill="#fff"
        d="M130.16 635.826a82.814 82.814 0 00-19.081 47.245 35.663 35.663 0 00.764 11.148 20.454 20.454 0 005.389 9.319c2.372 2.444 5.082 4.67 6.82 7.648a12.524 12.524 0 01.94 10.2c-1.315 4.198-4.06 7.674-6.862 10.98-3.111 3.672-6.401 7.435-7.62 12.208-.148.579-1.037.316-.89-.262 2.122-8.305 9.959-13.235 13.53-20.768 1.666-3.515 2.283-7.554.5-11.15-1.56-3.146-4.352-5.443-6.776-7.897a21.826 21.826 0 01-5.609-8.916 32.969 32.969 0 01-1.19-11.077 80.345 80.345 0 015.081-24.592 84.283 84.283 0 0114.368-24.76c.382-.457 1.015.221.635.674z"
      ></path>
      <path
        fill="#fff"
        d="M111.148 677.457a12.425 12.425 0 01-9.87-12.705.464.464 0 01.926.017 11.505 11.505 0 009.206 11.8c.585.119.319 1.007-.262.888zM115.866 702.457a23.948 23.948 0 0010.245-14.128c.15-.578 1.039-.315.89.262a24.91 24.91 0 01-10.692 14.68c-.504.321-.945-.495-.443-.814zM119.386 651.75a7.033 7.033 0 006.65-.552c.5-.328.94.488.444.814a7.881 7.881 0 01-7.356.627.479.479 0 01-.313-.576.466.466 0 01.575-.313z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M191.78 670.745c-.307.213-.613.427-.92.648a92.622 92.622 0 00-11.539 9.621c-.281.268-.562.543-.835.818a97.644 97.644 0 00-20.263 29.694 94.824 94.824 0 00-4.76 13.599c-1.711 6.417-3.056 13.512-6.681 18.833a16.267 16.267 0 01-1.217 1.604l-34.267 1.101c-.08-.037-.159-.065-.238-.102l-1.366.107c.047-.245.1-.497.148-.74.027-.143.061-.285.088-.426.02-.095.041-.19.054-.276a1.3 1.3 0 01.02-.087c.013-.086.034-.165.048-.244q.448-2.116.928-4.232c0-.008 0-.008.007-.016a139.425 139.425 0 0110.739-31.165c.147-.295.293-.597.456-.892a90.484 90.484 0 017.728-12.582 79.992 79.992 0 015.128-6.261 66.518 66.518 0 0116.237-12.997c12.082-6.884 26.231-9.827 39.494-6.285.339.09.67.181 1.01.28z"
      ></path>
      <path
        fill="#fff"
        d="M191.716 671.183a82.814 82.814 0 00-43.68 26.234 35.663 35.663 0 00-6.101 9.36 20.454 20.454 0 00-1.308 10.686c.423 3.38 1.247 6.789.84 10.213a12.524 12.524 0 01-5.39 8.71c-3.577 2.56-7.861 3.683-12.09 4.636-4.694 1.058-9.586 2.082-13.434 5.16-.466.372-1.017-.373-.552-.746 6.694-5.353 15.92-4.571 23.307-8.436 3.446-1.803 6.37-4.656 7.112-8.602.648-3.45-.198-6.966-.656-10.385a21.826 21.826 0 01.89-10.495 32.97 32.97 0 015.719-9.56 80.345 80.345 0 0118.863-16.577 84.283 84.283 0 0126.38-11.12c.579-.134.676.788.1.921z"
      ></path>
      <path
        fill="#fff"
        d="M151.472 692.976a12.425 12.425 0 01-.232-16.087c.381-.459 1.112.112.73.571a11.505 11.505 0 00.247 14.964c.394.447-.352.997-.745.552zM140.187 715.777a23.948 23.948 0 0016.687-5.112c.468-.37 1.02.374.552.745a24.91 24.91 0 01-17.375 5.284c-.596-.047-.456-.963.136-.917zM173.528 677.41a7.033 7.033 0 005.642 3.564c.596.039.456.955-.136.916a7.881 7.881 0 01-6.251-3.928.479.479 0 01.096-.648.466.466 0 01.649.096z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M656.735 684.772c-1.924-20.94-14.19-41.907-33.864-49.334a96.89 96.89 0 01-2.14 66.487c-3.323 8.17-7.868 16.911-5.26 25.336 1.622 5.243 5.87 9.35 10.683 11.988 4.812 2.637 10.203 3.994 15.527 5.325l1.027.906c9.129-18.944 15.951-39.767 14.027-60.708z"
      ></path>
      <path
        fill="#fff"
        d="M622.661 635.826a82.814 82.814 0 0119.08 47.245 35.663 35.663 0 01-.763 11.148 20.454 20.454 0 01-5.39 9.319c-2.372 2.444-5.082 4.67-6.82 7.648a12.524 12.524 0 00-.939 10.2c1.314 4.198 4.06 7.674 6.861 10.98 3.112 3.672 6.401 7.435 7.62 12.208.149.579 1.037.316.89-.262-2.122-8.305-9.959-13.235-13.53-20.768-1.666-3.515-2.283-7.554-.5-11.15 1.56-3.146 4.353-5.443 6.776-7.897a21.826 21.826 0 005.61-8.916 32.97 32.97 0 001.189-11.077 80.345 80.345 0 00-5.081-24.592 84.283 84.283 0 00-14.368-24.76c-.381-.457-1.014.221-.635.674z"
      ></path>
      <path
        fill="#fff"
        d="M641.672 677.457a12.425 12.425 0 009.871-12.705.464.464 0 00-.927.017 11.505 11.505 0 01-9.206 11.8c-.584.119-.319 1.007.262.888zM636.955 702.457a23.948 23.948 0 01-10.246-14.128c-.15-.578-1.039-.315-.89.262a24.91 24.91 0 0010.693 14.68c.503.321.944-.495.443-.814zM633.434 651.75a7.033 7.033 0 01-6.65-.552c-.5-.328-.94.488-.444.814a7.881 7.881 0 007.356.627.479.479 0 00.314-.576.466.466 0 00-.576-.313z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M561.041 670.745c.306.213.612.427.919.648a92.622 92.622 0 0111.54 9.621c.28.268.561.543.834.818a97.644 97.644 0 0120.263 29.694 94.824 94.824 0 014.76 13.599c1.712 6.417 3.057 13.512 6.682 18.833a16.268 16.268 0 001.216 1.604l34.268 1.101c.079-.037.158-.065.238-.102l1.366.107c-.047-.245-.102-.497-.149-.74-.027-.143-.061-.285-.088-.426-.02-.095-.04-.19-.054-.276a1.3 1.3 0 00-.02-.087c-.013-.086-.034-.165-.047-.244q-.449-2.116-.929-4.232c0-.008 0-.008-.007-.016a139.425 139.425 0 00-10.738-31.165c-.147-.295-.294-.597-.457-.892a90.484 90.484 0 00-7.727-12.582 79.992 79.992 0 00-5.129-6.261 66.519 66.519 0 00-16.237-12.997c-12.082-6.884-26.231-9.827-39.493-6.285-.34.09-.671.181-1.01.28z"
      ></path>
      <path
        fill="#fff"
        d="M561.104 671.183a82.814 82.814 0 0143.68 26.234 35.663 35.663 0 016.101 9.36 20.454 20.454 0 011.308 10.686c-.423 3.38-1.247 6.789-.84 10.213a12.524 12.524 0 005.39 8.71c3.577 2.56 7.862 3.683 12.09 4.636 4.694 1.058 9.587 2.082 13.434 5.159.466.373 1.018-.372.552-.745-6.694-5.353-15.92-4.571-23.306-8.436-3.447-1.803-6.371-4.656-7.113-8.602-.648-3.45.198-6.966.656-10.385a21.826 21.826 0 00-.89-10.495 32.97 32.97 0 00-5.719-9.56 80.345 80.345 0 00-18.862-16.577 84.283 84.283 0 00-26.38-11.12c-.58-.134-.677.788-.1.921z"
      ></path>
      <path
        fill="#fff"
        d="M601.348 692.976a12.425 12.425 0 00.232-16.087c-.38-.459-1.112.112-.73.571a11.505 11.505 0 01-.246 14.964c-.395.447.352.997.744.552zM612.633 715.777a23.948 23.948 0 01-16.687-5.112c-.467-.37-1.019.374-.552.745a24.91 24.91 0 0017.375 5.284c.596-.047.457-.963-.136-.917zM579.293 677.41a7.033 7.033 0 01-5.643 3.564c-.596.039-.456.955.136.916a7.881 7.881 0 006.251-3.928.479.479 0 00-.096-.648.466.466 0 00-.648.096z"
      ></path>
      <path
        fill="#3f3d56"
        d="M893.68 748.34a1.193 1.193 0 01-1.19 1.19H1.19a1.19 1.19 0 110-2.38h891.3a1.193 1.193 0 011.19 1.19z"
      ></path>
      <circle cx="574.112" cy="283.5" r="4.5" fill="#3f3d56"></circle>
      <path
        fill="#e4e4e4"
        d="M517.763 318.162l60.667-38.114a4.796 4.796 0 114.724 8.336l-63.868 32.465a1.546 1.546 0 01-1.523-2.687z"
      ></path>
      <path
        fill="#006c4c"
        d="M396.053 400.232a4.202 4.202 0 01.069-4.79 9.89 9.89 0 013.966-3.146 33.1 33.1 0 0110.349-3.27c2.74-.39 5.731-.513 7.857-2.286 2.111-1.76 3.289-5.054 6.024-5.328 2.646-.266 4.68 2.629 7.331 2.809 3.27.221 5.41-3.605 8.592-4.39 2.13-.525 4.319.402 6.316 1.308l11.987 5.438 6.145 2.788c2.146.973 4.566 2.285 4.971 4.606.557 3.19-2.99 5.44-6.024 6.573a96.97 96.97 0 01-67.125.009"
      ></path>
      <ellipse
        cx="414.112"
        cy="436.5"
        fill="#3f3d56"
        rx="157.5"
        ry="22.5"
      ></ellipse>
      <rect
        width="126"
        height="18"
        x="616.612"
        y="576"
        fill="#3f3d56"
        rx="9"
      ></rect>
      <path
        fill="#3f3d56"
        d="M785.951 415.54l-35.67 169.45 9.29 43.37 17.54 81.85V747a.776.776 0 01-.01.15 6.935 6.935 0 01-.44 2.38h-14.1a6.935 6.935 0 01-.44-2.38.775.775 0 01-.01-.15v-35.21l-15.92-74.29-3.22-15-6.23-29.07-1.8-8.42V585l1.78-8.44 1.37-6.51 33.18-157.59a7.5 7.5 0 1114.68 3.08zM633.112 585v162a.776.776 0 01-.01.15 6.935 6.935 0 01-.44 2.38h-14.1a6.935 6.935 0 01-.44-2.38.775.775 0 01-.01-.15V585a7.5 7.5 0 0115 0z"
      ></path>
      <path
        fill="#3f3d56"
        d="M643.612 558h72a27 27 0 0127 27h-126a27 27 0 0127-27zM751.612 637.5h-126a7.5 7.5 0 010-15h126a7.5 7.5 0 010 15z"
      ></path>
      <rect
        width="145.091"
        height="20.727"
        x="309.681"
        y="648.78"
        fill="#3f3d56"
        rx="9"
        transform="rotate(-180 305.646 621.527)"
      ></rect>
      <path
        fill="#3f3d56"
        d="M164.212 584.03l-2.3 9.48-8.44 34.72-3.64 15-16.9 69.58V743a7.392 7.392 0 01-1.26 4.15 7.241 7.241 0 01-2.57 2.38h-7.34a7.242 7.242 0 01-2.57-2.38 7.393 7.393 0 01-1.26-4.15v-31.99l18.78-77.3 12.12-49.92-41.1-195.25a7.495 7.495 0 1114.67-3.08l37.99 180.43 1.75 8.32 2.04 9.7zM298.752 583.91V743a7.389 7.389 0 01-1.26 4.15 7.242 7.242 0 01-2.57 2.38h-7.35a7.299 7.299 0 01-2.56-2.38 7.392 7.392 0 01-1.26-4.15V583.91a7.5 7.5 0 1115 0z"
      ></path>
      <path
        fill="#3f3d56"
        d="M301.612 583.91H156.52v-4.092a27 27 0 0127-27h91.09a27 27 0 0127 27v4.091zM291.248 643.227H146.157a7.5 7.5 0 010-15h145.091a7.5 7.5 0 010 15z"
      ></path>
      <path
        fill="#9e616a"
        d="M477.713 708.651L461.95 701.112 483.603 635.223 506.869 646.349 477.713 708.651z"
      ></path>
      <path
        fill="#9e616a"
        d="M584.542 702.683L567.592 706.865 543.033 642.018 568.051 635.845 584.542 702.683z"
      ></path>
      <path
        fill="#9e616a"
        d="M519.575 422.267a16.433 16.433 0 0022.262 5.585 15.742 15.742 0 002.112-1.53l49.837 28.599-2.165-29.358-46.994-22.658a16.372 16.372 0 00-19.86-2.587 15.755 15.755 0 00-5.192 21.95z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M572.36 444.152l8.81 5.368 39.563 24.126a23.328 23.328 0 0032.162-7.423 22.825 22.825 0 002.91-16.113l-17.585-87.853a24.377 24.377 0 00-32-18.498 23.21 23.21 0 00-14.644 28.195l18.387 62.347-28.185-13.14z"
      ></path>
      <path
        fill="#2f2e41"
        d="M551.53 684.51l-27.123-54.399.003-.124 2.678-99.777c.034-.887.106-1.778.214-2.655a30.352 30.352 0 0111.548-20.426 29.165 29.165 0 0121.754-5.95l77.56 10.038 2.69-10.999.5-.182L715.67 472.8l.428.698c9.733 15.836 16.537 47.47 18.39 56.787l.027.134c.039.197.062.316.07.35 3.826 15.901-11.981 42.242-27.729 46.2-33.17 8.455-62.835 1.86-97.185-5.777-12.318-2.74-25.047-5.57-39.07-8.019l.417 8.253a61.84 61.84 0 003.913 18.562c2.5 7.323 4.475 13.106.299 25.165l-2.02 5.834a19.884 19.884 0 01-2.045 17.629l-.406.62 17.887 45.264z"
      ></path>
      <path
        fill="#2f2e41"
        d="M506.965 660.453l-32.345-3.006 11.46-36.647a239.265 239.265 0 0117.674-69.517l15.63-37.042c.036-.825.105-1.654.206-2.467a30.347 30.347 0 0111.548-20.425 29.181 29.181 0 0121.754-5.95l77.445 10.023 3.373-28.31 74.335-9.948.34.554c12.993 21.14 21.242 37.69 25.96 52.083.236.734.446 1.483.626 2.23 3.852 16.008-5.764 32.238-21.434 36.178-39.935 10.182-87.957 9.899-151.096-.894l-30.557 72.72a111.676 111.676 0 01-10.793 19.796z"
      ></path>
      <ellipse
        cx="619.789"
        cy="255.037"
        fill="#9e616a"
        rx="37.467"
        ry="38.302"
      ></ellipse>
      <path
        fill="#e4e4e4"
        d="M630.608 496.76l-5.376-19.26-1.322-9.5-7.727.258-15.165-35.004-5.89-9.23q-1.802-1.897-3.264-3.648a82.226 82.226 0 01-14.556-23.438 61.58 61.58 0 01-4.26-19.3c-1.1-19.558 7.145-40.25 25.205-63.255a10.854 10.854 0 01-.588-2.522 10.671 10.671 0 019.278-11.814l17.42-1.956a10.281 10.281 0 017.623 2.222 10.688 10.688 0 013.948 7.256 10.928 10.928 0 01-1.232 6.32 46.52 46.52 0 0138.61 32.488 308.947 308.947 0 0124.78 61.206c1.193 4.158.662 8.442.149 12.585-.475 3.828-.965 7.785-.14 11.73.839 4.011 2.857 8.036 4.994 12.296 2.447 4.88 4.978 9.926 5.662 15.07l.048.361z"
      ></path>
      <path
        d="M577.771 396.749a81.957 81.957 0 0014.476 23.306 86.158 86.158 0 003.303 3.7l5.927 9.3c21.848-16.376 39.55-80.857 39.55-80.857l-24.65-4.796z"
        opacity="0.2"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#9e616a"
        d="M562.847 296.917a16.47 16.47 0 01-5.562 22.29 15.804 15.804 0 01-2.345 1.15l1.745 57.435-24.872-15.657 2.156-52.14a16.425 16.425 0 017.074-18.764 15.719 15.719 0 0121.804 5.686z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M558.871 356.112l-27.352 3.89 1.176 56.641a23.7 23.7 0 0011.586 19.973 22.708 22.708 0 0027.125-3.08l64.575-62.112a24.484 24.484 0 001.804-33.62 23.22 23.22 0 00-33.267-2.279 23.822 23.822 0 00-1.819 1.811l-42.64 49.053z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M330.104 725.568L349.518 725.567 358.754 650.683 330.101 650.684 330.104 725.568z"
      ></path>
      <circle cx="220.061" cy="255.703" r="38.748" fill="#ffb6b6"></circle>
      <path
        fill="#2f2e41"
        d="M355.854 702.5h-27.492l-7.009-9.395-1.897-8.852-1.633-1.58a13.628 13.628 0 01-2.298-16.76L295.6 572.952c-5.218-.46-64.069-5.721-104.978-12.961-10.47-1.853-18.644-6.942-24.295-15.126-13.888-20.111-7.94-52.651-7.877-52.977l.068-.363.367-.042 95.555-10.725.128.061c3.171 1.502 77.802 36.932 89.997 49.128 5.508 5.507 17.794 38.227 20.545 73.013.493 6.232-1.28 12.579-2.995 18.716-1.605 5.743-3.264 11.68-2.936 17.295.506 8.661.985 17.747 1.425 27.005.07 1.475 1.182 3.002 2.256 4.478 1.123 1.543 2.184 2.999 2.251 4.524.653 14.784-9.01 27.208-9.107 27.332z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M362.114 358.909a14.76 14.76 0 00-18.593 12.907l-32.195 10.062 1.343 21.057 45.266-14.849a14.84 14.84 0 004.18-29.177z"
      ></path>
      <path
        fill="#006c4c"
        d="M341.108 393.753l-24.062 9.107-.616 1.194a12.14 12.14 0 01-16.352 5.228l-54.085 20.47s-24.153-24.17-41.349-45.74l5.86-36.048a69.057 69.057 0 018.512-23.726c35.266 7.733 55.51 57.032 55.51 57.032l63.558-12.127z"
      ></path>
      <path
        fill="#2f2e41"
        d="M214.555 292.38c-9.701 2.62-20.396-.863-27.966-7.47s-12.306-15.97-15.035-25.641c-1.998-7.083-2.946-14.944.208-21.594s11.64-11.135 18.189-7.776c-4.04-4.706-2.022-12.49 2.696-16.516s11.196-5.18 17.366-5.811c8.954-.916 18.203-1.019 26.724 1.882s16.262 9.243 18.929 17.84a13.578 13.578 0 0016.194-3.656 12.301 12.301 0 01-6.777 13.338l13.055-4.233c2.245 4.752-1.69 10.634-6.72 12.156s-10.462-.141-15.236-2.34-9.4-4.979-14.586-5.836-11.277.809-13.676 5.485c-1.256 2.45-1.321 5.319-2.05 7.973s-2.578 5.407-5.322 5.624c-1.734.138-3.432-.786-5.16-.597-2.144.234-3.785 2.176-4.356 4.255a16.326 16.326 0 00-.015 6.424l3.316 25.348"
      ></path>
      <path
        fill="#006c4c"
        d="M166.55 549.495a.094.094 0 01-.023-.002l-.306-.053-.089-.297c-1.114-3.748-2.531-7.583-3.902-11.292a136.16 136.16 0 01-5.105-15.737 120.525 120.525 0 01-2.256-15.49c-.383-3.78-.778-7.69-1.45-11.538-.3-1.709.832-3.327 1.926-4.892 1.015-1.45 1.974-2.821 1.772-4.117-.205-1.326-1.675-2.782-3.097-4.19-1.559-1.544-3.17-3.14-3.398-4.82a480.113 480.113 0 01-4.326-69.163c.32-32 1.767-53.813 4.298-64.831 6.806-29.627 38.249-35.034 43.17-35.701a10.868 10.868 0 0114.699-6.062l22.34 10.227a36.371 36.371 0 0119.338 21.597l3.32 10.118 1.662 67.92L245.91 459l18 27c-95.933 52.67-97.052 63.495-97.36 63.495z"
      ></path>
      <path
        fill="#2f2e41"
        d="M319.657 718.022h6.312L350.613 708l13.208 10.02h.002a28.146 28.146 0 0128.145 28.145v.915l-72.31.002z"
      ></path>
      <path
        fill="#ffb6b6"
        d="M370.176 407.377a14.76 14.76 0 00-21.317 7.609l-33.706 1.314-4.541 15.7 47.917 2.453a14.84 14.84 0 0011.647-27.076z"
      ></path>
      <path
        fill="#006c4c"
        d="M342.489 436.146l-34.623-.432-4.382-.059-70.441-.887s-19.129-18.25-35.785-37.038l2.334-16.887a68.991 68.991 0 0123.132-42.715c23.856 15.94 37.691 47.492 37.691 47.492L283.612 396a101.559 101.559 0 0146.371 13.58l7.629 4.42z"
      ></path>
      <path
        fill="#2f2e41"
        d="M596.91 734.624l-11.52 2.843-4.74-10.369-2.583 12.176-30.553 7.54a7.074 7.074 0 01-6.993-11.556l20.24-22.873-2.713-10.995 26.042-4.802zM474.869 738.18l-10.986-4.485 2.273-11.172-9.256 8.322-29.137-11.893a7.074 7.074 0 011.152-13.459l29.827-6.572 4.28-10.485 23.877 11.45z"
      ></path>
      <path
        fill="#e4e4e4"
        d="M436.612 432.5h-9a36.541 36.541 0 01-36.5-36.5v-.5h82v.5a36.541 36.541 0 01-36.5 36.5z"
      ></path>
      <path
        fill="#3f3d56"
        d="M427.612 382.5c0 2.485-4.5 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 4.5-4.5 4.5-4.5 4.5 2.015 4.5 4.5zM454.612 382.5c0 2.485-4.5 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 4.5-4.5 4.5-4.5 4.5 2.015 4.5 4.5zM436.612 391.5c0 2.485-4.5 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 4.5-4.5 4.5-4.5 4.5 2.015 4.5 4.5z"
      ></path>
      <circle cx="405.112" cy="368.5" r="4.5" fill="#3f3d56"></circle>
      <path
        fill="#e4e4e4"
        d="M339.666 358.83l70.973 9.801a4.796 4.796 0 11-1.74 9.423l-69.794-16.187a1.546 1.546 0 01.561-3.038z"
      ></path>
      <path
        fill="#f0f0f0"
        d="M425.91 135.213V0h-9v135.213a49.498 49.498 0 00-45 49.287V198h31.5a18 18 0 0036 0h31.5v-13.5a49.498 49.498 0 00-45-49.287zM541.785 101.41V0h-6.75v101.41a37.123 37.123 0 00-33.75 36.965V148.5h23.625a13.5 13.5 0 1027 0h23.625v-10.125a37.123 37.123 0 00-33.75-36.965zM313.785 101.41V0h-6.75v101.41a37.123 37.123 0 00-33.75 36.965V148.5h23.625a13.5 13.5 0 1027 0h23.625v-10.125a37.123 37.123 0 00-33.75-36.965z"
      ></path>
      <path
        fill="#2f2e41"
        d="M720.048 238.425c-6.368-7.427-9.396-17.28-14.487-25.714-5.092-8.434-13.952-15.982-23.311-14.02-6.453 1.354-11.439 7.047-13.963 13.395-2.524 6.347-2.997 13.347-3.44 20.205.156-14.093-9.128-27.755-21.918-32.251-12.79-4.497-28.095.519-36.132 11.84a19.53 19.53 0 00-25.939-.544c-7.188 6.425-8.779 18.653-3.492 26.848s16.736 11.248 25.137 6.703l2.97 2.732c-1.672 5.848 1.57 12.755 7.02 14.956 4.42 1.786 10.66 1.488 12.313 6.126 1.156 3.241-1.004 6.7-3.302 9.19-2.297 2.49-5.025 5.062-5.301 8.508-.417 5.2 4.956 8.842 9.84 9.939 11.777 2.645 24.845-2.81 31.633-13.203q.197-.3.427-.602a45.48 45.48 0 009.148-34.775c-.382-2.402.003-4.19 1.66-4.758 7.158-2.459 27.215 2.702 33.284 7.366 6.07 4.665 10.932 10.838 16.778 15.803 5.847 4.965 13.298 8.795 20.722 7.443 5.946-1.083 11.004-5.4 14.406-10.606 3.402-5.207 5.36-11.276 7.114-17.307-13.148 7.595-31.142 4.416-41.167-7.274z"
      ></path>
    </svg>
  );
}

export default SignUpIcon;
