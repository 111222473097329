import dayjs, { Dayjs } from "dayjs";
import { Moment } from "moment";
import * as Yup from "yup";

import logger from "../../logger";

export interface SharedListItem {
  text: string;
  id: string;
  checked: boolean;
  active: boolean;
  group_id: string;
}

export class GroupUser {
  public balance: number = 0;
  public active: boolean = true;

  constructor(
    public user_id: string,
    public name: string,
    public profile_image?: string | null,
    public alias?: string | null,
    public last_seen?: string | null | unknown,
    public updated_at?: string | null | unknown,
    public created_at?: string | null | unknown, // public active?: boolean
    public email?: string | null | unknown
  ) {}
}

export class Group {
  public expense_history: Expense[] = [];
  public events: Event[] = [];
  public loaded_events: boolean = false;
  public statistics: Statistics[] = [];
  public shared_list: SharedListItem[] = [];
  public email?: string;
  public beta: boolean = false;

  constructor(
    public id: string,
    public name: string,
    public invite_open: boolean = false,
    public invite_uuid?: string,
    public users: GroupUser[] = [],
    public admins: GroupUser[] = [],
    public description: string = "",
    public created_at: Dayjs = dayjs()
  ) {}
}

enum EventTypeEnum {
  EET_EVENT,
}

export class Event {
  public type = EventTypeEnum.EET_EVENT;
  public payed_amount_sum: number = 0;

  public description?: string;

  constructor(
    public id: string,
    public title: string,
    public users: EventUser[],
    public start_date: Moment,
    public end_date?: Moment,
    public open: boolean = true,
    public closed_by?: string | null,
    public updated_at?: Moment
  ) {
    const schema = Yup.object().shape({
      id: Yup.string().uuid(),
    });

    schema
      .isValid({
        id,
      })
      .catch((e) => {
        logger.error(
          `[Event] (inside the Event class) error: ${JSON.stringify(e)}`
        );
        throw new Error("Id of Event is not valid: " + id);
      });
  }

  get date(): string {
    return this.start_date.toISOString();
  }
}

export class EventUser {
  public status: UserEventEnum = UserEventEnum.UNKNOWN;
  public number_guests: number = 0;
  public comment: string = "";

  constructor(
    public user_id: string,
    status?: string,
    number_guests?: number,
    comment?: string | null
  ) {
    this.comment = comment ? comment : "";
    if (status) {
      const key = Object.keys(UserEventEnum).filter(
        (x) => UserEventEnum[x as keyof typeof UserEventEnum] == status
      );

      if (key && key.length === 1) {
        this.status = UserEventEnum[key[0] as keyof typeof UserEventEnum];
      }

      if (!isNaN(Number(number_guests))) {
        this.number_guests = Number(number_guests);
      } else {
        console.error("Could not transform to bigint", number_guests);
      }
    }
  }

  get brings_guests(): boolean {
    return this.number_guests > BigInt(0);
  }
}

export enum UserEventEnum {
  UNKNOWN = "unspecified",
  EAT_ONLY = "eat_only",
  GROCERIES = "got_groceries",
  WANTS_TO_COOK = "cook",
  NOT_ATTEND = "not_attending",
  DONT_KNOW_YET = "dont_know_yet",
}

export class Expense {
  constructor(
    public id: string,
    public payed_amount: bigint,
    public expense_distribution: ExpenseDistribution[],
    public payed_at: Moment,
    public description: string,
    public payed_by: string,
    public updated_at: Moment | string,
    public deleted: boolean = false,
    public linked_event_id?: string,
    public updated_by_user_name?: string
  ) {}
}

export interface ExpenseDistribution {
  count?: bigint;
  payed_amount: bigint;
  user_id: string;
}

interface CREATE_EXPENSE_PARAM_INTERFACE {
  description: string;
  data: {
    count: number;
    payed_amount: number;
    user_id: string;
  }[];
  group_id: string;
  payed_by: string;
  payed_amount: number;
  event_id?: string;
  onError: (e: any) => void;
  onSuccess: () => void;
}

export interface Statistics {
  count: number;
  group_id: string;
  number_guests: string;
  status:
    | "cook"
    | "unspecified"
    | "eat_only"
    | "got_groceries"
    | "not_attending";
  user_name: string;
}
