import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import useNetwork from "../redux/network";
import ErrorGrid from "./styled/ErrorGrid";

const OfflineBar = () => {
  const { hasInternet } = useNetwork();

  return (
    <Collapse in={!hasInternet}>
      <ErrorGrid xs={12} item textAlign="center">
        <Typography>Je hebt geen internet</Typography>
      </ErrorGrid>
    </Collapse>
  );
};

export default OfflineBar;
