import {
  ListItem,
  List,
  TextFieldProps,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { FormikConfig, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import ErrorMessage from "./ErrorMessage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type FormikCustonConfig = PartialBy<
  FormikConfig<FormikValues>,
  "initialValues"
>;

export interface LabelInterface {
  label: string;
  field: string;
  schema: Yup.AnySchema;
  initValue: any;
  type: string;
  props: any;
}

export function getInitValues(labeledValues: LabelInterface[]) {
  const init: any = {};

  labeledValues.forEach((val) => {
    init[val.field] = val.initValue;
  });
  return init;
}

export function getValidationValues(labeledValues: LabelInterface[]) {
  const yupSchema: any = {};

  labeledValues.forEach((val) => {
    yupSchema[val.field] = val.schema;
  });

  const schema: Yup.AnyObjectSchema = Yup.object().shape(yupSchema);

  return schema;
}

/**
 * Create a form. Combines Formik and MUI and Yup
 * @author Ivo Chen
 * @param param0
 * @returns
 */
function FormikMuiForm({
  disabled,
  labeledValues,
  formik: { onSubmit, ...restFormik },
  inputProps,
  submitButtonText,
  isLoading,
  errorMessage,
  loadingButtonProps,
  errorMessageProp,
  ...rest
}: {
  disabled?: boolean;
  isLoading?: boolean;
  labeledValues: LabelInterface[];
  formik: FormikCustonConfig;
  inputProps?: TextFieldProps;
  submitButtonText: string;
  errorMessage?: string;
  loadingButtonProps?: any;
  errorMessageProp?: any;
}) {
  const formik = useFormik({
    initialValues: getInitValues(labeledValues),
    validationSchema: getValidationValues(labeledValues),
    onSubmit,

    ...restFormik,
  });

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <form
      {...rest}
      onSubmit={(e) => {
        e.preventDefault();
        if (!disabled) {
          formik.handleSubmit();
        }
      }}
    >
      <List>
        {labeledValues.map((value, key) => {
          const field = value.field;
          return (
            <ListItem key={"mui-form-field-" + field}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setPasswordVisible(!passwordVisible);
                            }}
                          >
                            {value.type === "password" &&
                              (!passwordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              ))}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...value.props}
                    type={
                      value.type === "password"
                        ? passwordVisible
                          ? "text"
                          : "password"
                        : value.type
                    }
                    key={key}
                    id={field}
                    label={value.label}
                    name={field}
                    onBlur={formik.handleBlur}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    error={
                      formik?.touched[field] && Boolean(formik?.errors[field])
                    }
                    helperText={formik?.touched[field] && formik?.errors[field]}
                    {...inputProps}
                  />
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
        <ErrorMessage {...errorMessageProp}>{errorMessage}</ErrorMessage>
        <ListItem {...loadingButtonProps}>
          <LoadingButton
            size="large"
            fullWidth
            variant="contained"
            type="submit"
            loading={isLoading}
            disabled={disabled}
          >
            {submitButtonText}
          </LoadingButton>
        </ListItem>
      </List>
    </form>
  );
}

export function createLabel(
  label: string,
  field: string,
  schema: Yup.AnySchema,
  initValue: any,
  type?: string,
  props?: any
): LabelInterface {
  if (!type) {
    type = "";
  }

  return { label, field, schema, initValue, type, props };
}

export default FormikMuiForm;
