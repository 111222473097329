import { styled } from "@mui/material/styles";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Container,
  Fade,
  Grid,
  SvgIcon,
  TextField,
} from "@mui/material";
// import { Keyboard } from "@capacitor/keyboard";
import { Device, OperatingSystem } from "@capacitor/device";
import { UserDivide } from "../logged-in/in-group/expense/Expense";
import { Keyboard } from "@capacitor/keyboard";
import { Capacitor } from "@capacitor/core";
import {
  PlusIcon,
  MinusIcon,
  PercentageIcon,
  MultipyIcon,
} from "../assets/svg/MathIcons";

interface KeyboardInterface {
  open?: boolean;
  setOpen?: (bool: boolean) => void;
  setFocussedElement?: (
    el: HTMLInputElement | HTMLTextAreaElement | undefined
  ) => void;
  focussedElement?: HTMLInputElement | HTMLTextAreaElement | undefined;
  setFocussedUser?: (user?: UserDivide) => void;
  focussedUser?: UserDivide;
  hidden: boolean;
  calculatorMode?: "insert" | "spend" | "dont_show";
  setCalculatorMode?: (type: "insert" | "spend" | "dont_show") => void;
  insertFunction?: any;
  setInsertFunction?: any;
  setDivideMoney?: (divideMoney: { money: bigint }) => void;
  divideMoney: { money: bigint };
}

const KeyboardCalculatorContext = createContext<KeyboardInterface>({
  hidden: true,
  divideMoney: { money: BigInt(0) },
});

const OperatorButton = styled(Button)(({ theme }) => ({
  // backgroundColor: theme.palette.grey[500],
  // color: theme.palette.primary.main,
  padding: 10,
  borderWidth: 1,
  borderColor: "black",
}));

enum Operations {
  "+",
  "-",
  "*",
  "/",
}

// This is an old makeStyles component. Now directly changed to classes
const classes = {
  container_normal: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "yellow",
  },
  container: {
    position: "absolute",
    color: "black",
    fill: "black",
    left: 0,
    bottom: "345px",
    zIndex: 5000000,
    width: "100%",
  },
  bar: {
    width: "100%",
    height: "100vh", //window?.visualViewport?.height ?? 100,
    top: 0,
    left: 0,
    position: "absolute",
  },

  icon: {
    // fontWeight: "bold",
    fontSize: "0.75rem",
  },
  hidden: {
    visibility: "hidden",
  },
  overflow_android: {
    position: "absolute",
    left: 0,
    top: 0,
    overflowY: "auto",
    maxHeight: "calc(100vh - 163px)",
  },
  overflow_ios: {
    position: "absolute",
    left: 0,
    top: 0,
    overflowY: "auto",
    maxHeight: "calc(100vh - 92px - 330px )",
  },
  fixedpos: {
    position: "absolute",
    height: "calc(100vh - 92px - 330px )",
    overflowY: "auto",
    left: 0,
    top: 0,
    width: "100%",
  },
  nativekeyboard_android: {
    position: "absolute",
    bottom: 63,
    width: "100%",
  },
  nativekeyboard_ios: {
    position: "absolute",
    bottom: 291,
    width: "100%",
  },
};

const useKeyboardL = () => {
  const [open, setOpen] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [insertFunction, setInsertFunction] = useState<any>(undefined);
  const [calculatorMode, setCalculatorMode] = useState<
    "insert" | "spend" | "dont_show"
  >("insert");
  const [divideMoney, setDivideMoney] = useState<{ money: bigint }>({
    money: BigInt(0),
  });
  const [viewport, setViewport] = useState<{
    maxHeight: number;
  }>({
    maxHeight: window?.visualViewport?.height ?? 100,
    // maxWidth: "100vw",
  });

  const [focussedUser, setFocussedUser] = useState<UserDivide | undefined>(
    undefined
  );
  const [focussedElement, setFocussedElement] = useState<
    HTMLInputElement | HTMLTextAreaElement | undefined
  >(undefined);
  const [os, setOS] = useState<OperatingSystem>("unknown");
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [keyboardHeightNative, setKeyboardHeightNative] = useState(0);
  const [platform, setPlatform] = useState<"ios" | "android" | "web">("web");

  const updateViewport = () => {
    // alert("Platform is: " + platform);
    if (
      os === "android" ||
      os === "ios" ||
      (os === "unknown" && Capacitor.isNativePlatform())
    ) {
      return;
    }

    const newHeight = window?.visualViewport?.height ?? 100;

    if (newHeight < viewport.maxHeight) {
      // alert("keyboard opened");
      setHidden(false);
      alert();
    } else if (newHeight === viewport.maxHeight) {
      // alert("keyboard closed");
      setHidden(true);
      alert();
      const el = document.getElementById("number-input");
      el?.removeEventListener("focus", () => {});
    }

    setTimeout(function () {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  };

  async function handleNativeKeyboard() {
    const { operatingSystem: os_l, platform } = await Device.getInfo();
    setOS(os_l);
    setPlatform(platform);

    if (
      (os_l === "ios" || os_l === "android") &&
      Capacitor.isNativePlatform()
    ) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        // setHidden(false);
        // alert("showing keyboard");
        // alert(JSON.stringify(info));
        setKeyboardHeightNative(info.keyboardHeight);
        setHidden(false);
      });

      Keyboard.addListener("keyboardDidHide", () => {
        setKeyboardHeightNative(0);
        setHidden(true);
      });
    } else {
      // alert("platform is ..." + JSON.stringify(os_l));
    }
  }

  useEffect(() => {
    handleNativeKeyboard();

    Device.getInfo().then((i) => {
      setOS(i.operatingSystem);
    });

    if (
      typeof window !== "undefined" &&
      typeof window?.visualViewport !== "undefined"
    ) {
      window?.visualViewport?.addEventListener("resize", updateViewport);
    }
  }, []);

  return {
    open,
    setOpen,
    setFocussedElement,
    focussedElement,
    setFocussedUser,
    focussedUser,
    hidden,
    setCalculatorMode,
    calculatorMode,
    insertFunction,
    setInsertFunction,
    setDivideMoney,
    divideMoney,
    os,
    keyboardHeight,
    keyboardHeightNative,
    platform,
  };
};

export default function useKeyboard() {
  const context = useContext(KeyboardCalculatorContext);
  if (!context) {
    throw Error("You forgot to wrap your component using KeyboardProvider");
  }

  return context;
}

export function KeyboardProvider({
  children,
  ...rest
}: {
  children: ReactNode;
}) {
  const hooks = useKeyboardL();

  const [viewport, setViewport] = useState<{
    maxHeight: number;
  }>({
    maxHeight: window?.visualViewport?.height ?? 100,
    // maxWidth: "100vw",
  });

  return (
    <KeyboardCalculatorContext.Provider value={hooks} {...rest}>
      <Container
        sx={
          hooks.hidden
            ? {}
            : hooks.os === "ios" && Capacitor.isNativePlatform()
            ? hooks.keyboardHeightNative > 0
              ? classes.fixedpos
              : {}
            : viewport.maxHeight > (window?.visualViewport?.height ?? 100)
            ? hooks.os === "ios"
              ? classes.overflow_ios
              : classes.overflow_android
            : {}
        }
      >
        {children}
      </Container>
      <Calculator os={hooks.os} platform={hooks.platform} />
    </KeyboardCalculatorContext.Provider>
  );
}

function Calculator({
  os,
  platform,
  ...rest
}: {
  os: OperatingSystem;
  platform: "web" | "android" | "ios";
}) {
  const [reload, setReload] = useState(false);
  const [viewport, setViewport] = useState<{
    maxHeight: number;
  }>({
    maxHeight: window?.visualViewport?.height ?? 100,
    // maxWidth: "100vw",
  });

  const [inputValue, setInputValue] = useState<string>("");
  const [prevFocus, setNewFocussedEl] = useState<Element | undefined>(
    undefined
  );
  const [isFaded, setIsFaded] = useState(true);

  const {
    open,
    focussedElement,
    focussedUser,
    hidden,
    calculatorMode,
    divideMoney,
  } = useKeyboard();

  if (focussedElement !== prevFocus) {
    setNewFocussedEl(focussedElement);

    focussedElement?.addEventListener("focus", (e) => {
      setInputValue(focussedElement.value);
    });

    focussedElement?.addEventListener("change", (e) => {
      setInputValue(focussedElement.value);
    });
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIsFaded(false);
      }, 500);
    } else {
      setIsFaded(true);
    }
  }, [open]);

  return (
    <Grid
      item
      {...rest}
      id={"keyboard-calculator"}
      // style={{ backgroundColor: "grey" }} //"#EFF1F0" }} // "#EFF1F0" }}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const el = document.getElementById("calculator-input");
        if (el) {
          el.focus();
        } else if (focussedElement) {
          focussedElement.addEventListener("focus", () => {});
          focussedElement.focus();
        }
        alert();

        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }, 150);

        e.preventDefault();
        e.stopPropagation();
      }}
      container
      sx={
        hidden
          ? classes.hidden
          : os === "ios" && platform !== "web"
          ? classes.nativekeyboard_ios
          : (os === "android" || os === "ios") && platform !== "web"
          ? classes.nativekeyboard_android
          : window?.visualViewport?.height ?? 100 < viewport.maxHeight
          ? classes.container
          : classes.hidden
      }
      alignItems="center"
      textAlign="center"
    >
      {open ? (
        <Grid container direction="row">
          <Fade in={!hidden} timeout={1000}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="none"
                hiddenLabel
                size="small"
                variant="filled"
                value={inputValue.toString()}
                inputProps={{
                  inputMode: "decimal",
                  style: {
                    borderRadius: 0,
                    borderWidth: 0,
                    borderBottomWidth: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: "grey",
                    textAlign: "center",
                  },
                }}
                onChange={(e) => {
                  // check NaN twice in a row.
                  let v = e.target.value;
                  v = v.replace(",", ".");

                  if (
                    v.length === 0 &&
                    focussedUser &&
                    calculatorMode === "insert"
                  ) {
                    setInputValue("");

                    focussedUser.manualInputMoney = BigInt(0);
                  }

                  if (
                    v.length === 0 &&
                    divideMoney &&
                    calculatorMode === "spend"
                  ) {
                    setInputValue("");

                    divideMoney.money = BigInt(0);
                  }

                  if (
                    v.length > 1 &&
                    isNaN(Number(v[v.length - 1])) &&
                    isNaN(Number(v[v.length - 2]))
                  ) {
                    return;
                  }

                  if (v[0] == "0") {
                    v = v.slice(1);
                  }

                  const inputValueNew = v;

                  setInputValue(inputValueNew);

                  try {
                    const num = Number(eval(inputValueNew));
                    if (!isNaN(num) && focussedUser) {
                      // alert("calculator mode: " + calculatorMode);
                      if (calculatorMode === "insert") {
                        focussedUser.manualInputMoney = BigInt(
                          Math.floor(num * 100)
                        );
                      } else {
                        // calculatorMode === "spend";
                        // TODO update the Divide Money
                        divideMoney.money = BigInt(Math.floor(num * 100));
                      }
                    }
                  } catch {
                    alert("it is not a number");
                  }

                  setReload(!reload);
                  alert();
                }}
                id="calculator-input"
              />
            </Grid>
          </Fade>

          {[
            { operation: Operations["+"], icon: PlusIcon },
            { operation: Operations["-"], icon: MinusIcon },
            { operation: Operations["/"], icon: PercentageIcon },
            { operation: Operations["*"], icon: MultipyIcon },
          ].map((operator, key) => {
            return (
              <Grid item key={key} xs={3} style={classes.icon}>
                <Fade in={!hidden} timeout={1000}>
                  <OperatorButton
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      let inputValueNew =
                        inputValue + Operations[operator.operation];

                      if (focussedUser) {
                        if (
                          inputValue.length < 1 &&
                          operator.operation === Operations["-"]
                        ) {
                          inputValueNew = BigInt(0) + "-";
                        } else if (inputValue.length < 1) {
                          return;
                        }

                        const lastO = inputValue.slice(-1);

                        if (["+", "-", "/", "*"].indexOf(lastO) === -1) {
                          // last operation is a number.

                          setInputValue(
                            inputValue + Operations[operator.operation]
                          );
                        } else if (
                          Object.keys(operator.operation).indexOf(lastO) === -1
                        ) {
                          setInputValue(
                            inputValue.slice(0, -1) +
                              Operations[operator.operation]
                          );
                        }
                        setReload(!reload);
                      }
                    }}
                  >
                    <SvgIcon style={classes.icon}>
                      {/* {PlusIcon} */}
                      {operator.icon}
                    </SvgIcon>
                  </OperatorButton>
                </Fade>
              </Grid>
            );
          })}
        </Grid>
      ) : null}
    </Grid>
  );
}
