import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ListItem,
  Stack,
  useTheme,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Title from "../../components/styled/Title";
import { Capacitor } from "@capacitor/core";
import { SubTitle } from "../../components/styled";
import { ReactNode, useEffect, useState } from "react";
import Spacing from "../../components/Spacing";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Device, OperatingSystem } from "@capacitor/device";

const statistics = require("../../assets/img/landingpage/statistics.png");
const no_receipt = require("../../assets/img/landingpage/no-receipt.png");
const split_costs = require("../../assets/img/landingpage/split-costs.png");
const signup_event = require("../../assets/img/landingpage/aanmelden.png");

export default function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [os, setOS] = useState<OperatingSystem>("unknown");

  const getOS = async () => {
    const os = await Device.getInfo();
    setOS(os.operatingSystem);
  };
  useEffect(() => {
    getOS();
  });

  const slideStyle = {
    height: "auto",
    maxWidth: 350,
  };

  if (Capacitor.isNativePlatform() || os === "ios" || os === "android") {
    return <Navigate replace to="/tutorial" />;
  }

  return (
    <Container>
      <Spacing margin={50} />

      <Grid item xs={12}>
        <CardContent>
          <Title>
            <div data-testid="landingscreen-title">
              De eetlijst voor jouw studentenhuis, club of groep
            </div>
          </Title>
          <SubTitle>
            Zie direct wie mee eet, kookt of boodschappen doet en verreken
            direct de kosten
          </SubTitle>
        </CardContent>
      </Grid>
      <Spacing variant="medium" />
      <Grid item xs={12} textAlign="center">
        <Link to="/register">
          <Button size="large" variant="contained">
            Aanmelden
          </Button>
        </Link>
      </Grid>

      <Spacing margin={75} />
      <Swiper
        slideToClickedSlide
        spaceBetween={10}
        slidesPerView={1.4}
        preloadImages={false}
        // width={400}
        // initialSlide={4}
        style={{
          padding: 10,
        }}
        centeredSlidesBounds
        centeredSlides
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
      >
        <SwiperSlide style={slideStyle}>
          <PreviewSlide
            title={"Geen gedoe met lijstjes in Whatsapp"}
            src={signup_event?.default}
            subTitle="Zie in één oogopslag wie mee eet"
          />
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <PreviewSlide
            src={no_receipt.default}
            title="Vergeet nooit meer je bonnetje in te voeren"
            subTitle="Wij sturen je een herinnering als er niks is
                    ingevoerd"
          ></PreviewSlide>
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <PreviewSlide
            src={split_costs.default}
            title="Verdeel de kosten met één knop"
            subTitle="Klik op 'Alle aanwezigen' en de app verdeelt de kosten
                    automatisch"
          />
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <PreviewSlide
            src={statistics.default}
            title="Bekijk statistieken"
            subTitle="Geen zin om te koken? Bekijk wie aan de beurt is om te
                    koken"
          />
        </SwiperSlide>
        <SwiperSlide style={slideStyle}>
          <PreviewSlide title="Meld je nu aan!" subTitle={""}>
            <Grid item xs={12} textAlign="center">
              <Button variant="contained" size="large">
                Aanmelden
              </Button>

              <Spacing variant="large" />
              <Title>Download de app!</Title>
              <Stack direction="row">
                <ListItem
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Grid item xs={12} textAlign="center">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={
                        "https://apps.apple.com/nl/app/samenn/id1589607263?l=nl"
                      }
                    >
                      {/* <Button>
                          <Grid xs={12} item>
                            <Typography variant="subtitle1">
                              Download on the
                            </Typography>
                          </Grid>
                          <Grid xs={12} item>
                            <Typography variant="subtitle1">
                              App Store
                            </Typography>
                          </Grid> */}
                      {/* </Button> */}
                      <img
                        alt="Apple Appstore icon"
                        style={{
                          height: "2rem",
                          color: "red",
                          fill: "red",
                        }}
                        src={
                          require("../../assets/img/landingpage/app-store.png")
                            .default
                        }
                      />
                    </a>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid item xs={12} textAlign="center">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={
                        "https://play.google.com/store/apps/details?id=nl.samenn.app"
                      }
                    >
                      <img
                        alt="Google Playstore icon"
                        style={{
                          height: "2rem",
                          color: "white",
                          fill: "white",
                        }}
                        src={
                          require("../../assets/img/landingpage/play-store.png")
                            .default
                        }
                      />
                    </a>
                  </Grid>
                </ListItem>
              </Stack>
            </Grid>
          </PreviewSlide>
        </SwiperSlide>
      </Swiper>
      <Grid
        item
        xs={12}
        textAlign="center"
        style={{
          paddingTop: 25,
          paddingBottom: 100,
        }}
      >
        <Title>Meld je nu aan!</Title>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            navigate("./register");
          }}
        >
          Aanmelden
        </Button>
      </Grid>
      <Box
        textAlign="center"
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Title>Of download de app</Title>

        <Stack direction="row">
          <ListItem
            style={{
              textAlign: "center",
            }}
          >
            <Grid item xs={12} textAlign="center">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={"https://apps.apple.com/nl/app/samenn/id1589607263?l=nl"}
              >
                <img
                  alt="Apple Appstore icon"
                  style={{
                    height: "2rem",
                    color: "red",
                    fill: "red",
                  }}
                  src={
                    require("../../assets/img/landingpage/app-store.png")
                      .default
                  }
                />
              </a>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid item xs={12} textAlign="center">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={
                  "https://play.google.com/store/apps/details?id=nl.samenn.app"
                }
              >
                <img
                  alt="Google Playstore icon"
                  style={{
                    height: "2rem",
                    color: "white",
                    fill: "white",
                  }}
                  src={
                    require("../../assets/img/landingpage/play-store.png")
                      .default
                  }
                />
              </a>
            </Grid>
          </ListItem>
        </Stack>
      </Box>
    </Container>
  );
}

function PreviewSlide({
  title,
  subTitle,
  src,
  children,
  ...rest
}: {
  title: ReactNode;
  subTitle: ReactNode;
  src?: string;
  children?: ReactNode;
}) {
  return (
    <Card
      style={{
        minHeight: "100%",
        display: "flex",
      }}
      // {...rest}
    >
      <Grid
        container
        direction="column"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          xs={2}
          textAlign={"center"}
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <CardContent
            style={{
              paddingBottom: 0,
            }}
          >
            <Title>{title}</Title>
          </CardContent>
        </Grid>
        <Grid
          item
          xs={src ? 1 : 9}
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <CardContent style={{ paddingTop: 0, marginTop: 0 }}>
            <SubTitle textAlign="center">{subTitle}</SubTitle>
            {children}
          </CardContent>
        </Grid>
        <Grid
          item
          xs={src ? 9 : 1}
          style={{
            maxHeight: "100%",
            overflow: "hidden",
            visibility: src ? "visible" : "hidden",
          }}
        >
          <CardContent>
            <img
              alt="Preview of app"
              src={src}
              style={{
                maxWidth: "100%",
                maxHeight: "80%",
              }}
            />
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
