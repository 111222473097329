import {
  AppBar,
  Container,
  Button,
  MobileStepper,
  useTheme,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Link,
  Stack,
  ListItem,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import TransferUsers from "./TransferUsers";
import TransferBalance from "./TransferBalance";
import { Logo, Title } from "../../components";
import WelcomeEetlijst from "./Welcome";
import FinishedSetup from "./FinishedSetup.eetlijst";
import HookInvestment from "./HookInvestment";
import InfoOverstap from "./InfoOverstap";
import { DeviceInfo } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import { useAppStatusQuery } from "../../redux/graphqlApi";
import CheckBalance from "./CheckBalance";
import CheckBalanceExplaination from "./CheckBalanceExplaination";
import InfoCheck from "./InfoCheck";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
const EetlijstLayout: React.FC = () => {
  const hostname = window.location;
  const [value, setValue] = useState("");
  const [device, setDevice] = useState<DeviceInfo | undefined>(undefined);
  const [active, setActive] = useState(false);
  const [updateIsDisabled, setUpdateIsDisabled] = useState(true);
  const { data: appStatus } = useAppStatusQuery({});
  const [swiper, setSwiper] = useState<Swiper>({});
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    // Als je dit in de developermode het wachtwoord hebt gevonden,
    // Whatsapp dan Ivo voor verdere instructies op 31613849341
    if (event.target.value === "welkom-bij-eetlijst-beta") {
      setActive(true);
    }
  };

  const createView = (title: string, view: JSX.Element) => {
    return {
      title,
      component: view,
    };
  };

  const views = [
    // <CheckStatis key={-1} />,
    createView("Welkom", <WelcomeEetlijst key={0} />),
    createView("Welkom", <InfoCheck key="checkinfo" />),
    createView("Welkom", <HookInvestment key="investment" />),
    createView("Welkom", <TransferUsers key={1} />),
    createView("Welkom", <CheckBalanceExplaination key={2} />),
    createView("Welkom", <CheckBalance key={2.1} />),
    createView("Welkom", <TransferBalance key={2.2} />),
    // <GroupOptions key={2} />,
    // <DefaultStatus key={3} />,
    // <AccountInfo key={3} />,
    createView("Welkom", <InfoOverstap key={3.2} />),
    createView("Welkom", <FinishedSetup key={4} />),
  ];
  const heightAppBar = 50;
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();

  if (appStatus && appStatus.beta_online === false) {
    return (
      <Container>
        <Grid textAlign={"center"}>
          <Title>Overstappen naar de beta is tijdelijk offline.</Title>
          <Typography>
            Momenteel is het niet mogelijk om je account over te zetten naar een
            nieuwe versie.
          </Typography>
          <Typography>
            Heb je een uitnodiging gekregen om de beta te testen? Kom dan later
            terug, of Whatsapp Ivo voor meer informatie.
          </Typography>
          <Typography>We gaan z.s.m. de beta hervatten.</Typography>
        </Grid>
      </Container>
    );
  }

  if (Capacitor.isNativePlatform()) {
    return (
      <>
        <AppBar
          style={{
            height: heightAppBar,
            textAlign: "center",
            backgroundColor: theme.palette.background.paper,
            padding: 5,
          }}
        >
          <Logo />
        </AppBar>
        <Container
          style={{
            minHeight: "calc(100vh - 48px)",
            paddingTop: heightAppBar + 5,
            paddingBottom: 55,
          }}
        >
          <Grid item textAlign="center">
            <Card>
              <CardContent>
                <Title>Login via de website</Title>
                <Typography>
                  Het overzetten van je oude account naar de nieuwe versie is
                  alleen mogelijk via de website.
                </Typography>
                <br />

                <Button
                  variant="contained"
                  LinkComponent={Link}
                  href="https://beta.eetlijst.nl/login"
                >
                  Ga naar de website
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </>
    );
  }

  if (!active && !hostname.host.includes("localhost")) {
    return (
      <>
        <AppBar
          style={{
            height: heightAppBar,
            textAlign: "center",
            backgroundColor: theme.palette.background.paper,
            padding: 5,
          }}
        >
          <Logo />
        </AppBar>
        <Container
          style={{
            minHeight: "calc(100vh - 48px)",
            paddingTop: heightAppBar + 5,
            paddingBottom: 55,
          }}
        >
          <Grid item textAlign="center">
            <Card>
              <CardContent>
                <Title>Welkom bij Eetlijst versie 5</Title>
                <Typography>
                  Leuk dat je de nieuwe Eetlijst wilt gebruiken!
                </Typography>
                <Typography>
                  Momenteel is de beta alleen beschikbaar via een uitnodiging.{" "}
                </Typography>
                <TextField label="uitnodigings-code" onChange={handleChange} />
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </>
    );
  }

  const PrevNextBtns = () => {
    if (!swiper) {
      return <>No Swiper yet</>;
    }

    return (
      <Grid container>
        <Grid xs={4} item>
          <Button
            disabled={activeSlideIndex === 0}
            onClick={() => {
              // @ts-expect-error
              swiper.slidePrev();
            }}
            variant="contained"
          >
            <KeyboardArrowLeft />
          </Button>
        </Grid>
        <Grid xs={4} item></Grid>
        <Grid xs={4} item textAlign="end">
          {activeSlideIndex + 1 !== views.length && (
            <Button
              variant="contained"
              onClick={() => {
                // @ts-expect-error
                swiper.slideNext();
              }}
            >
              <KeyboardArrowRight />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Container style={{ marginTop: 55 }}>
      <Swiper
        slideToClickedSlide
        spaceBetween={0}
        slidesPerView={1}
        preloadImages={false}
        onInit={(swiper) => {
          // @ts-expect-error
          setSwiper(swiper);
        }}
        // width={400}
        // initialSlide={4}
        style={{
          padding: 0,
          height: "calc(100vh - 150px)",
          paddingBottom: 0,
          overflowY: "scroll",
          maxHeight: "calc(100vh - 150px)",
        }}
        centeredSlidesBounds
        centeredSlides
        onSlideChange={(swiper) => {
          //@ts-expect-error
          setSwiper(swiper);
          setActiveSlideIndex(swiper.activeIndex);
        }}
        onSwiper={(swiper) => {
          //@ts-expect-error
          setSwiper(swiper);
        }}
      >
        {views.map((step, index) => {
          return (
            <SwiperSlide
              style={{
                height: "auto",
              }}
              key={"slide-" + index}
            >
              {/* <Grid container direction="column"> */}

              {/* <Box textAlign="center">
                <Title>{step.title}</Title>
              </Box> */}
              <Box
                style={{
                  height: "calc(100% - 95px)",
                }}
              >
                <Grid
                  container
                  textAlign="center"
                  alignItems="center"
                  justifyContent={"center"}
                  justifyItems={"center"}
                >
                  <Grid xs={12} item>
                    {step.component}
                  </Grid>
                </Grid>
                {/* <Center>{step.component}</Center> */}
              </Box>
              <PrevNextBtns />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
};

export default EetlijstLayout;
