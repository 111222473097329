import {
  AppBar,
  Container,
  Button,
  MobileStepper,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// import { Container } from './styles';

const EetlijstLayout: React.FC = () => {
  const heightAppBar = 50;
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  return <Outlet />;
};

export default EetlijstLayout;
