import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import Title from "../components/styled/Title";
import useAuth from "../hooks/auth/useAuth";
import Center from "../components/styled/Center";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
const ResetPassword: React.FC = () => {
  const { resetPassword } = useAuth();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successText, setSuccessText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // @ts-ignore
  const { user_id, reset_auth_id }: { user_id: string; reset_auth_id: string } =
    useParams();

  async function handleChangePassword() {
    setIsLoading(true);
    setError("");
    setSuccessText("");
    await resetPassword(user_id, reset_auth_id, password)
      .then(() => {
        setSuccessText("Wachtwoord is veranderd.");
        toast.success("Wachtwoord is veranderd.");
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={4} lg={3} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Title>Reset wachtwoord</Title>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography color="green" textAlign={"center"}>
                    {successText}
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography color="red" textAlign={"center"}>
                    {error}
                  </Typography>
                </Grid>
                {/* <FormGroup> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Wachtwoord"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    fullWidth
                    loading={isLoading}
                    variant="contained"
                    onClick={handleChangePassword}
                  >
                    {successText ? <CheckIcon /> : "Verander wachtwoord"}
                  </LoadingButton>
                  {successText && (
                    <Button
                      fullWidth
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login nu in
                    </Button>
                  )}
                </Grid>
              </Grid>
              {/* </FormGroup> */}
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
