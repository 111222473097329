import { Card, CardContent, TextField, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { ChangeEvent, useState } from "react";
import Title from "../../components/styled/Title";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/auth";

import LoadingButton from "@mui/lab/LoadingButton";

import { Navigate, useNavigate } from "react-router-dom";
import logger from "../../logger";
import { useCreateGroupMutation } from "../../redux/graphqlApi";
import { toast } from "react-toastify";

const CreateGroup: React.FC = () => {
  const [groupName, setGroupName] = useState<string>("");
  const [customError, setCustomError] = useState("");

  const [createNewGroup, { data: result, isLoading, error }] =
    useCreateGroupMutation({});

  const user = useSelector(selectCurrentUser);

  async function createGroup() {
    setCustomError("");
    if (groupName.length > 25) {
      return setCustomError("Groep naam is te lang. max 25 characters. ");
    }

    if (groupName.length < 1) {
      return setCustomError("Geef de groep een naam. ");
    }

    createNewGroup({ name: groupName, user_id: user.user_id })
      .unwrap()
      .catch((e) => {
        toast.error(`Error: ${e}`);
      });
  }

  if (result?.id) {
    return <Navigate to={`./../group/${result.id}/settings`} />;
  }

  return (
    <Container>
      <Card>
        <CardContent>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Title>Nieuwe groep</Title>
              </Grid>
              <Grid item xs={12}>
                <Typography color="error">{JSON.stringify(error)}</Typography>
                <Typography color="error">{customError}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Naam"
                  variant="outlined"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGroupName(e.target.value.toString())
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={isLoading}
                  onClick={async () => {
                    createGroup();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Maak aan
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>

          {/* <CardActionArea> */}

          {/* </CardActionArea> */}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CreateGroup;
