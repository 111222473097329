import {
  List,
  ListItem,
  Typography,
  Grid,
  ListItemText,
  Divider,
  TextField,
  Button,
  ClickAwayListener,
} from "@mui/material";
import React, { useState } from "react";
import { Title } from "../../components";

import { useDispatch, useSelector } from "react-redux";
import {
  changeUser,
  EetlijstUser,
  selectChooseNewBalance,
  selectLogin,
  selectUsersEetlijst,
} from "../../redux/auth_eetlijst";
import TextBold from "../../components/Bold";

const CheckBalance: React.FC = () => {
  const { pass, login } = useSelector(selectLogin);

  const choosenNewBalance = useSelector(selectChooseNewBalance);
  const users = useSelector(selectUsersEetlijst);

  const activeUsers = users.filter((u) => u.active);
  const toMuch = activeUsers.reduce((a, b) => {
    return a + BigInt(b.balance);
  }, BigInt(0));

  let pp = BigInt(0);
  let residue = BigInt(0);

  if (activeUsers.length !== 0) {
    pp = toMuch / BigInt(activeUsers.length);
    residue = toMuch % BigInt(activeUsers.length);
  }

  return (
    <>
      <Title>Controleer balans</Title>

      <Typography>
        Controleer de nieuwe balans met de gegevens in de oude Eetlijst. Ga naar
        &quot;kostenbeheer&quot; door te klikken op het euro teken. Bekijk
        voor&nbsp;
        <TextBold>elke</TextBold> huisgenoot of de balans klopt.
      </Typography>
      <br />

      <Grid
        container
        style={{
          minHeight: 200,
          maxHeight: 1000,
          height: "100%",
        }}
        spacing={1}
      >
        <Grid
          xs={4}
          item
          style={{
            minHeight: 200,
            maxHeight: 1000,
            height: "100%",
          }}
        >
          <List>
            <Divider />
            <ListItem>
              <Grid container>
                <Grid xs={6} item>
                  <ListItemText>
                    <Typography>Naam</Typography>
                  </ListItemText>
                </Grid>
                <Grid xs={6} item>
                  <ListItemText disableTypography>
                    <Typography
                      style={{
                        opacity: `${!choosenNewBalance ? 1 : 0.5}`,
                      }}
                    >
                      Nieuwe balans
                    </Typography>
                  </ListItemText>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />

            {users
              .filter((u) => u.active)
              .map((user, index) => {
                return <UserBalance key={index} user={user} />;
              })}
          </List>
        </Grid>
        <Grid
          xs={8}
          item
          style={{
            minHeight: 400,
            height: "100% ",
          }}
        >
          <iframe
            title="Oude Eetlijst: versie 4"
            width="100%"
            style={{
              minHeight: 400,
              height: "100%",
            }}
            src={`https://www.eetlijst.nl/login.php?login=${login}&pass=${pass}`}
          ></iframe>
        </Grid>
      </Grid>

      {/* <Dialog open={open} onClose={toggleDialog}>
        <DialogContent>
          <Title>Hoe controleer ik dit?</Title>
          <Typography>
            Ga naar "kostenbeheer" door te klikken op het euro teken. Bekijk
            daarna voor <TextBold>elke</TextBold> huisgenoot of de balans klopt.
          </Typography>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

function UserBalance({ user }: { user: EetlijstUser }) {
  const [change, setChange] = useState(false);
  const [value, setValue] = useState<any>(user.balance / 100);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const save = () => {
    setError("");

    const num = Math.floor(Math.round(value * 100));

    if (!isNaN(num) && typeof num === "number") {
      dispatch(
        changeUser({
          ...user,
          balance: num,
        })
      );

      return true;
    }
    setError(`${num} is niet een getal`);
    return false;
  };

  return (
    <ListItem>
      <Grid xs={4} item>
        <ListItemText>
          <Typography>{user.name}</Typography>
        </ListItemText>
      </Grid>
      <Grid xs={6} item>
        <ListItemText disableTypography>
          {!change ? (
            <Typography color={user.balance > 0 ? "success" : "error"}>
              {Number(user.balance / 100).toFixed(2)}
              &nbsp;euro
            </Typography>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                if (change) {
                  const saved = save();
                  if (saved) {
                    setChange(false);
                  }
                } else {
                  setChange(true);
                }
              }}
            >
              <TextField
                inputProps={{ inputMode: "decimal" }}
                type="number"
                value={value}
                onChange={(e) => {
                  console.log("this is the target value: ", e.target.value);
                  setValue(e.target.value);
                }}
              />
            </ClickAwayListener>
          )}
        </ListItemText>
      </Grid>
      <Grid xs={1} item>
        <Typography color="red">{error}</Typography>
        <Button
          onClick={() => {
            if (change) {
              const saved = save();
              if (saved) {
                setChange(false);
              }
            } else {
              setChange(true);
            }
          }}
        >
          {change ? "Opslaan" : "Wijzig"}
        </Button>
      </Grid>
    </ListItem>
    // <Divider />
  );
}

export default CheckBalance;
