// From YT: https://www.youtube.com/watch?v=A5YiqaQbsyI

import Bugsnag from "@bugsnag/js";
import { format, createLogger, transports, LoggerOptions } from "winston";

const { printf, combine, colorize, timestamp } = format;

const logFormat = printf(({ level, message, stack, timestamp }) => {
  const log = `[${level}] [${timestamp}] ${
    stack || message
  } [state]: ${localStorage.getItem("persist:root")} ${
    process.env.REACT_APP_LOG_HOST
  }:${Number(process.env.REACT_APP_LOG_PORT)}`;

  if (process.env.NODE_ENV === "development" && level === "error") {
    console.error("The log is", log);
  }

  if (level.indexOf("error") > -1 && process.env.NODE_ENV === "production") {
    console.error("Error is send to bugsnag");
    Bugsnag.notify(new Error(log));
  }
  return log;
});

const transports_array: any[] = [new transports.Console()];
if (process.env.NODE_ENV === "production") {
  transports_array.push(
    new transports.Http({
      level: "error",
      host: process.env.REACT_APP_LOG_HOST,
      port: Number(process.env.REACT_APP_LOG_PORT),
      path: "api/v1/log",
    })
  );
}

/**
 * This is uesd during development.
 */
const logger = createLogger({
  level: "error", // TODO set this variable in the environment
  format: combine(
    colorize(),
    timestamp({ format: "YYYY-MM-DD HH:mm:ss" }),
    format.errors({ stack: true }),
    logFormat
  ),
  transports: transports_array,
});

export default logger;
