import { useTheme, Fab, CircularProgress } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import { ReactNode } from "react";
function StyledFab({
  icon,
  color,
  disabled,
  isLoading,
}: {
  icon: ReactNode;
  color: string;
  disabled?: boolean;
  isLoading?: boolean;
}) {
  const theme = useTheme();
  return (
    <div>
      <Fab
        disabled={disabled}
        style={{
          fill: disabled
            ? `${theme.palette.text.disabled}`
            : `${theme.palette.background.default}`,
          padding: 10,
          zIndex: 1,
          backgroundColor: `${color}`,
        }}
      >
        {icon}
        {isLoading ? (
          <div
            style={{
              zIndex: 1,
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "green",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                zIndex: 1,
              }}
            />
          </div>
        ) : null}
      </Fab>
    </div>
  );
}

export function EatOnlyFab(props: any) {
  const theme = useTheme();
  return (
    <StyledFab
      icon={
        <CheckRoundedIcon
          {...props}
          style={{ width: "100%", height: "100%" }}
        />
      }
      color={theme.palette.success.main}
      {...props}
    />
  );
}

export function GroceriesFab(props: any) {
  const theme = useTheme();
  return (
    <StyledFab
      icon={
        <LocalGroceryStoreRoundedIcon
          {...props}
          style={{ width: "100%", height: "100%" }}
        />
      }
      color={"#8ECAE6"} // theme.palette.primary.main
      {...props}
    />
  );
}

export function UnknownFab(props: any) {
  const theme = useTheme();
  return (
    <StyledFab
      icon={
        <QuestionMarkRoundedIcon
          {...props}
          style={{ width: "100%", height: "100%" }}
        />
      }
      {...props}
      color={theme.palette.grey[500]}
    />
  );
}

export function NotAttendFab(props: any) {
  const theme = useTheme();
  return (
    <StyledFab
      icon={
        <ClearRoundedIcon
          {...props}
          style={{ width: "100%", height: "100%" }}
        />
      }
      color={theme.palette.error.main} // theme.palette.grey[500]
      {...props}
    />
  );
}

export function CookFab({ ...rest }) {
  const theme = useTheme();
  return (
    <StyledFab
      icon={
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          x="0px"
          y="0px"
        >
          <g transform="translate(0,100) scale(0.1,-0.1)" stroke="none">
            <path
              d="M390 962 c-19 -9 -53 -35 -75 -58 -34 -35 -48 -42 -94 -47 -92 -11
              -167 -64 -206 -144 -22 -45 -20 -154 5 -207 23 -50 71 -96 129 -122 l41 -18 0
              -141 0 -141 33 -32 33 -32 240 0 240 0 32 33 32 33 0 140 0 140 41 18 c58 26
              106 72 129 122 25 53 27 162 5 207 -39 80 -114 133 -206 144 -46 5 -60 12 -94
              47 -22 23 -56 49 -77 59 -48 23 -161 23 -208 -1z m151 -96 c45 -19 73 -52 88
              -103 6 -24 15 -43 19 -43 3 0 25 7 49 16 57 22 107 12 149 -30 53 -53 55 -127
              5 -185 -21 -24 -85 -51 -122 -51 l-29 0 0 -70 0 -70 -205 0 -205 0 0 70 0 70
              -29 0 c-16 0 -50 9 -75 20 -36 15 -50 29 -67 63 -28 58 -20 108 25 153 42 42
              92 52 149 30 24 -9 45 -16 49 -16 3 0 11 20 18 44 13 47 44 83 88 102 36 17
              54 16 93 0z m157 -693 l-3 -48 -200 0 -200 0 -3 48 -3 47 206 0 206 0 -3 -47z"
            />
          </g>
        </svg>
      }
      color={theme.palette.secondary.main}
      {...rest}
    />
  );
}
