import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  TextareaAutosize,
  DialogContentText,
  DialogActions,
  Divider,
  ListItemText,

  useTheme,
  Box,
  FormControl,
  ClickAwayListener,
  Typography,
  Stack,
  Chip,
  Avatar,
} from "@mui/material";
import PersonRemoveAlt1RoundedIcon from "@mui/icons-material/PersonRemoveAlt1Rounded";
import React, { useContext, useEffect, useRef, useState } from "react";
import Title from "../../../components/styled/Title";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { useSelector } from "react-redux";
import logger from "../../../logger";
import { Share } from "@capacitor/share";
import { RootState } from "../../../redux/store";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Textarea from "@mui/joy/Textarea";
import { CssVarsProvider } from "@mui/joy/styles";
import {
  useGetGroupQuery,
  useUpdateGroupMutation,
  useUpdateUserGroupRelationMutation,
} from "../../../redux/graphqlApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { HeaderContext } from "../../../layout/InsideGroupLayout";
import { LoadingButton } from "@mui/lab";
import Spacing from "../../../components/Spacing";
import { SubTitle } from "../../../components/styled";
import dayjs from "dayjs";

const Settings: React.FC = () => {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { data: group } = useGetGroupQuery({ group_id });

  const currentUser = useSelector(
    (state: RootState) => state.user.current_user
  );
  const [open, setOpenDialog] = useState(false);
  const [copySuccess, setCopySuccess] = useState("Kopieer link");
  const textAreaRef = useRef(undefined);

  const [updateUserRelation, { error, data }] =
    useUpdateUserGroupRelationMutation({});
  const { setTitle } = useContext(HeaderContext);

  const users = group?.users;

  const toggleUserRelation = async (user_id: string, active: boolean) => {
    user_id = user_id.trim();

    await updateUserRelation({ user_id, group_id, active })
      .unwrap()

      .catch((e) => {
        console.error("There was an error ", e);
      });
  };

  const invite_link = `https://beta.eetlijst.nl/invite/${group_id}/${group?.invite_uuid}`;

  function copyToClipboard(e: any) {
    if (!textAreaRef?.current) {
      console.warn("Text area ref does not exist.");
      return;
    }

    //@ts-ignore
    textAreaRef?.current?.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Gekopieerd!");
  }

  function handleInvite() {
    if (!group || !group.invite_uuid) {
      window.location.reload();
      return;
    }

    console.info(
      window.location.origin + "/invite/" + group_id + "/" + group?.invite_uuid
    );
  }

  function handleClose() {
    if (!group || !group.invite_uuid) {
      logger.error("Invite link incorrect.. " + JSON.stringify(group));
      return;
    }
    setOpenDialog(false);
  }

  useEffect(() => {
    setTitle("Groep informatie");
  });

  function handleWhatsappShare() {
    if (!group || !group.invite_uuid) {
      logger.error("[invite_link] is not correct");
      toast.error("Kon geen invite link aanmaken.");
      window.location.reload();
      return;
    } else {
      if (invite_link.indexOf("undefined") === -1) {
        window.open(
          `https://wa.me/?text=Wordt lid van onze Eetlijst groep via: ${invite_link.toString()}`,
          "_blank"
        );
      } else {
        window.location.reload();
      }
    }
  }

  const share = async () => {
    try {
      await Share.share({
        title: "Deel de Eetlijst groeps link",
        text: "Wordt nu lid van onze Eetlijst groep via deze link",
        url: `${invite_link.toString()}`,
        dialogTitle: "Share with buddies",
      });
    } catch (e) {
      setOpenDialog(true);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box textAlign="center" justifyContent={"center"} alignItems="center">
          <Box
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Avatar
              style={{
                width: "5rem",
                height: "5rem",
              }}
              variant="rounded"
            />
          </Box>
          <Title
            onClick={() => {
              console.info(group);
            }}
          >
            {group?.name}
          </Title>
          <Typography variant="subtitle2">
            Sinds {dayjs(group?.created_at).locale("nl").format("MMMM YYYY")}
          </Typography>
          <Typography variant="subtitle2">
            Totaal {group?.users.length} leden
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} textAlign="center">
        <Card onClick={handleWhatsappShare}>
          <CardContent style={{ padding: 5 }}>
            <WhatsAppIcon
              style={{
                // backgroundColor: theme.palette.success.main,
                // padding: 7,
                fontSize: "1rem",
                // borderRadius: 5, //,theme.shape.borderRadius,
                // color: theme.palette.primary.contrastText,
              }}
            />
            <Typography variant="subtitle2">Uitnodigen</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4} textAlign="center">
        <Card onClick={share}>
          <CardContent style={{ padding: 5 }}>
            <ShareRoundedIcon
              style={{
                // backgroundColor: theme.palette.success.main,
                // padding: 7,
                fontSize: "1rem",
                // borderRadius: 5, //,theme.shape.borderRadius,
                // color: theme.palette.primary.contrastText,
              }}
            />
            <Typography variant="subtitle2">Uitnodigen</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4} textAlign="center">
        <Card
          onClick={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
              "_blank"
            );
          }}
        >
          <CardContent style={{ padding: 5 }}>
            <ChatBubbleOutlineIcon
              style={{
                // backgroundColor: theme.palette.error.main,
                // padding: 7,
                fontSize: "1rem",
                // borderRadius: 5, //,theme.shape.borderRadius,
                // color: theme.palette.primary.contrastText,
              }}
            />
            <Typography variant="subtitle2">Feedback</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <ChangeDescription />

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Deel uitnodiging"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <TextareaAutosize
                    // @ts-ignore
                    ref={textAreaRef}
                    aria-label="empty textarea"
                    placeholder="Empty"
                    value={invite_link}
                    style={{ width: 200 }}
                    readOnly
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={copyToClipboard}
                >
                  {copySuccess}
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={12}>
        <Card>
          <CardContent>
            <List>
              <Divider />

              <ListItemButton onClick={handleWhatsappShare}>
                <ListItemIcon>
                  <WhatsAppIcon
                    style={{
                      backgroundColor: theme.palette.success.main,
                      padding: 7,
                      fontSize: "1rem",
                      borderRadius: 5, //,theme.shape.borderRadius,
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Deel via Whatsapp</ListItemText>
                <ListItemIcon
                  style={{
                    direction: "rtl",
                  }}
                >
                  <ArrowForwardIosRounded
                    style={{
                      fontSize: "1rem",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>

              <Divider />

              <ListItemButton onClick={share}>
                <ListItemIcon>
                  <ShareRoundedIcon
                    style={{
                      backgroundColor: theme.palette.success.main,
                      padding: 7,
                      fontSize: "1rem",
                      borderRadius: 5, //,theme.shape.borderRadius,
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Deel uitnodigingslink</ListItemText>
                <ListItemIcon
                  style={{
                    direction: "rtl",
                  }}
                >
                  <ArrowForwardIosRounded
                    style={{
                      fontSize: "1rem",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
              <Divider />

              <ListItemButton
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
                    "_blank"
                  );
                }}
              >
                <ListItemIcon>
                  <ChatBubbleOutlineIcon
                    style={{
                      backgroundColor: theme.palette.error.main,
                      padding: 7,
                      fontSize: "1rem",
                      borderRadius: 5, //,theme.shape.borderRadius,
                      color: theme.palette.primary.contrastText,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>Geef feedback</ListItemText>
                <ListItemIcon
                  style={{
                    direction: "rtl",
                  }}
                >
                  <ArrowForwardIosRounded
                    style={{
                      fontSize: "1rem",
                    }}
                  />
                </ListItemIcon>
              </ListItemButton>
              <Divider />
            </List>
            <Spacing />
          </CardContent>
        </Card>
      </Grid> */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <SubTitle>{users?.length} leden</SubTitle>
            <List>
              <Divider />
              {users
                ?.sort((a, b) => Number(b.active) - Number(a.active))
                .map((u) => {
                  return (
                    <div key={u.name}>
                      <ListItem>
                        <Grid container>
                          <Grid item xs={8}>
                            <ListItemText
                              primary={
                                <>
                                  {u.name}&nbsp;
                                  {u.user_id === currentUser?.user_id && (
                                    <Chip
                                      size="small"
                                      color="success"
                                      label="Jij"
                                    />
                                  )}
                                </>
                              }
                              secondary={
                                u.active
                                  ? u.email ?? "Geen email"
                                  : "Geen lid meer"
                              }
                            />
                          </Grid>
                          <Grid item xs={4} textAlign="end">
                            <Button
                              disabled={!u.active}
                              variant="contained"
                              color="warning"
                              endIcon={<PersonRemoveAlt1RoundedIcon />}
                              onClick={() => {
                                if (Number(u.balance) !== 0) {
                                  toast.error(
                                    `Saldo moet op 0 staan om de gebruiker te verwijderen. Huidige balans is ${
                                      Number(u.balance) / 100
                                    }`
                                  );
                                } else {
                                  if (
                                    window.confirm(
                                      `Weet je zeker dat je ${u.name} wilt verwijderen?`
                                    )
                                  ) {
                                    toggleUserRelation(u.user_id, !u.active);
                                  }
                                }
                              }}
                            >
                              {!u.active
                                ? "Geen lid meer"
                                : u.user_id === currentUser?.user_id
                                ? "Verlaat"
                                : "Verwijder"}
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

function ChangeDescription() {
  //@ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { data: group } = useGetGroupQuery({ group_id });
  const [changeDescription, setChangeDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateGroupMutation, result] = useUpdateGroupMutation();
  const [newDescription, setNewDescription] = useState(
    group?.description ?? ""
  );
  const theme = useTheme();

  const updateDescription = async () => {
    setIsLoading(true);
    if (!group) {
      setIsLoading(false);
      return;
    }

    const response = await updateGroupMutation({
      id: group.id,
      beta: group.beta,
      name: group.name,
      description: newDescription,
    }).catch((e) => {
      toast.error(`Error ${e}`);
    });

    setChangeDescription(false);
    setIsLoading(false);
  };

  if (changeDescription) {
    return (
      <ListItem>
        <FormControl fullWidth>
          <ClickAwayListener onClickAway={updateDescription}>
            <CssVarsProvider>
              <Textarea
                style={{
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.mode === "dark" ? "white" : "black",
                }}
                onChange={(v) => {
                  setNewDescription(v.target.value);
                }}
                value={newDescription}
                minRows={3}
              />
            </CssVarsProvider>
          </ClickAwayListener>
          <Spacing />
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={updateDescription}
            >
              Opslaan
            </LoadingButton>
            <Button
              onClick={() => {
                setChangeDescription(false);
              }}
              variant="outlined"
            >
              Annuleer
            </Button>
          </Stack>
        </FormControl>
      </ListItem>
    );
  }

  return (
    <>
      {group?.description ? (
        <Stack
          style={{
            width: "100%",
          }}
        >
          <ClickAwayListener onClickAway={updateDescription}>
            <div>
              <CssVarsProvider>
                <Textarea
                  style={{
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.mode === "dark" ? "white" : "black",
                    borderWidth: "px",
                    borderColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[300],
                  }}
                  onChange={(v) => {
                    setNewDescription(v.target.value);
                  }}
                  value={newDescription}
                  minRows={3}
                />
              </CssVarsProvider>
            </div>
          </ClickAwayListener>
          {/* <CssVarsProvider>
            <Textarea
              disabled
              style={{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.mode === "dark" ? "white" : "black",
                borderColor: "rgba(0, 0, 0, 0.1)",
              }}
              value={group.description}
              maxRows={8}
            />
          </CssVarsProvider>

          <Typography
            variant="subtitle1"
            color="primary"
            onClick={() => {
              setChangeDescription(true);
            }}
          >
            Wijzig
          </Typography> */}
        </Stack>
      ) : (
        <Box textAlign="center">
          <Typography
            variant="subtitle1"
            color="primary"
            onClick={() => {
              setChangeDescription(true);
            }}
          >
            Maak groepsbeschrijving aan
          </Typography>
        </Box>
      )}
    </>
  );
}

export default Settings;
