import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, createMigrate } from "redux-persist";
import thunk from "redux-thunk";

// import groupReducer from "./groups";
import authReducer from "./auth";
import { notificationReducer } from "./notification";
import { migrations } from "./migrations";
import { eetlijstApi, eetlijstSlice } from "./auth_eetlijst";
import { graphqlApi } from "./graphqlApi";
import { restApi } from "./nodeApi";
import { rtkQueryErrorLogger } from "./middleware";
// import networkReducer from "./network";

export const resetStore = createAction("RESET_STORE");
export const updateStore = createAction("UPDATE_STORE");

const MIGRATION_DEBUG = false;
const persistConfig = {
  key: "root",
  storage,
  version: 9,
  debug: MIGRATION_DEBUG,
  blacklist: [
    [eetlijstSlice.name].toString(),
    [eetlijstApi.reducerPath].toString(),
    [restApi.reducerPath].toString(),
    [graphqlApi.reducerPath].toString(),
  ],
  // @ts-expect-error
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const reducers = combineReducers({
  user: authReducer,
  notification: notificationReducer,
  [eetlijstSlice.name]: eetlijstSlice.reducer,
  [eetlijstApi.reducerPath]: eetlijstApi.reducer,
  [restApi.reducerPath]: restApi.reducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (resetStore.match(action)) {
    storage.removeItem("persist:root");
    return reducers(undefined, action);
  }

  return reducers(state, action);
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

export const persistedStore = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware(),
      // {
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      // }
      rtkQueryErrorLogger,
      thunk,
      graphqlApi.middleware,
      eetlijstApi.middleware,
      restApi.middleware,
    ];
  },
  //   [
  //   thunk,
  //   eetlijstApi.middleware,
  //   restApi.middleware,
  //   graphqlApi.middleware,
  // ],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootPersistedState = ReturnType<typeof persistedStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
