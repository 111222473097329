import { Haptics, ImpactStyle } from "@capacitor/haptics";

export default function vibrate(style?: ImpactStyle) {
  if (!style) {
    style = ImpactStyle.Medium;
  }

  Haptics.impact({ style }).catch((e) => {
    console.error(e);
  });
}
