import { useEffect } from "react";
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import { useNavigate } from "react-router-dom";

export default function AppUrlListener() {
  const navigate = useNavigate();

  useEffect(() => {
    CapApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split(".nl").pop();

      console.log("This is the slug: ", slug);

      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return <div />;
}
