import { Button, Card, CardContent, Grid, List, ListItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo, Title } from "../components";
import Center from "../components/styled/Center";

// import { Container } from './styles';

const LoginSplit: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent="center"
      style={{ height: "calc(100vh - 75px)" }}
    >
      <Grid item xs={10} sm={8} md={4} lg={3} textAlign="center">
        <Center>
          <Card>
            <CardContent>
              <Title>Login</Title>

              <List>
                <ListItem>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      navigate("../eetlijst");
                    }}
                  >
                    Login met&nbsp;
                    <img
                      style={{ height: "1.5rem" }}
                      src={require("../assets/img/eetlijst.png").default}
                    />
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      navigate("../");
                    }}
                  >
                    Login met&nbsp;{" "}
                    <Logo style={{ color: "white" }} height={"1rem"} />
                  </Button>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Center>
      </Grid>
    </Grid>
  );
};

export default LoginSplit;
