import {
  AppBar,
  Container,
  Grid,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Box,
  Stack,
  Avatar,
  useTheme,
  TextField,
  Dialog,
  DialogContent,
} from "@mui/material";
import EmojiPeopleRoundedIcon from "@mui/icons-material/EmojiPeopleRounded";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { GroupProvider } from "../logged-in/in-group/useGroup";
import Title from "../components/styled/Title";
import GroupBottomNav from "../logged-in/in-group/GroupBottomNav";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { useDispatch, useSelector } from "react-redux";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { createContext, useContext, useState } from "react";
import OfflineBar from "../components/OfflineBar";
import { Loading } from "../components";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import {
  useCurrentUserInfoQuery,
  useFetchGroupsQuery,
  useGetGroupQuery,
} from "../redux/graphqlApi";
import { selectCurrentUser, switchUser } from "../redux/auth";
import Center from "../components/styled/Center";
import { RouterLink } from "../components/RouterLink";
import WhatsApp from "@mui/icons-material/WhatsApp";
import { GroupUser } from "../redux/interfaces/interfaces";
import { LoadingButton } from "@mui/lab";
import { SubTitle } from "../components/styled";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function InGroupRouter() {
  // @ts-ignore
  const params: { group_id: string } = useParams();
  const navigate = useNavigate();

  const {
    data: currentGroup,
    error: errorGroupQuery,
    isLoading: isLoadingGroup,
  } = useGetGroupQuery({
    group_id: params.group_id,
  });

  if (errorGroupQuery) {
    console.error(errorGroupQuery);
  }

  if (isLoadingGroup) {
    return (
      <>
        <Center>
          <Loading />
        </Center>
      </>
    );
  }

  if (!currentGroup) {
    return (
      <>
        <Typography>Kon de groep niet vinden...</Typography>

        <Button
          onClick={() => {
            navigate("/groups");
          }}
        >
          Terug naar overzicht
        </Button>
      </>
    );
  }

  return (
    <GroupProvider>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
    </GroupProvider>
  );
}

interface HeaderInterface {
  title: string;
  setTitle: (title: string) => void;
}
// @ts-expect-error
export const HeaderContext = createContext<HeaderInterface>(null);

const HeaderWrapper = ({ children }: { children: any }) => {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const { data: group } = useGetGroupQuery({ group_id });

  const [title, setTitle] = useState(group?.name ?? "");

  return (
    <HeaderContext.Provider value={{ title: title ?? group?.name, setTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};

function Header() {
  const { title } = useContext(HeaderContext);
  const [open, setOpen] = useState(false);

  // const modal = useRef<HTMLIonModalElement>(null);
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const { data: user } = useCurrentUserInfoQuery({
    user_id: currentUser.user_id,
  });
  const { data: groups } = useFetchGroupsQuery({
    user_id: currentUser.user_id,
  });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const theme = useTheme();
  const navigator = useNavigate();

  return (
    <Grid container>
      {/* <Grid xs={2} item component="nav" aria-label="mailbox folders"> */}
      <Drawer
        // variant="permanent"
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        style={{
          zIndex: 99999,
        }}
        PaperProps={{
          style: {
            borderRadius: 0,

            // width: "16.66%",
          },
        }}
      >
        <List>
          <div onClick={() => toggleDrawer()}>
            <RouterLink
              icon={<Avatar variant="rounded" />}
              to={`/account`}
              text={"Account"}

              // match={group.id}
            />
          </div>

          {/* <ListItem>Groepen</ListItem>
          <Divider /> */}
          {groups?.map((group, index) => {
            return (
              <div onClick={() => toggleDrawer()} key={`group-${index}`}>
                <RouterLink
                  icon={<PeopleAltRoundedIcon />}
                  to={`/groups/group/${group.id}/show`}
                  text={group.name}
                  match={group.id}
                />
              </div>
            );
          })}
          <div onClick={() => toggleDrawer()}>
            <RouterLink
              icon={<AddRoundedIcon />}
              to={"/groups/new/"}
              text={"Nieuwe groep"}

              // match={group.id}
            />
          </div>
          <ListItemButton
            onClick={() => {
              window.open(
                `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
                "_blank"
              );
            }}
            style={{
              backgroundColor: theme.palette.success.light,
            }}
          >
            <ListItemText>Feedback</ListItemText>
            <ListItemIcon>
              <WhatsApp />
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Drawer>
      {/* </Grid> */}
      {/* <Grid xs={10} item> */}
      <AppBar
        position="sticky"
        style={{
          marginBottom: 10,
          marginRight: 50,
          marginTop: -1,
        }}
      >
        <div
          style={{
            backgroundColor: theme.palette.background.paper,
            padding: "0.5rem 1rem 0.5rem 1rem",
          }}
        >
          <Grid container>
            <Grid item xs={1}>
              <IconButton onClick={() => toggleDrawer()}>
                <MenuRoundedIcon />
              </IconButton>
            </Grid>

            <Grid item xs={9} textAlign="start">
              <Title
                style={{
                  position: "relative",
                  top: 0,
                  height: "100%",
                  alignItems: "start",
                  verticalAlign: "start",
                  marginLeft: 20,
                  // left: 20,
                  paddingBottom: 8,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    textAlign: "start",
                    color: theme.palette.primary.main,
                    top: "calc(50% - 1.25rem)",
                    fontSize: "1.5rem",
                    alignItems: "start",
                    justifyContent: "start",
                    fontStyle: "bold",
                    // height: "100%",
                    // top: 0,

                    width: "100%",
                  }}
                >
                  {title}
                  {/* {group_name?.charAt(0)?.toUpperCase() + group_name?.slice(1)} */}
                </div>
              </Title>
            </Grid>
            <Grid xs={2} item textAlign="end">
              <Stack
                direction="row"
                spacing={1}
                textAlign="end"
                justifyContent={"end"}
              >
                {/* <Grid
                item
                xs={1}
                textAlign="center"
                style={{
                  height: "50%",
                  width: "50%",
                }}
              > */}
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.open(
                      `https://api.whatsapp.com/send?phone=31613849341&text=Hoi Ivo, ik had een feedback/idee voor Eetlijst: `,
                      "_blank"
                    );
                  }}
                >
                  {/* <ChatBubbleOutlineIcon /> */}
                {/* Feedback */}
                {/* </Button> */}
                <Typography
                  style={{
                    fontSize: "0.5rem",
                  }}
                >
                  {/* Geef feedback */}
                </Typography>
                {/* </Grid>

              <Grid item xs={1} textAlign="end"> */}
                {user?.origin === "eetlijst.nl" && (
                  <IconButton
                    // expand="block"
                    id="open-modal"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <SwitchAccountIcon />
                  </IconButton>
                )}

                <IconButton onClick={() => navigator("./settings")}>
                  <SettingsOutlinedIcon />
                </IconButton>
                {/* </Grid> */}
              </Stack>
            </Grid>
          </Grid>
        </div>
        <OfflineBar />
        <Dialog
          style={{
            zIndex: 99999,
          }}
          open={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <DialogContent
            style={
              {
                // width: "100%",
              }
            }
          >
            <Grid xs={12} item>
              <Title>Wissel van account</Title>

              <SubTitle>Ingelogd als: &quot;{currentUser.name}&quot;</SubTitle>
              <SwitchAccoutModal setShowModal={setShowModal} />
            </Grid>
          </DialogContent>
        </Dialog>

        {/* <IonModal
          isOpen={showModal}
          ref={modal}
          trigger="open-modal"
          canDismiss={true} // ={canDismiss}
          // presentingElement={presentingElement}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Wissel van account</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  onIonFocus={() => {
                    dismiss();
                  }}
                >
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-text-wrap" {...{ for: "terms" }}>
            <SwitchAccoutModal />
          </IonContent> */}
        {/* </IonModal> */}
      </AppBar>
      {/* PADDING is for the bottom navigation. */}
      <Container style={{ paddingBottom: 75 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
          <Outlet />
        </LocalizationProvider>
      </Container>

      <GroupBottomNav />
      {/* </Grid> */}
    </Grid>
  );
}

function SwitchAccoutModal({
  setShowModal,
}: {
  setShowModal: (b: boolean) => void;
}) {
  const { group_id } = useParams();
  const { data: currentGroup } = useGetGroupQuery({
    group_id: group_id ?? "invalid-group-id",
  });
  const currentUser = useSelector(selectCurrentUser);

  // const [activeStep, setActiveStep] = useState(0);
  let activeStep = 0;
  const [selectedUser, setSelectedUser] = useState<GroupUser | undefined>(
    undefined
  );

  if (selectedUser) {
    activeStep = 1;
  }

  const PickUser = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <List>
          {currentGroup?.users
            ?.filter((u) => u.active && u.user_id !== currentUser.user_id)
            .map((user, index) => {
              return (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    console.log("Clicking");
                    setSelectedUser(user);
                  }}
                >
                  <IconButton>
                    <EmojiPeopleRoundedIcon />
                  </IconButton>

                  <Box textAlign="center">
                    <ListItemText>{user.name}</ListItemText>
                  </Box>
                </ListItemButton>
              );
            })}
        </List>
      </Box>
    );
  };

  const EnterPass = () => {
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const dispatch = useDispatch();
    const { group_id } = useParams();
    const onSuccess = () => {
      setIsLoading(false);
      toast.success("Je bent gewisseld van account!");
      setShowModal(false);
    };

    return (
      <List>
        <ListItem>
          <Typography color={"red"}>{error}</Typography>
        </ListItem>
        <ListItem>{selectedUser?.name}</ListItem>
        <ListItem>
          <TextField
            label="Wachtwoord"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </ListItem>
        <Stack direction={"row"}>
          <Typography color="error">{error?.message}</Typography>
          <LoadingButton
            loading={isLoading}
            onClick={() => {
              setIsLoading(true);
              dispatch(
                switchUser({
                  group_id: group_id ?? "invalid-group-id",
                  user_id: selectedUser?.user_id ?? "invalid-user-id",
                  password,
                  onError: (e) => {
                    setIsLoading(false);
                    console.log("There was an error switching account");
                    setError(e);
                  },

                  onSuccess,
                })
              );
            }}
          >
            Inloggen
          </LoadingButton>
          <Button
            onClick={() => {
              setSelectedUser(undefined);
            }}
          >
            Annuleer
          </Button>
        </Stack>
      </List>
    );
  };

  const steps = [<PickUser key="pick-user" />, <EnterPass key="pass" />];
  return (
    <Box sx={{ width: "100%" }}>
      {/* <div> */}
      {/* Selected user: {JSON.stringify(selectedUser)} */}
      {steps[activeStep]}
      {/* {JSON.stringify(activeStep)} */}
    </Box>
  );
}
export default InGroupRouter;
