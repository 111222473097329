import { Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading, Title } from "../../components";
import logger from "../../logger";
import { RootState } from "../../redux/store";

// import { Container } from './styles';

const AcceptedInvite: React.FC = () => {
  const navigate = useNavigate();
  const { group_id, invite_id } = useParams();
  const user = useSelector((state: RootState) => state.user.current_user);
  const [error, setError] = useState<{} | undefined>();

  const user_id = user?.user_id;
  useEffect(() => {
    acceptInvite();
  }, []);

  // create a function to add up to numbers

  const acceptInvite = async () => {
    const fetchResponse = await fetch(
      `${process.env.REACT_APP_GRAPHQL_REST}/join_group`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }, // process.env.TOKEN}, // [process.env.HEADER]
        body: JSON.stringify({
          group_id,
          user_id,
          invite_id,
        }),
      }
    )
      .then(async (res) => {
        return await res.json();
      })
      .catch((e) => {
        toast.error("Kon de groep gegevens niet laden. " + e);
        logger.error(`[joinGroup] kon groep niet laden 
        group_id:${group_id} user_id:${user_id} invite_id:${invite_id} URL:${"https://eet-schema.hasura.app/api/rest/join_group"} ${JSON.stringify(
          e
        )}`);
      });

    if (fetchResponse?.joinGroup?.accepted) {
      if (!user_id) {
        navigate(
          "../../groups/group/" + fetchResponse.joinGroup.group_id + "/show"
        );
      } else {
        navigate(
          "../../../groups/group/" + fetchResponse.joinGroup.group_id + "/show"
        );
      }
    } else {
      if (
        fetchResponse?.joinGroup?.error &&
        (JSON.stringify(fetchResponse?.joinGroup?.error).includes(
          "Foreign key violation."
        ) ||
          JSON.stringify(fetchResponse?.joinGroup?.error).includes(
            "Uniqueness violation. duplicate key"
          ))
      ) {
        // window.location.reload();
        toast.info("Je bent al lid van deze groep.");
        navigate(`../../../groups/group/${group_id}/show`);
        return;
      }

      toast.error(
        "Er is iets mis gegaan: " + JSON.stringify(fetchResponse.joinGroup)
      );

      if (!fetchResponse.joinGroup) {
        setError(fetchResponse.error);
      } else {
        setError(fetchResponse.joinGroup);
      }
    }
  };

  if (error) {
    return (
      <Card>
        <CardContent>
          <Title>Fout bij het toetreden.</Title>
          <Typography>
            Weet je niet wat deze fout betekent? Neem contact op via
            info@eetlijst.nl samen en kopieër de error.
          </Typography>

          <Typography color="red">
            Error: {error ? JSON.stringify(error) : null}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Title>Je wordt toegelaten tot de groep.</Title>
        <Typography>1 moment gedult..</Typography>
        <Loading />
      </CardContent>
    </Card>
  );
};

export default AcceptedInvite;
