import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OptionBar = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0.5rem",
  zIndex: 10000,
  bottom: 63,

  left: 0,
  position: "fixed",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[10],
}));

export default OptionBar;
