import moment from "moment";
import { Moment } from "moment";
import { Event } from "./interfaces/interfaces";

export const filterTodaysEvents = (events: Event[], day?: Moment): Event[] => {
  if (!day) {
    day = moment();
  }

  events = events?.filter((e) => {
    return day?.isSame(e.start_date, "day");
  });

  if (!events) {
    return [];
  }

  return events;
};
