import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Fragment, useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CssVarsProvider } from "@mui/joy/styles";

import { Expense } from "../../../redux/interfaces/interfaces";
import moment from "moment";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import { HeaderContext } from "../../../layout/InsideGroupLayout";

import NoExpense from "../../../assets/svg/undraw_no_expense";
import { selectCurrentUser } from "../../../redux/auth";
import {
  useGetExpensesQuery,
  useGetGroupQuery,
} from "../../../redux/graphqlApi";
import { Loading } from "../../../components";
import Spacing from "../../../components/Spacing";
import dayjs from "dayjs";

export default function HistroyWrapper() {
  // @ts-ignore
  const { event_id }: { event_id: string } = useParams();
  const { setTitle } = useContext(HeaderContext);

  useEffect(() => {
    setTitle("Geld");
  }, []);

  if (event_id) {
    return <Histroy event_id={event_id} />;
  }

  return (
    <div style={{ paddingBottom: 48 }}>
      <Card>
        <CardContent>
          <Histroy />
        </CardContent>
      </Card>
    </div>
  );
}

export function Histroy({ event_id }: { event_id?: string }) {
  // @ts-expect-error
  const { group_id }: { group_id: string } = useParams();
  const theme = useTheme();

  const { data: group } = useGetGroupQuery({ group_id });
  const { data: expenses = [], isLoading } = useGetExpensesQuery({ group_id });
  const removedUsersIds =
    group?.users
      .filter((u) => !u.active)
      .map((u) => {
        return u.user_id;
      }) ?? [];
  const [activeFilterOption, setActiveFilterOption] = useState<
    "editDate" | "enterDate"
  >("editDate");
  const currentUser = useSelector(selectCurrentUser);

  const expensesFiltered = [...expenses].sort((a, b) => {
    if (activeFilterOption === "editDate") {
      return (
        // @ts-ignore
        moment(b.updated_at).format("YYYYMMDDhhmm") -
        // @ts-ignore
        moment(a.updated_at).format("YYYYMMDDhhmm")
      );
    } else {
      return (
        // @ts-ignore
        moment(b.payed_at).format("YYYYMMDDhhmm") -
        // @ts-ignore
        moment(a.payed_at).format("YYYYMMDDhhmm")
      );
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <CssVarsProvider>
        <Tabs
          aria-label="Basic tabs"
          defaultValue={0}
          style={{
            backgroundColor: theme.palette.background.paper,
            color: theme.typography.body1.color,
          }}
        >
          {/* <IonSegment value={activeFilterOption}>
            <IonSegmentButton value="editDate">
              <IonLabel>Aangepaste datum eerst</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="enterDate">
              <IonLabel>Invoer datum eerst</IonLabel>
            </IonSegmentButton>
          </IonSegment> */}

          <TabList
            style={{
              backgroundColor: theme.palette.background.paper,
              color: theme.typography.body1.color,
            }}
          >
            <Tab
              style={{
                backgroundColor: theme.palette.background.default,
                color: theme.typography.body1.color,
                border: `${theme.palette.primary.main} ${
                  activeFilterOption === "enterDate" ? "1px" : "2px"
                }  solid`,
              }}
              onClick={() => {
                setActiveFilterOption("editDate");
              }}
            >
              Aangepaste datum eerst
            </Tab>
            <Tab
              style={{
                backgroundColor: theme.palette.background.paper,
                color: theme.typography.body1.color,
                border: `${theme.palette.primary.main}  ${
                  activeFilterOption === "enterDate" ? "2px" : "1px"
                } solid`,
              }}
              onClick={() => {
                setActiveFilterOption("enterDate");
              }}
            >
              Invoer datum eerst
            </Tab>
          </TabList>
        </Tabs>
      </CssVarsProvider>
      <Box textAlign="center">
        <Spacing />
      </Box>
      <Divider />
      <List style={{ paddingTop: 0, marginTop: 0 }}>
        {expensesFiltered.map((e: Expense, index) => {
          let relative_path = "";

          if (event_id) {
            relative_path = "../../../expenses/";
          }

          const payedBy = group?.users.find((u) => {
            return u.user_id === e.payed_by;
          });

          let payedByUser = BigInt(0);

          const dist = e.expense_distribution.find(
            (u) => u.user_id === currentUser.user_id
          );

          if (dist) {
            payedByUser = payedByUser + BigInt(dist.payed_amount);
          }

          return (
            <Fragment key={"expense-history-" + index}>
              <Link
                to={
                  e.deleted ||
                  e.expense_distribution.some((u) =>
                    removedUsersIds.includes(u.user_id)
                  )
                    ? ""
                    : relative_path + `../expenses/expense/${e.id}/show`
                }
              >
                <ListItemButton
                  disabled={
                    e.deleted ||
                    e.expense_distribution.some((u) =>
                      removedUsersIds.includes(u.user_id)
                    )
                  }
                  style={{
                    textDecoration: e.deleted ? "line-through" : "",
                    opacity: 1,
                    padding: 0,
                  }}
                >
                  <ListItem key={index} disablePadding>
                    <ListItemText
                      primary={
                        <Grid container>
                          <Grid item xs={8}>
                            <Stack>
                              <Box>{e.description}</Box>
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  style={{ color: "gray" }}
                                >
                                  {moment(e.payed_at).format("DD MMM")} door{" "}
                                  {payedBy?.name}
                                </Typography>
                                {!dayjs(e.updated_at.toString()).isSame(
                                  dayjs(e.payed_at.toString())
                                ) ? (
                                  <Typography
                                    variant="subtitle2"
                                    style={{ color: "gray" }}
                                  >
                                    {e.deleted
                                      ? "Verwijderd door:"
                                      : "Aangepast door:"}{" "}
                                    {e.updated_by_user_name}&nbsp;op&nbsp;
                                    {moment(e.updated_at)
                                      .locale("nl")
                                      .format("DD MMM")}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Stack>
                          </Grid>
                          <Grid item xs={4} textAlign="end">
                            <Stack>
                              <Box>
                                €&nbsp;
                                {Number(Number(e.payed_amount) / 100).toFixed(
                                  2
                                )}
                              </Box>
                              <Box>
                                {payedByUser !== BigInt(0) && (
                                  <Chip
                                    size="small"
                                    label={`€ ${Number(payedByUser) / 100}`}
                                    color={
                                      payedByUser > 0 ? "success" : "error"
                                    }
                                  />
                                )}
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                </ListItemButton>
              </Link>
              <Divider component="li" />
            </Fragment>
          );
        })}
        {expenses.length <= 0 ? (
          <ListItem>
            <Box>
              <NoExpense style={{ width: "45%", maxWidth: 200 }} />
            </Box>
            <Typography>Nog geen uitgaven</Typography>
          </ListItem>
        ) : null}
      </List>
    </>
  );
}
