import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

export default function TextBold({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      style={{
        display: "inline",
        fontWeight: "bold",
      }}
    >
      {children}
    </Box>
  );
}
